import { compileCustomTsWorkerPath$, initEditorAction$, resetEditorAction$ } from '../../store/editor/editor';
import { Language } from '../../store/editor/types';

export const initEditor = (language: Language = 'ts'): void => {
    let customTsWorkerSrc = compileCustomTsWorkerPath$.value;
    if (!customTsWorkerSrc) {
        customTsWorkerSrc = document.getElementById('customTsWorker')?.getAttribute('src') ?? null;
        if (customTsWorkerSrc) {
            compileCustomTsWorkerPath$.next(customTsWorkerSrc);
        }
    }

    initEditorAction$.next({ customWorkerPath: customTsWorkerSrc ?? undefined, language });
};

export const resetEditor = (): void => resetEditorAction$.next();
