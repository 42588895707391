import { getMyConnections as getMyConnectionsRpc } from '@avst-stitch/repository-lib/lib';
import { createConnection as createConnectionRpc } from '@avst-stitch/repository-lib/lib';
import { saveConnection as saveConnectionRpc } from '@avst-stitch/repository-lib/lib';
import { deleteConnection as deleteConnectionRpc } from '@avst-stitch/repository-lib/lib';
import { saveConnectionInstance as saveConnectionInstanceRpc } from '@avst-stitch/repository-lib/lib';
import { Response as UserConnections } from '@avst-stitch/repository-lib/lib/rpcs/getMyConnections';
import { Response as CreatedConnection } from '@avst-stitch/repository-lib/lib/rpcs/createConnection';
import { Response as SavedConnection } from '@avst-stitch/repository-lib/lib/rpcs/saveConnection';
import { Response as ConnectionDeletion } from '@avst-stitch/repository-lib/lib/rpcs/deleteConnection';
import { repositoryInvoker } from '../utils/repository';
export type { UserConnections, CreatedConnection };

export const getLoggedInUserConnections = async (
    connectionTypeUid?: string,
    source?: 'connectors'
): Promise<UserConnections> => {
    return await getMyConnectionsRpc(repositoryInvoker, {
        connectionTypeUid,
        source,
    });
};

export const createConnection = async (
    connectionTypeUid: string,
    name?: string,
    apiHandlerTypeUid?: string,
    eventListenerTypeUid?: string
): Promise<CreatedConnection> => {
    return await createConnectionRpc(repositoryInvoker, {
        connectionTypeUid,
        name,
        apiHandlerTypeUid,
        eventListenerTypeUid,
    });
};

export const saveConnection = async (uid: string, name?: string): Promise<SavedConnection> => {
    return await saveConnectionRpc(repositoryInvoker, {
        uid,
        name,
    });
};

export const saveConnectionInstance = async (
    connectionUid: string,
    instanceUrl: string,
    cloudId?: string
): Promise<SavedConnection> => {
    return await saveConnectionInstanceRpc(repositoryInvoker, {
        connectionUid,
        instanceUrl,
        cloudId,
    });
};

export const deleteConnection = async (uid: string, ignoreWarnings?: boolean): Promise<ConnectionDeletion> => {
    return await deleteConnectionRpc(repositoryInvoker, {
        uid,
        ignoreWarnings,
    });
};
