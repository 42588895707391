import { configTopic$, stitchSession$ } from '../store/config';
import { publishLocalFeedbackEventAction$ } from '../store/feedback';
import { getFetchOptions } from './fetch';

export const isAccountVerified = async (): Promise<boolean> => {
    const verifyAccountUrl = configTopic$.value.trigger?.verifyAccount;
    if (!verifyAccountUrl) {
        throw new Error('No verify account url configured in meta');
    }

    const fetchOptions = getFetchOptions({ Authorization: stitchSession$.value?.jwt ?? '' });
    const response = await fetch(verifyAccountUrl, fetchOptions);
    if (!response.ok) {
        console.error('Error while checking user verification status');

        const message = await response.text();
        if (message) {
            console.error('Error encountered while checking if user account is verified: ' + message);
            throw new Error(message);
        } else {
            throw Error(`Unexpected error while checking if user account is verified: ${response.status}`);
        }
    }

    const respBody = await response.text();
    if (respBody) {
        return JSON.parse(respBody)?.accountVerified ?? false;
    } else {
        return false;
    }
};

export const updateUserConsent = async (termsAccepted: boolean): Promise<void> => {
    const consentTermsUrl = configTopic$.value.trigger?.consentTerms;
    if (!consentTermsUrl) {
        throw new Error('No consent terms url configured in meta');
    }

    const fetchOptions = getFetchOptions({ Authorization: stitchSession$.value?.jwt ?? '' }, { termsAccepted });
    await fetch(consentTermsUrl, fetchOptions);
};

export const resetAccountPassword = async (userEmail: string): Promise<void> => {
    const auth0BaseUrl = `https://${configTopic$.value.auth0?.domain}`;
    try {
        const fetchOptions = {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
                client_id: configTopic$.value.auth0.clientId,
                email: userEmail,
                connection: 'Username-Password-Authentication',
            }),
        };
        const response = await fetch(`${auth0BaseUrl}/dbconnections/change_password/`, fetchOptions);
        const respBody = await response.text();
        if (response.ok && respBody) {
            publishLocalFeedbackEventAction$.next({
                level: 'SUCCESS',
                message: respBody,
            });
        } else {
            throw new Error(`Error ocurred while password reset. Invalid status code: ${response.status}.`);
        }
    } catch (err) {
        publishLocalFeedbackEventAction$.next({
            level: 'ERROR',
            message: 'Something went wrong with password reset.',
        });
        throw new Error((err as Error).message);
    }
};
