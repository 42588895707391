import { BehaviorSubject, map, Subject } from 'rxjs';
import { getEventListenerSetupDetails } from '../../../data/event-listener';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';

export const gitlabEventListenerSetupDialogOpenAction$ = monitor(
    'gitlabEventListenerSetupDialogOpenAction$',
    new Subject<GitlabEventListenerSetupBaseDetails>()
);
export const gitlabEventListenerSetupDialogCloseAction$ = monitor(
    'gitlabEventListenerSetupDialogCloseAction$',
    new Subject<void>()
);

export const gitlabEventListenerSetupDialogLoading$ = monitor(
    'gitlabEventListenerSetupDialogLoading$',
    new BehaviorSubject(false)
);
export const gitlabEventListenerSetupDialogOpen$ = monitor(
    'gitlabEventListenerSetupDialogOpen$',
    new BehaviorSubject(false)
);
export const gitlabEventListenerSetupDialogDetails$ = monitor(
    'gitlabEventListenerSetupDialogDetails$',
    new BehaviorSubject<GitlabEventListenerSetupDetails | undefined>(undefined)
);

export type GitlabEventListenerSetupBaseDetails = {
    uid: string;
    environmentUid: string;
    webhookUrl: string;
    eventTypeName: string;
};

export type GitlabEventListenerSetupDetails = {
    gitlabUrl?: string;
} & GitlabEventListenerSetupBaseDetails;

gitlabEventListenerSetupDialogOpenAction$
    .pipe(
        map(async (details) => {
            gitlabEventListenerSetupDialogLoading$.next(true);
            gitlabEventListenerSetupDialogOpen$.next(true);

            try {
                const { connectionBaseUrl } = await getEventListenerSetupDetails(details.uid, details.environmentUid);

                gitlabEventListenerSetupDialogDetails$.next({
                    ...details,
                    gitlabUrl: connectionBaseUrl,
                });
                gitlabEventListenerSetupDialogLoading$.next(false);
            } catch (e) {
                console.error('Error while loading Event Listener setup instructions', e);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to load Event Listener setup instructions, please try again, if the issue persists please contact support.`,
                    toastOptions: {
                        autoClose: false,
                    },
                });

                gitlabEventListenerSetupDialogCloseAction$.next();
            }
        })
    )
    .subscribe();

gitlabEventListenerSetupDialogCloseAction$.subscribe(() => {
    gitlabEventListenerSetupDialogOpen$.next(false);
    gitlabEventListenerSetupDialogDetails$.next(undefined);
});
