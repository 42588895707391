import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const SlackIcon: React.FC<SvgIconProps> = (props) => {
    const [st0, st1, st2, st3] =
        props.color || props.htmlColor || props.style?.color
            ? [{}, {}, {}, {}]
            : [{ fill: '#E01E5A' }, { fill: '#36C5F0' }, { fill: '#2EB67D' }, { fill: '#ECB22E' }];

    return (
        <SvgIcon
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="70 70 130 130"
            className="productIcon"
            aria-label="Slack"
        >
            <g>
                <g>
                    <path
                        {...st0}
                        d="M99.4,151.2c0,7.1-5.8,12.9-12.9,12.9c-7.1,0-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9h12.9V151.2z"
                    />
                    <path
                        {...st0}
                        d="M105.9,151.2c0-7.1,5.8-12.9,12.9-12.9s12.9,5.8,12.9,12.9v32.3c0,7.1-5.8,12.9-12.9,12.9s-12.9-5.8-12.9-12.9V151.2z"
                    />
                </g>
                <g>
                    <path
                        {...st1}
                        d="M118.8,99.4c-7.1,0-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9s12.9,5.8,12.9,12.9v12.9H118.8z"
                    />
                    <path
                        {...st1}
                        d="M118.8,105.9c7.1,0,12.9,5.8,12.9,12.9s-5.8,12.9-12.9,12.9H86.5c-7.1,0-12.9-5.8-12.9-12.9s5.8-12.9,12.9-12.9H118.8z"
                    />
                </g>
                <g>
                    <path
                        {...st2}
                        d="M170.6,118.8c0-7.1,5.8-12.9,12.9-12.9c7.1,0,12.9,5.8,12.9,12.9s-5.8,12.9-12.9,12.9h-12.9V118.8z"
                    />
                    <path
                        {...st2}
                        d="M164.1,118.8c0,7.1-5.8,12.9-12.9,12.9c-7.1,0-12.9-5.8-12.9-12.9V86.5c0-7.1,5.8-12.9,12.9-12.9c7.1,0,12.9,5.8,12.9,12.9V118.8z"
                    />
                </g>

                <g>
                    <path
                        {...st3}
                        d="M151.2,170.6c7.1,0,12.9,5.8,12.9,12.9c0,7.1-5.8,12.9-12.9,12.9c-7.1,0-12.9-5.8-12.9-12.9v-12.9H151.2z"
                    />
                    <path
                        {...st3}
                        d="M151.2,164.1c-7.1,0-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9h32.3c7.1,0,12.9,5.8,12.9,12.9c0,7.1-5.8,12.9-12.9,12.9H151.2z"
                    />
                </g>
            </g>
        </SvgIcon>
    );
};
