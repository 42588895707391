import { BehaviorSubject, map, Subject } from 'rxjs';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import { loggedInUserConnections$ } from '../../connections';

export const netSuiteEventListenerSetupDialogOpenAction$ = monitor(
    'netSuiteEventListenerSetupDialogOpenAction$',
    new Subject<NetSuiteEventListenerSetupBaseDetails>()
);
export const netSuiteEventListenerSetupDialogCloseAction$ = monitor(
    'netSuiteEventListenerSetupDialogCloseAction$',
    new Subject<void>()
);

export const netSuiteEventListenerSetupDialogLoading$ = monitor(
    'netSuiteEventListenerSetupDialogLoading$',
    new BehaviorSubject(false)
);
export const netSuiteEventListenerSetupDialogOpen$ = monitor(
    'netSuiteEventListenerSetupDialogOpen$',
    new BehaviorSubject(false)
);
export const netSuiteEventListenerSetupDialogDetails$ = monitor(
    'netSuiteEventListenerSetupDialogDetails$',
    new BehaviorSubject<NetSuiteEventListenerSetupDetails | undefined>(undefined)
);

export type NetSuiteEventListenerSetupBaseDetails = {
    uid: string;
    environmentUid: string;
    webhookUrl: string;
    connectionUid?: string;
};

export type NetSuiteEventListenerSetupDetails = {
    accountId?: string;
} & NetSuiteEventListenerSetupBaseDetails;

netSuiteEventListenerSetupDialogOpenAction$
    .pipe(
        map(async (details) => {
            netSuiteEventListenerSetupDialogOpen$.next(true);
            netSuiteEventListenerSetupDialogLoading$.next(true);
            try {
                const accountId = loggedInUserConnections$.value.find(
                    (connection) => connection.uid === details.connectionUid
                )?.netSuiteAccountId;
                if (accountId) {
                    netSuiteEventListenerSetupDialogDetails$.next({
                        ...details,
                        accountId,
                    });
                } else {
                    netSuiteEventListenerSetupDialogDetails$.next(details);
                }

                netSuiteEventListenerSetupDialogLoading$.next(false);
            } catch (e) {
                console.error('Error while loading Event Listener setup instructions', e);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to load Event Listener setup instructions, please try again, if the issue persists please contact support.`,
                    toastOptions: {
                        autoClose: false,
                    },
                });

                netSuiteEventListenerSetupDialogCloseAction$.next();
            }
        })
    )
    .subscribe();

netSuiteEventListenerSetupDialogCloseAction$.subscribe(() => {
    netSuiteEventListenerSetupDialogOpen$.next(false);
    netSuiteEventListenerSetupDialogDetails$.next(undefined);
});
