import { getPreDeploymentData as getPreDeploymentDataRpc } from '@avst-stitch/repository-lib/lib';
import { createDeployment as createDeploymentRpc } from '@avst-stitch/repository-lib/lib';
import { saveEnvironmentDeploymentPointers as saveEnvironmentDeploymentPointersRpc } from '@avst-stitch/repository-lib/lib';
import { getWorkspaceDeployments as getWorkspaceDeploymentRpc } from '@avst-stitch/repository-lib/lib';
import { Response as PreDeploymentData } from '@avst-stitch/repository-lib/lib/rpcs/getPreDeploymentData';
import { Response as CreatedDeployment } from '@avst-stitch/repository-lib/lib/rpcs/createDeployment';
import { Response as WorkspaceDeployments } from '@avst-stitch/repository-lib/lib/rpcs/getWorkspaceDeployments';
import { Request as SaveEnvironmentDeploymentPointersRequest } from '@avst-stitch/repository-lib/lib/rpcs/saveEnvironmentDeploymentPointers';
import { repositoryInvoker } from '../utils/repository';
export type { PreDeploymentData, CreatedDeployment, WorkspaceDeployments };

export const getPreDeploymentData = async (workspaceUid: string): Promise<PreDeploymentData> => {
    return await getPreDeploymentDataRpc(repositoryInvoker, {
        workspaceUid,
    });
};

export const createDeployment = async (
    workspaceUid: string,
    environmentUids: string[],
    version?: string,
    label?: string
): Promise<CreatedDeployment> => {
    return await createDeploymentRpc(repositoryInvoker, {
        workspaceUid,
        environmentUids,
        version,
        label,
    });
};

export const saveEnvironmentDeploymentPointers = async (
    workspaceUid: string,
    mappings: SaveEnvironmentDeploymentPointersRequest['mappings']
): Promise<void> => {
    return await saveEnvironmentDeploymentPointersRpc(repositoryInvoker, {
        workspaceUid,
        mappings,
    });
};

export const getWorkspaceDeployments = async (workspaceUid: string): Promise<WorkspaceDeployments> => {
    return await getWorkspaceDeploymentRpc(repositoryInvoker, {
        workspaceUid,
    });
};
