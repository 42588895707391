import { pingWorkspace } from '../data/workspace';
import { selectedReadOnlyTemplate$ } from '../store/templates';
import { loggedInUserDetails$ } from '../store/user';
import { selectedWorkspace$, selectedWorkspaceUid$ } from '../store/workspace';

export const startWorkspacePinger = (): void => {
    setInterval(() => {
        const workspaceUid = selectedWorkspaceUid$.value;

        if (workspaceUid) {
            pingWorkspace(
                workspaceUid,
                !selectedWorkspace$.value?.locked &&
                    !selectedReadOnlyTemplate$.value &&
                    !loggedInUserDetails$.value?.impersonating
            )
                .then((response) => {
                    if (response.lockAcquiredBy) {
                        const selectedWorkspace = selectedWorkspace$.value;
                        if (selectedWorkspace) {
                            selectedWorkspace$.next({ ...selectedWorkspace, locked: response.lockAcquiredBy });
                        }
                    }
                })
                .catch((e) => console.error('Failed to ping workspace', e));
        }
    }, 10000);
};
