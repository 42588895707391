import { createWorkspaceEnvironment as createWorkspaceEnvironmentRpc } from '@avst-stitch/repository-lib/lib';
import { switchWorkspaceEnvironment as switchWorkspaceEnvironmentRpc } from '@avst-stitch/repository-lib/lib';
import { renameEnvironment as renameEnvironmentRpc } from '@avst-stitch/repository-lib/lib';
import { deleteEnvironment as deleteEnvironmentRpc } from '@avst-stitch/repository-lib/lib';
import { Response as CreatedEnvironment } from '@avst-stitch/repository-lib/lib/rpcs/createWorkspaceEnvironment';
import { repositoryInvoker } from '../utils/repository';
export type { CreatedEnvironment };

export const createWorkspaceEnvironment = async (workspaceUid: string, name: string): Promise<CreatedEnvironment> => {
    return await createWorkspaceEnvironmentRpc(repositoryInvoker, {
        workspaceUid,
        name,
    });
};

export const switchWorkspaceEnvironment = async (workspaceUid: string, environmentUid: string): Promise<void> => {
    return await switchWorkspaceEnvironmentRpc(repositoryInvoker, {
        workspaceUid,
        environmentUid,
    });
};

export const renameEnvironment = async (uid: string, name: string): Promise<void> => {
    return await renameEnvironmentRpc(repositoryInvoker, {
        uid,
        name,
    });
};

export const deleteEnvironment = async (uid: string): Promise<void> => {
    return await deleteEnvironmentRpc(repositoryInvoker, {
        uid,
    });
};
