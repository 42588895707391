import { APP } from '@avst-stitch/repository-lib/constants';
import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    connectionCreatedAction$,
    ConnectionDetails,
    connectionCreatedFirstTimeAction$,
    connectionCreatedFirstTime$,
} from '..';
import { createConnection, saveConnection } from '../../../data/connection';
import { InformativeError } from '../../../utils/repository';
import { configTopic$ } from '../../config';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import {
    initiate,
    loadLoggedInUserConnectionsWhenEventIsEmitted,
    saveConnectionAllowed,
    openConsentWindow,
    handleManageConnectionError,
} from '../utils';
import { promptQuestion } from '../../confirm';
import { AYSYWTSAETCWHAI } from '../../../i18n';

export interface MondayConnectionDetails extends ConnectionDetails {
    instanceUrl?: string;
}

interface MondayAppSetupSaveProps {
    instanceUrl: string;
}

export const mondayManageConnectionOpenDialogAction$ = monitor(
    'mondayManageConnectionOpenDialogAction$',
    new Subject<MondayConnectionDetails>()
);
export const mondayManageConnectionCloseDialogAction$ = monitor(
    'mondayManageConnectionCloseDialogAction$',
    new Subject<void>()
);

export const mondayAppSetupOpenDialogAction$ = monitor(
    'mondayAppSetupOpenDialogAction$',
    new Subject<MondayConnectionDetails>()
);
export const mondayAppSetupCloseDialogAction$ = monitor('mondayAppSetupCloseDialogAction$', new Subject<void>());
export const mondayAppSetupDialogOpen$ = monitor('mondayAppSetupDialogOpen$', new BehaviorSubject(false));
export const mondayAppSetupAuthorizeAction$ = monitor(
    'mondayAppSetupAuthorizeAction$',
    new Subject<MondayAppSetupSaveProps>()
);
export const mondayAppSetupDialogErrors$ = monitor(
    'mondayAppSetupDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const mondaySaveConnectionAction$ = monitor('mondaySaveConnectionAction$', new Subject<string>());
export const mondayConnectionCreatedAction$ = monitor('mondayConnectionCreatedAction$', new Subject<string>());
export const mondayConnectionSavedAction$ = monitor(
    'mondayConnectionSavedAction$',
    new Subject<{ uid: string; connectionTypeUid: string }>()
);
export const mondayManageConnectionInitiateSetupAction$ = monitor(
    'mondayManageConnectionInitiateSetupAction$',
    new Subject<string>()
);

export const mondayManageConnectionDialogOpen$ = monitor(
    'mondayManageConnectionDialogOpen$',
    new BehaviorSubject(false)
);
export const mondayConnectionSaving$ = monitor('mondayConnectionSaving$', new BehaviorSubject(false));
export const mondayManageConnectionAuthorizeLoading$ = monitor(
    'mondayManageConnectionAuthorizeLoading$',
    new BehaviorSubject(false)
);
export const mondayManageConnectionDetails$ = monitor(
    'mondayManageConnectionDetails$',
    new BehaviorSubject<MondayConnectionDetails | undefined>(undefined)
);
export const mondayManageConnectionDialogErrors$ = monitor(
    'mondayManageConnectionDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const mondayAppSetupDialogStage$ = monitor('mondayAppSetupDialogStage$', new BehaviorSubject(0));

mondayAppSetupOpenDialogAction$.subscribe((details) => {
    mondayAppSetupDialogErrors$.next(undefined);
    mondayManageConnectionDialogErrors$.next(undefined);
    mondayAppSetupDialogOpen$.next(true);
    mondayAppSetupDialogStage$.next(0);
    mondayManageConnectionDetails$.next(details);
});

mondayAppSetupCloseDialogAction$.subscribe(() => {
    mondayAppSetupDialogOpen$.next(false);
    mondayManageConnectionAuthorizeLoading$.next(false);
});

mondayManageConnectionOpenDialogAction$.subscribe((details) => {
    mondayAppSetupDialogErrors$.next(undefined);
    mondayManageConnectionDialogErrors$.next(undefined);
    mondayManageConnectionDialogOpen$.next(true);
    mondayManageConnectionDetails$.next(details);
});

mondayManageConnectionCloseDialogAction$.subscribe(() => {
    mondayManageConnectionDialogOpen$.next(false);
    mondayAppSetupDialogOpen$.next(false);
    mondayManageConnectionDetails$.next(undefined);
});

mondayConnectionCreatedAction$.subscribe((uid) => connectionCreatedAction$.next(uid));

mondayAppSetupAuthorizeAction$
    .pipe(
        map(async (appInfoProps) => {
            mondayConnectionSaving$.next(true);
            mondayAppSetupDialogErrors$.next(undefined);

            const { uid, connectionType } = mondayManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    throw Error('monday.com Connector type not defined');
                }
                if (!uid) {
                    throw Error('monday.com Connector has not been created so cannot configure it');
                }

                const baseUrl = configTopic$.value.connection?.monday?.baseUrl;
                if (!baseUrl) {
                    throw new Error('No monday.com Connector url configured in meta');
                }

                const response = await initiate(
                    {
                        url: appInfoProps.instanceUrl,
                        connectionId: uid,
                        connectionType: 'monday',
                    },
                    `${baseUrl}/initiate`
                );
                openConsentWindow(response.location, mondayManageConnectionAuthorizeLoading$);

                mondayConnectionCreatedAction$.next(uid);
            } catch (e) {
                if (e instanceof InformativeError) {
                    mondayAppSetupDialogErrors$.next(e.message);
                } else {
                    mondayAppSetupDialogErrors$.next(
                        `Failed to save monday.com application details. Please ensure ${appInfoProps.instanceUrl} ` +
                            `exists and is accessible then try again, if the issue persists please contact support.`
                    );
                    console.error('Error while saving monday.com application info', e);
                }
            }

            mondayConnectionSaving$.next(false);
            mondayManageConnectionAuthorizeLoading$.next(false);
        })
    )
    .subscribe();

mondaySaveConnectionAction$
    .pipe(
        map(async (name) => {
            mondayConnectionSaving$.next(true);
            mondayManageConnectionDialogErrors$.next(undefined);

            const { uid, connectionType } = mondayManageConnectionDetails$.value ?? {};

            try {
                if (!connectionType) {
                    throw Error('monday.com Connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (saveConnectionAllowed(uid)) {
                        await saveMondayConnection(uid, name, connectionTypeUid);
                    } else {
                        promptQuestion({
                            message: AYSYWTSAETCWHAI,
                            onProceed: async () => {
                                await saveMondayConnection(uid, name, connectionTypeUid);
                            },
                        });
                    }
                } else {
                    promptQuestion({
                        message: AYSYWTSAETCWHAI,
                        onProceed: async () => {
                            try {
                                const { uid } = await createConnection(
                                    connectionType.uid,
                                    name,
                                    connectionType.apiHandlerTypes[0]?.uid ?? '',
                                    connectionType.eventListenerTypes[0]?.uid ?? ''
                                );

                                mondayConnectionCreatedAction$.next(uid);
                                mondayConnectionSavedAction$.next({ uid, connectionTypeUid });
                                mondayManageConnectionCloseDialogAction$.next();
                                publishLocalFeedbackEventAction$.next({
                                    level: 'SUCCESS',
                                    message: 'Connector created.',
                                });
                                connectionCreatedFirstTimeAction$.next();
                            } catch (e) {
                                handleManageConnectionError(e, mondayManageConnectionDialogErrors$, APP.MONDAY.NAME);
                            }
                        },
                    });
                }
            } catch (e) {
                handleManageConnectionError(e, mondayManageConnectionDialogErrors$, APP.MONDAY.NAME);
            }

            mondayConnectionSaving$.next(false);
        })
    )
    .subscribe();

mondayManageConnectionInitiateSetupAction$
    .pipe(
        map(async (name) => {
            mondayConnectionSaving$.next(true);
            mondayManageConnectionAuthorizeLoading$.next(true);
            mondayManageConnectionDialogErrors$.next(undefined);

            const {
                uid,
                connectionType,
                name: currentName,
                ...restOfDetails
            } = mondayManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    throw Error('monday.com Connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (currentName !== name) {
                        await saveConnection(uid, name);
                    }

                    mondayConnectionSavedAction$.next({ uid, connectionTypeUid });
                    mondayAppSetupOpenDialogAction$.next({
                        ...restOfDetails,
                        uid,
                        connectionType,
                        name,
                    });
                } else {
                    const { uid } = await createConnection(
                        connectionType.uid,
                        name,
                        connectionType.apiHandlerTypes[0]?.uid ?? '',
                        connectionType.eventListenerTypes[0]?.uid ?? ''
                    );

                    mondayConnectionCreatedAction$.next(uid);
                    mondayConnectionSavedAction$.next({ uid, connectionTypeUid });
                    mondayManageConnectionDetails$.next({
                        connectionType,
                        name,
                        uid,
                    });

                    mondayConnectionSaving$.next(false);

                    mondayAppSetupOpenDialogAction$.next({
                        connectionType,
                        name,
                        uid,
                    });
                    connectionCreatedFirstTime$.next(true);
                }
            } catch (e) {
                handleManageConnectionError(e, mondayManageConnectionDialogErrors$, APP.MONDAY.NAME);
                mondayManageConnectionAuthorizeLoading$.next(false);
            }

            mondayConnectionSaving$.next(false);
        })
    )
    .subscribe();

loadLoggedInUserConnectionsWhenEventIsEmitted(mondayConnectionSavedAction$);

const saveMondayConnection = async (uid: string, name: string, connectionTypeUid: string): Promise<void> => {
    try {
        await saveConnection(uid, name);

        mondayConnectionSavedAction$.next({ uid, connectionTypeUid });
        mondayManageConnectionCloseDialogAction$.next();
        publishLocalFeedbackEventAction$.next({
            level: 'SUCCESS',
            message: 'Connector saved.',
        });
        if (connectionCreatedFirstTime$.value) {
            connectionCreatedFirstTimeAction$.next();
            connectionCreatedFirstTime$.next(false);
        }
    } catch (e) {
        handleManageConnectionError(e, mondayManageConnectionDialogErrors$, APP.MONDAY.NAME);
    }
};
