import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React from 'react';
import { SlackConnection } from './SlackConfigureDialog';

export enum SlackWizardStage {
    INPUT_APP_DETAILS,
    ADD_SCOPES,
    INSTALL,
    INPUT_BOT_TOKEN,
}

export interface SlackWizardStepsProps {
    readonly stage: SlackWizardStage;
}

export const SlackWizardSteps: React.FC<SlackWizardStepsProps> = ({ stage }) => {
    return (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={SlackWizardStage.INPUT_APP_DETAILS}>
                <StepLabel>Input App Details</StepLabel>
            </Step>
            <Step key={SlackWizardStage.ADD_SCOPES}>
                <StepLabel>Add Scopes</StepLabel>
            </Step>
            <Step key={SlackWizardStage.INSTALL}>
                <StepLabel>Install App</StepLabel>
            </Step>
            <Step key={SlackWizardStage.INPUT_BOT_TOKEN}>
                <StepLabel>Input Token</StepLabel>
            </Step>
        </Stepper>
    );
};

export type StageType = React.FC<
    SlackConnection & {
        stage: SlackWizardStage;
        setStage: (stage: SlackWizardStage) => void;
    }
>;
