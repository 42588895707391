import {
    checkDiscountCodeIsValid as checkDiscountCodeIsValidRpc,
    createDiscountCode as createDiscountCodeRpc,
    deleteDiscountCode as deleteDiscountCodeRpc,
} from '@avst-stitch/repository-lib/lib';

import {
    Response as CheckDiscountCodeIsValidResponse,
    Request as CheckDiscountCodeIsValidRequest,
} from '@avst-stitch/repository-lib/lib/rpcs/checkDiscountCodeIsValid';

import {
    Response as CreateDiscountCodeResponse,
    Request as CreateDiscountCodeRequest,
} from '@avst-stitch/repository-lib/lib/rpcs/createDiscountCode';

import {
    Response as DeleteDiscountCodeResponse,
    Request as DeleteDiscountCodeRequest,
} from '@avst-stitch/repository-lib/lib/rpcs/deleteDiscountCode';

import {
    Response as DiscountCodeDetails,
    Request as DiscountCodeDetailsRequest,
} from '@avst-stitch/repository-lib/lib/rpcs/report/getDiscountCodeDetails';

import { getDiscountCodeDetailsReport as getDiscountCodeDetailsReportRpc } from '@avst-stitch/repository-lib/lib';

import { repositoryInvoker } from '../utils/repository';

export type { CreateDiscountCodeRequest, DiscountCodeDetails };

export const checkDiscountCodeIsValid = async (
    request: CheckDiscountCodeIsValidRequest
): Promise<CheckDiscountCodeIsValidResponse> => {
    return await checkDiscountCodeIsValidRpc(repositoryInvoker, request);
};

export const createDiscountCode = async (request: CreateDiscountCodeRequest): Promise<CreateDiscountCodeResponse> => {
    return await createDiscountCodeRpc(repositoryInvoker, request);
};

export const deleteDiscountCode = async (request: DeleteDiscountCodeRequest): Promise<DeleteDiscountCodeResponse> => {
    return await deleteDiscountCodeRpc(repositoryInvoker, request);
};

export const getDiscountCodeDetailsReport = async (
    request: DiscountCodeDetailsRequest
): Promise<DiscountCodeDetails> => {
    return await getDiscountCodeDetailsReportRpc(repositoryInvoker, request);
};
