import { BehaviorSubject, map, Subject } from 'rxjs';
import { getEventListenerSetupDetails } from '../../../data/event-listener';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';

export const mondayEventListenerSetupDialogOpenAction$ = monitor(
    'mondayEventListenerSetupDialogOpenAction$',
    new Subject<MondayEventListenerSetupBaseDetails>()
);
export const mondayEventListenerSetupDialogCloseAction$ = monitor(
    'mondayEventListenerSetupDialogCloseAction$',
    new Subject<void>()
);

export const mondayEventListenerSetupDialogLoading$ = monitor(
    'mondayEventListenerSetupDialogLoading$',
    new BehaviorSubject(false)
);
export const mondayEventListenerSetupDialogOpen$ = monitor(
    'mondayEventListenerSetupDialogOpen$',
    new BehaviorSubject(false)
);
export const mondayEventListenerSetupDialogDetails$ = monitor(
    'mondayEventListenerSetupDialogDetails$',
    new BehaviorSubject<MondayEventListenerSetupDetails | undefined>(undefined)
);

export type MondayEventListenerSetupBaseDetails = {
    uid: string;
    environmentUid: string;
    webhookUrl: string;
    eventTypeName: string;
};

export type MondayEventListenerSetupDetails = {
    mondayUrl?: string;
} & MondayEventListenerSetupBaseDetails;

mondayEventListenerSetupDialogOpenAction$
    .pipe(
        map(async (details) => {
            mondayEventListenerSetupDialogOpen$.next(true);
            mondayEventListenerSetupDialogLoading$.next(true);

            try {
                const { connectionBaseUrl } = await getEventListenerSetupDetails(details.uid, details.environmentUid);

                mondayEventListenerSetupDialogDetails$.next({
                    ...details,
                    mondayUrl: connectionBaseUrl,
                });
                mondayEventListenerSetupDialogLoading$.next(false);
            } catch (e) {
                console.error('Error while loading Event Listener setup instructions', e);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to load Event Listener setup instructions, please try again, if the issue persists please contact support.`,
                    toastOptions: {
                        autoClose: false,
                    },
                });

                mondayEventListenerSetupDialogCloseAction$.next();
            }
        })
    )
    .subscribe();

mondayEventListenerSetupDialogCloseAction$.subscribe(() => {
    mondayEventListenerSetupDialogOpen$.next(false);
    mondayEventListenerSetupDialogDetails$.next(undefined);
});
