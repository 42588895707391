import React, { useCallback, useEffect, useRef, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import type * as monacoApi from 'monaco-editor/esm/vs/editor/editor.api';
import { styled } from '@mui/material/styles';
import { useMonaco } from './useMonaco';
import { EditorContext } from './EditorContext';
import { useTheme } from '@mui/styles';
import { Position, ScriptPosition } from '../../../workspace-resources/scripts/ScriptDetails';

type EditorRef = React.MutableRefObject<monacoApi.editor.IStandaloneCodeEditor | undefined>;
type ContainerRef = React.RefObject<HTMLDivElement>;

interface EditorProps {
    readonly value?: string;
    readonly name: string;
    readonly uid: string;
    readonly readOnly: boolean;
    readonly readme?: boolean;
    readonly editorRef?: EditorRef;
    readonly containerRef?: ContainerRef;
    readonly injectionRef?: ContainerRef;
    readonly hasUnsavedChanges?: boolean;
    // readonly open: boolean;
    // readonly onTriggerScript: (scriptUid: string) => void;
    readonly onSave: () => void;
    readonly scriptUid: string;
    // readonly onCollapse: () => void;
    // readonly onExpand: () => void;
    readonly storedPosition?: Position;
    readonly onStoreScriptPosition?: (scriptPosition: ScriptPosition) => void;
    readonly environmentUid?: string;
}

const StyledCodeEditor = styled('div')<{ readme?: boolean }>(({ theme, readme }) => ({
    height: '100%',
    minHeight: 60,
    padding: readme ? theme.spacing(2, 0, 7) : theme.spacing(2, 0, 10),
    position: 'relative',
    width: '100%',
    zIndex: 1101,
}));

const StyledContainerDiv = styled('div')(({ theme }) => ({
    height: '100%',
    position: 'relative',
    width: '100%',
    '& .scroll-decoration': {
        display: 'none',
    },
    '& .vertical.scrollbar': {
        width: `${theme.spacing(2.5)} !important`,
    },
    '& .horizontal.scrollbar': {
        bottom: '-10px !important',
        height: `${theme.spacing(3)} !important`,
    },
    '&.readme canvas': {
        display: 'none !important',
    },
    '& .vertical>.slider': {
        width: `${theme.spacing(1.75)} !important`,
    },
    '& .horizontal>.slider': {
        height: `${theme.spacing(1.75)} !important`,
    },
    '& .rename-input': {
        color: theme.palette.getContrastText(theme.palette.background.default),
    },
}));
const StyledInjectionDiv = styled('div')({
    inset: 0,
    position: 'absolute',
});

export const Editor: React.FC<EditorProps> = ({
    name,
    uid,
    environmentUid,
    value,
    readOnly,
    readme,
    editorRef = useRef(),
    containerRef = useRef(null),
    injectionRef = useRef(null),
    children,
    onSave,
    storedPosition,
    onStoreScriptPosition,
}) => {
    const monaco = useMonaco();
    const [editor, setEditor] = useState<monacoApi.editor.IStandaloneCodeEditor | undefined>(undefined);

    const darkTheme = useTheme().palette.mode === 'dark';

    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.updateOptions({ readOnly });
        }
    }, [readOnly]);

    useEffect(() => {
        // TODO - is there a better way to do the theme switching?
        // This only works in Storybook if the page is reloaded when the theme changes
        if (monaco && !editorRef.current) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            editorRef.current = monaco.editor.create(injectionRef.current!, {
                value,
                minimap: { enabled: false },
                fixedOverflowWidgets: true,
                theme: darkTheme ? 'vs-dark' : 'vs',
            });
        }
        setEditor(editorRef.current);
        editorRef.current?.updateOptions({ readOnly });

        return () => {
            // editor state may be undefined here, so we need to use the ref instead
            if (editorRef.current) {
                setEditor(undefined);
                try {
                    editorRef.current.dispose();
                    editorRef.current.getModel()?.dispose();
                } catch (e) {
                    // Be tolerant of errors during disposal, but report them via the console
                    console.error(e);
                }
            }
            editorRef.current = undefined;
        };
    }, [monaco, darkTheme]);

    useEffect(() => {
        if (monaco) {
            const isMarkdown = name.endsWith('md');
            const fileName = isMarkdown ? name : `/${name}.ts`;
            const language = isMarkdown ? 'markdown' : 'typescript';

            const uri = monaco.Uri.file(fileName);
            const model = monaco.editor.getModel(uri);
            if (!model) {
                monaco.editor.createModel(value ?? '', language, uri);
            }
        }
    }, [monaco]);

    useEffect(() => {
        if (editor && value !== undefined && value !== editor.getValue()) {
            editor.setValue(value);
        }
        if (editor) {
            editor.onDidScrollChange(handleRegisterScroll);
            const visiblePosition = editor.getScrolledVisiblePosition(storedPosition ?? { lineNumber: 1, column: 1 });
            if (visiblePosition) {
                editor.setScrollPosition({ scrollTop: visiblePosition.top });
            }
        }
    }, [editor, uid]);

    useResizeObserver({
        ref: containerRef,
        onResize: () => {
            editor?.layout();
        },
    });

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false);
        return () => {
            document.removeEventListener('keydown', handleKeyDown, false);
        };
    }, []);

    const handleRegisterScroll = (): void => {
        // multiple scripts might use the same instance of editor, registering only selected script
        if (editor && editor.getModel()?.uri.path.includes(name)) {
            const startLineNumber = editor.getVisibleRanges()?.[0]?.startLineNumber ?? 1;
            onStoreScriptPosition?.({
                key: `${environmentUid}/${uid}`,
                position: {
                    lineNumber: startLineNumber,
                    column: 1,
                },
            });
        }
    };

    const handleKeyDown = useCallback((event) => {
        if (
            !readOnly &&
            (window.navigator.platform.match('Mac') ? event.metaKey : event.ctrlKey) &&
            (event.keyCode == 83 || (event.key && event.key.toLowerCase() === 's'))
        ) {
            event.preventDefault();
            onSave();
        }
    }, []);

    return (
        <StyledCodeEditor readme={readme}>
            <StyledContainerDiv className={readme ? 'readme' : 'script'} ref={containerRef}>
                <StyledInjectionDiv ref={injectionRef} />
                <EditorContext.Provider value={editor}>{children}</EditorContext.Provider>
            </StyledContainerDiv>
        </StyledCodeEditor>
    );
};
