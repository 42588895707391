import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

export enum ZoomEventListenerWizardStage {
    SAVE_TOKEN,
    ADD_EVENT,
}

export interface ZoomWizardStepsProps {
    readonly stage: ZoomEventListenerWizardStage;
}

export const ZoomEventListenerWizardSteps: React.FC<ZoomWizardStepsProps> = ({ stage }) => {
    return (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={ZoomEventListenerWizardStage.SAVE_TOKEN}>
                <StepLabel>Save Secret Token</StepLabel>
            </Step>
            <Step key={ZoomEventListenerWizardStage.ADD_EVENT}>
                <StepLabel>Add Event</StepLabel>
            </Step>
        </Stepper>
    );
};
