import { BehaviorSubject, Subject } from 'rxjs';
import { monitor } from '../../monitor';

export const githubEventListenerSetupDialogOpenAction$ = monitor(
    'githubEventListenerSetupDialogOpenAction$',
    new Subject<GithubEventListenerSetupDetails>()
);
export const githubEventListenerSetupDialogCloseAction$ = monitor(
    'githubEventListenerSetupDialogCloseAction$',
    new Subject<void>()
);
export const githubEventListenerSetupDialogOpen$ = monitor(
    'githubEventListenerSetupDialogOpen$',
    new BehaviorSubject(false)
);
export const githubEventListenerSetupDialogDetails$ = monitor(
    'githubEventListenerSetupDialogDetails$',
    new BehaviorSubject<GithubEventListenerSetupDetails | undefined>(undefined)
);

export type GithubEventListenerSetupDetails = {
    uid: string;
    environmentUid: string;
    webhookUrl: string;
    eventTypeName: string;
};

githubEventListenerSetupDialogOpenAction$.subscribe((details) => {
    githubEventListenerSetupDialogDetails$.next(details);
    githubEventListenerSetupDialogOpen$.next(true);
});

githubEventListenerSetupDialogCloseAction$.subscribe(() => {
    githubEventListenerSetupDialogOpen$.next(false);
    githubEventListenerSetupDialogDetails$.next(undefined);
});
