import React from 'react';
import { styled, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Button } from '../common/buttons/Button';
import { IconCircle } from '../for-deprecation/IconCircle';
import { InfoIcon } from '../icons/InfoIcon';
import DialogActions from '@mui/material/DialogActions';
import { DialogCloseButton } from '../for-deprecation/dialog/DialogComponents';
import { Theme } from '@mui/material/styles';

export interface WizardInstructionsCardProps {
    icon?: JSX.Element;
    instructions?: string | JSX.Element;
    offsetTop?: number;
    tip?: string | JSX.Element;
    title: string;
    onClose(): void;
    onNext(): void;
    onPrevious?(): void;
}

const arrowStyles = (theme: Theme) =>
    ({
        color: theme.palette.text.secondary,
        height: 22,
        position: 'absolute',
        width: 22,
    } as const);

const StyledArrow = styled(ArrowBackOutlinedIcon)(({ theme }) => ({
    ...arrowStyles(theme),
    left: theme.spacing(-3),
    top: theme.spacing(4),
}));

const StyledArrowDown = styled(ArrowDownwardIcon)(({ theme }) => ({
    ...arrowStyles(theme),
    bottom: theme.spacing(-3),
    left: 0,
}));

const StyledButtonContainer = styled(DialogActions)(({ theme }) => ({
    '& > button:not(:last-of-type)': {
        color: theme.palette.secondary.light,
        '&:hover': {
            color: theme.palette.secondary.main,
        },
    },
}));

const StyledCard = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.constants.boxShadow,
    left: theme.spacing(3),
    marginTop: theme.spacing(3),
    minHeight: 202,
    padding: theme.spacing(2, 2, 1, 3),
    position: 'absolute',
    top: 215,
    width: 550,
}));

const StyledContentContainer = styled('div')(({ theme }) => ({
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1, 3, 0, 5.75),
    minHeight: 96,
}));

const StyledInstructions = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(2),
    ...theme.typography.overflowBlock,
}));

const StyledTip = styled('div')(({ theme }) => ({
    marginLeft: theme.spacing(0.5),
    ...theme.typography.overflowBlock,
}));

const StyledTipContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
        height: 20,
        width: 20,
    },
}));

const StyledTitleContainer = styled('div')(() => ({
    alignItems: 'center',
    display: 'flex',
}));
export const WizardInstructionsCard: React.FC<WizardInstructionsCardProps> = ({
    icon,
    instructions,
    offsetTop = 0,
    tip,
    title,
    onClose,
    onNext,
    onPrevious,
}) => {
    const theme = useTheme();
    return (
        <>
            <StyledCard sx={{ top: offsetTop < 400 ? offsetTop : 0 }}>
                {offsetTop < 400 && <StyledArrow />}
                <DialogCloseButton onClick={onClose} />
                <StyledTitleContainer>
                    {icon && <IconCircle icon={icon} background={theme.palette.background.paper} />}
                    <Typography variant="subtitle1">{title}</Typography>
                </StyledTitleContainer>
                <StyledContentContainer>
                    {instructions && <StyledInstructions>{instructions}</StyledInstructions>}
                    {tip && (
                        <StyledTipContainer>
                            <InfoIcon />
                            <StyledTip>{tip}</StyledTip>
                        </StyledTipContainer>
                    )}
                </StyledContentContainer>
                <StyledButtonContainer>
                    {onPrevious && (
                        <Button variant="text" onClick={onPrevious}>
                            Prev
                        </Button>
                    )}
                    <Button variant="text" onClick={onNext}>
                        Next
                    </Button>
                </StyledButtonContainer>
                {offsetTop > 400 && <StyledArrowDown />}
            </StyledCard>
        </>
    );
};
