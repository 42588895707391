import { getOrganisationsWithUtilization as getOrganisationsWithUtilizationRpc } from '@avst-stitch/repository-lib/lib';
import {
    Request as OrganisationsUtilizationRequest,
    Response as OrganisationsUtilizationData,
} from '@avst-stitch/repository-lib/lib/rpcs/report/getOrganisationsWithUtilization';
import { repositoryInvoker } from '../../utils/repository';
export type { OrganisationsUtilizationRequest, OrganisationsUtilizationData };

export const getOrganisationsWithUtilization = async (
    request: OrganisationsUtilizationRequest
): Promise<OrganisationsUtilizationData> => {
    return await getOrganisationsWithUtilizationRpc(repositoryInvoker, request);
};
