import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const GoogleSheetsIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 88"
            className="productIcon"
            aria-label="Google Sheets"
        >
            <path fill="#188038" d="M42 0l22 22-11 2-11-2-2-11z"></path>
            <path
                fill="#34a853"
                d="M42 22V0H6C2.685 0 0 2.685 0 6v76c0 3.315 2.685 6 6 6h52c3.315 0 6-2.685 6-6V22z"
            ></path>
            <path
                fill="#fff"
                d="M12 34v29h40V34zm17.5 24H17v-7h12.5zm0-12H17v-7h12.5zM47 58H34.5v-7H47zm0-12H34.5v-7H47z"
            ></path>
        </SvgIcon>
    );
};
