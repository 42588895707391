export enum WizardStep {
    NONE,
    START,
    CREATE_WORKSPACE,
    ADD_API_CONNECTION,
    SAVE_API_CONNECTION,
    ADD_EVENT_LISTENER,
    SAVE_EVENT_LISTENER,
    CREATE_SCRIPT,
    SUCCESS,
}

export enum WizardState {
    CLOSED,
    CONFIRM,
    MINIMIZED,
    ACTIVE,
}

export interface WizardWorkspaceDetails {
    workspaceUid?: string;
    environmentUid?: string;
}

export interface Wizard {
    state: WizardState;
    step: WizardStep;
    createdUnsavedResource?: string;
    workspaceDetails?: WizardWorkspaceDetails;
}

export interface ResourceManagerOffsets {
    container: number;
    scripts: number;
    apiConnections: number;
    eventListeners: number;
}
