import { useObservableState } from 'observable-hooks';
import { selectedInvocationId$, selectedInvocationLogs$ } from '../../../store/invocationlogs';
import { InvocationLogs } from '../../../components/reporting/printed-logs/invocation-logs';

export const InvocationLogsContainer: React.FC = () => {
    const invocationId = useObservableState(selectedInvocationId$);
    const invocationLogs = useObservableState(selectedInvocationLogs$);

    return <InvocationLogs invocationId={invocationId ?? ''} logs={invocationLogs} />;
};
