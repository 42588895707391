import { BehaviorSubject } from 'rxjs';
import { monitor } from './monitor';

export interface Role {
    uid: string;
    label: string;
    description?: string;
}

export const industryRoles$ = monitor('industryRoles$', new BehaviorSubject<Role[]>([]));
