/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { HalfPieChart, Proportion } from 'half-pie-chart';
import { styled, useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export interface UsageWidgetProps {
    id: string; //Unique identifier for classNames
    mainTitle: string;
    counterTitle: string; // Bold stuff within the circle
    subtitle?: string; // Small stuff within the circle
    percentage: number;
    showViewReport: boolean;
    color: string; // Hex color of the circle
    noBorder?: boolean;
    onClick?(): void;
}

const chartWidth = 140;
const chartHeight = chartWidth / 2;

const StyledCardMedia = styled(CardMedia)(() => ({
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    justifySelf: 'flex-end',
    position: 'relative',
}));

const StyledChartTitleArea = styled('div')(({ theme }) => ({
    bottom: theme.spacing(1),
    left: '50%',
    maxWidth: 100,
    position: 'absolute',
    textAlign: 'center',
    transform: 'translateX(-50%)',
}));

const StyledCircle = styled('div')(({ theme, color }) => ({
    ...theme.typography.radiusCircle,
    backgroundColor: color,
    border: `2px solid ${theme.palette.background.paper}`,
    boxShadow: `0px 1px 1px rgba(0, 0, 0, 0.16)`,
    height: 16,
    left: -4,
    position: 'absolute',
    bottom: 'calc(50% - 4px)',
    width: 16,
}));

const StyledCircleContainer = styled('div')<{ percentage: number }>(({ theme, percentage }) => ({
    backgroundColor: 'transparent',
    borderRadius: theme.constants.radiusCircle,
    height: chartWidth,
    left: 12,
    position: 'absolute',
    top: 4,
    transform: percentage !== 0 ? `rotate(${rotate(percentage)}deg)` : undefined,
    width: chartWidth,
    zIndex: 10,
}));

const StyledContainer = styled('div')<{ id: string; color: string }>(({ id, color }) => ({
    position: 'relative',
    [`& .DashCard-${id}`]: {
        backgroundColor: 'transparent',
        borderRadius: 0,
        display: 'inline-block',
        maxWidth: 'unset',
        padding: 0,
    },
    '& h1': {
        display: 'none',
    },
    '& .rowPie': {
        display: 'none',
    },
    [`& .chart-skills-${id}`]: {
        boxSizing: 'content-box',
        height: chartHeight,
        margin: 0,
        padding: '4px 12px 0',
        width: chartWidth,
        '&::after': {
            content: 'none',
        },
        '& li': {
            border: '8px solid',
            borderTop: 'none',
            left: 12,
            '&:last-of-type': {
                borderColor: color,
                opacity: 0.16,
            },
        },
    },
}));

const StyledUpperCardContainer = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(0.5),
}));

const StyledUsageCard = styled(Card, {
    shouldForwardProp: (prop) => prop !== 'showViewReport' && prop !== 'noBorder',
})<{ showViewReport: boolean; noBorder: boolean }>(({ theme, showViewReport, noBorder }) => ({
    backgroundColor: theme.palette.background.default,
    boxSizing: 'border-box',
    boxShadow: noBorder ? 'none' : theme.constants.boxShadow,
    cursor: showViewReport ? 'pointer' : undefined,
    display: 'flex',
    flexDirection: 'column',
    height: 220,
    padding: theme.spacing(1.5, 2, 4),
    width: 306,
    '&:hover': showViewReport
        ? {
              outline: theme.constants.borderSelected,
              '& .viewReport': {
                  color: theme.palette.secondary.main,
              },
          }
        : null,
}));

const StyledViewReport = styled('div')(({ theme }) => ({
    color: theme.palette.primary.dark,
    ...theme.typography.flexAlignCenter,
    '& .MuiSvgIcon-root': {
        height: 12,
        width: 12,
    },
}));

const rotate = (percentage: number): number => {
    return (170 / 100) * percentage;
};

export const UsageWidget: React.FC<UsageWidgetProps> = ({
    color,
    counterTitle,
    onClick,
    mainTitle,
    percentage,
    showViewReport = true,
    subtitle,
    id,
    noBorder = false,
}) => {
    const theme = useTheme();

    const visualPercentage = percentage < 0 ? 0 : percentage > 100 ? 100 : percentage;

    const left: Proportion[] = [
        {
            value: visualPercentage,
            color,
        },
    ];

    const right: Proportion[] = [
        {
            value: 100 - visualPercentage,
        },
    ];
    //For some reason left and right are inversed here
    return (
        <StyledUsageCard
            onClick={showViewReport ? onClick : undefined}
            showViewReport={showViewReport}
            noBorder={noBorder}
        >
            <CardContent>
                <StyledUpperCardContainer>
                    {showViewReport && (
                        <StyledViewReport className="viewReport">
                            <Typography variant="body2">View report</Typography>
                            <ArrowForwardIcon />
                        </StyledViewReport>
                    )}
                </StyledUpperCardContainer>
                <Typography component="h3" variant="h6">
                    {mainTitle}
                </Typography>
            </CardContent>
            <StyledCardMedia>
                <StyledContainer id={id} color={color}>
                    <HalfPieChart
                        cardBackColor={theme.palette.background.default}
                        left={right}
                        right={left}
                        name={id}
                    />
                    <StyledCircleContainer percentage={visualPercentage}>
                        <StyledCircle color={color} />
                    </StyledCircleContainer>
                </StyledContainer>
                <StyledChartTitleArea>
                    <Typography variant="h6" sx={{ lineHeight: 1.1 }} noWrap>
                        {counterTitle}
                    </Typography>
                    <Typography fontSize={10} color="text.secondary" noWrap>
                        {subtitle}
                    </Typography>
                </StyledChartTitleArea>
            </StyledCardMedia>
        </StyledUsageCard>
    );
};
