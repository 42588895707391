import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React from 'react';

export enum ChangeOrganizationPlanWizardStage {
    PAYMENT_METHOD,
    BILLING_DETAILS,
}

export interface ChangeOrganizationPlanWizardStepsProps {
    readonly stage: ChangeOrganizationPlanWizardStage;
    readonly period?: 'monthly' | 'annually';
}

export const ChangeOrganizationPlanWizardSteps: React.FC<ChangeOrganizationPlanWizardStepsProps> = ({
    stage,
    period = 'monthly',
}) => {
    return (
        <Stepper alternativeLabel activeStep={stage}>
            {period === 'annually' && (
                <Step key={ChangeOrganizationPlanWizardStage.PAYMENT_METHOD}>
                    <StepLabel>Payment method</StepLabel>
                </Step>
            )}
            <Step key={ChangeOrganizationPlanWizardStage.BILLING_DETAILS}>
                <StepLabel>Billing details</StepLabel>
            </Step>
        </Stepper>
    );
};
