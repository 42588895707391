import { getWorkspace as getWorkspaceRpc } from '@avst-stitch/repository-lib/lib';
import { getWorkspaceResources as getWorkspaceResourcesRpc } from '@avst-stitch/repository-lib/lib';
import { getWorkspaceEnvironments as getWorkspaceEnvironmentsRpc } from '@avst-stitch/repository-lib/lib';
import { updateWorkspaceLanguage as updateWorkspaceLanguageRpc } from '@avst-stitch/repository-lib/lib';
import { exitWorkspace as exitWorkspaceRpc } from '@avst-stitch/repository-lib/lib';
import { pingWorkspace as pingWorkspaceRpc } from '@avst-stitch/repository-lib/lib/rpcs/pingWorkspace';
import { assumeWorkspaceEditControl as assumeWorkspaceEditControlRpc } from '@avst-stitch/repository-lib/lib/rpcs/assumeWorkspaceEditControl';
import { Response as Workspace } from '@avst-stitch/repository-lib/lib/rpcs/getWorkspace';
import { Response as WorkspaceResources } from '@avst-stitch/repository-lib/lib/rpcs/getWorkspaceResources';
import { Response as WorkspaceEnvironments } from '@avst-stitch/repository-lib/lib/rpcs/getWorkspaceEnvironments';
import { Response as PingWorkspaceResponse } from '@avst-stitch/repository-lib/lib/rpcs/pingWorkspace';
import { repositoryInvoker } from '../utils/repository';
export type { Workspace, WorkspaceResources, WorkspaceEnvironments };

export const getWorkspace = async (
    workspaceUid: string,
    acquireLock = false,
    viewTemplate = false
): Promise<Workspace> => {
    return await getWorkspaceRpc(repositoryInvoker, {
        workspaceUid,
        acquireLock,
        viewTemplate,
    });
};

export const getWorkspaceResources = async (
    workspaceUid: string,
    environmentUid: string
): Promise<WorkspaceResources> => {
    return await getWorkspaceResourcesRpc(repositoryInvoker, {
        workspaceUid,
        environmentUid,
    });
};

export const getWorkspaceEnvironments = async (workspaceUid: string): Promise<WorkspaceEnvironments> => {
    return await getWorkspaceEnvironmentsRpc(repositoryInvoker, {
        workspaceUid,
    });
};

export const updateWorkspaceLanguage = async (uid: string, language: string): Promise<void> => {
    return await updateWorkspaceLanguageRpc(repositoryInvoker, {
        uid,
        language,
    });
};

export const exitWorkspace = async (workspaceUid: string): Promise<void> => {
    return await exitWorkspaceRpc(repositoryInvoker, {
        workspaceUid,
    });
};

export const pingWorkspace = async (workspaceUid: string, holdingLock: boolean): Promise<PingWorkspaceResponse> => {
    return await pingWorkspaceRpc(repositoryInvoker, {
        workspaceUid,
        holdingLock,
    });
};

export const assumeWorkspaceEditControl = async (workspaceUid: string): Promise<void> => {
    return await assumeWorkspaceEditControlRpc(repositoryInvoker, {
        workspaceUid,
    });
};
