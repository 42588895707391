import { useObservableState } from 'observable-hooks';
import { OrganisationDetailsReportPage } from '../../components/report/OrganisationDetailsReportPage';
import {
    selectedOrganisationDetails$,
    organisationPlanLimitsLoading$,
    organisationPlanLimitsError$,
    selectedOrganisationPlanLimits$,
} from '../../store/report/organisations';

export const OrganisationDetailsReportPageContainer: React.FC = () => {
    const details = useObservableState(selectedOrganisationDetails$);
    const limits = useObservableState(selectedOrganisationPlanLimits$);
    const loading = useObservableState(organisationPlanLimitsLoading$);
    const error = useObservableState(organisationPlanLimitsError$);

    return (
        <OrganisationDetailsReportPage
            organisation={details}
            limits={limits}
            limitsLoading={loading}
            limitsError={error}
        />
    );
};
