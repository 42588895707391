import React, { ReactNode } from 'react';
import { styled } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import { ProductIcon } from '../icons/ProductIcon';
import { ThreeDotsButton } from '../common/buttons/ThreeDotsButton';
import { StyledCard } from './DashboardComponents';
import { SelectorItemDescription, SelectorItemTitle, TemplateStyledChip } from '../app-main/SelectorComponents';
import Tooltip from '@mui/material/Tooltip';

interface DashboardTemplateCardProps {
    description?: string;
    incoming?: string[];
    onSelect(uid: string): void;
    outgoing?: string[];
    title?: string;
    uid: string;
    onEdit(uid: string): void;
    userCanWorkWithTemplates: boolean;
}

// do not put any width constraints on the card containers, let the parent Grid xs do the sizing

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    height: '50%',
    marginBottom: theme.spacing(2),
}));

const StyledIconContainer = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    boxShadow: theme.constants.boxShadow,
    height: theme.spacing(4),
    justifyContent: 'center',
    flex: `0 0 ${theme.spacing(4)}`,
    '&:not(:last-child)': {
        marginRight: theme.spacing(1),
    },
    '& .MuiSvgIcon-root': {
        height: 24,
        width: 24,
    },
}));

const StyledLowerCard = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
}));

const StyledMediaContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxWidth: 240,
}));

const StyledRow = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    height: theme.spacing(4),
    '&:not(:first-of-type)': {
        marginTop: theme.spacing(1),
    },
}));

export const DashboardTemplateCard: React.FC<DashboardTemplateCardProps> = ({
    description,
    incoming = [],
    onSelect,
    outgoing = [],
    title,
    uid,
    onEdit,
    userCanWorkWithTemplates,
}) => {
    const iconList = (connections: string[]): ReactNode[] => {
        return connections.map((connection, index) => {
            return (
                index < 4 && (
                    <Tooltip key={index} title={connection} describeChild>
                        <StyledIconContainer key={index}>
                            <ProductIcon name={connection} />
                        </StyledIconContainer>
                    </Tooltip>
                )
            );
        });
    };
    const MediaContainer: React.FC = () => {
        return (
            <>
                <StyledMediaContainer>
                    <StyledRow>
                        <TemplateStyledChip
                            label="Incoming"
                            sx={{ backgroundColor: 'connections.main' }}
                            icon={<DownloadOutlinedIcon />}
                        />
                        {iconList(incoming)}
                    </StyledRow>
                    <StyledRow>
                        <TemplateStyledChip
                            sx={{ backgroundColor: 'connections.light' }}
                            label="Outgoing"
                            icon={<UploadOutlinedIcon />}
                        />
                        {iconList(outgoing)}
                    </StyledRow>
                </StyledMediaContainer>
            </>
        );
    };

    return (
        <>
            <StyledCard className="template-card" elevation={1} onClick={() => onSelect(uid)}>
                {userCanWorkWithTemplates && (
                    <ThreeDotsButton
                        uid={uid}
                        actions={[{ name: 'Edit', icon: <ModeEditOutlineOutlinedIcon />, onSelect: onEdit }]}
                    />
                )}
                <StyledCardContent>
                    <SelectorItemTitle
                        title={title}
                        sx={(theme) => (userCanWorkWithTemplates ? { marginRight: theme.spacing(2) } : {})}
                    />
                    <SelectorItemDescription description={description} />
                </StyledCardContent>
                <StyledLowerCard>
                    <CardMedia component={MediaContainer} />
                </StyledLowerCard>
            </StyledCard>
        </>
    );
};
