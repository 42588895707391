import { useObservableState } from 'observable-hooks';
import { BitbucketCloudEventListenerSetupDialog } from '../../../../components/connections/apps/bitbucket-cloud/EventListenerSetupDialog';
import { configTopic$ } from '../../../../store/config';
import {
    bitbucketCloudEventListenerSetupDialogCloseAction$,
    bitbucketCloudEventListenerSetupDialogDetails$,
    bitbucketCloudEventListenerSetupDialogLoading$,
    bitbucketCloudEventListenerSetupDialogOpen$,
} from '../../../../store/connection/bitbucket-cloud/setup-event-listener';

export const BitbucketCloudEventListenerSetupDialogContainer: React.FC = () => {
    const loading = useObservableState(bitbucketCloudEventListenerSetupDialogLoading$);
    const open = useObservableState(bitbucketCloudEventListenerSetupDialogOpen$);
    const details = useObservableState(bitbucketCloudEventListenerSetupDialogDetails$);
    const config = useObservableState(configTopic$);

    return (
        <BitbucketCloudEventListenerSetupDialog
            loading={loading}
            open={open}
            eventTypeName={details?.eventTypeName ?? ''}
            webhookBaseUrl={config.trigger?.srcExternalUrl ?? ''}
            webhookUrlId={details?.webhookUrl ?? ''}
            setupBaseUrl={details?.setupBaseUrl}
            onClose={() => bitbucketCloudEventListenerSetupDialogCloseAction$.next()}
        />
    );
};
