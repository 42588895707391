/* eslint-disable sonarjs/cognitive-complexity */
import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button } from '../../../common/buttons/Button';
import {
    ChangeOrganizationPlanWizardSteps,
    ChangeOrganizationPlanWizardStage,
} from './ChangeOrganizationPlanWizardSteps';
import { ExistingBillingDetailsList } from '../../billing/ExistingBillingDetailsList';
import { DialogAlert, DialogTitleMain } from '../../../for-deprecation/dialog/DialogComponents';
import { IconCircle } from '../../../for-deprecation/IconCircle';
import isEmail from 'validator/lib/isEmail';
import { handleKeyDown } from '../../../../utils/handleKeyDown';
import { autoFocus } from '../../../../utils/autoFocus';
import { AbsoluteCenteredLoadingSpinner } from '../../../common/loading/AbsoluteCenteredLoadingSpinner';
import { OrganizationPlan } from '../../../../data/organization';
import { OrganizationInvoicePlanInformation } from '../../create-organization-wizard/OrganizationInvoicePlanInformation';

export interface ChangeOrganizationPlanWizardDialogProps {
    billingEntities?: {
        uid: string;
        contactName: string;
        contactEmail: string;
    }[];
    currentBillingEntity?: string;
    plan?: Plan;
    currentPlan?: OrganizationPlan;
    errors?: string;
    open?: boolean;
    saving?: boolean;
    loading?: boolean;
    onCancel: () => void;
    onCreate(event: ChangeOrganizationPlanWizardDialogEvent): void;
}

export interface ChangeOrganizationPlanWizardDialogEvent {
    plan?: Plan;
    paymentMethod?: PaymentMethod;
    existingBillingEntityUid?: string;
    newBillingEntity?: {
        contactName: string;
        contactEmail: string;
    };
}

interface Plan {
    tier: 'free' | 'silver' | 'gold' | 'platinum';
    period: 'monthly' | 'annually';
}
type BillingForm = 'saved' | 'create';
type PaymentMethod = OrganizationPlan['paymentType'];

const StyledRemark = styled('div')(({ theme }) => ({
    display: 'flex',
    paddingLeft: theme.spacing(0.5),
    justifyContent: 'start',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    width: '60%',
    marginBottom: theme.spacing(1),
    borderRadius: theme.constants.borderRadius,
    outline: `1px solid ${theme.palette.action.focus}`,
    '&:hover': {
        outline: theme.constants.borderSelected,
    },
}));

const StyledFormControlLabelTitle = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
}));

const StyledPaymentMethodRadioGroup = styled(RadioGroup)(({ theme }) => ({
    paddingLeft: theme.spacing(1.625),
}));

const StyledFormControlLabelText = styled('div')(({ theme }) => ({
    paddingLeft: theme.spacing(1),
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    outline: `1px solid ${theme.palette.action.focus}`,
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    outline: `1px solid ${theme.palette.action.focus}`,
}));

export const ChangeOrganizationPlanWizardDialog: React.FC<ChangeOrganizationPlanWizardDialogProps> = ({
    billingEntities = [],
    currentBillingEntity,
    plan,
    currentPlan,
    errors,
    open = false,
    saving = false,
    loading = false,
    onCancel,
    onCreate,
}) => {
    const paymentMethodChangeErrorMessage = 'Please contact support to change your payment method.';
    const billingEntityChangeErrorMessage = 'Please contact support to change your billing details.';
    const [stage, setStage] = useState<ChangeOrganizationPlanWizardStage>(
        plan?.period === 'annually'
            ? ChangeOrganizationPlanWizardStage.PAYMENT_METHOD
            : ChangeOrganizationPlanWizardStage.BILLING_DETAILS
    );

    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(currentPlan?.paymentType ?? 'FREE');
    const [billingFormType, setBillingFormType] = useState<BillingForm>(billingEntities.length ? 'saved' : 'create');
    const [paymentMethodErrors, setPaymentMethodErrors] = useState<string | undefined>(
        currentPlan &&
            currentPlan.tier !== 'free' &&
            currentPlan.subscriptionStatus !== 'SUSPENDED' &&
            paymentMethod !== currentPlan.paymentType
            ? paymentMethodChangeErrorMessage
            : undefined
    );
    const [billingContactName, setBillingContactName] = useState('');
    const [billingContactEmail, setBillingContactEmail] = useState('');
    const [selectedBillingEntity, setSelectedBillingEntity] = useState(currentBillingEntity ?? billingEntities[0]?.uid);
    const [billingDetailsErrors, setBillingDetailsErrors] = useState<string | undefined>(
        currentPlan?.paymentType === 'CARD' &&
            currentPlan.tier !== 'free' &&
            currentPlan.subscriptionStatus !== 'SUSPENDED' &&
            selectedBillingEntity &&
            selectedBillingEntity !== currentBillingEntity
            ? billingEntityChangeErrorMessage
            : undefined
    );
    const billingContactNameRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!billingEntities.length) {
            setBillingFormType('create');
        } else {
            setBillingFormType('saved');
        }
    }, [billingEntities.length]);

    useEffect(() => {
        setStage(
            plan?.period === 'annually'
                ? ChangeOrganizationPlanWizardStage.PAYMENT_METHOD
                : ChangeOrganizationPlanWizardStage.BILLING_DETAILS
        );
        setPaymentMethod(currentPlan?.paymentType ?? 'FREE');
        setBillingFormType(billingEntities.length ? 'saved' : 'create');
        setPaymentMethodErrors(
            currentPlan &&
                currentPlan.tier !== 'free' &&
                currentPlan.subscriptionStatus !== 'SUSPENDED' &&
                paymentMethod !== currentPlan.paymentType
                ? paymentMethodChangeErrorMessage
                : undefined
        );
        setBillingContactName('');
        setBillingContactEmail('');
        setSelectedBillingEntity(currentBillingEntity ?? billingEntities[0]?.uid);
        setBillingDetailsErrors(
            currentPlan?.paymentType === 'CARD' &&
                currentPlan.tier !== 'free' &&
                currentPlan.subscriptionStatus !== 'SUSPENDED' &&
                selectedBillingEntity &&
                selectedBillingEntity !== currentBillingEntity
                ? billingEntityChangeErrorMessage
                : undefined
        );
    }, [open]);

    useEffect(() => {
        if (billingFormType === 'create' && stage === ChangeOrganizationPlanWizardStage.BILLING_DETAILS) {
            autoFocus(billingContactNameRef);
        }
    }, [billingFormType]);

    const billingEmailValidationError = !!billingContactEmail && !isEmail(billingContactEmail);

    const hasValidBillingDetails =
        billingFormType === 'saved'
            ? !!selectedBillingEntity
            : !!billingContactEmail && !!billingContactName && !billingEmailValidationError;

    const error = <DialogAlert severity="error" alertTitle="Error" text={errors} />;
    const paymentMethodError = <DialogAlert severity="error" alertTitle="Error" text={paymentMethodErrors} />;
    const billingDetailsError = <DialogAlert severity="error" alertTitle="Error" text={billingDetailsErrors} />;

    const handleChangeOrganizationPlan = (): void => {
        if (plan) {
            const event: ChangeOrganizationPlanWizardDialogEvent = {
                paymentMethod: plan.tier === 'free' ? 'FREE' : plan.period === 'monthly' ? 'CARD' : paymentMethod,
                plan,
            };

            if (billingFormType === 'create') {
                event.newBillingEntity = {
                    contactEmail: billingContactEmail,
                    contactName: billingContactName,
                };
            } else {
                event.existingBillingEntityUid = selectedBillingEntity;
            }

            onCreate(event);
        }
    };

    const handlePaymentMethodChange = (method: PaymentMethod): void => {
        setPaymentMethod(method);
        if (
            currentPlan &&
            currentPlan.tier !== 'free' &&
            currentPlan.subscriptionStatus !== 'SUSPENDED' &&
            method !== currentPlan.paymentType
        ) {
            setPaymentMethodErrors(paymentMethodChangeErrorMessage);
        } else {
            setPaymentMethodErrors(undefined);
        }
    };

    const handleBillingFormChange = (form: BillingForm): void => {
        setBillingFormType(form);
        if (
            form === 'saved' &&
            currentPlan?.paymentType === 'CARD' &&
            currentPlan.tier !== 'free' &&
            currentPlan.subscriptionStatus !== 'SUSPENDED' &&
            selectedBillingEntity &&
            selectedBillingEntity !== currentBillingEntity
        ) {
            setBillingDetailsErrors(billingEntityChangeErrorMessage);
        } else {
            setBillingDetailsErrors(undefined);
        }
    };

    const handleBillingEntityChange = (billingEntity: string): void => {
        setSelectedBillingEntity(billingEntity);
        if (
            currentPlan?.tier !== 'free' &&
            currentPlan?.paymentType === 'CARD' &&
            currentBillingEntity !== billingEntity
        ) {
            setBillingDetailsErrors(billingEntityChangeErrorMessage);
        } else {
            setBillingDetailsErrors(undefined);
        }
    };

    const getStageContent = (): JSX.Element => {
        switch (stage) {
            case ChangeOrganizationPlanWizardStage.PAYMENT_METHOD:
                return (
                    <Dialog
                        open={open}
                        onKeyDown={(event) =>
                            handleKeyDown({
                                event,
                                enterFn: () => setStage(ChangeOrganizationPlanWizardStage.BILLING_DETAILS),
                                escFn: onCancel,
                            })
                        }
                    >
                        {loading ? (
                            <AbsoluteCenteredLoadingSpinner />
                        ) : (
                            <>
                                <ChangeOrganizationPlanWizardSteps stage={stage} period={plan?.period} />
                                <DialogTitleMain
                                    title="Payment method"
                                    icon={<IconCircle icon={<PaymentsOutlinedIcon />} />}
                                />
                                {errors && error}
                                {paymentMethodErrors && paymentMethodError}
                                <DialogContent>
                                    <StyledPaymentMethodRadioGroup
                                        name="controlled-radio-buttons-group-billing"
                                        value={paymentMethod}
                                        onChange={(e) => handlePaymentMethodChange(e.target.value as PaymentMethod)}
                                    >
                                        <StyledFormControlLabel
                                            value="CARD"
                                            control={<Radio />}
                                            label={
                                                <>
                                                    <StyledFormControlLabelTitle>
                                                        <CreditCardIcon />
                                                        <StyledFormControlLabelText>
                                                            Credit / Debit Card
                                                        </StyledFormControlLabelText>
                                                    </StyledFormControlLabelTitle>
                                                </>
                                            }
                                            sx={{
                                                '&>.MuiTypography-root': {
                                                    fontWeight: paymentMethod === 'CARD' ? 500 : 400,
                                                },
                                            }}
                                        />
                                        <StyledFormControlLabel
                                            value="INVOICE"
                                            control={<Radio />}
                                            label={
                                                <>
                                                    <StyledFormControlLabelTitle>
                                                        <ReceiptIcon />
                                                        <StyledFormControlLabelText>Invoice</StyledFormControlLabelText>
                                                    </StyledFormControlLabelTitle>
                                                </>
                                            }
                                            sx={{
                                                '&>.MuiTypography-root': {
                                                    fontWeight: paymentMethod === 'INVOICE' ? 500 : 400,
                                                },
                                            }}
                                        />
                                    </StyledPaymentMethodRadioGroup>
                                    {paymentMethod === 'INVOICE' && <OrganizationInvoicePlanInformation />}
                                </DialogContent>
                                <DialogActions>
                                    <Button variant="outlined" onClick={onCancel}>
                                        Cancel
                                    </Button>
                                    <Button
                                        disabled={!!paymentMethodErrors || paymentMethod === 'FREE'}
                                        onClick={() => setStage(ChangeOrganizationPlanWizardStage.BILLING_DETAILS)}
                                    >
                                        Next
                                    </Button>
                                </DialogActions>
                            </>
                        )}
                    </Dialog>
                );
            case ChangeOrganizationPlanWizardStage.BILLING_DETAILS:
                return (
                    <Dialog
                        open={open}
                        onKeyDown={(event) =>
                            handleKeyDown({
                                event,
                                enterCondition: hasValidBillingDetails,
                                enterFn: handleChangeOrganizationPlan,
                                escFn: onCancel,
                            })
                        }
                    >
                        {loading ? (
                            <AbsoluteCenteredLoadingSpinner />
                        ) : (
                            <>
                                {plan?.period === 'annually' && (
                                    <ChangeOrganizationPlanWizardSteps stage={stage} period={plan?.period} />
                                )}
                                <DialogTitleMain
                                    title="Billing details"
                                    icon={<IconCircle icon={<ReceiptLongOutlinedIcon />} />}
                                />
                                {errors && error}
                                {billingDetailsErrors && billingDetailsError}
                                {paymentMethod === 'INVOICE' && (
                                    <StyledRemark>
                                        <Typography color="text.secondary">
                                            We will get in touch to arrange payment via Invoice
                                        </Typography>
                                    </StyledRemark>
                                )}
                                <DialogContent>
                                    <StyledAccordion
                                        disabled={!billingEntities.length}
                                        expanded={billingFormType === 'saved'}
                                        onChange={(_, checked) =>
                                            checked ? handleBillingFormChange('saved') : undefined
                                        }
                                    >
                                        <StyledAccordionSummary
                                            expandIcon={billingFormType === 'saved' ? <RemoveIcon /> : <AddIcon />}
                                        >
                                            Use existing billing details
                                        </StyledAccordionSummary>
                                        <AccordionDetails>
                                            <ExistingBillingDetailsList
                                                billingEntities={billingEntities}
                                                onSelect={(uid) => handleBillingEntityChange(uid)}
                                                selectedBillingEntity={selectedBillingEntity}
                                            />
                                        </AccordionDetails>
                                    </StyledAccordion>
                                    <StyledAccordion
                                        disabled={
                                            currentPlan?.paymentType === 'CARD' &&
                                            currentPlan.tier !== 'free' &&
                                            currentPlan.subscriptionStatus !== 'SUSPENDED'
                                        }
                                        expanded={billingFormType === 'create'}
                                        onChange={(_, checked) =>
                                            checked ? handleBillingFormChange('create') : undefined
                                        }
                                    >
                                        <StyledAccordionSummary
                                            expandIcon={billingFormType === 'create' ? <RemoveIcon /> : <AddIcon />}
                                        >
                                            Create new billing details
                                        </StyledAccordionSummary>
                                        <AccordionDetails>
                                            <>
                                                <TextField
                                                    inputRef={billingContactNameRef}
                                                    variant="outlined"
                                                    label="Billing contact name"
                                                    placeholder="Enter a name"
                                                    value={billingContactName}
                                                    required
                                                    onChange={(event) => {
                                                        setBillingContactName(event.target.value);
                                                    }}
                                                    sx={{ marginTop: 2 }}
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    label="Email"
                                                    placeholder="Enter an email"
                                                    required
                                                    error={billingEmailValidationError}
                                                    value={billingContactEmail}
                                                    onChange={(event) => {
                                                        setBillingContactEmail(event.target.value);
                                                    }}
                                                />
                                            </>
                                        </AccordionDetails>
                                    </StyledAccordion>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        busy={saving}
                                        onClick={() =>
                                            plan?.period === 'annually'
                                                ? setStage(ChangeOrganizationPlanWizardStage.PAYMENT_METHOD)
                                                : onCancel()
                                        }
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        disabled={!hasValidBillingDetails || !!billingDetailsErrors}
                                        busy={saving}
                                        onClick={handleChangeOrganizationPlan}
                                    >
                                        {paymentMethod === 'CARD' ? 'Proceed to payment' : 'Next'}
                                    </Button>
                                </DialogActions>
                            </>
                        )}
                    </Dialog>
                );
        }
    };

    return getStageContent();
};
