import { BehaviorSubject, map, Subject } from 'rxjs';
import { getEventListenerSetupDetails } from '../../../data/event-listener';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';

export const salesforceEventListenerSetupDialogOpenAction$ = monitor(
    'salesforceEventListenerSetupDialogOpenAction$',
    new Subject<SalesforceEventListenerSetupBaseDetails>()
);
export const salesforceEventListenerSetupDialogCloseAction$ = monitor(
    'salesforceEventListenerSetupDialogCloseAction$',
    new Subject<void>()
);

export const salesforceEventListenerSetupDialogLoading$ = monitor(
    'salesforceEventListenerSetupDialogLoading$',
    new BehaviorSubject(false)
);
export const salesforceEventListenerSetupDialogOpen$ = monitor(
    'salesforceEventListenerSetupDialogOpen$',
    new BehaviorSubject(false)
);
export const salesforceEventListenerSetupDialogDetails$ = monitor(
    'salesforceEventListenerSetupDialogDetails$',
    new BehaviorSubject<SalesforceEventListenerSetupDetails | undefined>(undefined)
);

export type SalesforceEventListenerSetupBaseDetails = {
    uid: string;
    environmentUid: string;
    webhookUrl: string;
};

export type SalesforceEventListenerSetupDetails = {
    setupBaseUrl?: string;
} & SalesforceEventListenerSetupBaseDetails;

salesforceEventListenerSetupDialogOpenAction$
    .pipe(
        map(async (details) => {
            salesforceEventListenerSetupDialogOpen$.next(true);
            salesforceEventListenerSetupDialogLoading$.next(true);

            try {
                const { connectionBaseUrl } = await getEventListenerSetupDetails(details.uid, details.environmentUid);

                if (connectionBaseUrl) {
                    salesforceEventListenerSetupDialogDetails$.next({
                        ...details,
                        setupBaseUrl: connectionBaseUrl,
                    });
                } else {
                    salesforceEventListenerSetupDialogDetails$.next(details);
                }

                salesforceEventListenerSetupDialogLoading$.next(false);
            } catch (e) {
                console.error('Error while loading Event Listener setup instructions', e);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to load Event Listener setup instructions, please try again, if the issue persists please contact support.`,
                    toastOptions: {
                        autoClose: false,
                    },
                });

                salesforceEventListenerSetupDialogCloseAction$.next();
            }
        })
    )
    .subscribe();

salesforceEventListenerSetupDialogCloseAction$.subscribe(() => {
    salesforceEventListenerSetupDialogOpen$.next(false);
    salesforceEventListenerSetupDialogDetails$.next(undefined);
});
