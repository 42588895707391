import { useObservableState } from 'observable-hooks';
import { SlackEventListenerSetupDialog } from '../../../../components/connections/apps/slack/EventListenerSetupDialog';
import { configTopic$ } from '../../../../store/config';
import {
    slackEventListenerSetupDialogCloseAction$,
    slackEventListenerSetupDialogDetails$,
    slackEventListenerSetupDialogLoading$,
    slackEventListenerSetupDialogOpen$,
} from '../../../../store/connection/slack/setup-event-listener';

export const SlackEventListenerSetupDialogContainer: React.FC = () => {
    const loading = useObservableState(slackEventListenerSetupDialogLoading$);
    const open = useObservableState(slackEventListenerSetupDialogOpen$);
    const details = useObservableState(slackEventListenerSetupDialogDetails$);
    const config = useObservableState(configTopic$);

    return (
        <SlackEventListenerSetupDialog
            loading={loading}
            open={open}
            eventTypeName={details?.eventTypeName ?? ''}
            eventTypeCategory={details?.eventTypeCategory ?? ''}
            webhookBaseUrl={config.trigger?.srcExternalUrl ?? ''}
            webhookUrlId={details?.webhookUrl ?? ''}
            setupBaseUrl={details?.setupBaseUrl}
            onClose={() => slackEventListenerSetupDialogCloseAction$.next()}
        />
    );
};
