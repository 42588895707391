import loader from '@monaco-editor/loader';
import type * as monacoApi from 'monaco-editor/esm/vs/editor/editor.api';
import { ScriptWithUid } from '../../utils/types';
import { MonacoScripts } from './types';

export const addScriptModels = async (scripts: ScriptWithUid[]): Promise<MonacoScripts> => {
    const monaco = await loader.init();
    let oldModels = monaco.editor
        .getModels()
        .filter(
            (oldModel) =>
                !oldModel.uri.path.startsWith('/api/') &&
                !oldModel.uri.path.endsWith('.md') &&
                !oldModel.uri.path.includes('replayInvocationPayload')
        );

    const models: monacoApi.editor.ITextModel[] = [];
    const monacoScripts: MonacoScripts = [];

    scripts.forEach((script) => {
        const uri = monaco.Uri.file(`/${script.name}.ts`);
        const model = monaco.editor.getModel(uri);

        if (!model) {
            monaco.editor.createModel(script.content, 'typescript', uri);
        } else {
            models.push(model);
            oldModels = oldModels.filter((oldModel) => uri.path !== oldModel.uri.path);
        }

        monacoScripts.push({ uid: script.uid, name: script.name, uri });
    });

    oldModels.forEach((model) => model.dispose());

    // Refresh every model that already was registered in Monaco
    models.forEach((model) => model.setValue(model.getValue()));
    return monacoScripts;
};
