import { useObservableState } from 'observable-hooks';
import { StatuspageEventListenerSetupDialog } from '../../../../components/connections/apps/statuspage/EventListenerSetupDialog';
import { configTopic$ } from '../../../../store/config';
import {
    statuspageEventListenerSetupDialogCloseAction$,
    statuspageEventListenerSetupDialogDetails$,
    statuspageEventListenerSetupDialogOpen$,
} from '../../../../store/connection/statuspage/setup-event-listener';

export const StatuspageEventListenerSetupDialogContainer: React.FC = () => {
    const open = useObservableState(statuspageEventListenerSetupDialogOpen$);
    const details = useObservableState(statuspageEventListenerSetupDialogDetails$);
    const config = useObservableState(configTopic$);

    return (
        <StatuspageEventListenerSetupDialog
            open={open}
            webhookBaseUrl={config.trigger?.srcExternalUrl ?? ''}
            webhookUrlId={details?.webhookUrl ?? ''}
            onClose={() => statuspageEventListenerSetupDialogCloseAction$.next()}
        />
    );
};
