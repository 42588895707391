import React from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';

export interface HtmlHead {
    name?: string;
}

export const HtmlHead: React.FC<HtmlHead> = ({ name }) => {
    return (
        <HelmetProvider>
            <Helmet>
                <title>{name}</title>
            </Helmet>
        </HelmetProvider>
    );
};
