import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React from 'react';
import { ClientInfo, GitlabConnection, InstanceType } from './GitlabAppConfigureDialog';

export enum GitlabWizardStage {
    CHOOSEINSTANCETYPE,
    ADDURL,
    CREATE,
    DETAILS,
    AUTHORIZE,
    SUCCESS,
}

export interface GitlabWizardStepsProps {
    readonly stage: GitlabWizardStage;
    readonly instanceType: 'SAAS' | 'SELF-MANAGED';
}

export const GitlabWizardSteps: React.FC<GitlabWizardStepsProps> = ({ stage, instanceType = 'SAAS' }) => {
    return instanceType === 'SELF-MANAGED' ? (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={GitlabWizardStage.CHOOSEINSTANCETYPE}>
                <StepLabel>Choose Instance type</StepLabel>
            </Step>
            <Step key={GitlabWizardStage.ADDURL}>
                <StepLabel>Add Gitlab Instance URL</StepLabel>
            </Step>
            <Step key={GitlabWizardStage.CREATE}>
                <StepLabel>Create Application</StepLabel>
            </Step>
            <Step key={GitlabWizardStage.DETAILS}>
                <StepLabel>Enter Details</StepLabel>
            </Step>
            <Step key={GitlabWizardStage.AUTHORIZE}>
                <StepLabel>Authorize</StepLabel>
            </Step>
        </Stepper>
    ) : (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={GitlabWizardStage.CHOOSEINSTANCETYPE}>
                <StepLabel>Choose Instance type</StepLabel>
            </Step>
            <Step key={GitlabWizardStage.AUTHORIZE}>
                <StepLabel>Authorize</StepLabel>
            </Step>
        </Stepper>
    );
};

export type StageType = React.FC<
    GitlabConnection & {
        stage: GitlabWizardStage;
        setStage: (stage: GitlabWizardStage) => void;
        onConfirmationModalOpen: (clientInfo: ClientInfo) => void;
        onChooseInstanceType: (type: InstanceType) => void;
    }
>;
