import { LoaderFn } from 'react-location';
import { LocationGenerics } from '../../router';
import { selectScriptAction$, unsavedScriptDetails$ } from '../../store/workspace/script';
import { getUnsavedScriptCached, legacyImportsRegex } from '../../utils/script';
import { loadErrorPage } from '../../store/error';
import { legacyPackageImports$ } from '../../store/editor/editor';
import { selectedReadOnlyTemplate$ } from '../../store/templates';
import { getTemplate } from '../../data/template';

export const scriptDetailsLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    try {
        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const scriptUid = routeMatch.params.scriptUid!;
        const environmentUid = routeMatch.params.environmentUid;
        const templateUid = routeMatch.params.templateUid;

        const isTemplate = !environmentUid;

        const template = isTemplate
            ? selectedReadOnlyTemplate$.value ?? (await getTemplate(templateUid ?? ''))
            : undefined;

        const currentEnvironmentUid = isTemplate ? template?.environmentUid : environmentUid;

        await getUnsavedScriptCached(scriptUid, currentEnvironmentUid);
        selectScriptAction$.next({ scriptUid, environmentUid: currentEnvironmentUid ?? '' });
        const scriptKey = `${scriptUid ?? ''}_${environmentUid ?? ''}`;
        const selectedScript = unsavedScriptDetails$.value[scriptKey];
        const legacyPackageImports = selectedScript?.content.match(legacyImportsRegex) || null;
        legacyPackageImports$.next(legacyPackageImports);
    } catch (e) {
        loadErrorPage({
            error: e,
            background: 'paper',
            genericMessage: 'Failed to load script.',
        });

        throw e;
    }

    return {};
};
