import { LoaderFn } from 'react-location';
import { getUsersWithUtilization } from '../../data/report/usersUtilization';
import { LocationGenerics } from '../../router';
import { userUtilizationReportData$ } from '../../store/report/usersUtilization';

export const usersUtilizationReportLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    const users = await getUsersWithUtilization({
        timePeriod: routeMatch.search.timePeriod ? (routeMatch.search.timePeriod as string).substring(1) : undefined,
    });

    userUtilizationReportData$.next(users);

    return {};
};
