export const isHashWebapp = (): boolean => !!process.env.PUBLIC_URL.substring(1).length;

export const getBasePath = (): string => {
    return isHashWebapp() ? process.env.PUBLIC_URL + '/' : '/';
};

export const getPathName = (): string => {
    const pathNameParts = window.location.pathname.split('/');
    return isHashWebapp() && pathNameParts[2] ? pathNameParts[2] : pathNameParts[1] ?? 'root';
};
