import React from 'react';
import { styled } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

export interface WizardMinimizedIconProps {
    displayed?: boolean;
    onClose(): void;
    onResume(): void;
}

const StyledCancelIcon = styled(CancelIcon)(({ theme }) => ({
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    height: 12,
    pointerEvents: 'all',
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    width: 12,
    zIndex: 2,
    '&:hover': {
        color: theme.palette.secondary.dark,
    },
}));

const StyledContainer = styled('div')(() => ({
    backgroundColor: 'transparent',
    bottom: 96,
    height: 48,
    left: 16,
    position: 'absolute',
    width: 48,
    zIndex: 1200,
}));

const StyledIcon = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.constants.borderRadius,
    boxShadow: theme.constants.boxShadow,
    cursor: 'pointer',
    height: '100%',
    width: '100%',
    zIndex: 1,
    '&:hover': {
        outline: theme.constants.borderSelected,
    },
    '& img': {
        height: 40,
        left: '50%',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
}));

export const WizardMinimizedIcon: React.FC<WizardMinimizedIconProps> = ({ displayed, onClose, onResume }) => {
    return displayed ? (
        <StyledContainer>
            <StyledCancelIcon
                onClick={(e) => {
                    e.stopPropagation();
                    onClose();
                }}
            />
            <StyledIcon onClick={onResume}>
                <img
                    className="chromatic-ignore"
                    src={process.env.PUBLIC_URL + '/wizard-wave-slow.gif'}
                    alt="Wizard waving"
                />
            </StyledIcon>
        </StyledContainer>
    ) : null;
};
