import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React from 'react';

export interface AzureDevopsConnection {
    clientInfo: ClientInfo;
    name: string;
    open: boolean;
    onClose: () => void;
    saving: boolean;
    currentStage: AzureDevopsWizardStage;
    setStage: (stage: AzureDevopsWizardStage) => void;
    onSave: (props: ClientInfo) => void;
    errors?: string;
    setError: (error: string) => void;
    clearErrors: () => void;
    callbackUrl: string;
    adminConsentUrl: string;
}

export interface ClientInfo {
    clientId: string;
    clientSecret: string;
    tenantId: string;
}

export type InstanceType = 'FULLY-MANAGED' | 'SELF-MANAGED';

export enum AzureDevopsWizardStage {
    CHOOSEINSTANCETYPE,
    CREATE,
    CONFIGURE,
    SECRET,
    SCOPES,
    CONSENT,
    AUTHORIZE,
    SUCCESS,
}

export interface AzureDevopsWizardStepsProps {
    readonly stage: AzureDevopsWizardStage;
    readonly instanceType: 'FULLY-MANAGED' | 'SELF-MANAGED';
}

export const AzureDevopsWizardSteps: React.FC<AzureDevopsWizardStepsProps> = ({ stage, instanceType }) => {
    const isFullyManaged = instanceType === 'FULLY-MANAGED';
    return (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={AzureDevopsWizardStage.CHOOSEINSTANCETYPE}>
                <StepLabel>Choose instance type</StepLabel>
            </Step>
            <Step
                key={AzureDevopsWizardStage.CREATE}
                completed={stage >= AzureDevopsWizardStage.CONFIGURE && !isFullyManaged}
                disabled={isFullyManaged}
            >
                <StepLabel>Create Application</StepLabel>
            </Step>
            <Step
                key={AzureDevopsWizardStage.CONFIGURE}
                completed={stage >= AzureDevopsWizardStage.SECRET && !isFullyManaged}
                disabled={isFullyManaged}
            >
                <StepLabel>Configure Application</StepLabel>
            </Step>
            <Step
                key={AzureDevopsWizardStage.SECRET}
                completed={stage >= AzureDevopsWizardStage.SCOPES && !isFullyManaged}
                disabled={isFullyManaged}
            >
                <StepLabel>Generate Application Secret</StepLabel>
            </Step>
            <Step
                key={AzureDevopsWizardStage.SCOPES}
                completed={stage >= AzureDevopsWizardStage.AUTHORIZE && !isFullyManaged}
                disabled={isFullyManaged}
            >
                <StepLabel>Configure Permissions</StepLabel>
            </Step>
            <Step
                key={AzureDevopsWizardStage.CONSENT}
                completed={stage === AzureDevopsWizardStage.AUTHORIZE && isFullyManaged}
                disabled={instanceType === 'SELF-MANAGED'}
            >
                <StepLabel>Obtain Administrator Consent</StepLabel>
            </Step>
            <Step key={AzureDevopsWizardStage.AUTHORIZE}>
                <StepLabel>Authorize</StepLabel>
            </Step>
        </Stepper>
    );
};
