import { LoaderFn } from 'react-location';
import { getApiHandler } from '../../data/api-handler';
import { LocationGenerics } from '../../router';
import { loadApps } from '../../store/apps';
import { loadLoggedInUserConnections } from '../../store/connection/utils';
import { selectedEnvironmentUid$, selectedWorkspaceSelectedResource$ } from '../../store/workspace';
import { selectedApiHandlerDetails$, selectedApiHandlerErrors$ } from '../../store/workspace/api-handler';
import { loadErrorPage } from '../../store/error';

export const apiHandlerDetailsLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    try {
        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const apiHandlerUid = routeMatch.params.apiHandlerUid!;
        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const environmentUid = routeMatch.params.environmentUid!;

        const [apiHandler] = await Promise.all([getApiHandler(apiHandlerUid, environmentUid), loadApps()]);
        await loadLoggedInUserConnections(apiHandler.connectionTypeUid);

        selectedApiHandlerDetails$.next(apiHandler);
        selectedWorkspaceSelectedResource$.next({
            resourceType: 'API_HANDLER',
            uid: apiHandlerUid,
        });
        selectedApiHandlerErrors$.next(undefined);
    } catch (e) {
        loadErrorPage({
            error: e,
            background: 'paper',
            genericMessage: 'Failed to load API Connection.',
        });

        throw e;
    }

    return {};
};

export const readOnlyApiHandlerDetailsLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    try {
        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const apiHandlerUid = routeMatch.params.apiHandlerUid!;

        const selectedEnvironmentUid = selectedEnvironmentUid$.value;
        const [selectedApiHandler] = await Promise.all([
            getApiHandler(apiHandlerUid, selectedEnvironmentUid),
            loadApps(),
        ]);
        if (!selectedApiHandler) {
            throw new Error('Api handler details cannot be found');
        }

        selectedApiHandlerDetails$.next({
            ...selectedApiHandler,
            connectionTypeUid: '',
        });
        selectedWorkspaceSelectedResource$.next({
            resourceType: 'API_HANDLER',
            uid: apiHandlerUid,
        });
        selectedApiHandlerErrors$.next(undefined);
    } catch (e) {
        loadErrorPage({
            error: e,
            background: 'paper',
            genericMessage: 'Failed to load API Connection details.',
        });
        throw e;
    }

    return {};
};
