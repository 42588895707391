import { BehaviorSubject } from 'rxjs';
import { monitor } from './monitor';

export interface InvocationLog {
    id: string;
    severity: string;
    message: string;
    timestamp?: number;
    linkUrl?: string;
    largePayload?: boolean;
}

export const selectedInvocationId$ = monitor(
    'selectedInvocationId$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const selectedInvocationLogs$ = monitor('selectedInvocationLogs$', new BehaviorSubject<InvocationLog[]>([]));

export const selectedInvocationLogId$ = monitor(
    'selectedInvocationLogId$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const selectedInvocationLogContent$ = monitor(
    'selectedInvocationLogContent$',
    new BehaviorSubject<string | undefined>(undefined)
);
