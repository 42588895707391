import { useObservableState, useSubscription } from 'observable-hooks';
import { useNavigate } from 'react-location';
import {
    OrganizationPlanDetails,
    OrganizationPlanUpdateEvent,
} from '../../../components/organizations/plan/OrganizationPlanDetails';
import {
    selectedOrganizationPlan$,
    selectedOrganizationUid$,
    organizationPlanChangedAction$,
    organizationInvoicePlanInformationDialogOpen$,
    organizationSwitchedAction$,
} from '../../../store/organization';
import {
    changeOrganizationPlanValidationError$,
    organizationPlanUpdating$,
    openChangeOrganizationPlanConfirmationDialogAction$,
    resumeOrganizationPlanAction$,
} from '../../../store/organization/changeOrganizationPlan';
import { openCancelOrganizationPlanFlowDialogAction$ } from '../../../store/organization/cancelOrganizationPlan';
import { getBasePath } from '../../../utils/path';

export const OrganizationPlanContainer: React.FC = () => {
    const navigate = useNavigate();

    const plan = useObservableState(selectedOrganizationPlan$);
    const orgUid = useObservableState(selectedOrganizationUid$);
    const updating = useObservableState(organizationPlanUpdating$);
    const errors = useObservableState(changeOrganizationPlanValidationError$);

    useSubscription(organizationPlanChangedAction$, () => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        navigate({ to: '../' });
        setTimeout(() => navigate({ to: `${getBasePath()}team/${orgUid}/plan` }), 100);
    });

    useSubscription(organizationSwitchedAction$, (uid) => navigate({ to: `${getBasePath()}team/${uid}/plan` }));

    const handleUpdate = (selectedPlan?: OrganizationPlanUpdateEvent): void => {
        if (selectedPlan) {
            openChangeOrganizationPlanConfirmationDialogAction$.next(selectedPlan);
        }
    };

    const handleResume = (): void => {
        if (orgUid) {
            resumeOrganizationPlanAction$.next({ organizationUid: orgUid });
        }
    };

    return (
        <OrganizationPlanDetails
            plan={
                plan && {
                    ...plan,
                    period: plan.period.toLocaleLowerCase() as 'monthly' | 'annually',
                }
            }
            errors={errors}
            updating={updating}
            onUpgrade={handleUpdate}
            onCancel={() => openCancelOrganizationPlanFlowDialogAction$.next()}
            onResume={handleResume}
            onOpenInformation={() => organizationInvoicePlanInformationDialogOpen$.next(true)}
        />
    );
};
