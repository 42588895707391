import { LoaderFn } from 'react-location';
import { getOrganisations, OrganisationsRequest } from '../../data/report/organisations';
import { LocationGenerics } from '../../router';
import { organisations$ } from '../../store/report/organisations';

export const organisationsReportLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    const organisations = await getOrganisations({
        userType: routeMatch.search.userType as OrganisationsRequest['userType'],
        status: routeMatch.search.status as OrganisationsRequest['status'],
        plan: routeMatch.search.plan as OrganisationsRequest['plan'],
        planPeriod: routeMatch.search.planPeriod as OrganisationsRequest['planPeriod'],
    });
    organisations$.next(organisations);

    return {};
};
