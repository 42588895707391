import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { BitbucketCloudConnection } from './BitbucketCloudAppConfigureDialog';

export enum BitbucketCloudWizardStage {
    ADDURL,
    CREATE,
    DETAILS,
    AUTHORIZE,
    SUCCESS,
}

export interface BitbucketCloudWizardStepsProps {
    readonly stage: BitbucketCloudWizardStage;
}

export const BitbucketCloudWizardSteps: React.FC<BitbucketCloudWizardStepsProps> = ({ stage }) => {
    return (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={BitbucketCloudWizardStage.ADDURL}>
                <StepLabel>Add Bitbucket Cloud Workspace URL</StepLabel>
            </Step>
            <Step key={BitbucketCloudWizardStage.CREATE}>
                <StepLabel>Create Application in Bitbucket Cloud</StepLabel>
            </Step>
            <Step key={BitbucketCloudWizardStage.DETAILS}>
                <StepLabel>Enter New Application Details</StepLabel>
            </Step>
            <Step key={BitbucketCloudWizardStage.AUTHORIZE}>
                <StepLabel>Authorize</StepLabel>
            </Step>
        </Stepper>
    );
};

export type StageType = React.FC<
    BitbucketCloudConnection & {
        stage: BitbucketCloudWizardStage;
        setStage: (stage: BitbucketCloudWizardStage) => void;
    }
>;
