import { useObservableState, useSubscription } from 'observable-hooks';
import {
    billingEntityUpdating$,
    billingEntityUpdatedAction$,
    updateBillingEntityAction$,
    updateBillingEntityValidationError$,
    selectedBillingDetails$,
} from '../../../store/billing-details';
import { useLocation, useNavigate } from 'react-location';
import { getBasePath } from '../../../utils/path';
import { EditBillingDetails } from '../../../components/organizations/billing/EditBillingDetails';

export const BillingEntityContainer: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const updateError = useObservableState(updateBillingEntityValidationError$);
    const updating = useObservableState(billingEntityUpdating$);
    const { uid, contactName, contactEmail, associatedOrganizations } =
        useObservableState(selectedBillingDetails$) || {};

    useSubscription(billingEntityUpdatedAction$, () => {
        handleGoBack();
    });

    const handleGoBack = (): void => {
        if (location.current.key !== 'default') {
            location.history.back();
        } else {
            navigate({ to: `${getBasePath()}billing` });
        }
    };

    return (
        <EditBillingDetails
            uid={uid || ''}
            name={contactName || ''}
            email={contactEmail || ''}
            organizations={associatedOrganizations}
            errors={updateError}
            onUpdate={(event) => updateBillingEntityAction$.next(event)}
            onCancel={handleGoBack}
            updating={updating}
        />
    );
};
