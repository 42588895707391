/* eslint-disable sonarjs/no-duplicate-string */
import { useState } from 'react';
import { styled } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { BillingPlanCard } from '../create-organization-wizard/BillingPlanCard';
import { Button } from '../../common/buttons/Button';
import { Plan, prices } from '../create-organization-wizard/CreateOrganizationWizardDialog';
import { DialogAlert } from '../../for-deprecation/dialog/DialogComponents';
import { OrganizationPlan } from '../../../data/organization';
import { formatBytesToString } from '../../../utils/formatBytes';
import { limitsAndQuotasDocumentationUrl } from '../../../utils/documentation';

interface OrganizationPlanDetailsProps {
    errors?: string;
    plan?: Omit<OrganizationPlan, 'period'> & { period: Plan['period'] };
    updating: boolean;
    onUpgrade: (event?: OrganizationPlanUpdateEvent) => void;
    onCancel: () => void;
    onResume?: () => void;
    onOpenInformation?: () => void;
}

export interface OrganizationPlanUpdateEvent {
    tier: Plan['tier'];
    period: Plan['period'];
}

const StyledCancelPlanButton = styled(Button)(({ theme }) => ({
    color: theme.palette.error.main,
}));

const StyledDivContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'end',
    marginTop: theme.spacing(1),
}));

const StyledCardContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(1),
    '& .MuiCard-root:not(:last-of-type)': {
        marginRight: theme.spacing(2),
    },
}));

const StyledContainer = styled('div')(() => ({
    minHeight: '100%',
}));

const StyledContent = styled('div')(() => ({
    display: 'inline-block',
    position: 'relative',
}));

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(1),
}));

const StyledChip = styled(Chip)(({ theme }) => ({
    paddingLeft: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    borderRadius: 0,
}));

const StyledSelectPaymentMethodContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    paddingLeft: theme.spacing(0.5),
    justifyContent: 'start',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
}));

const StyledPaymentMethodContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const StyledPaymentMethodTooltipContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
}));

const StyledPaymentMethodTooltipButton = styled(Button)(() => ({
    textTransform: 'capitalize',
}));

const StyledSpan = styled('span')(({ theme }) => ({
    color: theme.palette.success.main,
}));

const StyledDialogAlert = styled(DialogAlert)(() => ({
    '& .MuiAlert-action': {
        paddingTop: 0,
        displey: 'flex',
        alignItems: 'center',
    },
}));

const StyledResumeButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
    '&:hover': {
        backgroundColor: theme.palette.success.dark,
    },
}));

export const OrganizationPlanDetails: React.FC<OrganizationPlanDetailsProps> = ({
    errors,
    plan,
    updating,
    onUpgrade,
    onCancel,
    onResume,
    onOpenInformation,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const isCustomPlan = !['free', 'silver', 'gold', 'platinum', 'suspended'].includes(plan?.tier || '');
    const [currentPeriod, setCurrentPeriod] = useState<Plan['period']>(plan?.period || 'annually');

    return (
        <StyledContainer>
            {plan?.subscriptionStatus === 'CANCELLED' && (
                <StyledDialogAlert
                    severity="warning"
                    text="Resume your team plan to process events after subscription end date"
                    alertTitle="Team plan is suspended"
                    variant="outlined"
                    action={
                        <StyledResumeButton startIcon={<AutorenewIcon />} busy={updating} onClick={onResume}>
                            Resume plan
                        </StyledResumeButton>
                    }
                />
            )}
            {plan?.subscriptionStatus === 'SUSPENDED' && (
                <StyledDialogAlert
                    severity="error"
                    text="Reactivate your team plan to resume event processing for all affected workspaces"
                    alertTitle="Team plan is cancelled"
                    variant="outlined"
                    action={
                        <StyledResumeButton
                            startIcon={<AutorenewIcon />}
                            busy={updating}
                            onClick={() =>
                                onUpgrade({
                                    tier: plan.tier as Plan['tier'],
                                    period: plan.period,
                                })
                            }
                        >
                            Reactivate plan
                        </StyledResumeButton>
                    }
                />
            )}
            {errors && <DialogAlert severity="error" text={errors} alertTitle="Error" />}
            <StyledContent>
                <StyledRadioGroup
                    name="controlled-radio-buttons-group-plan"
                    value={currentPeriod}
                    onChange={(e) => setCurrentPeriod(e.target.value as Plan['period'])}
                >
                    <FormControlLabel
                        value="monthly"
                        control={<Radio />}
                        label="Pay monthly"
                        sx={{
                            '&>.MuiTypography-root':
                                currentPeriod === 'monthly' ? { fontWeight: 500 } : { fontWeight: 400 },
                        }}
                    />

                    <FormControlLabel
                        value="annually"
                        control={<Radio />}
                        label={
                            <>
                                <span>Yearly billing </span>
                                <StyledSpan>(2 months for free)</StyledSpan>
                            </>
                        }
                        sx={{
                            '&>.MuiTypography-root':
                                currentPeriod === 'annually' ? { fontWeight: 500 } : { fontWeight: 400 },
                        }}
                    />
                </StyledRadioGroup>
                <StyledPaymentMethodContainer>
                    <StyledSelectPaymentMethodContainer>
                        <Typography color="text.secondary">Available Payment Methods:</Typography>
                        <StyledChip label="Credit / Debit Card" icon={<CreditCardIcon />} />
                        {currentPeriod === 'annually' && <StyledChip label="Invoice" icon={<ReceiptIcon />} />}
                    </StyledSelectPaymentMethodContainer>
                    <StyledPaymentMethodTooltipContainer>
                        <Tooltip
                            title="To switch between invoice and credit card payment methods, please create a new team and select your preferred payment method during the setup process."
                            placement="top"
                        >
                            <StyledPaymentMethodTooltipButton variant="text" startIcon={<InfoOutlinedIcon />}>
                                Changing Payment Method
                            </StyledPaymentMethodTooltipButton>
                        </Tooltip>
                    </StyledPaymentMethodTooltipContainer>
                </StyledPaymentMethodContainer>
                <StyledCardContainer>
                    <BillingPlanCard
                        active={plan?.tier === 'free' && plan.period === currentPeriod}
                        disabled={plan?.subscriptionStatus !== 'SUSPENDED'}
                        subscriptionStatus={plan?.subscriptionStatus}
                        planEndDate={plan?.endDate}
                        title="Free"
                        price="Free"
                        timeUnit={currentPeriod === 'monthly' ? 'monthly' : 'yearly'}
                        buttonText={
                            plan?.subscriptionStatus === 'SUSPENDED' ||
                            plan?.tier === 'custom' ||
                            (plan?.tier === 'free' && plan.period !== currentPeriod)
                                ? 'Select free'
                                : plan?.tier === 'free'
                                ? 'Your current plan'
                                : 'Downgrade to free'
                        }
                        value="free"
                        loading={updating}
                        onUpgrade={() =>
                            onUpgrade({
                                tier: 'free',
                                period: currentPeriod,
                            })
                        }
                    />
                    <BillingPlanCard
                        active={plan?.tier === 'silver' && plan.period === currentPeriod}
                        planEndDate={plan?.endDate}
                        subscriptionStatus={plan?.subscriptionStatus}
                        paymentMethod={plan?.paymentType}
                        disabled={
                            (plan?.paymentType === 'INVOICE' &&
                                plan.subscriptionStatus !== 'SUSPENDED' &&
                                currentPeriod === 'monthly') ||
                            plan?.subscriptionStatus === 'CANCELLED'
                        }
                        title="Silver"
                        price={prices.silver[currentPeriod]}
                        timeUnit={currentPeriod === 'monthly' ? 'monthly' : 'yearly'}
                        value="silver"
                        buttonText={
                            plan?.subscriptionStatus === 'SUSPENDED' ||
                            plan?.tier === 'custom' ||
                            (plan?.tier === 'silver' && plan.period !== currentPeriod)
                                ? 'Select silver'
                                : plan?.tier === 'silver'
                                ? 'Your current plan'
                                : plan?.tier === 'free'
                                ? 'Upgrade to silver'
                                : 'Downgrade to silver'
                        }
                        loading={updating}
                        onUpgrade={() =>
                            onUpgrade({
                                tier: 'silver',
                                period: currentPeriod,
                            })
                        }
                        onOpen={onOpenInformation}
                    />
                    <BillingPlanCard
                        active={plan?.tier === 'gold' && plan.period === currentPeriod}
                        planEndDate={plan?.endDate}
                        subscriptionStatus={plan?.subscriptionStatus}
                        paymentMethod={plan?.paymentType}
                        disabled={
                            (plan?.paymentType === 'INVOICE' &&
                                plan.subscriptionStatus !== 'SUSPENDED' &&
                                currentPeriod === 'monthly') ||
                            plan?.subscriptionStatus === 'CANCELLED'
                        }
                        title="Gold"
                        price={prices.gold[currentPeriod]}
                        timeUnit={currentPeriod === 'monthly' ? 'monthly' : 'yearly'}
                        value="gold"
                        buttonText={
                            plan?.subscriptionStatus === 'SUSPENDED' ||
                            plan?.tier === 'custom' ||
                            (plan?.tier === 'gold' && plan.period !== currentPeriod)
                                ? 'Select gold'
                                : plan?.tier === 'gold'
                                ? 'Your current plan'
                                : plan?.tier === 'platinum'
                                ? 'Downgrade to gold'
                                : 'Upgrade to gold'
                        }
                        loading={updating}
                        onUpgrade={() =>
                            onUpgrade({
                                tier: 'gold',
                                period: currentPeriod,
                            })
                        }
                        onOpen={onOpenInformation}
                    />
                    <BillingPlanCard
                        active={plan?.tier === 'platinum' && plan.period === currentPeriod}
                        planEndDate={plan?.endDate}
                        subscriptionStatus={plan?.subscriptionStatus}
                        paymentMethod={plan?.paymentType}
                        disabled={
                            (plan?.paymentType === 'INVOICE' &&
                                plan.subscriptionStatus !== 'SUSPENDED' &&
                                currentPeriod === 'monthly') ||
                            plan?.subscriptionStatus === 'CANCELLED'
                        }
                        title="Platinum"
                        price={prices.platinum[currentPeriod]}
                        timeUnit={currentPeriod === 'monthly' ? 'monthly' : 'yearly'}
                        value="platinum"
                        buttonText={
                            plan?.subscriptionStatus === 'SUSPENDED' ||
                            plan?.tier === 'custom' ||
                            (plan?.tier === 'platinum' && plan.period !== currentPeriod)
                                ? 'Select platinum'
                                : plan?.tier === 'platinum'
                                ? 'Your current plan'
                                : 'Upgrade to platinum'
                        }
                        loading={updating}
                        onUpgrade={() =>
                            onUpgrade({
                                tier: 'platinum',
                                period: currentPeriod,
                            })
                        }
                        onOpen={onOpenInformation}
                    />
                    {isCustomPlan && plan?.period === currentPeriod && (
                        <BillingPlanCard
                            active
                            planEndDate={plan?.endDate}
                            subscriptionStatus={plan?.subscriptionStatus}
                            paymentMethod={plan?.paymentType}
                            title="Custom"
                            price={prices.gold[currentPeriod]}
                            timeUnit={currentPeriod === 'monthly' ? 'monthly' : 'yearly'}
                            value="custom"
                            customValues={{
                                cost: plan.cost,
                                connectors: `${plan.connectorsLimit === -1 ? 'Unlimited' : plan.connectorsLimit}`,
                                functionInvocations: `${
                                    plan.invocationsLimit === -1
                                        ? 'Unlimited'
                                        : plan.invocationsLimit.toLocaleString('en-US')
                                }`,
                                storageCapacity: `${formatBytesToString(plan.recordStorageCapacityLimit).replace(
                                    ' ',
                                    ''
                                )}`,
                            }}
                            buttonText="Your current plan"
                            loading={updating}
                            onUpgrade={() => ({})}
                            onOpen={onOpenInformation}
                        />
                    )}
                </StyledCardContainer>
                <StyledDivContainer>
                    <Link href={limitsAndQuotasDocumentationUrl} target="_blank">
                        Additional quotas and limits may apply
                    </Link>
                </StyledDivContainer>
                {plan?.tier !== 'suspended' &&
                    plan?.subscriptionStatus !== 'CANCELLED' &&
                    plan?.subscriptionStatus !== 'SUSPENDED' && (
                        <StyledDivContainer>
                            <StyledCancelPlanButton variant="text" onClick={onCancel}>
                                Cancel Plan
                            </StyledCancelPlanButton>
                        </StyledDivContainer>
                    )}
            </StyledContent>
        </StyledContainer>
    );
};
