import {
    getWorkspaceSharingDetails as getWorkspaceSharingDetailsRpc,
    saveWorkspaceSharingDetails as saveWorkspaceSharingDetailsRpc,
    addWorkspaceToOrganization as addWorkspaceToOrganizationRpc,
} from '@avst-stitch/repository-lib/lib';
import { WorkspaceSharingDetails } from './workspaces';
import { repositoryInvoker } from '../utils/repository';
import { Response } from '@avst-stitch/repository-lib/lib/rpcs/addWorkspaceToOrganization';

export const getWorkspaceSharingDetails = async (workspaceUid: string): Promise<WorkspaceSharingDetails> => {
    return await getWorkspaceSharingDetailsRpc(repositoryInvoker, { workspaceUid });
};

export const saveWorkspaceSharingDetails = async (
    workspaceUid: string,
    visibility: 'PRIVATE' | 'PUBLIC'
): Promise<void> => {
    return await saveWorkspaceSharingDetailsRpc(repositoryInvoker, { workspaceUid, visibility });
};

export const addWorkspaceToOrganization = async (workspaceUid: string, organizationUid: string): Promise<Response> => {
    return await addWorkspaceToOrganizationRpc(repositoryInvoker, { workspaceUid, organizationUid });
};
