import { useObservableState } from 'observable-hooks';
import { DefaultWorkspaceView } from '../../components/workspace-layouts/DefaultWorkspaceView';
import { openAppSelectorAction$ } from '../../store/apps';
import { resourceManagerOffsets$, wizardState$, wizardStep$, wizardWorkspaceDetails$ } from '../../store/wizard';
import { loadingWorkspaceResources$, selectedWorkspaceResources$, selectedWorkspaceUid$ } from '../../store/workspace';
import { createApiHandlerAction$ } from '../../store/workspace/api-handler';
import { createEventListenerAction$ } from '../../store/workspace/event-listener';
import { createNewScriptAction$ } from '../../store/workspace/script';
import {
    navigateToEnvironmentVariablesAction$,
    savedEnvironmentVariables$,
} from '../../store/workspace/environment-variable';
import { navigateToReadmeFileAction$ } from '../../store/workspace/readme';
import { featureFlagsTopic$ } from '../../store/config';

export const DefaultWorkspaceViewContainer: React.FC = () => {
    const { apiHandlers, eventListeners, scripts, readmeFile } = useObservableState(selectedWorkspaceResources$);
    const loadingWorkspaceResources = useObservableState(loadingWorkspaceResources$);
    const blank = !apiHandlers.length && !eventListeners.length && !scripts.length;
    const selectedWorkspaceUid = useObservableState(selectedWorkspaceUid$);
    const wizardState = useObservableState(wizardState$);
    const wizardStep = useObservableState(wizardStep$);
    const wizardWorkspaceDetails = useObservableState(wizardWorkspaceDetails$);
    const resourceManagerOffsets = useObservableState(resourceManagerOffsets$);
    const environmentVariables = useObservableState(savedEnvironmentVariables$);
    const featureFlags = useObservableState(featureFlagsTopic$);

    if (!loadingWorkspaceResources) {
        if (environmentVariables?.length && featureFlags.environmentVariables) {
            navigateToEnvironmentVariablesAction$.next(true);
        } else if (!readmeFile?.isDefault) {
            navigateToReadmeFileAction$.next(readmeFile?.uid ?? '');
        }
    }

    return (
        <DefaultWorkspaceView
            blank={blank}
            loading={loadingWorkspaceResources}
            resourceManagerOffsets={resourceManagerOffsets}
            wizard={{ state: wizardState, step: wizardStep, workspaceDetails: wizardWorkspaceDetails }}
            workspaceUid={selectedWorkspaceUid}
            onCreateNewApiConnection={() =>
                openAppSelectorAction$.next({
                    nextAction: (app) =>
                        createApiHandlerAction$.next({
                            appUid: app.uid,
                            apiHandlerTypeUid: app.connectionType.apiHandlerTypes[0]?.uid ?? '',
                        }),
                    mode: 'API_HANDLER',
                })
            }
            onCreateNewEventListener={() =>
                openAppSelectorAction$.next({
                    nextAction: (app) =>
                        createEventListenerAction$.next({
                            appUid: app.uid,
                            eventListenerTypeUid: app.connectionType.eventListenerTypes[0]?.uid ?? '',
                        }),
                    mode: 'EVENT_LISTENER',
                })
            }
            onCreateNewScript={() => createNewScriptAction$.next()}
        />
    );
};
