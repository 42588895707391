import { useObservableState, useSubscription } from 'observable-hooks';
import {
    billingEntities$,
    billingEntityCreating$,
    createBillingEntityDialogOpen$,
    createBillingEntityAction$,
    createNewBillingEntityValidationError$,
    billingEntityCreatedAction$,
} from '../../../store/billing-details';
import { useEffect } from 'react';
import { useNavigate } from 'react-location';
import { BillingDetails } from '../../../components/organizations/billing';
import { getBasePath } from '../../../utils/path';

export const BillingDetailsContainer: React.FC = () => {
    const navigate = useNavigate();
    const billingEntities = useObservableState(billingEntities$);
    const createError = useObservableState(createNewBillingEntityValidationError$);
    const creating = useObservableState(billingEntityCreating$);
    const createOpen = useObservableState(createBillingEntityDialogOpen$);

    useEffect(() => {
        if (createOpen) {
            createNewBillingEntityValidationError$.next('');
        }
    }, [createOpen]);

    useSubscription(billingEntityCreatedAction$, (uid) => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        // TODO: find a fix
        navigate({ to: '../' });
        setTimeout(() => navigate({ to: `${getBasePath()}billing/${uid}` }), 100);
    });

    return (
        <BillingDetails
            billingEntities={billingEntities}
            createBillingEntityError={createError}
            billingEntityIsCreating={creating}
            createBillingEntityDialogOpen={createOpen}
            onCreateBillingEntity={(event) => createBillingEntityAction$.next(event)}
            onBillingEntityDialogOpen={(open) => createBillingEntityDialogOpen$.next(open)}
            onEditBillingDetails={(uid) => navigate({ to: `${getBasePath()}billing/${uid}` })}
        />
    );
};
