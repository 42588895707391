import { getScriptingFamiliarityOptions as getScriptingFamiliarityOptionsRpc } from '@avst-stitch/repository-lib/lib';
import { saveOnboardingResponses as saveOnboardingResponsesRpc } from '@avst-stitch/repository-lib/lib';
import { getOnboardingTemplates as getOnboardingTemplatesRpc } from '@avst-stitch/repository-lib/lib';
import { Response as scriptingFamiliarityOptions } from '@avst-stitch/repository-lib/lib/rpcs/getScriptingFamiliarityOptions';
import { Request as SaveOnboardingResponsesRequest } from '@avst-stitch/repository-lib/lib/rpcs/saveOnboardingResponses';
import { Response as OnboardingTemplates } from '@avst-stitch/repository-lib/lib/rpcs/getOnboardingTemplates';
import { repositoryInvoker } from '../utils/repository';
export type { scriptingFamiliarityOptions, OnboardingTemplates };

export const getScriptingFamiliarityOptions = async (): Promise<scriptingFamiliarityOptions> => {
    return await getScriptingFamiliarityOptionsRpc(repositoryInvoker);
};

export const saveOnboardingResponses = async (request: SaveOnboardingResponsesRequest): Promise<void> => {
    return await saveOnboardingResponsesRpc(repositoryInvoker, request);
};

export const getOnboardingTemplates = async (): Promise<OnboardingTemplates> => {
    return await getOnboardingTemplatesRpc(repositoryInvoker);
};
