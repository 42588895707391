import { useObservableState } from 'observable-hooks';
import { ServiceNowManageConnectionDialog } from '../../../../components/connections/apps/servicenow/ManageConnectionDialog';
import {
    serviceNowConnectionSaving$,
    serviceNowManageConnectionAuthorizeLoading$,
    serviceNowManageConnectionCloseDialogAction$,
    serviceNowManageConnectionDetails$,
    serviceNowManageConnectionDialogErrors$,
    serviceNowManageConnectionDialogOpen$,
    serviceNowManageConnectionInitiateSetupAction$,
    serviceNowSaveConnectionAction$,
} from '../../../../store/connection/servicenow/setup-connection';
import { loggedInUserConnections$ } from '../../../../store/connections';

export const ServiceNowManageConnectionDialogContainer: React.FC = () => {
    const open = useObservableState(serviceNowManageConnectionDialogOpen$);
    const saving = useObservableState(serviceNowConnectionSaving$);
    const authorizeLoading = useObservableState(serviceNowManageConnectionAuthorizeLoading$);
    const details = useObservableState(serviceNowManageConnectionDetails$);
    const errors = useObservableState(serviceNowManageConnectionDialogErrors$);
    const connection = useObservableState(loggedInUserConnections$).find(
        (connection) => connection.uid === details?.uid
    );

    return (
        <ServiceNowManageConnectionDialog
            open={open}
            saving={saving}
            errors={errors}
            authorizeLoading={authorizeLoading}
            name={connection?.name ?? ''}
            authorized={!!connection?.authorized}
            instanceUrl={details?.instanceUrl}
            onCancel={() => serviceNowManageConnectionCloseDialogAction$.next()}
            onSave={(name) => serviceNowSaveConnectionAction$.next(name)}
            onAuthorize={(name) => serviceNowManageConnectionInitiateSetupAction$.next(name)}
            connectionType={details?.connectionType.name}
        />
    );
};
