import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import RadioGroup from '@mui/material/RadioGroup';
import { Button } from '../../../common/buttons/Button';
import { GithubEventListenerWizardStage, GithubEventListenerWizardSteps } from './EventListenerWizardSteps';
import { ReadOnlyTextField } from '../../../common/textfield/ReadOnlyTextField';
import { handleKeyDown } from '../../../../utils/handleKeyDown';
import { Scope, getMappedWebhooks, getSetupInstructions, getWebhookOptions } from './utils';
import { APP } from '@avst-stitch/repository-lib/constants';

interface GithubEventListenerSetupDialogProps {
    open?: boolean;
    eventTypeName: string;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const GithubEventListenerSetupDialog: React.FC<GithubEventListenerSetupDialogProps> = ({
    open = false,
    webhookBaseUrl,
    webhookUrlId,
    eventTypeName,
    onClose,
}) => {
    const mappedWebhooks = getMappedWebhooks(eventTypeName);

    const [stage, setStage] = useState(GithubEventListenerWizardStage.CHOOSE_WEBHOOK_TYPE);
    const [scope, setScope] = useState<Scope | null>(mappedWebhooks?.scopes[0] ?? null);

    const webhookOptions = getWebhookOptions(eventTypeName);
    const setupInstructions = scope && getSetupInstructions(scope, eventTypeName);

    useEffect(() => {
        setStage(GithubEventListenerWizardStage.CHOOSE_WEBHOOK_TYPE);
        setScope(mappedWebhooks?.scopes[0] ?? null);
    }, [open]);

    const getStageContent = (): JSX.Element => {
        switch (stage) {
            case GithubEventListenerWizardStage.CHOOSE_WEBHOOK_TYPE:
                return (
                    <Dialog
                        open={open}
                        onKeyDown={(event) =>
                            handleKeyDown({
                                event,
                                enterCondition: !!scope,
                                enterFn: () => setStage(GithubEventListenerWizardStage.SETUP_WEBHOOK),
                                escFn: onClose,
                            })
                        }
                    >
                        <GithubEventListenerWizardSteps stage={stage} />
                        <DialogTitle sx={{ mb: 2 }} variant="h6">
                            Event Listener setup instructions for {APP.GITHUB.NAME}
                        </DialogTitle>
                        <DialogContentText variant="subtitle2">
                            You need to setup a webhook in your {APP.GITHUB.NAME} instance in order to listen to events
                            in ScriptRunner Connect. Please select where you wish to create the webhook from the options
                            available for this event:
                        </DialogContentText>
                        <DialogContent>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={scope}
                                onChange={(e) => setScope(e.target.value as Scope)}
                            >
                                {webhookOptions}
                            </RadioGroup>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={!scope} variant="outlined" onClick={() => onClose()}>
                                Cancel
                            </Button>

                            <Button onClick={() => setStage(GithubEventListenerWizardStage.SETUP_WEBHOOK)}>Next</Button>
                        </DialogActions>
                    </Dialog>
                );

            case GithubEventListenerWizardStage.SETUP_WEBHOOK:
                return (
                    <Dialog
                        maxWidth="md"
                        open={open}
                        onKeyDown={(event) => handleKeyDown({ event, enterFn: onClose, escFn: onClose })}
                    >
                        <GithubEventListenerWizardSteps stage={stage} />
                        <DialogTitle sx={{ mb: 2 }} variant="h6">
                            Event Listener setup instructions for {APP.GITHUB.NAME}
                        </DialogTitle>
                        <DialogContent>
                            <ReadOnlyTextField label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />
                            {setupInstructions}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                onClick={() => setStage(GithubEventListenerWizardStage.CHOOSE_WEBHOOK_TYPE)}
                            >
                                Back
                            </Button>

                            <Button onClick={() => onClose()}>Done</Button>
                        </DialogActions>
                    </Dialog>
                );
        }
    };

    if (mappedWebhooks && mappedWebhooks.scopes.length > 1) {
        return getStageContent();
    } else {
        return (
            <Dialog
                maxWidth="md"
                open={open}
                onKeyDown={(event) => handleKeyDown({ event, enterFn: onClose, escFn: onClose })}
            >
                <DialogTitle sx={{ mb: 2 }} variant="h6">
                    Event Listener setup instructions for {APP.GITHUB.NAME}
                </DialogTitle>
                <DialogContent>
                    <ReadOnlyTextField label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />
                    <DialogContentText>This event is only available for {scope}</DialogContentText>
                    {setupInstructions}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose()}>Done</Button>
                </DialogActions>
            </Dialog>
        );
    }
};
