import { BehaviorSubject, map, Subject } from 'rxjs';
import { getEventListenerSetupDetails } from '../../../data/event-listener';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';

export const opsgenieEventListenerSetupDialogOpenAction$ = monitor(
    'opsgenieEventListenerSetupDialogOpenAction$',
    new Subject<OpsgenieEventListenerSetupBaseDetails>()
);
export const opsgenieEventListenerSetupDialogCloseAction$ = monitor(
    'opsgenieEventListenerSetupDialogCloseAction$',
    new Subject<void>()
);
export const opsgenieEventListenerSetupDialogLoading$ = monitor(
    'opsgenieEventListenerSetupDialogLoading$',
    new BehaviorSubject(false)
);
export const opsgenieEventListenerSetupDialogOpen$ = monitor(
    'opsgenieEventListenerSetupDialogOpen$',
    new BehaviorSubject(false)
);
export const opsgenieEventListenerSetupDialogDetails$ = monitor(
    'opsgenieEventListenerSetupDialogDetails$',
    new BehaviorSubject<OpsgenieEventListenerSetupDetails | undefined>(undefined)
);

export type OpsgenieEventListenerSetupBaseDetails = {
    uid: string;
    environmentUid: string;
    webhookUrl: string;
    eventTypeName: string;
};

export type OpsgenieEventListenerSetupDetails = {
    setupBaseUrl?: string;
} & OpsgenieEventListenerSetupBaseDetails;

opsgenieEventListenerSetupDialogOpenAction$
    .pipe(
        map(async (details) => {
            opsgenieEventListenerSetupDialogOpen$.next(true);
            opsgenieEventListenerSetupDialogLoading$.next(true);

            try {
                const { connectionBaseUrl } = await getEventListenerSetupDetails(details.uid, details.environmentUid);

                if (connectionBaseUrl) {
                    opsgenieEventListenerSetupDialogDetails$.next({
                        ...details,
                        setupBaseUrl: connectionBaseUrl,
                    });
                } else {
                    opsgenieEventListenerSetupDialogDetails$.next(details);
                }

                opsgenieEventListenerSetupDialogLoading$.next(false);
            } catch (e) {
                console.error('Error while loading Event Listener setup instructions', e);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to load Event Listener setup instructions, please try again, if the issue persists please contact support.`,
                    toastOptions: {
                        autoClose: false,
                    },
                });

                opsgenieEventListenerSetupDialogCloseAction$.next();
            }
        })
    )
    .subscribe();

opsgenieEventListenerSetupDialogCloseAction$.subscribe(() => {
    opsgenieEventListenerSetupDialogOpen$.next(false);
    opsgenieEventListenerSetupDialogDetails$.next(undefined);
});
