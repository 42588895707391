import { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import {
    ApiHandlerResource,
    EventListenerResource,
    WorkspaceLanguage,
    ResourceName,
    ScheduledTriggerResource,
    TreeResource,
} from './types';
import { InfoIcon } from '../../icons/InfoIcon';
import { ResourceTreeScript } from './ResourceTreeScript';
import { ResourceTreeFolder } from './ResourceTreeFolder';
import { ResourceTreeApiHandler } from './ResourceTreeApiHandler';
import { ResourceTreeEventListener } from './ResourceTreeEventListener';
import { ResourceTreeScheduledTrigger } from './ResourceTreeScheduledTrigger';
import {
    StyledAccordion,
    StyledAccordionDetails,
    StyledAccordionSummary,
    StyledResourceInfo,
    StyledResourceSubtitle,
} from './ResourceTreeComponents';
import { readLocalStorage, saveLocalStorage } from '../../../utils/localStorage';
import {
    absoluteCenterStyles,
    resourceTreeAccordionSummaryHeight,
    resourceTreeIconSize,
    resourceTreeIconWrapperSize,
    resourceTreeSideBarWidth,
    wizardFocusStyles,
} from './resourceTreeStyles';

interface ResourceTreeSectionProps {
    apiHandlers?: ApiHandlerResource[];
    deployedEnvironmentMode?: boolean;
    eventListeners?: EventListenerResource[];
    externalTriggerBaseUrl?: string;
    innerRef?: React.RefObject<HTMLDivElement>;
    language?: WorkspaceLanguage;
    noResourcesCreated?: boolean;
    readOnlyMode?: boolean;
    resourceIcon: JSX.Element;
    resourceName: ResourceName;
    scheduledTriggers?: ScheduledTriggerResource[];
    selectedNode?: string;
    tooltipText: string;
    treeNodes?: TreeResource[];
    wizardFocus?: boolean;
    wizardIncompleteNode?: string;
    workspaceUid: string;
    onCreateNew(): void;
    onDelete(uid: string): void;
    onOpen(uid: string): void;
    onPathCopy?(uid: string): void;
    onToggleExpand?(): void;
    onTrigger?(uid: string): void;
    onUrlCopy?(uid: string): void;
}

const StyledAddButton = styled(IconButton)(({ theme }) => ({
    ...theme.typography.radiusCircle,
    '& .MuiSvgIcon-root': {
        color: theme.palette.text.secondary,
        height: resourceTreeIconSize,
        width: resourceTreeIconSize,
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
}));

const StyledSideBarIcon = styled('div')(() => ({
    height: resourceTreeAccordionSummaryHeight,
    left: `calc(-${resourceTreeSideBarWidth / 2}px - 12px)`,
    position: 'absolute',
    top: 0,
    width: resourceTreeIconWrapperSize,
    '& .MuiSvgIcon-root': {
        ...absoluteCenterStyles,
        height: resourceTreeIconSize,
        width: resourceTreeIconSize,
    },
}));

const StyledResourceName = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    '& .MuiTypography-root': {
        fontWeight: theme.typography.fontWeightBold,
        marginRight: theme.spacing(1.5),
    },
}));

export const ResourceTreeSection: React.FC<ResourceTreeSectionProps> = ({
    apiHandlers = [],
    deployedEnvironmentMode = false,
    eventListeners = [],
    externalTriggerBaseUrl,
    innerRef,
    language,
    noResourcesCreated = false,
    readOnlyMode = false,
    resourceIcon,
    resourceName,
    scheduledTriggers = [],
    selectedNode,
    tooltipText,
    treeNodes = [],
    wizardFocus = false,
    wizardIncompleteNode,
    workspaceUid,
    onCreateNew,
    onDelete,
    onOpen,
    onPathCopy,
    onToggleExpand,
    onTrigger,
    onUrlCopy,
}) => {
    const modifiedResourceName = resourceName.replace(/\s/g, '').toLowerCase();
    const ariaId = modifiedResourceName + '-aria';
    const expandedSectionKey = `isExpanded-${workspaceUid}-${modifiedResourceName}`;
    const [expanded, setExpanded] = useState(readLocalStorage(expandedSectionKey, true));
    const theme = useTheme();
    const showAddButton = !deployedEnvironmentMode && !readOnlyMode;
    const showCreateWarning = !readOnlyMode && noResourcesCreated;

    useEffect(() => {
        setTimeout(() => {
            onToggleExpand?.();
        }, 300);
    }, [expanded]);

    const handleCreateNew = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation();
        onCreateNew();
    };

    const handleExpand = (): void => {
        saveLocalStorage(expandedSectionKey, !expanded);
        setExpanded(!expanded);
    };

    const getNodeList = (): JSX.Element => {
        switch (resourceName) {
            case 'Scripts':
                return (
                    <StyledAccordionDetails>
                        {treeNodes.map((tn, i) => {
                            if (tn.type === 'FOLDER') {
                                return (
                                    <ResourceTreeFolder
                                        deployedEnvironmentMode={deployedEnvironmentMode}
                                        fullPath={tn.fullPath}
                                        key={i}
                                        language={language}
                                        name={tn.name}
                                        readOnlyMode={readOnlyMode}
                                        selectedNode={selectedNode}
                                        subItems={tn.subItems}
                                        workspaceUid={workspaceUid}
                                        onDelete={onDelete}
                                        onOpen={onOpen}
                                        onTrigger={onTrigger}
                                        onToggleExpand={onToggleExpand}
                                    />
                                );
                            } else {
                                return (
                                    <ResourceTreeScript
                                        deleting={tn.deleting}
                                        deployedEnvironmentMode={deployedEnvironmentMode}
                                        executing={tn.executing}
                                        key={i}
                                        language={language}
                                        name={tn.name}
                                        readOnlyMode={readOnlyMode}
                                        selectedNode={selectedNode}
                                        uid={tn.uid}
                                        unsaved={tn.unsaved}
                                        wizardIncomplete={wizardIncompleteNode === tn.uid}
                                        onDelete={onDelete}
                                        onOpen={onOpen}
                                        onTrigger={onTrigger}
                                    />
                                );
                            }
                        })}
                    </StyledAccordionDetails>
                );
            case 'API Connections':
                return (
                    <StyledAccordionDetails>
                        {showCreateWarning && (
                            <StyledResourceInfo>
                                <StyledResourceSubtitle className="warning">
                                    <WarningOutlinedIcon />
                                    <Typography>Create new API Connection</Typography>
                                </StyledResourceSubtitle>
                            </StyledResourceInfo>
                        )}
                        {apiHandlers.map((ah) => {
                            const existingRecommendedApiVersion = ah.libraries?.some((l) => l.recommended);
                            return (
                                <ResourceTreeApiHandler
                                    appName={ah.appName}
                                    connectionName={ah.connectionName}
                                    deleting={ah.deleting}
                                    deployedEnvironmentMode={deployedEnvironmentMode}
                                    key={ah.uid}
                                    needsSetup={ah.warning}
                                    path={ah.path}
                                    readOnlyMode={readOnlyMode}
                                    existingRecommendedApiVersion={existingRecommendedApiVersion}
                                    remnant={ah.remnant}
                                    selectedApiHandlerLibrary={ah.selectedApiHandlerLibrary}
                                    selectedNode={selectedNode}
                                    uid={ah.uid}
                                    wizardIncomplete={wizardIncompleteNode === ah.uid}
                                    onDelete={onDelete}
                                    onOpen={onOpen}
                                    onPathCopy={onPathCopy}
                                />
                            );
                        })}
                    </StyledAccordionDetails>
                );
            case 'Event Listeners':
                return (
                    <StyledAccordionDetails>
                        {showCreateWarning && (
                            <StyledResourceInfo>
                                <StyledResourceSubtitle className="warning">
                                    <WarningOutlinedIcon />
                                    <Typography>Create new Event Listener</Typography>
                                </StyledResourceSubtitle>
                            </StyledResourceInfo>
                        )}
                        {eventListeners.map((el) => {
                            return (
                                <ResourceTreeEventListener
                                    appName={el.appName}
                                    deleting={el.deleting}
                                    deployedEnvironmentMode={deployedEnvironmentMode}
                                    executing={el.executing}
                                    eventType={el.eventTypeName}
                                    externalTriggerBaseUrl={externalTriggerBaseUrl}
                                    inactive={el.inactive}
                                    key={el.uid}
                                    needsSetup={el.warning}
                                    readOnlyMode={readOnlyMode}
                                    remnant={el.remnant}
                                    scriptName={el.scriptName}
                                    selectedNode={selectedNode}
                                    uid={el.uid}
                                    urlId={el.urlId}
                                    wizardIncomplete={wizardIncompleteNode === el.uid}
                                    onDelete={onDelete}
                                    onOpen={onOpen}
                                    onTrigger={onTrigger}
                                    onUrlCopy={onUrlCopy}
                                    disabled={el.disabled}
                                />
                            );
                        })}
                    </StyledAccordionDetails>
                );
            case 'Scheduled Triggers':
                return (
                    <StyledAccordionDetails>
                        {showCreateWarning && (
                            <StyledResourceInfo>
                                <StyledResourceSubtitle className="warning">
                                    <WarningOutlinedIcon />
                                    <Typography>Create new Scheduled Trigger</Typography>
                                </StyledResourceSubtitle>
                            </StyledResourceInfo>
                        )}
                        {scheduledTriggers.map((st) => {
                            return (
                                <ResourceTreeScheduledTrigger
                                    cronDescription={st.cronDescription}
                                    cronExpression={st.cronExpression}
                                    deleting={st.deleting}
                                    deployedEnvironmentMode={deployedEnvironmentMode}
                                    inactive={st.inactive}
                                    key={st.uid}
                                    needsSetup={st.warning}
                                    readOnlyMode={readOnlyMode}
                                    remnant={st.remnant}
                                    scriptName={st.scriptName}
                                    selectedNode={selectedNode}
                                    uid={st.uid}
                                    onDelete={onDelete}
                                    onOpen={onOpen}
                                    disabled={st.disabled}
                                />
                            );
                        })}
                    </StyledAccordionDetails>
                );
        }
    };

    const nodeList = getNodeList();
    const createNewTooltip = `Create new ${resourceName.slice(0, -1)}`;

    return (
        <>
            <StyledAccordion expanded={expanded} data-test-id={`resource-tree-${ariaId}`}>
                <StyledAccordionSummary
                    ref={innerRef}
                    onClick={handleExpand}
                    expandIcon={
                        expanded ? (
                            <Tooltip title={`Collapse ${resourceName} section`} placement="left-end">
                                <IconButton>
                                    <IndeterminateCheckBoxOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title={`Expand ${resourceName} section`} placement="left-end">
                                <IconButton>
                                    <AddBoxOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        )
                    }
                >
                    <StyledResourceName>
                        <Typography noWrap>{resourceName}</Typography>
                        <Tooltip title={tooltipText} placement="top" role="button">
                            <InfoIcon />
                        </Tooltip>
                    </StyledResourceName>
                    {showAddButton && (
                        <Tooltip title={createNewTooltip} placement="top" role="button">
                            <StyledAddButton
                                sx={wizardFocus ? wizardFocusStyles(theme) : null}
                                onClick={handleCreateNew}
                            >
                                <AddCircleRoundedIcon />
                            </StyledAddButton>
                        </Tooltip>
                    )}
                    <StyledSideBarIcon>{resourceIcon}</StyledSideBarIcon>
                </StyledAccordionSummary>
                {nodeList}
            </StyledAccordion>
        </>
    );
};
