import { getConnectionTypesReport as getConnectionTypesReportRpc } from '@avst-stitch/repository-lib/lib';
import {
    Response as ConnectionTypes,
    Request as ConnectionTypesReportRequest,
} from '@avst-stitch/repository-lib/lib/rpcs/report/getConnectionTypes';
import { repositoryInvoker } from '../../utils/repository';

export type { ConnectionTypesReportRequest, ConnectionTypes };

export const getConnectionTypes = async (request: ConnectionTypesReportRequest): Promise<ConnectionTypes> => {
    return await getConnectionTypesReportRpc(repositoryInvoker, request);
};
