import { useObservableState, useSubscription } from 'observable-hooks';
import { useNavigate } from 'react-location';
import { NewWorkspaceDialog, NewWorkspaceUpdateEvent } from '../../components/workspace-dialogs/NewWorkspaceDialog';
import { loggedInUserDetails$ } from '../../store/user';
import {
    updateWizardStateAction$,
    updateWizardStepAction$,
    wizardState$,
    wizardStep$,
    wizardWorkspaceDetails$,
} from '../../store/wizard';
import {
    cancelNewBlankWorkspaceAction$,
    loadingWorkspaceFormDetails$,
    newBlankWorkspaceDialogOpen$,
    newDuplicatedWorkspace$,
    newWorkspaceFromTemplate$,
    openWorkspacePreviewDialogErrors$,
    saveNewBlankWorkspaceAction$,
    savingNewBlankWorkspace$,
    workspaceCreatedAction$,
} from '../../store/workspaces';
import { getBasePath } from '../../utils/path';
import { selectedOrganizationUid$ } from '../../store/organization';
import { WizardState, WizardStep } from '../../components/wizard/dialogs/types';

export const CreateNewWorkspaceDialogContainer: React.FC = () => {
    const navigate = useNavigate();

    const createNewBlankWorkspaceDialogOpen = useObservableState(newBlankWorkspaceDialogOpen$); //Name misleading
    const savingNewBlankWorkspace = useObservableState(savingNewBlankWorkspace$); //Name misleading
    const newBlankWorkspaceErrors = useObservableState(openWorkspacePreviewDialogErrors$);
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);
    const newWorkspaceFromTemplate = useObservableState(newWorkspaceFromTemplate$);
    const newDuplicatedWorkspace = useObservableState(newDuplicatedWorkspace$);
    const wizardState = useObservableState(wizardState$);
    const wizardStep = useObservableState(wizardStep$);
    const loadingWorkspaceFormDetails = useObservableState(loadingWorkspaceFormDetails$);
    const selectedOrganizationUid = useObservableState(selectedOrganizationUid$);

    const handleCancel = (): void => {
        cancelNewBlankWorkspaceAction$.next();
        if (wizardState === WizardState.ACTIVE) {
            updateWizardStateAction$.next(WizardState.CONFIRM);
        }
    };

    const handleSave = ({ name, description, template }: NewWorkspaceUpdateEvent): void => {
        saveNewBlankWorkspaceAction$.next({
            name,
            description,
            template,
            templateUid: newWorkspaceFromTemplate?.sourceUid,
            sourceWorkspaceUid: newDuplicatedWorkspace?.sourceUid,
            organizationUid: selectedOrganizationUid ?? '',
        });
    };

    const navigateToWorkspace = (workspaceUid: string, environmentUid: string): void =>
        navigate({ to: `${getBasePath()}workspace/${workspaceUid}/environment/${environmentUid}` });

    const duplicatedWorkspaceName = newDuplicatedWorkspace && newDuplicatedWorkspace.name + ' - copy';

    useSubscription(workspaceCreatedAction$, ({ workspaceUid, environmentUid }) => {
        if (wizardState === WizardState.ACTIVE && wizardStep === WizardStep.CREATE_WORKSPACE) {
            updateWizardStepAction$.next(WizardStep.ADD_API_CONNECTION);
            wizardWorkspaceDetails$.next({ workspaceUid, environmentUid });
        }

        navigateToWorkspace(workspaceUid, environmentUid);
    });

    const templateOption =
        !!loggedInUserDetails && loggedInUserDetails.canWorkWithTemplates && !newWorkspaceFromTemplate;

    return (
        <NewWorkspaceDialog
            name={newWorkspaceFromTemplate?.name ?? duplicatedWorkspaceName}
            description={newWorkspaceFromTemplate?.description ?? newDuplicatedWorkspace?.description}
            templateSelectorDisabled={!templateOption}
            open={createNewBlankWorkspaceDialogOpen}
            saving={savingNewBlankWorkspace}
            errors={newBlankWorkspaceErrors}
            onCancel={handleCancel}
            onSave={handleSave}
            loading={loadingWorkspaceFormDetails}
        />
    );
};
