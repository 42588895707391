import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';
import React from 'react';

const Centered = styled('div')(() => ({
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
}));

export const AbsoluteCenteredLoadingSpinner: React.FC = () => {
    return (
        <Centered>
            <CircularProgress />
        </Centered>
    );
};
