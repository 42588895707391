import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React from 'react';

export enum SalesforceEventListenerWizardStage {
    CREATE_OUTBOUND_MESSAGE,
    CREATE_AUTOMATED_FLOW,
}

export interface SalesforceWizardStepsProps {
    readonly stage: SalesforceEventListenerWizardStage;
}

export const SalesforceEventListenerWizardSteps: React.FC<SalesforceWizardStepsProps> = ({ stage }) => {
    return (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={SalesforceEventListenerWizardStage.CREATE_OUTBOUND_MESSAGE}>
                <StepLabel>Create outbound message</StepLabel>
            </Step>
            <Step key={SalesforceEventListenerWizardStage.CREATE_AUTOMATED_FLOW}>
                <StepLabel>Create flow for outbound message</StepLabel>
            </Step>
        </Stepper>
    );
};
