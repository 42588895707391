import { BehaviorSubject } from 'rxjs';
import { monitor } from './monitor';

export interface InvocationLog {
    id: string;
    severity: string;
    message: string;
    timestamp?: number;
    linkUrl?: string;
    largePayload?: boolean;
}

export const selectedHttpLogsInvocationId$ = monitor(
    'selectedHttpLogsInvocationId$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const selectedHttpLogsUrl$ = monitor('selectedHttpLogsUrl$', new BehaviorSubject<string | undefined>(undefined));

export const selectedHttpLogs$ = monitor('selectedHttpLogs$', new BehaviorSubject<string[]>([]));
