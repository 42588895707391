import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import React, { useState } from 'react';
import { Button } from '../../../common/buttons/Button';
import { ZenDeskIcon } from '../../../icons/ZenDeskIcon';
import { OpenInNewLink } from '../../../common/OpenInNewLink';
import {
    ConnectionModalTextField,
    ConnectionModalSecretTextField,
} from '../../../common/textfield/ConnectionModalTextField';
import { ReadOnlyTextField } from '../../../common/textfield/ReadOnlyTextField';
import { ZenDeskWizardStage, ZenDeskWizardSteps, StageType } from './ZenDeskWizardSteps';
import { DialogAlert, DialogTitleMain } from '../../../for-deprecation/dialog/DialogComponents';
import { testUrl } from '../../../../utils/connection';

export interface ZenDeskClientInfo {
    instanceUrl: string;
    clientId: string;
    clientSecret: string;
}

export interface ZenDeskConnection {
    clientInfo: ZenDeskClientInfo;
    open: boolean;
    onClose: () => void;
    saving: boolean;
    currentStage: ZenDeskWizardStage;
    setStage: (stage: ZenDeskWizardStage) => void;
    onSave: (props: onSaveProps) => void;
    errors?: string;
    setError: (error: string) => void;
    clearErrors: () => void;
    callbackUrl: string;
}

export interface onSaveProps {
    instanceUrl: string;
    clientId: string;
    clientSecret: string;
}

export enum ZENDESK_URLS {
    INITIATE_PATH = '/oauth/authorizations/new',
    TOKEN_PATH = '/oauth/tokens',
}

const StageContent: StageType = (props) => {
    const [instanceUrl, setInstanceUrl] = useState(props.clientInfo.instanceUrl);
    const [clientId, setClientId] = useState(props.clientInfo.clientId);
    const [clientSecret, setClientSecret] = useState(props.clientInfo.clientSecret);

    const [badUrlAttempted, setBadUrlAttempted] = useState<boolean>(false);

    const ZENDESK_PATH = '/admin/apps-integrations/apis/zendesk-api/oauth_clients/';
    const ZENDESK_DOMAIN = 'zendesk.com';

    const generateSettingsUrl = (): string => {
        return instanceUrl.endsWith(ZENDESK_PATH)
            ? instanceUrl
            : instanceUrl.endsWith('/')
            ? instanceUrl.substring(0, instanceUrl.length - 1) + ZENDESK_PATH
            : instanceUrl + ZENDESK_PATH;
    };

    switch (props.stage) {
        case ZenDeskWizardStage.ADDURL:
            return (
                <>
                    <DialogContent>
                        <DialogContentText>This connector requires a ZenDesk instance url.</DialogContentText>
                        <ConnectionModalTextField
                            label="https://<ZENDESK_ACCOUNT>.zendesk.com"
                            value={instanceUrl}
                            onUpdate={(e) => {
                                if (badUrlAttempted) {
                                    props.clearErrors();
                                    setBadUrlAttempted(false);
                                }
                                setInstanceUrl(e.target.value);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.onClose?.()}>
                            Close
                        </Button>
                        <Button
                            //eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick={async () => {
                                if ((await testUrl(instanceUrl)) && instanceUrl.includes(ZENDESK_DOMAIN)) {
                                    setInstanceUrl(instanceUrl);
                                    props.setStage(ZenDeskWizardStage.CREATE);
                                    setBadUrlAttempted(false);
                                } else {
                                    props.setError(
                                        `Please enter a valid URL starting with "https://" and ending with ${ZENDESK_DOMAIN}`
                                    );
                                    setBadUrlAttempted(true);
                                }
                            }}
                            disabled={!instanceUrl}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case ZenDeskWizardStage.CREATE:
            return (
                <>
                    <DialogAlert
                        severity="info"
                        text="If you already have an application, skip the steps below and click next"
                    />

                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Visit the{' '}
                                <OpenInNewLink url={generateSettingsUrl()}>ZenDesk OAuth Clients</OpenInNewLink> page
                                and click <strong>Add OAuth client</strong> to add a new connection.
                            </li>
                            <li>Copy the values below into the form.</li>

                            <ReadOnlyTextField label="Name" value="ScriptRunnerConnect" />
                            <ReadOnlyTextField label="Redirect URL" value={props.callbackUrl} />

                            <li>
                                Set the <strong>Client kind</strong> to confidential.
                            </li>
                            <li>
                                Click <strong>Save</strong>.
                            </li>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => props.setStage(ZenDeskWizardStage.ADDURL)}>
                            Back
                        </Button>
                        <Button onClick={() => props.setStage(ZenDeskWizardStage.DETAILS)}>Next</Button>
                    </DialogActions>
                </>
            );
        case ZenDeskWizardStage.DETAILS:
            return (
                <>
                    <DialogContent>
                        <DialogContentText component="ol">
                            <li>
                                Click on the newly created client (or an existing one if you skipped the previous step).
                            </li>
                            <li>
                                Copy the generated <strong>Unique identifier</strong> into the form below.
                            </li>
                            <li>
                                Copy the <strong>Secret</strong> and copy the value into the form below.
                            </li>
                            <li>Save the config.</li>
                        </DialogContentText>
                        <ConnectionModalTextField
                            label="Unique identifier"
                            value={clientId}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setClientId(e.target.value.trim());
                            }}
                        />
                        <ConnectionModalSecretTextField
                            label="Secret"
                            value={clientSecret}
                            onUpdate={(e) => {
                                if (props.errors) props.clearErrors();
                                setClientSecret(e.target.value.trim());
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(ZenDeskWizardStage.CREATE);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            busy={props.saving}
                            disabled={!clientId || !clientSecret}
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(ZenDeskWizardStage.AUTHORIZE);
                            }}
                        >
                            Next
                        </Button>
                    </DialogActions>
                </>
            );
        case ZenDeskWizardStage.AUTHORIZE:
            return (
                <>
                    <DialogContent>
                        <DialogContentText>
                            To access information in ZenDesk you need to authorize our app to be able to make requests
                            on your behalf.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (props.errors) props.clearErrors();
                                props.setStage(ZenDeskWizardStage.DETAILS);
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            busy={props.saving}
                            disabled={!clientId || !clientSecret}
                            onClick={() =>
                                props.onSave({
                                    clientId,
                                    clientSecret,
                                    instanceUrl,
                                })
                            }
                        >
                            Authorize
                        </Button>
                    </DialogActions>
                </>
            );
        case ZenDeskWizardStage.SUCCESS:
            return (
                <>
                    <DialogContentText>Success</DialogContentText>
                </>
            );
    }
};

export const ZenDeskConnectionAppConfigureDialog: React.FC<ZenDeskConnection> = (props) => {
    const error = <DialogAlert severity="error" alertTitle="Error" text={props.errors} />;
    return (
        <Dialog open={props.open} onClose={() => props.onClose()}>
            <DialogTitleMain title="Configure Connector" variant="h6" icon={<ZenDeskIcon />} />
            {props.errors && error}
            <ZenDeskWizardSteps stage={props.currentStage} />
            <StageContent {...props} stage={props.currentStage} setStage={props.setStage} />
        </Dialog>
    );
};
