export const getFetchOptions = (headers: Record<string, string>, body?: unknown): RequestInit => {
    if (body) {
        return {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body: typeof body === 'string' ? body : JSON.stringify(body),
        };
    } else {
        return {
            method: 'POST',
            headers,
        };
    }
};
