import { useState } from 'react';
import Typography from '@mui/material/Typography';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import { Filter } from './Filter';
import {
    PageContainer,
    PageUpperContainer,
    PageUpperGroup,
    PageTitle,
    PageViewButtonGroup,
    PageGridContainer,
} from '../../app-main/PageComponents';
import { QuickFilter, QuickFilters } from './QuickFilter';
import {
    SelectorViewButton,
    viewSelectedStyle,
    SelectorTitleIcon,
    SelectorTable,
} from '../../app-main/SelectorComponents';
import { TableBody, TableHead, TableRow } from '@mui/material';
import {
    StyledIconCell,
    StyledShortCell,
    StyledTextCell,
} from '../../workspace-layouts/workspace-selector/WorkspaceSelectorTableItem';
import { TemplateSelectorCard } from './TemplateSelectorCard';
import { TemplateSelectorTableItem } from './TemplateSelectorTableItem';
import { readLocalStorage, saveLocalStorage } from '../../../utils/localStorage';

interface TemplateSelectorProps {
    appsForFilterOptions: { name: string }[];
    activeFilters: FilteringOptions;
    packagesFilterItems: string[];
    quickFilters: QuickFilters;
    templates?: TemplateItem[];
    userCanWorkWithTemplates: boolean;
    onEdit(uid: string): void;
    onIncomingFilterUpdate(app: string): void;
    onOutgoingFilterUpdate(app: string): void;
    onPackagesFilterUpdate(app: string): void;
    onQuickFilterReset(): void;
    onQuickFilterUpdate(app: string): void;
    onResetFilter(): void;
    onSelect(templateUid: string): void;
}

export interface TemplateItem {
    description?: string;
    draft?: boolean;
    incoming?: string[];
    isNew?: boolean;
    name: string;
    outgoing?: string[];
    uid: string;
}

export interface FilteringOptions {
    count: number;
    incoming: string[];
    outgoing: string[];
    packages: string[];
}

export const TemplateSelector: React.FC<TemplateSelectorProps> = ({
    activeFilters,
    appsForFilterOptions,
    packagesFilterItems,
    quickFilters,
    templates = [],
    userCanWorkWithTemplates,
    onEdit,
    onIncomingFilterUpdate,
    onOutgoingFilterUpdate,
    onPackagesFilterUpdate,
    onQuickFilterReset,
    onQuickFilterUpdate,
    onResetFilter,
    onSelect,
}) => {
    const [view, setView] = useState<'grid' | 'list'>(readLocalStorage('templatesView', 'grid'));

    const handleViewChange = (view: 'grid' | 'list'): void => {
        setView(view);
        saveLocalStorage('templatesView', view);
    };

    const remappedApps = appsForFilterOptions.map((a) => a.name);
    const filters = [
        {
            filterName: 'Incoming',
            options: remappedApps,
            handler: onIncomingFilterUpdate,
            activeFilters: activeFilters.incoming,
            icons: true,
        },
        {
            filterName: 'Outgoing',
            options: remappedApps,
            handler: onOutgoingFilterUpdate,
            activeFilters: activeFilters.outgoing,
            icons: true,
        },
        {
            filterName: 'Packages',
            options: packagesFilterItems,
            handler: onPackagesFilterUpdate,
            activeFilters: activeFilters.packages,
            icons: false,
        },
    ];

    const createLabel = (): string => {
        const currentResultsLength = templates.length ?? 0;
        return currentResultsLength === 1 ? currentResultsLength + ' Result' : currentResultsLength + ' Results';
    };

    const items = templates.map((template) => {
        if (view === 'grid') {
            return (
                <TemplateSelectorCard
                    key={template.uid}
                    description={template.description}
                    draft={template.draft}
                    isNew={template.isNew}
                    incoming={template.incoming}
                    outgoing={template.outgoing}
                    title={template.name}
                    uid={template.uid}
                    onSelect={onSelect}
                    onEdit={onEdit}
                    userCanWorkWithTemplates={userCanWorkWithTemplates}
                />
            );
        } else if (view === 'list') {
            return (
                <TemplateSelectorTableItem
                    key={template.uid}
                    description={template.description}
                    draft={template.draft}
                    isNew={template.isNew}
                    incoming={template.incoming}
                    outgoing={template.outgoing}
                    title={template.name}
                    uid={template.uid}
                    onSelect={onSelect}
                    onEdit={onEdit}
                    userCanWorkWithTemplates={userCanWorkWithTemplates}
                />
            );
        }
    });

    const tableTitles = (
        <TableRow>
            <StyledTextCell>Title</StyledTextCell>
            <StyledTextCell>Description</StyledTextCell>
            <StyledIconCell>
                <SelectorTitleIcon>
                    <DownloadOutlinedIcon />
                </SelectorTitleIcon>
                Incoming
            </StyledIconCell>
            <StyledIconCell>
                <SelectorTitleIcon>
                    <UploadOutlinedIcon />
                </SelectorTitleIcon>
                Outgoing
            </StyledIconCell>
            <StyledShortCell>Status</StyledShortCell>
            <StyledShortCell sx={{ textAlign: 'end' }}>Actions</StyledShortCell>
        </TableRow>
    );

    return (
        <PageContainer>
            <PageUpperContainer>
                <PageTitle title="Templates" label={createLabel()} />
                <PageUpperGroup>
                    <PageViewButtonGroup>
                        <SelectorViewButton
                            icon="grid"
                            onClick={() => {
                                if (view === 'list') {
                                    handleViewChange('grid');
                                }
                            }}
                            sx={(theme) => {
                                return view === 'grid' ? viewSelectedStyle(theme) : null;
                            }}
                        />
                        <SelectorViewButton
                            icon="list"
                            onClick={() => {
                                if (view === 'grid') {
                                    handleViewChange('list');
                                }
                            }}
                            sx={(theme) => {
                                return view === 'list' ? viewSelectedStyle(theme) : null;
                            }}
                        />
                    </PageViewButtonGroup>
                    <Filter filters={filters} count={activeFilters.count} reset={onResetFilter} />
                </PageUpperGroup>
            </PageUpperContainer>
            <QuickFilter filters={quickFilters} onUpdate={onQuickFilterUpdate} onReset={onQuickFilterReset} />
            {view === 'grid' && items.length > 0 && <PageGridContainer>{items}</PageGridContainer>}
            {view === 'list' && items.length > 0 && (
                <SelectorTable>
                    <TableHead>{tableTitles}</TableHead>
                    <TableBody className="padme">{items}</TableBody>
                </SelectorTable>
            )}
            {items.length === 0 && <Typography margin={4}>No templates found</Typography>}
        </PageContainer>
    );
};
