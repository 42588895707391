import { useObservableState } from 'observable-hooks';
import { MondayAppConfigureDialog } from '../../../../components/connections/apps/monday/MondayAppConfigureDialog';
import { configTopic$ } from '../../../../store/config';
import {
    mondayConnectionSaving$,
    mondayManageConnectionDetails$,
    mondayAppSetupDialogErrors$,
    mondayAppSetupDialogOpen$,
    mondayAppSetupCloseDialogAction$,
    mondayAppSetupAuthorizeAction$,
    mondayAppSetupDialogStage$,
} from '../../../../store/connection/monday/setup-connection';

export const MondayConfigureDialog: React.FC = () => {
    const open = useObservableState(mondayAppSetupDialogOpen$);
    const saving = useObservableState(mondayConnectionSaving$);
    const details = useObservableState(mondayManageConnectionDetails$);
    const errors = useObservableState(mondayAppSetupDialogErrors$);
    const currentStage = useObservableState(mondayAppSetupDialogStage$);
    const installUrl = configTopic$.value.connection?.monday?.installUrl;

    return (
        <MondayAppConfigureDialog
            open={open}
            onClose={() => {
                mondayAppSetupCloseDialogAction$.next();
                mondayAppSetupDialogStage$.next(0);
            }}
            currentStage={currentStage}
            setStage={(stage) => mondayAppSetupDialogStage$.next(stage)}
            instanceUrl={details?.instanceUrl ?? ''}
            saving={saving}
            errors={errors}
            setError={(error) => mondayAppSetupDialogErrors$.next(error)}
            clearErrors={() => mondayAppSetupDialogErrors$.next(undefined)}
            onSave={(appInfoProps) => mondayAppSetupAuthorizeAction$.next(appInfoProps)}
            installUrl={installUrl ?? 'Install URL not available. Check with ScriptRunner Connect support'}
        />
    );
};
