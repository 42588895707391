import { useObservableState } from 'observable-hooks';
import { ShareWorkspaceDialog } from '../../components/workspace-dialogs/ShareWorkspaceDialog';
import { selectedWorkspace$, selectedWorkspaceUid$ } from '../../store/workspace';
import {
    cancelWorkspaceSharingDetailsAction$,
    loadingWorkspaceSharingDetails$,
    saveWorkspaceSharingDetailsAction$,
    savingWorkspaceSharingDetails$,
    workspaceSharingDetails$,
    workspaceSharingDialogOpen$,
} from '../../store/workspace/sharing';
import { loggedInUserDetails$ } from '../../store/user';

export const ShareWorkspaceDialogContainer: React.FC = () => {
    const workspaceSharingDialogOpen = useObservableState(workspaceSharingDialogOpen$);
    const loadingWorkspaceSharingDetails = useObservableState(loadingWorkspaceSharingDetails$);
    const workspaceSharingDetails = useObservableState(workspaceSharingDetails$);
    const savingWorkspaceSharingDetails = useObservableState(savingWorkspaceSharingDetails$);
    const selectedWorkspaceUid = useObservableState(selectedWorkspaceUid$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);

    const canShare =
        selectedWorkspace?.organization?.members.find((m) => m.email === loggedInUserDetails?.email)?.role !== 'MEMBER';

    return (
        <ShareWorkspaceDialog
            open={workspaceSharingDialogOpen}
            loading={loadingWorkspaceSharingDetails}
            organizationUsers={workspaceSharingDetails?.allUsers ?? []}
            adminUsers={workspaceSharingDetails?.adminUsers ?? []}
            saving={savingWorkspaceSharingDetails}
            sharingState={workspaceSharingDetails?.visibility}
            workspaceLocked={!!selectedWorkspace?.locked}
            canShare={canShare}
            onCancel={() => cancelWorkspaceSharingDetailsAction$.next()}
            onShare={(visibility) => {
                saveWorkspaceSharingDetailsAction$.next({
                    visibility,
                    workspaceUid: selectedWorkspaceUid ?? '',
                });
            }}
        />
    );
};
