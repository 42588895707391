import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';
import { Button } from '../../common/buttons/Button';
import Box from '@mui/system/Box';
import { AvatarContainer } from '../../common/Avatars';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Person4OutlinedIcon from '@mui/icons-material/Person4Outlined';
import { ThreeDotsButton } from '../../common/buttons/ThreeDotsButton';
import { selectorChipStyle } from '../../app-main/SelectorComponents';
import { DialogAlert } from '../../for-deprecation/dialog/DialogComponents';
import { StyledUserContainer, StyledNameAndEmailContainer, StyledImageBox } from '../OrganizationComponents';

type Role = 'SUPER_ADMIN' | 'ADMIN' | 'MEMBER';

interface UsersProps {
    users: {
        uid: string;
        email: string;
        firstName?: string;
        lastName?: string;
        avatar?: string;
        role: string;
        canChangeRole: boolean;
        canRemove: boolean;
    }[];
    pendingUsers: {
        uid: string;
        email: string;
        firstName?: string;
        lastName?: string;
        avatar?: string;
        role: string;
        canChangeRole: boolean;
        canRemove: boolean;
        canResendInvite: boolean;
    }[];
    roles: { name: string; value: Role }[];
    canInvite: boolean;
    onAddMember(): void;
    onResendInvite(uid: string): void;
    onInviteRemove(uid: string): void;
    onUserRemove(uid: string): void;
    onInvitedUserRoleUpdate(event: UpdateUserPermissionsEvent): void;
    onUserRoleUpdate(event: UpdateUserPermissionsEvent): void;
    error?: string;
    saving: boolean;
}

interface MemberProps {
    uid: string;
    avatar?: string;
    firstName?: string;
    lastName?: string;
    email: string;
    role: string;
    roles: { name: string; value: Role }[];
    invitePending?: boolean;
    canRemove: boolean;
    canChangeRole: boolean;
    canResendInvite?: boolean;
    onInviteRemove(uid: string): void;
    onUserRemove(uid: string): void;
    onInvitedUserRoleUpdate(event: UpdateUserPermissionsEvent): void;
    onUserRoleUpdate(event: UpdateUserPermissionsEvent): void;
    onResendInvite(uid: string): void;
    saving: boolean;
}

interface UpdateUserPermissionsEvent {
    memberUid?: string;
    inviteUid?: string;
    role: Role;
}

const StyledContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: 596,
}));

const StyledAddMemberButton = styled(Button)(() => ({
    textTransform: 'none',
}));

const StyledAddMemberContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    paddingBottom: theme.spacing(2),
}));

const StyledUsersContainer = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(3),
}));

export const MemberManagement: React.FC<UsersProps> = ({
    users,
    pendingUsers,
    roles,
    onAddMember,
    onResendInvite,
    canInvite,
    onInviteRemove,
    onUserRemove,
    onInvitedUserRoleUpdate,
    onUserRoleUpdate,
    error,
    saving = false,
}) => {
    return (
        <StyledContainer>
            <Typography pb={2}>Invite members and manage the roles and permissions of existing members.</Typography>
            {error && <DialogAlert severity="error" alertTitle="Error" text={error} />}
            <StyledAddMemberContainer>
                <StyledAddMemberButton
                    variant="text"
                    onClick={onAddMember}
                    startIcon={<PersonAddAltOutlinedIcon />}
                    disabled={!canInvite}
                >
                    Invite new member
                </StyledAddMemberButton>
            </StyledAddMemberContainer>
            <StyledUsersContainer>
                {pendingUsers &&
                    pendingUsers.map((user) => (
                        <Member
                            key={`pending-member-${user.uid}`}
                            uid={user.uid}
                            avatar={user.avatar}
                            firstName={user.firstName}
                            lastName={user.lastName}
                            email={user.email}
                            role={user.role}
                            roles={roles}
                            canRemove={user.canRemove}
                            canChangeRole={user.canChangeRole}
                            invitePending={true}
                            canResendInvite={user.canResendInvite}
                            onInviteRemove={onInviteRemove}
                            onUserRemove={onUserRemove}
                            onResendInvite={onResendInvite}
                            onInvitedUserRoleUpdate={onInvitedUserRoleUpdate}
                            onUserRoleUpdate={onUserRoleUpdate}
                            saving={saving}
                        />
                    ))}
                {users &&
                    users.map((user) => (
                        <Member
                            key={`member-${user.uid}`}
                            uid={user.uid}
                            avatar={user.avatar}
                            firstName={user.firstName}
                            lastName={user.lastName}
                            email={user.email}
                            role={user.role}
                            roles={roles}
                            canRemove={user.canRemove}
                            canChangeRole={user.canChangeRole}
                            onUserRemove={onUserRemove}
                            onInviteRemove={onInviteRemove}
                            onResendInvite={onResendInvite}
                            onInvitedUserRoleUpdate={onInvitedUserRoleUpdate}
                            onUserRoleUpdate={onUserRoleUpdate}
                            saving={saving}
                        />
                    ))}
            </StyledUsersContainer>
        </StyledContainer>
    );
};

const StyledStatus = styled(Chip)(({ theme }) => ({
    ...selectorChipStyle(theme),
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.text.secondary,
    width: 70,
}));

const StyledFormControl = styled(FormControl)(() => ({
    height: 24,
}));

const StyledPendingAvatar = styled(Box)(({ theme }) => ({
    ...theme.typography.radiusCircle,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.text.disabled}`,
    height: 30,
    marginRight: theme.spacing(1),
    width: 30,
    '& .MuiSvgIcon-root': {
        color: theme.palette.text.disabled,
        fontSize: '1.75rem',
    },
}));

const StyledThreeDotsContainer = styled(Box)(({ theme }) => ({
    alignSelf: 'baseline',
    display: 'flex',
    marginTop: theme.spacing(0.4),
    position: 'relative',
}));

const MemberAvatarAndCredentialsContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    width: 200,
}));

const StyledNameTypography = styled(Typography)(({ theme }) => ({
    ...theme.typography.overflowLine,
    color: theme.palette.text.primary,
    width: 200,
}));

const StyledEmailTypography = styled(Typography)(({ theme }) => ({
    ...theme.typography.overflowLine,
    color: theme.palette.text.secondary,
}));

export const Member: React.FC<MemberProps> = ({
    uid,
    avatar,
    firstName,
    lastName,
    email,
    role,
    roles,
    invitePending = false,
    canRemove,
    canChangeRole,
    canResendInvite,
    onResendInvite,
    onInviteRemove,
    onUserRemove,
    onInvitedUserRoleUpdate,
    onUserRoleUpdate,
    saving = false,
}) => {
    const handleThreeDotsActions = (): { name: string; onSelect?(uid: string): void }[] => {
        const actions = [];
        if (invitePending && canResendInvite) {
            actions.push({
                name: 'Resend invite',
                onSelect: (uid: string) => onResendInvite(uid),
            });
        }
        if (canRemove && !invitePending) {
            actions.push({
                name: 'Remove user',
                onSelect: (uid: string) => {
                    onUserRemove(uid);
                },
            });
        }
        if (invitePending && canRemove) {
            actions.push({
                name: 'Remove user',
                onSelect: (uid: string) => {
                    onInviteRemove(uid);
                },
            });
        }
        return actions;
    };

    const actions = handleThreeDotsActions();
    const imgAlt = lastName ? lastName : avatar;

    return (
        <StyledUserContainer key={uid}>
            <MemberAvatarAndCredentialsContainer>
                {avatar ? (
                    <StyledImageBox>
                        <img src={`data:image/jpeg;base64,${avatar}`} height={30} width={30} alt={imgAlt} />
                    </StyledImageBox>
                ) : invitePending ? (
                    <StyledPendingAvatar sx={{ flexShrink: 0 }}>
                        <Person4OutlinedIcon />
                    </StyledPendingAvatar>
                ) : (
                    <Box paddingRight={1}>
                        <AvatarContainer
                            size="small"
                            credentials={{
                                firstName: firstName ?? '',
                                lastName: lastName ?? '',
                                email,
                            }}
                        />
                    </Box>
                )}
                <StyledNameAndEmailContainer>
                    {invitePending ? (
                        <StyledStatus label="Pending" />
                    ) : (
                        (firstName || lastName) && (
                            <StyledNameTypography variant="subtitle2">
                                {firstName}&nbsp;
                                {lastName}
                            </StyledNameTypography>
                        )
                    )}
                    <StyledEmailTypography>{email}</StyledEmailTypography>
                </StyledNameAndEmailContainer>
            </MemberAvatarAndCredentialsContainer>
            <StyledFormControl>
                <Select
                    labelId="role-label"
                    id={`role-${uid}`}
                    value={role}
                    label="Role"
                    disableUnderline={true}
                    sx={{ width: 110 }}
                    variant="standard"
                    onChange={(e) => {
                        if (invitePending) {
                            onInvitedUserRoleUpdate({
                                inviteUid: uid,
                                role: e.target.value as Role,
                            });
                        } else {
                            onUserRoleUpdate({
                                memberUid: uid,
                                role: e.target.value as Role,
                            });
                        }
                    }}
                    disabled={!canChangeRole || saving}
                >
                    {roles.map((role, i) => {
                        return (
                            <MenuItem key={`role-${i}`} value={role.value}>
                                {role.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </StyledFormControl>
            <StyledThreeDotsContainer>
                {actions.length > 0 && <ThreeDotsButton uid={uid} actions={actions} disabled={saving} />}
            </StyledThreeDotsContainer>
        </StyledUserContainer>
    );
};
