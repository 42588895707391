import { BehaviorSubject, Subject } from 'rxjs';
import { monitor } from '../../monitor';

export const genericEventListenerSetupDialogOpen$ = monitor(
    'genericEventListenerSetupDialogOpen$',
    new BehaviorSubject(false)
);
export const genericEventListenerSetupDialogDetails$ = monitor(
    'genericEventListenerSetupDialogDetails$',
    new BehaviorSubject<GenericEventListenerSetupDialogDetails | undefined>(undefined)
);

export const genericEventListenerSetupDialogOpenAction$ = monitor(
    'genericEventListenerSetupDialogOpenAction$',
    new Subject<GenericEventListenerSetupDialogDetails>()
);
export const genericEventListenerSetupDialogCloseAction$ = monitor(
    'genericEventListenerSetupDialogCloseAction$',
    new Subject<void>()
);

genericEventListenerSetupDialogOpenAction$.subscribe((details) => {
    genericEventListenerSetupDialogOpen$.next(true);
    genericEventListenerSetupDialogDetails$.next(details);
});

genericEventListenerSetupDialogCloseAction$.subscribe(() => genericEventListenerSetupDialogOpen$.next(false));

export interface GenericEventListenerSetupDialogDetails {
    urlPath: string;
}
