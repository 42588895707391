import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const ZoomIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            style={{ isolation: 'isolate' }}
            viewBox="0, 0, 260, 260"
            className="productIcon"
            aria-label="Zoom"
        >
            <g>
                <linearGradient
                    id="SVGID_1_"
                    gradientUnits="userSpaceOnUse"
                    x1="41.2083"
                    y1="283.7917"
                    x2="218.7917"
                    y2="-23.7917"
                >
                    <stop offset="0" style={{ stopColor: '#0845BF' }} />
                    <stop offset="0.5" style={{ stopColor: '#0B5CFF' }} />
                    <stop offset="0.6122" style={{ stopColor: '#1463FD' }} />
                    <stop offset="0.7951" style={{ stopColor: '#2C76F7' }} />
                    <stop offset="1" style={{ stopColor: '#4F90EE' }} />
                </linearGradient>
                <path className="st0" fill={`url('#SVGID_1_')`} d="M0,0h260v260H0V0z" />
                <path
                    className="st1"
                    fill="#FFFFFF"
                    d="M77.6,149.9H45.6c-2.1,0-4.1-1.1-5.1-3c-1.1-2.2-0.7-4.8,1-6.5l22.3-22.3h-16c-4.4,0-8-3.6-8-8h29.6
                c2.1,0,4.1,1.1,5.1,3c1.1,2.2,0.7,4.8-1,6.5l-22.3,22.3h18.5C74.1,141.9,77.6,145.5,77.6,149.9z M204.7,109.5
                c-4.6,0-8.7,2-11.6,5.2c-2.8-3.2-7-5.2-11.6-5.2c-8.5,0-15.5,7.3-15.5,15.8v24.6c4.4,0,8-3.6,8-8v-16.7c0-4.1,3.2-7.6,7.3-7.7
                c4.3-0.2,7.9,3.3,7.9,7.6v16.9c0,4.4,3.6,8,8,8v-24.7c0-4.1,3.2-7.6,7.3-7.7c4.3-0.2,7.9,3.3,7.9,7.6v16.9c0,4.4,3.6,8,8,8v-24.6
                C220.2,116.8,213.2,109.5,204.7,109.5z M117.9,130c0,11.3-9.2,20.5-20.5,20.5s-20.5-9.2-20.5-20.5c0-11.3,9.2-20.5,20.5-20.5
                S117.9,118.7,117.9,130z M109.9,130c0-6.9-5.6-12.5-12.5-12.5s-12.5,5.6-12.5,12.5c0,6.9,5.6,12.5,12.5,12.5S109.9,136.9,109.9,130
                z M162.1,130c0,11.3-9.2,20.5-20.5,20.5c-11.3,0-20.5-9.2-20.5-20.5c0-11.3,9.2-20.5,20.5-20.5C152.9,109.5,162.1,118.7,162.1,130z
                M154.1,130c0-6.9-5.6-12.5-12.5-12.5c-6.9,0-12.5,5.6-12.5,12.5c0,6.9,5.6,12.5,12.5,12.5C148.5,142.5,154.1,136.9,154.1,130z"
                />
            </g>
        </SvgIcon>
    );
};
