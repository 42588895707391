import { useObservableState } from 'observable-hooks';
import { SlackConfigureDialog } from '../../../../components/connections/apps/slack/SlackConfigureDialog';
import {
    slackConnectionSaving$,
    slackManageConnectionDetails$,
    slackAppSetupDialogErrors$,
    slackAppSetupDialogOpen$,
    slackAppSetupCloseDialogAction$,
    slackAppSetupSaveAction$,
    slackAppSetupDialogStage$,
} from '../../../../store/connection/slack/setup-connection';

export const SlackAppConfigureDialog: React.FC = () => {
    const open = useObservableState(slackAppSetupDialogOpen$);
    const saving = useObservableState(slackConnectionSaving$);
    const details = useObservableState(slackManageConnectionDetails$);
    const errors = useObservableState(slackAppSetupDialogErrors$);
    const currentStage = useObservableState(slackAppSetupDialogStage$);

    return (
        <SlackConfigureDialog
            open={open}
            onClose={() => {
                slackAppSetupCloseDialogAction$.next();
                slackAppSetupDialogStage$.next(0);
            }}
            currentStage={currentStage}
            setStage={(stage) => slackAppSetupDialogStage$.next(stage)}
            appInfo={{
                botUserOAuthAccessToken: details?.botUserOAuthAccessToken ?? '',
                appId: details?.appId ?? '',
                signingSecret: details?.signingSecret ?? '',
            }}
            saving={saving}
            errors={errors}
            clearErrors={() => slackAppSetupDialogErrors$.next(undefined)}
            onSave={(appInfoProps) => slackAppSetupSaveAction$.next(appInfoProps)}
        />
    );
};
