import { LoaderFn } from 'react-location';
import { LocationGenerics } from '../../router';
import { getDiscountCodesReport } from '../../data/report/discountCodes';
import { discountCodesList$ } from '../../store/report/discountCodes';

export const discountCodesLoader: LoaderFn<LocationGenerics> = async () => {
    const discountCodes = await getDiscountCodesReport();

    discountCodesList$.next(discountCodes);

    return {};
};
