import { getUsersWithUtilization as getUsersWithUtilizationRpc } from '@avst-stitch/repository-lib/lib';
import {
    Request as UsersUtilizationRequest,
    Response as UsersUtilizationData,
} from '@avst-stitch/repository-lib/lib/rpcs/report/getUsersWithUtilization';
import { repositoryInvoker } from '../../utils/repository';
export type { UsersUtilizationRequest, UsersUtilizationData };

export const getUsersWithUtilization = async (request: UsersUtilizationRequest): Promise<UsersUtilizationData> => {
    return await getUsersWithUtilizationRpc(repositoryInvoker, request);
};
