import {
    publishTemplate as publishTemplateRpc,
    unpublishTemplate as unpublishTemplateRpc,
    getTemplate as getTemplateRpc,
} from '@avst-stitch/repository-lib/lib';
import { Response as Template } from '@avst-stitch/repository-lib/lib/rpcs/getTemplate';
import { repositoryInvoker } from '../utils/repository';
export type { Template };

export const publishTemplate = async (templateUid: string): Promise<void> => {
    await publishTemplateRpc(repositoryInvoker, {
        uid: templateUid,
    });
};

export const unpublishTemplate = async (templateUid: string): Promise<void> => {
    await unpublishTemplateRpc(repositoryInvoker, {
        uid: templateUid,
    });
};

export const getTemplate = async (templateUid: string): Promise<Template> => {
    return await getTemplateRpc(repositoryInvoker, {
        uid: templateUid,
    });
};
