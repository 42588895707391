import { LoaderFn } from 'react-location';
import { getBillingEntities } from '../data/billing-details';
import { LocationGenerics } from '../router';
import {
    billingEntities$,
    selectedBillingDetails$,
    updateBillingEntityValidationError$,
} from '../store/billing-details';
import { loadErrorPage } from '../store/error';

export const billingEntityLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    try {
        const entities = await getBillingEntities();
        billingEntities$.next(entities);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const billingEntityUid = routeMatch.params.billingEntityUid!;
        const selectedBillingEntity = entities.find((e) => e.uid === billingEntityUid);
        selectedBillingDetails$.next(selectedBillingEntity);
        updateBillingEntityValidationError$.next('');
    } catch (e) {
        loadErrorPage({
            error: e,
            background: 'paper',
            genericMessage: 'Failed to load billing entity.',
        });

        throw e;
    }

    return {};
};
