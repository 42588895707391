import { LoaderFn } from 'react-location';
import { getEventListener, getEventListenerTestPayloads } from '../../data/event-listener';
import { LocationGenerics } from '../../router';
import { loadApps } from '../../store/apps';
import { loadLoggedInUserConnections } from '../../store/connection/utils';
import { selectedEnvironmentUid$, selectedWorkspaceSelectedResource$ } from '../../store/workspace';
import { selectedEventListenerDetails$, selectedEventListenerErrors$ } from '../../store/workspace/event-listener';
import {
    loadEventListenerPayloadContentAction$,
    selectedEventListenerPayloads$,
    selectedEventListenerSelectedPayloadErrors$,
    selectedEventListenerSelectedPayloadUid$,
} from '../../store/workspace/event-listener-payload';
import { loadErrorPage } from '../../store/error';
import { publishLocalFeedbackEventAction$ } from '../../store/feedback';
import { InformativeError } from '../../utils/repository';

export const eventListenerLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const eventListenerUid = routeMatch.params.eventListenerUid!;
    //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const environmentUid = routeMatch.params.environmentUid!;

    await eventListenerDetailsLoader(eventListenerUid, environmentUid);

    if (selectedEventListenerDetails$.value?.urlId) {
        await eventListenerTestEventPayloadsLoader(eventListenerUid, environmentUid);
    }
    return {};
};

export const readOnlyEventListenerLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const eventListenerUid = routeMatch.params.eventListenerUid!;

    const selectedEnvironmentUid = selectedEnvironmentUid$.value;
    await eventListenerDetailsLoader(eventListenerUid, selectedEnvironmentUid);

    return {};
};

const eventListenerDetailsLoader = async (eventListenerUid: string, environmentUid?: string): Promise<void> => {
    try {
        const [eventListener] = await Promise.all([getEventListener(eventListenerUid, environmentUid), loadApps()]);
        await loadLoggedInUserConnections(eventListener.connectionTypeUid);

        selectedEventListenerDetails$.next(eventListener);
        selectedWorkspaceSelectedResource$.next({
            resourceType: 'EVENT_LISTENER',
            uid: eventListenerUid,
        });
        selectedEventListenerErrors$.next(undefined);
    } catch (e) {
        loadErrorPage({
            error: e,
            background: 'paper',
            genericMessage: 'Failed to load Event Listener.',
        });

        throw e;
    }
};

const eventListenerTestEventPayloadsLoader = async (
    eventListenerUid: string,
    environmentUid: string
): Promise<void> => {
    try {
        const payloads = await getEventListenerTestPayloads(eventListenerUid, environmentUid);

        selectedEventListenerPayloads$.next(payloads);

        selectedEventListenerSelectedPayloadErrors$.next(undefined);
        selectedEventListenerSelectedPayloadUid$.next(payloads.selectedTestPayloadUid);

        if (payloads.selectedTestPayloadUid) {
            loadEventListenerPayloadContentAction$.next(payloads.selectedTestPayloadUid);
        }
    } catch (e) {
        const message = e instanceof InformativeError ? e.message : 'Failed to load Event Listener Test Payloads';

        message;
        publishLocalFeedbackEventAction$.next({
            level: 'ERROR',
            message,
        });
        throw e;
    }
};
