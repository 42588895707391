import { getRegisteredVsActiveUsersReport as getRegisteredVsActiveUsersReportRpc } from '@avst-stitch/repository-lib/lib';
import {
    Request as RegisteredVsActiveUsersRequest,
    Response as Users,
} from '@avst-stitch/repository-lib/lib/rpcs/report/getRegisteredVsActiveUsers';
import { repositoryInvoker } from '../../utils/repository';
export type { RegisteredVsActiveUsersRequest, Users };

export const getRegisteredVsActiveUsers = async (request: RegisteredVsActiveUsersRequest): Promise<Users> => {
    return await getRegisteredVsActiveUsersReportRpc(repositoryInvoker, request);
};
