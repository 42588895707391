import { useObservableState } from 'observable-hooks';
import { NewEnvironmentDialog } from '../../components/workspace-dialogs/NewEnvironmentDialog';
import {
    closeCreateNewWorkspaceEnvironmentDialogAction$,
    createNewWorkspaceEnvironmentAction$,
    createNewWorkspaceEnvironmentDialogErrors$,
    createNewWorkspaceEnvironmentDialogOpen$,
    creatingNewWorkspaceEnvironment$,
} from '../../store/workspace/environment';

export const CreateNewWorkspaceEnvironmentDialogContainer: React.FC = () => {
    const open = useObservableState(createNewWorkspaceEnvironmentDialogOpen$);
    const creating = useObservableState(creatingNewWorkspaceEnvironment$);
    const errors = useObservableState(createNewWorkspaceEnvironmentDialogErrors$);

    return (
        <NewEnvironmentDialog
            open={open}
            saving={creating}
            errors={errors}
            onCancel={() => closeCreateNewWorkspaceEnvironmentDialogAction$.next()}
            onSave={(name) => createNewWorkspaceEnvironmentAction$.next(name)}
        />
    );
};
