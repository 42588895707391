import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React from 'react';
import { MondayConnection } from './MondayAppConfigureDialog';

export enum MondayWizardStage {
    ADDURL,
    INSTALL,
    AUTHORIZE,
    SUCCESS,
}

export interface MondayWizardStepsProps {
    readonly stage: MondayWizardStage;
}

export const MondayWizardSteps: React.FC<MondayWizardStepsProps> = ({ stage }) => {
    return (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={MondayWizardStage.ADDURL}>
                <StepLabel>Add monday.com account URL</StepLabel>
            </Step>
            <Step key={MondayWizardStage.INSTALL}>
                <StepLabel>Install Application in monday.com</StepLabel>
            </Step>
            <Step key={MondayWizardStage.AUTHORIZE}>
                <StepLabel>Authorize</StepLabel>
            </Step>
        </Stepper>
    );
};

export type StageType = React.FC<
    MondayConnection & {
        stage: MondayWizardStage;
        setStage: (stage: MondayWizardStage) => void;
    }
>;
