import { useObservableState, useSubscription } from 'observable-hooks';
import { DiscountCodesReportPage } from '../../components/report/DiscountCodesReportPage';
import { discountCodesList$ } from '../../store/report/discountCodes';
import {
    createDiscountCodeAction$,
    createDiscountCodeError$,
    createDiscountCodeInProgress$,
    discountCodeCreatedAction$,
} from '../../store/discountCode';
import { useNavigate } from 'react-location';
import { getBasePath } from '../../utils/path';

export const DiscountCodesReportPageContainer: React.FC = () => {
    const discountCodes = useObservableState(discountCodesList$);
    const createDiscountCodeError = useObservableState(createDiscountCodeError$);
    const createDiscountCodeInProgress = useObservableState(createDiscountCodeInProgress$);

    const navigate = useNavigate();

    useSubscription(discountCodeCreatedAction$, () => {
        navigate({ to: '../' });
        setTimeout(() => navigate({ to: `${getBasePath()}/reports/discountCodes` }), 100);
    });
    return (
        <DiscountCodesReportPage
            discountCodes={discountCodes}
            createDiscountCodeInProgress={createDiscountCodeInProgress}
            onCreate={(code, expiryDate) => {
                createDiscountCodeAction$.next({ code, expiryDate: new Date(expiryDate) });
            }}
            createDiscountCodeError={createDiscountCodeError}
        />
    );
};
