import React from 'react';
import { MicrosoftIcon } from '../../../icons/MicrosoftIcon';
import { ConnectionDialog } from '../../ConnectionDialog';

export interface ManageConnectionDialog {
    readonly tenantId?: string;
    readonly onSave: (name: string) => void;
    readonly saving: boolean;
    readonly authorizeLoading: boolean;
    readonly onAuthorize: (name: string) => void;
    readonly open: boolean;
    readonly onCancel: () => void;
    readonly name: string;
    readonly authorized: boolean;
    readonly errors?: string;
    readonly connectionType?: string;
}

export const MicrosoftManageConnectionDialog: React.FC<ManageConnectionDialog> = ({
    name,
    saving,
    onSave,
    onAuthorize,
    authorizeLoading,
    open,
    onCancel,
    authorized,
    errors,
    tenantId,
    connectionType,
}) => {
    return (
        <>
            <ConnectionDialog
                authorized={authorized}
                name={name}
                instanceId={tenantId}
                onSave={onSave}
                onAuthorize={onAuthorize}
                authorizeLoading={!!authorizeLoading}
                saving={saving}
                icon={<MicrosoftIcon fontSize="inherit" />}
                open={open}
                onCancel={onCancel}
                errors={errors}
                connectionType={connectionType}
            />
        </>
    );
};
