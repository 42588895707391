import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React from 'react';
import { ZoomConnection } from './ZoomAppConfigureDialog';

export enum ZoomWizardStage {
    CREATE,
    DETAILS,
    ADD_SCOPES,
    AUTHORIZE,
    SUCCESS,
}

export interface ZoomWizardStepsProps {
    readonly stage: ZoomWizardStage;
}

export const ZoomWizardSteps: React.FC<ZoomWizardStepsProps> = ({ stage }) => {
    return (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={ZoomWizardStage.CREATE}>
                <StepLabel>Create Application in Zoom</StepLabel>
            </Step>
            <Step key={ZoomWizardStage.DETAILS}>
                <StepLabel>Enter New Application Details</StepLabel>
            </Step>
            <Step key={ZoomWizardStage.ADD_SCOPES}>
                <StepLabel>Add Required Scopes</StepLabel>
            </Step>
            <Step key={ZoomWizardStage.AUTHORIZE}>
                <StepLabel>Authorize</StepLabel>
            </Step>
        </Stepper>
    );
};

export type StageType = React.FC<
    ZoomConnection & {
        stage: ZoomWizardStage;
        setStage: (stage: ZoomWizardStage) => void;
    }
>;
