import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React from 'react';

export enum NetSuiteEventListenerWizardStage {
    UPLOAD_SCRIPT,
    CREATE_AND_DEPLOY,
}

export interface NetSuiteWizardStepsProps {
    readonly stage: NetSuiteEventListenerWizardStage;
}

export const NetSuiteEventListenerWizardSteps: React.FC<NetSuiteWizardStepsProps> = ({ stage }) => {
    return (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={NetSuiteEventListenerWizardStage.UPLOAD_SCRIPT}>
                <StepLabel>Upload Script</StepLabel>
            </Step>
            <Step key={NetSuiteEventListenerWizardStage.CREATE_AND_DEPLOY}>
                <StepLabel>Create Script Record and Deploy</StepLabel>
            </Step>
        </Stepper>
    );
};
