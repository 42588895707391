interface ElementWithName {
    name: string;
}

export const searchByName = <T extends ElementWithName>(array: T[], searchTerm: string): T[] => {
    return searchTerm
        ? array.filter((e) => {
              return e.name.toLowerCase().includes(searchTerm.toLowerCase());
          })
        : array;
};
