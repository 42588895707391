import { useObservableState } from 'observable-hooks';
import {
    askImpersonationPasswordDialogOpen$,
    askImpersonationPasswordDialogSaving$,
    closeImpersonationPasswordDialogAction$,
    impersonationPasswordError$,
    selectedImpersonateeUid$,
    startImpersonationAction$,
} from '../../store/user';
import { ImpersonationPasswordDialog } from '../../components/report/ImpersonationPasswordDialog';

export const ImpersonationPasswordDialogContainer: React.FC = () => {
    const errors = useObservableState(impersonationPasswordError$);
    const saving = useObservableState(askImpersonationPasswordDialogSaving$);
    const open = useObservableState(askImpersonationPasswordDialogOpen$);
    const impersonateeUserUid = useObservableState(selectedImpersonateeUid$);

    return (
        <ImpersonationPasswordDialog
            errors={errors}
            open={open}
            saving={saving}
            onCancel={() => closeImpersonationPasswordDialogAction$.next()}
            onProceed={(password) => {
                startImpersonationAction$.next({
                    impersonateeUserUid: impersonateeUserUid ?? '',
                    password: password ?? '',
                });
            }}
        />
    );
};
