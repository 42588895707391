import { useObservableState } from 'observable-hooks';
import { ConfirmDialog } from '../../components/common/dialog/ConfirmDialog';
import { confirmDialogDetails$, confirmDialogOpen$ } from '../../store/confirm';

export const ConfirmDialogContainer: React.FC = () => {
    const details = useObservableState(confirmDialogDetails$);
    const open = useObservableState(confirmDialogOpen$);

    return <ConfirmDialog open={open} details={details ?? {}} />;
};
