import { Theme } from '@mui/material/styles';
import { keyframes, Keyframes } from '@emotion/react';

const pulse = (theme: Theme): Keyframes =>
    keyframes({
        '0%': {
            backgroundColor: theme.palette.secondary.dark,
            opacity: 0.2,
        },
        '50%': {
            backgroundColor: theme.palette.secondary.light,
            opacity: 0,
        },
        '100%': {
            backgroundColor: theme.palette.secondary.dark,
            opacity: 0.2,
        },
    });

export const remnantStyles = (theme: Theme) =>
    ({
        color: theme.palette.text.disabled,
        fontStyle: 'italic',
        opacity: 0.87,
        '.MuiTypography-root': {
            color: theme.palette.secondary.main,
        },
    } as const);

export const selectedStyles = (theme: Theme) =>
    ({
        backgroundColor: theme.palette.action.selected,
        '& .MuiSvgIcon-root': {
            opacity: 1,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.focus,
        },
    } as const);

export const wizardIncompleteStyles = (theme: Theme) =>
    ({
        '&:before': {
            animation: `${pulse(theme)} 1.5s infinite ease`,
            content: '""',
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
        },
    } as const);

export const wizardFocusStyles = (theme: Theme) =>
    ({
        border: `2px solid ${theme.palette.secondary.main}`,
        '& .MuiSvgIcon-root': {
            color: theme.palette.primary.main,
        },
        '&:hover .MuiSvgIcon-root': {
            color: theme.palette.secondary.main,
        },
    } as const);

export const absoluteCenterStyles = {
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
};

export const dashedBorderStyle = (theme: Theme): string => `1px dashed ${theme.palette.action.active}`;

export const resourceTreeIconWrapperSize = 24;
export const resourceTreeIconSize = 20;
export const resourceTreeAccordionSummaryHeight = 32;
export const resourceTreeSideBarWidth = 32;
