import {
    MetaDirectory,
    MetaEntry,
    MetaFile,
    PackageError,
    PackageLocator,
    WithContent,
    WithError,
    WithIntegrity,
    WithPath,
} from './types';

export const isMetaFile = (entry: MetaEntry | PackageError): entry is MetaFile =>
    !hasError(entry) && entry.type === 'file' && typeof entry.path === 'string';

export const isMetaDirectory = (entry: MetaEntry | PackageError): entry is MetaDirectory =>
    !hasError(entry) && entry.type === 'directory' && Array.isArray(entry.files);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasError = <T>(val: T): val is T & WithError => typeof (val as any).error === 'string';

export const hasContent = <T>(val: T & (WithContent | WithError)): val is T & WithContent =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof (val as any).content === 'string' && !hasError(val);

export const isValidPackage = <T extends PackageLocator>(pkg: T | PackageError): pkg is T => !hasError(pkg);

export const hasIntegrityAndPath = <T>(val: T): val is T & WithIntegrity & WithPath =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof (val as any).integrity === 'string' && typeof (val as any).path === 'string';
