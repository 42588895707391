// import React from 'react';
import { styled } from '@mui/material';
import Card from '@mui/material/Card';

export const dashboardCardStyles = {
    border: 'none',
    height: '100%',
    minWidth: 260,
    position: 'relative',
    width: '100%',
    '@media (max-width: 900px)': {
        minWidth: 200,
    },
} as const;

export const StyledCard = styled(Card)(({ theme }) => ({
    ...dashboardCardStyles,
    boxShadow: theme.constants.boxShadow,
    cursor: 'pointer',
    padding: theme.spacing(2, 2, 1),
    '&:hover': {
        outline: theme.constants.borderSelected,
    },
}));
