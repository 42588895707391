export const saveLocalStorage = (key: string, value: unknown): void => {
    try {
        if (typeof value === 'string') {
            localStorage.setItem(key, value);
        } else {
            localStorage.setItem(key, JSON.stringify(value));
        }
    } catch (e) {
        console.warn('Failed to save value into local storage', e, {
            key,
            value,
        });
    }
};
/* eslint-disable @typescript-eslint/no-explicit-any */
export const readLocalStorage: <T = any | undefined>(key: string, defaultValue: T) => T = (key, defaultValue) => {
    try {
        const value = localStorage.getItem(key);
        if (value) {
            return typeof defaultValue === 'string' ? value : JSON.parse(value);
        }
    } catch (e) {
        console.warn('Failed to read value from local storage', e);
    }
    return defaultValue;
};
