import { useObservableState } from 'observable-hooks';
import { JsmCloudAssetsAppConfigureDialog } from '../../../../components/connections/apps/jsmCloudAssets/JsmCloudAssetsAppConfigureDialog';
import {
    jsmCloudAssetsConnectionSaving$,
    jsmCloudAssetsSetupDialogErrors$,
    jsmCloudAssetsSetupDialogOpen$,
    jsmCloudAssetsSetupCloseDialogAction$,
    jsmCloudAssetsSetupAuthorizeAction$,
} from '../../../../store/connection/jsmCloudAssets/setup-connection';

export const JsmCloudAssetsConfigureDialog: React.FC = () => {
    const open = useObservableState(jsmCloudAssetsSetupDialogOpen$);
    const saving = useObservableState(jsmCloudAssetsConnectionSaving$);
    const errors = useObservableState(jsmCloudAssetsSetupDialogErrors$);

    return (
        <JsmCloudAssetsAppConfigureDialog
            open={open}
            onCancel={() => jsmCloudAssetsSetupCloseDialogAction$.next()}
            saving={saving}
            errors={errors}
            onSave={(apiKey) => jsmCloudAssetsSetupAuthorizeAction$.next({ apiKey })}
        />
    );
};
