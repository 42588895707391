import { LoaderFn } from 'react-location';
import { getUserFeedback } from '../../data/report/userFeedback';
import { LocationGenerics } from '../../router';
import { userFeedback$ } from '../../store/report/userFeedback';

export const userFeedbackReportLoader: LoaderFn<LocationGenerics> = async () => {
    const feedback = await getUserFeedback();
    userFeedback$.next(feedback);

    return {};
};
