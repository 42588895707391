import loader from '@monaco-editor/loader';
import { publishLocalFeedbackEventAction$ } from '../../store/feedback';
import { CompilationError } from '../../utils/repository';
import { ScriptWithUid } from '../../utils/types';
import { CompiledOutput, TypeScriptWorker, TypeScriptDiagnostic, ModulesAndWorker, ScriptModule } from './types';

interface CompileProps {
    scripts: ScriptWithUid[];
    haltOnErrors?: boolean;
    publishCompileError?: boolean;
}
/**
 * Compile all workspace scripts
 */
export const compile = async ({
    scripts,
    haltOnErrors = false,
    publishCompileError = true,
}: CompileProps): Promise<CompiledOutput[]> => {
    const scriptsAndWorker = await getMonacoWorker(scripts);
    if (scriptsAndWorker) {
        return await Promise.all(
            scriptsAndWorker.modules.map((module) =>
                compileScript(module, scriptsAndWorker.worker, haltOnErrors, publishCompileError)
            )
        );
    } else {
        throw Error('Monaco worker not found');
    }
};

const getMonacoWorker = async (scripts: ScriptWithUid[]): Promise<ModulesAndWorker | null> => {
    const monaco = await loader.init();
    const modules: ScriptModule[] = [];

    scripts.forEach((script) => {
        const uri = monaco.Uri.file(`/${script.name}.ts`);
        const model = monaco.editor.getModel(uri);

        if (!model) {
            monaco.editor.createModel(script.content, 'typescript', uri);
        }

        modules.push({
            name: script.name,
            uid: script.uid,
            uri,
        });
    });

    try {
        const apiHandlerModels = monaco.editor
            .getModels()
            .filter((model) => model.uri.path.startsWith('/api/'))
            .map((model) => ({ uri: model.uri, name: model.uri.path }));

        const createWorker = await monaco.languages.typescript.getTypeScriptWorker();
        const worker = await createWorker(
            ...modules.map((script) => script.uri),
            ...apiHandlerModels.map((model) => model.uri)
        );
        return {
            modules: [...modules, ...apiHandlerModels],
            worker,
        };
    } catch (e) {
        // May throw an 'TypeScript not registered!' error if TS support hasn't yet loaded due to
        // no TS models being registered with Monaco.
        // This is fine, we'll just fall through and return no worker.
        console.error(e);
    }

    return null;
};

/**
 * Compile script
 */
const compileScript = async (
    script: ScriptModule,
    worker: TypeScriptWorker,
    haltOnErrors: boolean,
    publishCompileError: boolean
): Promise<CompiledOutput> => {
    const filename = script.uri.toString();

    const [syntacticDiagnostics, semanticDiagnostics, suggestionsDiagnostics, compilerOptionsDiagnostics, output] =
        await Promise.all([
            worker.getSyntacticDiagnostics(filename),
            worker.getSemanticDiagnostics(filename),
            worker.getSuggestionDiagnostics(filename),
            worker.getCompilerOptionsDiagnostics(filename),
            worker.getEmitOutput(filename),
        ]);

    const diagnostics = [
        ...syntacticDiagnostics,
        ...semanticDiagnostics,
        ...suggestionsDiagnostics,
        ...compilerOptionsDiagnostics,
    ];

    const hasErrors = diagnostics.some(isError);

    if (hasErrors && publishCompileError) {
        const message = `Compilation error in file ${script.name}: ${diagnostics
            .filter(isError)
            .map((d) => (typeof d.messageText === 'string' ? d.messageText : d.messageText.messageText))
            .join(', ')}`;

        publishLocalFeedbackEventAction$.next({
            level: 'ERROR',
            message,
            noToast: true,
        });

        if (haltOnErrors) {
            throw new CompilationError(message);
        }
    }

    return {
        ...script,
        compiled: {
            diagnostics,
            hasErrors,
            files: output.outputFiles,
        },
    };
};

const isError = ({ category }: TypeScriptDiagnostic): boolean => category === 1;
