import { UserNameDetails } from './types';

// TODO: move it into commons
export const getUserDisplayName = (user: UserNameDetails): string => {
    let userDisplayName = '';

    if (user.firstName) {
        userDisplayName += user.firstName;
    }

    if (user.lastName) {
        userDisplayName += ` ${user.lastName}`;
    }

    userDisplayName = userDisplayName.trim();

    if (!userDisplayName) {
        userDisplayName = user.email;
    }

    return userDisplayName;
};
