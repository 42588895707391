export class Retry {
    constructor(public input: RequestInfo, public init?: RequestInit) {}
}

export class Abort extends Error {}

export interface RefetchInit {
    readonly retries: number;
    readonly retryDelay: number;
    readonly debug: boolean;
    cache?: RequestInit['cache'];
    // eslint-disable-next-line max-len
    afterFetch?(
        time: number,
        response?: Response,
        error?: unknown
    ): Retry | Abort | undefined | void | Promise<Retry | Abort | undefined | void>;
}

let defaultInit: RefetchInit = {
    retries: 4,
    retryDelay: 0,
    debug: false,
};

/**
 * Wrapper for fetch that retries on failure, and eventually throws on any non-ok status
 */
export const refetch =
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async (input: RequestInfo, init?: RequestInit & Partial<RefetchInit>): Promise<Response> => {
        const { retries, retryDelay, debug, afterFetch, ...reqInit } = { ...defaultInit, ...init };

        let error: unknown = null;

        const startTime = Date.now();

        // eslint-disable-next-line max-len
        const handleAfterFetch = async (
            time: number,
            response?: Response,
            error?: unknown
        ): Promise<Response | undefined> => {
            if (afterFetch) {
                let afterFetchResponse = afterFetch(time, response, error);
                if (afterFetchResponse instanceof Promise) {
                    afterFetchResponse = await afterFetchResponse;
                }
                if (afterFetchResponse instanceof Retry) {
                    return await refetch(afterFetchResponse.input, {
                        ...(afterFetchResponse.init ?? {}),
                        retries,
                        retryDelay,
                        debug,
                        afterFetch,
                    });
                } else if (afterFetchResponse instanceof Abort) {
                    throw afterFetchResponse;
                }
            }
            return undefined;
        };

        try {
            let response = await fetch(input, reqInit);
            const time = Date.now() - startTime;

            const afterFetchResponse = await handleAfterFetch(time, response);
            if (afterFetchResponse) {
                response = afterFetchResponse;
            }

            if (response.ok) {
                return response;
            }

            error = response;
        } catch (e) {
            if (e instanceof Abort) {
                throw e;
            } else {
                const time = Date.now() - startTime;

                const afterFetchResponse = await handleAfterFetch(time, undefined, e);
                if (afterFetchResponse) {
                    return afterFetchResponse;
                }

                error = e;
            }
        }

        if (retries > 0) {
            if (debug) {
                // eslint-disable-next-line max-len
                console.debug(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    `Retry fetch in ${retryDelay}ms: ${(input as any).url ?? input} (retries: ${retries - 1})`
                );
            }
            await sleep(retryDelay);
            return await refetch(input, {
                ...init,
                cache: 'no-cache',
                retries: retries - 1,
                retryDelay: retryDelay * 2,
                debug,
            });
        } else {
            throw error;
        }
    };

const sleep = (duration: number): Promise<unknown> => new Promise((resolve) => setTimeout(resolve, duration));

export const setRefetchDefaults = (init: Partial<RefetchInit>): void => {
    defaultInit = {
        ...defaultInit,
        ...init,
    };
};
