import { sendMessageToAIAssistance as sendMessageToAIAssistanceRpc } from '@avst-stitch/repository-lib/lib';
import { saveAIAssistanceResponse as saveAIAssistanceResponseRpc } from '@avst-stitch/repository-lib/lib';
import { Request as sendMessageToAIRequest } from '@avst-stitch/repository-lib/lib/rpcs/sendMessageToAIAssistance';
import { Request as SaveAIResponseRequest } from '@avst-stitch/repository-lib/lib/rpcs/saveAIAssistanceResponse';
import { repositoryInvoker } from '../utils/repository';

export const sendMessageToAIAssistance = async (request: sendMessageToAIRequest): Promise<void> => {
    return await sendMessageToAIAssistanceRpc(repositoryInvoker, request);
};

export const saveAIAssistanceResponse = async (request: SaveAIResponseRequest): Promise<void> => {
    return await saveAIAssistanceResponseRpc(repositoryInvoker, request);
};
