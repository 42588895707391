import { styled } from '@mui/material';
import Box from '@mui/system/Box';

export const StyledUserContainer = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
}));

export const StyledNameAndEmailContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: theme.spacing(1),
}));

export const StyledImageBox = styled(Box)<{ size?: number }>(({ theme, size = 30 }) => ({
    ...theme.typography.radiusCircle,
    display: 'flex',
    flexShrink: 0,
    height: size,
    marginRight: theme.spacing(1),
    width: size,
}));

export const StyledImageTextBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: 60,
    justifyContent: 'space-around',
    marginLeft: theme.spacing(1),
}));

export const StyledOrgDetailsContainer = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    padding: theme.spacing(0, 0, 2),
}));
