import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

export enum GithubEventListenerWizardStage {
    CHOOSE_WEBHOOK_TYPE,
    SETUP_WEBHOOK,
}

export interface GithubWizardStepsProps {
    readonly stage: GithubEventListenerWizardStage;
}

export const GithubEventListenerWizardSteps: React.FC<GithubWizardStepsProps> = ({ stage }) => {
    return (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={GithubEventListenerWizardStage.CHOOSE_WEBHOOK_TYPE}>
                <StepLabel>Choose webhook type</StepLabel>
            </Step>
            <Step key={GithubEventListenerWizardStage.SETUP_WEBHOOK}>
                <StepLabel>Setup webhook</StepLabel>
            </Step>
        </Stepper>
    );
};
