import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import { Button } from '../../../common/buttons/Button';
import { ReadOnlyTextField } from '../../../common/textfield/ReadOnlyTextField';
import { handleKeyDown } from '../../../../utils/handleKeyDown';

interface StatuspageEventListenerSetupDialogProps {
    open?: boolean;
    webhookBaseUrl: string;
    webhookUrlId: string;
    onClose: () => void;
}

export const StatuspageEventListenerSetupDialog: React.FC<StatuspageEventListenerSetupDialogProps> = ({
    open = false,
    webhookBaseUrl,
    webhookUrlId,
    onClose,
}) => {
    const connectionTypeName = 'Statuspage';
    return (
        <>
            <Dialog
                maxWidth="md"
                open={open}
                onKeyDown={(event) => handleKeyDown({ event, enterFn: onClose, escFn: onClose })}
            >
                <DialogTitle sx={{ mb: 2 }} variant="h6">
                    Event Listener setup instructions for {connectionTypeName}
                </DialogTitle>

                <DialogContent>
                    <ReadOnlyTextField label="Webhook URL" value={`${webhookBaseUrl}/${webhookUrlId}`} />
                    <DialogContentText variant="subtitle2">
                        You need to setup a webhook in your {connectionTypeName} instance in order to listen to events
                        in ScriptRunner Connect.
                    </DialogContentText>
                    <DialogContentText component="ol">
                        <li>Copy the URL above.</li>
                        <li>
                            Log into your
                            <Link target="_blank" href="https://manage.statuspage.io/">
                                {' '}
                                {connectionTypeName} instance
                            </Link>
                        </li>
                        <li>
                            On the left side menu, make sure that you have selected the organization and page which you
                            want to subscribe to.
                        </li>
                        <li>
                            Click <strong>Subscribers</strong>.
                        </li>
                        <li>
                            Make sure that you have webhooks enabled. To do that click on <strong>Options</strong> to
                            the right of the search box, select <strong>Settings</strong> and on the appearing modal,
                            make sure that you have the <strong>Webhook</strong> option checked.
                        </li>

                        <li>
                            To create a new webhook, click <strong>Options</strong> again and select{' '}
                            <strong>Add subscriber</strong>
                        </li>
                        <li>
                            Under <strong>Subscriber type</strong> select <strong>Webhook</strong>.
                        </li>
                        <li>
                            Paste the copied URL into the <strong>Endpoint URL</strong> field.
                        </li>
                        <li>
                            Enter any email into the <strong>Email address</strong> field. This is where{' '}
                            {connectionTypeName} will send notifications should the endpoint fail.
                        </li>
                        <li>
                            Click <strong>Add subscriber</strong>
                        </li>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Done</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
