import {
    getMyBillingEntities as getMyBillingEntitiesRpc,
    saveBillingEntity as saveBillingEntityRpc,
    createBillingEntity as createBillingEntityRpc,
} from '@avst-stitch/repository-lib/lib';
import { Response as BillingEntities } from '@avst-stitch/repository-lib/lib/rpcs/getMyBillingEntities';
import { repositoryInvoker } from '../utils/repository';
export type { BillingEntities };

export const getBillingEntities = async (): Promise<BillingEntities> => {
    return await getMyBillingEntitiesRpc(repositoryInvoker);
};

export const updateBillingEntity = async (uid: string, contactName: string, contactEmail: string): Promise<void> => {
    await saveBillingEntityRpc(repositoryInvoker, { uid, contactName, contactEmail });
};

export const createBillingEntity = async (contactName: string, contactEmail: string): Promise<{ uid: string }> => {
    return await createBillingEntityRpc(repositoryInvoker, { contactName, contactEmail });
};
