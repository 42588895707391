import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React from 'react';
import { AtlassianCloudConnection, InstanceType } from './AtlassianCloudAppConfigureDialog';

export enum AtlassianCloudWizardStage {
    SPECIFY_SERVICE_ACCOUNT,
    CHOOSE_INSTANCE_TYPE,
    CREATE,
    CONFIGURE,
    DETAILS,
    AUTHORIZE,
    SUCCESS,
}

export interface AtlassianCloudWizardStepsProps {
    readonly stage: AtlassianCloudWizardStage;
    readonly instanceType: 'SAAS' | 'SELF-MANAGED';
}

export const AtlassianCloudWizardSteps: React.FC<AtlassianCloudWizardStepsProps> = ({
    stage,
    instanceType = 'SAAS',
}) => {
    return instanceType === 'SELF-MANAGED' ? (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={AtlassianCloudWizardStage.SPECIFY_SERVICE_ACCOUNT}>
                <StepLabel>Specify Service Account (Optional)</StepLabel>
            </Step>
            <Step key={AtlassianCloudWizardStage.CHOOSE_INSTANCE_TYPE}>
                <StepLabel>Choose Instance Type</StepLabel>
            </Step>
            <Step key={AtlassianCloudWizardStage.CREATE}>
                <StepLabel>Create Application</StepLabel>
            </Step>
            <Step key={AtlassianCloudWizardStage.CONFIGURE}>
                <StepLabel>Configure Application</StepLabel>
            </Step>
            <Step key={AtlassianCloudWizardStage.DETAILS}>
                <StepLabel>Enter Details</StepLabel>
            </Step>
            <Step key={AtlassianCloudWizardStage.AUTHORIZE}>
                <StepLabel>Authorize</StepLabel>
            </Step>
        </Stepper>
    ) : (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={AtlassianCloudWizardStage.SPECIFY_SERVICE_ACCOUNT}>
                <StepLabel>Specify Service Account (Optional)</StepLabel>
            </Step>
            <Step key={AtlassianCloudWizardStage.CHOOSE_INSTANCE_TYPE}>
                <StepLabel>Choose Instance Type</StepLabel>
            </Step>
            <Step key={AtlassianCloudWizardStage.AUTHORIZE}>
                <StepLabel>Authorize</StepLabel>
            </Step>
        </Stepper>
    );
};

export type StageType = React.FC<
    AtlassianCloudConnection & {
        stage: AtlassianCloudWizardStage;
        instanceType: InstanceType;
        setStage: (stage: AtlassianCloudWizardStage) => void;
        onChooseInstanceType: (type: InstanceType) => void;
    }
>;
