import { hasIntegrityAndPath } from './guards';
import { WithContent, WithError, WithFilePath, WithName, WithPath, WithVersion } from './types';
import { fetchFromJsdelivr } from './jsdelivr';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cache = new Map<string, any>();

/**
 * Fetch the content from jsdelivr
 */
export const fetchContent =
    // eslint-disable-next-line max-len
    async <E extends WithPath & WithFilePath & WithName & WithVersion>(
        entry: E
    ): Promise<E & (WithContent | WithError)> => {
        if (hasIntegrityAndPath(entry) && cache.has(`${entry.integrity}|${entry.name}${entry.path}`)) {
            return cache.get(`${entry.integrity}|${entry.name}${entry.path}`);
        }

        let error = '';

        try {
            // Uncomment to simulate randomized errors, consider putting it behind a feature flag
            // if (Math.random() > 0.7) {
            //     throw Error('Randomized error')
            // }

            let content: string | undefined = undefined;

            const response = await fetchFromJsdelivr({
                name: entry.name,
                version: entry.version,
                path: entry.path,
            });

            content = await response.text();

            const result = {
                ...entry,
                content,
            };

            if (hasIntegrityAndPath(entry)) {
                cache.set(`${entry.integrity}|${entry.name}${entry.path}`, result);
            }

            return result;
        } catch (e) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            error = (e as any).statusText ?? String(e);
        }

        return {
            ...entry,
            error,
        };
    };

export const clearCache = (): void => cache.clear();
