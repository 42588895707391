import AddIcon from '@mui/icons-material/Add';
import { Button } from '../../common/buttons/Button';
import { OrganizationSelectorCard } from './OrganizationSelectorCard';
import {
    PageContainer,
    PageGridContainer,
    PageSubTitle,
    PageTitle,
    PageUpperContainer,
    PageUpperGroup,
} from '../../app-main/PageComponents';
import {
    EmptyStateContainer,
    EmptyStateImg,
    EmptyStateText,
    StyledActionButton,
} from '../../app-main/EmptyStateComponents';

interface OrganizationSelectorProps {
    organizations: {
        uid: string;
        name: string;
        avatar?: string; // Base64 of the image (add rounding mask in FE), if not present use the MUI avatar for the first letter
        createdDate: string; // ISO date, conver to local date in FE
        planName: string;
        ownedDefaultOrganization?: boolean;
        members: {
            firstName?: string;
            lastName?: string; // Show name on hover
            email: string;
            avatar?: string; // Base64 of the image (add rounding mask in FE), if not present use the MUI avatar with the first letter of the first and last name
        }[];
    }[];
    docsUrl: string;
    onOpen(orgUid: string): void;
    onDelete(orgUid: string): void;
    onCreateNew(): void;
}

export const OrganizationSelector: React.FC<OrganizationSelectorProps> = ({
    docsUrl,
    organizations = [],
    onCreateNew,
    onOpen,
    onDelete,
}) => {
    const items = organizations.map((organization) => {
        return (
            <OrganizationSelectorCard
                key={organization.uid}
                ownedDefaultOrganization={organization.ownedDefaultOrganization}
                createdDate={organization.createdDate}
                name={organization.name}
                planName={organization.planName}
                uid={organization.uid}
                members={organization.members}
                avatar={organization.avatar}
                onOpen={onOpen}
                onDelete={onDelete}
            />
        );
    });

    const createLabel = (): string => {
        const currentResultsLength = organizations.length ?? 0;

        return currentResultsLength === 1 ? currentResultsLength + ' Result' : currentResultsLength + ' Results';
    };

    return (
        <PageContainer>
            <PageUpperContainer>
                <PageUpperGroup>
                    <PageTitle title="Teams" label={createLabel()} />
                </PageUpperGroup>
                {items.length > 0 && (
                    <PageUpperGroup>
                        <Button onClick={onCreateNew} startIcon={<AddIcon />}>
                            Create New
                        </Button>
                    </PageUpperGroup>
                )}
            </PageUpperContainer>
            {items.length > 0 && <PageGridContainer>{items}</PageGridContainer>}
            {items.length === 0 && (
                <EmptyStateContainer>
                    <EmptyStateImg src={process.env.PUBLIC_URL + '/wizard-wave-slow.gif'} alt="Hello" />
                    <PageSubTitle variant="h6">Create your first team</PageSubTitle>
                    <EmptyStateText>
                        An <strong>team</strong> is a dedicated space, where you can invite team members to collaborate
                        with you on a solution.
                    </EmptyStateText>
                    <StyledActionButton startIcon={<AddIcon />} onClick={onCreateNew}>
                        Create New
                    </StyledActionButton>
                    <EmptyStateText>Not sure where to start?</EmptyStateText>
                    <Button variant="outlined" onClick={() => window.open(docsUrl)}>
                        View Documentation
                    </Button>
                </EmptyStateContainer>
            )}
        </PageContainer>
    );
};
