import { getUserFeedbackAttachment as getUserFeedbackAttachmentRpc } from '@avst-stitch/repository-lib/lib';
import {
    Response as AttachmentUrl,
    Request,
} from '@avst-stitch/repository-lib/lib/rpcs/report/getUserFeedbackAttachment';
import { repositoryInvoker } from '../../utils/repository';
export type { AttachmentUrl };

export const getUserFeedbackAttachment = async (request: Request): Promise<AttachmentUrl> => {
    return await getUserFeedbackAttachmentRpc(repositoryInvoker, request);
};
