import { LoaderFn } from 'react-location';
import { getTemplatesUsed, TemplatesUsedRequest } from '../../data/report/templatesUsed';
import { LocationGenerics } from '../../router';
import { templatesUsed$ } from '../../store/report/templatesUsed';

export const templateUsedReportLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    const templates = await getTemplatesUsed({
        userType: routeMatch.search.userType as TemplatesUsedRequest['userType'],
    });

    templatesUsed$.next(templates);

    return {};
};
