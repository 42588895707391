// Add styles to theme when implemented across the app
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

interface DialogCloseButtonProps extends IconButtonProps {
    onClose(): void;
}

// Remove and use IconButton directly when styles get overwritten for icon buttons in the theme
const StyledDialogIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.secondary,
    height: 30,
    width: 30,
}));

// Overwriting theme styles
export const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        minHeight: 'unset',
        minWidth: 444,
        padding: theme.spacing(0),
        width: 'auto',
    },
}));

export const StyledDialogHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
}));

export const StyledDialogTitleContainer = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1.5),
}));

// Keeping this here to import all dialog components from one file until this gets written to the theme
export const StyledDialogTitle = styled(DialogTitle)(() => ({}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
    padding: theme.spacing(2),
    '& .MuiFormControl-root, .MuiInputBase-root': {
        width: '100%', // Overwrite the theme, this needs a lot of adjustment
    },
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
    margin: 0, // Overwriting the theme
    padding: theme.spacing(2),
}));

export const DialogCloseButton: React.FC<DialogCloseButtonProps> = ({ onClose, ...props }) => {
    return (
        <StyledDialogIconButton {...props} onClick={onClose}>
            <CloseOutlinedIcon />
        </StyledDialogIconButton>
    );
};
