import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React from 'react';
import { ServiceNowConnection } from './ServiceNowAppConfigureDialog';

export enum ServiceNowWizardStage {
    ADDURL,
    CREATE,
    DETAILS,
    AUTHORIZE,
    SUCCESS,
}

export interface ServiceNowWizardStepsProps {
    readonly stage: ServiceNowWizardStage;
}

export const ServiceNowWizardSteps: React.FC<ServiceNowWizardStepsProps> = ({ stage }) => {
    return (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={ServiceNowWizardStage.ADDURL}>
                <StepLabel>Add ServiceNow Instance URL</StepLabel>
            </Step>
            <Step key={ServiceNowWizardStage.CREATE}>
                <StepLabel>Create Application in ServiceNow</StepLabel>
            </Step>
            <Step key={ServiceNowWizardStage.DETAILS}>
                <StepLabel>Enter New Application Details</StepLabel>
            </Step>
            <Step key={ServiceNowWizardStage.AUTHORIZE}>
                <StepLabel>Authorize</StepLabel>
            </Step>
        </Stepper>
    );
};

export type StageType = React.FC<
    ServiceNowConnection & {
        stage: ServiceNowWizardStage;
        setStage: (stage: ServiceNowWizardStage) => void;
    }
>;
