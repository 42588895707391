import { useObservableState } from 'observable-hooks';
import { AppSelector } from '../../components/app-main/AppSelector';
import {
    apps$,
    appSelectorMode$,
    appSelectorNextAction$,
    appSelectorOpen$,
    loadingAppSelector$,
} from '../../store/apps';

export const AppSelectorContainer: React.FC = () => {
    const open = useObservableState(appSelectorOpen$);
    const loading = useObservableState(loadingAppSelector$);
    const apps = useObservableState(apps$);
    const mode = useObservableState(appSelectorMode$);

    const handleSelectApp = (appUid: string): void => {
        const app = (apps$.value ?? []).find((app) => app.uid === appUid);
        const nextAction = appSelectorNextAction$.value;

        if (app && nextAction) {
            nextAction(app);
        }
    };

    const title =
        mode === 'API_HANDLER'
            ? 'Select App for API Connection'
            : mode === 'EVENT_LISTENER'
            ? 'Select App for Event Listener'
            : 'Select App for Connector';

    return (
        <AppSelector
            open={open}
            loading={loading}
            apps={apps}
            mode={mode ?? 'API_HANDLER'}
            onSelectApp={handleSelectApp}
            onCancel={() => appSelectorOpen$.next(false)}
            title={title}
        />
    );
};
