import { useObservableState } from 'observable-hooks';
import { useMatch, useNavigate } from 'react-location';
import { ConnectionTypesReportPage } from '../../components/report/ConnectionTypesReportPage';
import { ConnectionTypesReportRequest } from '../../data/report/connectionTypes';
import { connectionTypes$ } from '../../store/report/connectionTypes';

export const ConnectionTypesReportPageContainer: React.FC = () => {
    const navigate = useNavigate();
    const match = useMatch();
    const connectionTypes = useObservableState(connectionTypes$);

    return (
        <ConnectionTypesReportPage
            connectionTypes={connectionTypes}
            userType={match.search.userType as ConnectionTypesReportRequest['userType']}
            onSearch={(userType) => {
                navigate({
                    to: '../',
                });
                setTimeout(
                    () =>
                        navigate({
                            to: './users',
                            search: {
                                search: true,
                                userType,
                            },
                        }),
                    100
                );
            }}
        />
    );
};
