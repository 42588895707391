import { useObservableState } from 'observable-hooks';
import { GenericEventListenerSetupDialog } from '../../../../components/connections/apps/generic/EventListenerSetupDialog';
import { configTopic$ } from '../../../../store/config';
import {
    genericEventListenerSetupDialogCloseAction$,
    genericEventListenerSetupDialogDetails$,
    genericEventListenerSetupDialogOpen$,
} from '../../../../store/connection/generic/setup-event-listener';

export const GenericEventListenerSetupDialogContainer: React.FC = () => {
    const open = useObservableState(genericEventListenerSetupDialogOpen$);
    const details = useObservableState(genericEventListenerSetupDialogDetails$);
    const config = useObservableState(configTopic$);

    return (
        <GenericEventListenerSetupDialog
            open={open}
            url={`${config.trigger?.srcExternalUrl}/${details?.urlPath}`}
            onClose={() => genericEventListenerSetupDialogCloseAction$.next()}
        />
    );
};
