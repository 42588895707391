import { useObservableState } from 'observable-hooks';
import { AzureDevopsEventListenerSetupDialog } from '../../../../components/connections/apps/azure-devops/EventListenerSetupDialog';
import { configTopic$ } from '../../../../store/config';
import {
    azureDevopsEventListenerSetupDialogCloseAction$,
    azureDevopsEventListenerSetupDialogDetails$,
    azureDevopsEventListenerSetupDialogErrors$,
    azureDevopsEventListenerSetupDialogLoading$,
    azureDevopsEventListenerSetupDialogOpen$,
    azureDevopsEventListenerSetupDialogSaving$,
} from '../../../../store/connection/azure-devops/setup-event-listener';
import { selectedWorkspace$ } from '../../../../store/workspace';

export const AzureDevopsEventListenerSetupDialogContainer: React.FC = () => {
    const loading = useObservableState(azureDevopsEventListenerSetupDialogLoading$);
    const saving = useObservableState(azureDevopsEventListenerSetupDialogSaving$);
    const open = useObservableState(azureDevopsEventListenerSetupDialogOpen$);
    const details = useObservableState(azureDevopsEventListenerSetupDialogDetails$);
    const errors = useObservableState(azureDevopsEventListenerSetupDialogErrors$);
    const config = useObservableState(configTopic$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);

    return (
        <AzureDevopsEventListenerSetupDialog
            loading={loading}
            open={open}
            webhookBaseUrl={config.trigger?.srcExternalUrl ?? ''}
            webhookUrlId={details?.webhookUrl ?? ''}
            workspaceLocked={!!selectedWorkspace?.locked}
            errors={errors}
            saving={saving}
            onClose={() => azureDevopsEventListenerSetupDialogCloseAction$.next()}
        />
    );
};
