import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const BitbucketIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 62.42 62.42"
            className="productIcon"
            aria-label="Bitbucket"
        >
            <defs>
                <linearGradient
                    id="New_Gradient_Swatch_1"
                    x1="64.01"
                    x2="32.99"
                    y1="30.27"
                    y2="54.48"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.18" stopColor="#0052cc"></stop>
                    <stop offset="1" stopColor="#2684ff"></stop>
                </linearGradient>
            </defs>
            <g data-name="bitbucket">
                <g>
                    <path
                        fill="#2684ff"
                        d="M2 6.26a2 2 0 00-2 2.32l8.49 51.54a2.72 2.72 0 002.66 2.27h40.73a2 2 0 002-1.68l8.49-52.12a2 2 0 00-2-2.32zm35.75 37.25h-13l-3.52-18.39H40.9z"
                        transform="translate(0 -3.13)"
                    ></path>
                    <path
                        fill="url(#New_Gradient_Swatch_1)"
                        d="M59.67 25.12H40.9l-3.15 18.39h-13L9.4 61.73a2.71 2.71 0 001.75.66h40.74a2 2 0 002-1.68z"
                        transform="translate(0 -3.13)"
                    ></path>
                </g>
            </g>
        </SvgIcon>
    );
};
