import { useObservableState } from 'observable-hooks';
import {
    ApiConnectionImportDialog,
    ApiConnectionImportDialogProps,
} from '../../components/workspace-dialogs/ApiConnectionImportDialog';
import { selectedWorkspaceResources$ } from '../../store/workspace';
import {
    apiHandlerImportDialogErrors$,
    apiHandlerImportDialogOpen$,
    closeApiConnectionImportDialogAction$,
    importApiHandlerDialogImportAction$,
} from '../../store/workspace/api-handler';

type ApiConnections = ApiConnectionImportDialogProps['apiConnections'];

export const ApiConnectionImportDialogContainer: React.FC = () => {
    const open = useObservableState(apiHandlerImportDialogOpen$);
    const errors = useObservableState(apiHandlerImportDialogErrors$);
    const workspaceResources = useObservableState(selectedWorkspaceResources$);

    const apiConnections = workspaceResources.apiHandlers.filter(
        (ah) => ah.remnantEnvironments.length === 0 && !!ah.path && !!ah.connectionName
    ) as ApiConnections;

    return (
        <ApiConnectionImportDialog
            open={open}
            errors={errors}
            apiConnections={apiConnections}
            loading={false}
            saving={false}
            onCancel={() => closeApiConnectionImportDialogAction$.next()}
            onImport={(uid, importName) => importApiHandlerDialogImportAction$.next({ uid, importName })}
        />
    );
};
