import { BehaviorSubject, map, Subject } from 'rxjs';
import { getFetchOptions } from '../../../utils/fetch';
import { InformativeError } from '../../../utils/repository';
import { configTopic$, stitchSession$ } from '../../config';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import { SESSION_ID } from '../../..';
import { ZoomEventListenerWizardStage } from '../../../components/connections/apps/zoom/ZoomEventListenerWizardSteps';

export const zoomEventListenerSetupDialogOpenAction$ = monitor(
    'zoomEventListenerSetupDialogOpenAction$',
    new Subject<ZoomEventListenerSetupBaseDetails>()
);
export const zoomEventListenerSetupDialogCloseAction$ = monitor(
    'zoomEventListenerSetupDialogCloseAction$',
    new Subject<void>()
);
export const zoomEventListenerSetupDialogSaveTokenAction$ = monitor(
    'zoomEventListenerSetupDialogSaveTokenAction$',
    new Subject<ZoomEventListenerSaveTokenDetails>()
);

export const zoomEventListenerSetupDialogSaving$ = monitor(
    'zoomEventListenerSetupDialogSaving$',
    new BehaviorSubject(false)
);

export const zoomEventListenerSetupDialogLoading$ = monitor(
    'zoomEventListenerSetupDialogLoading$',
    new BehaviorSubject(false)
);
export const zoomEventListenerSetupDialogOpen$ = monitor(
    'zoomEventListenerSetupDialogOpen$',
    new BehaviorSubject(false)
);
export const zoomEventListenerSetupDialogDetails$ = monitor(
    'zoomEventListenerSetupDialogDetails$',
    new BehaviorSubject<ZoomEventListenerSetupBaseDetails | undefined>(undefined)
);
export const zoomEventListenerSetupDialogErrors$ = monitor(
    'zoomEventListenerSetupDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const zoomEventListenerSetupDialogStage$ = monitor(
    'zoomEventListenerSetupDialogStage$',
    new BehaviorSubject(ZoomEventListenerWizardStage.SAVE_TOKEN)
);

export type ZoomEventListenerSetupBaseDetails = {
    uid: string;
    environmentUid: string;
    webhookUrl: string;
    token: string;
    eventTypeName: string;
};

interface ZoomEventListenerSaveTokenDetails {
    uid: string;
    environmentUid: string;
    token: string;
}

zoomEventListenerSetupDialogOpenAction$
    .pipe(
        map(async (details) => {
            zoomEventListenerSetupDialogStage$.next(ZoomEventListenerWizardStage.SAVE_TOKEN);
            zoomEventListenerSetupDialogLoading$.next(true);
            zoomEventListenerSetupDialogOpen$.next(true);

            try {
                zoomEventListenerSetupDialogDetails$.next(details);
                zoomEventListenerSetupDialogLoading$.next(false);
            } catch (e) {
                console.error('Error while loading Event Listener setup instructions', e);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to load Event Listener setup instructions, please try again, if the issue persists please contact support.`,
                    toastOptions: {
                        autoClose: false,
                    },
                });

                zoomEventListenerSetupDialogCloseAction$.next();
            }
        })
    )
    .subscribe();

zoomEventListenerSetupDialogSaveTokenAction$
    .pipe(
        map(async (details) => {
            zoomEventListenerSetupDialogErrors$.next(undefined);
            zoomEventListenerSetupDialogSaving$.next(true);
            try {
                const baseUrl = configTopic$.value.connection?.zoom?.baseUrl;
                if (!baseUrl) {
                    throw new Error('No Zoom Connector URL configured in meta');
                }
                const saveTokenUrl = `${baseUrl}/save-token`;

                const fetchOptions = getFetchOptions(
                    { Authorization: stitchSession$.value?.jwt ?? '', 'x-stitch-session-id': SESSION_ID },
                    details
                );
                await fetch(saveTokenUrl, fetchOptions);
                zoomEventListenerSetupDialogStage$.next(ZoomEventListenerWizardStage.ADD_EVENT);
            } catch (e) {
                if (e instanceof InformativeError) {
                    zoomEventListenerSetupDialogErrors$.next(e.message);
                } else {
                    console.error('Error while saving Event Listener token', e);
                    zoomEventListenerSetupDialogErrors$.next(
                        'Failed to save Zoom Teams Outgoing Webhook event token. Please try again, if the issue persists please contact support.'
                    );
                }
            }
            zoomEventListenerSetupDialogSaving$.next(false);
        })
    )
    .subscribe();

zoomEventListenerSetupDialogCloseAction$.subscribe(() => {
    zoomEventListenerSetupDialogOpen$.next(false);
    zoomEventListenerSetupDialogSaving$.next(false);
    zoomEventListenerSetupDialogDetails$.next(undefined);
    zoomEventListenerSetupDialogErrors$.next(undefined);
});
