import { useObservableState } from 'observable-hooks';
import { ReadmeFileDetails } from '../../components/workspace-resources/ReadmeFileDetails';
import {
    parsedReadmeFileContent$,
    readmeFileBeingSaved$,
    readmeFileHasUnsavedChanges$,
    saveReadmeFileAction$,
    selectedReadmeFileContentChangedAction$,
    selectedReadmeFileUid$,
    unsavedReadmeFileDetails$,
} from '../../store/workspace/readme';
import { selectedReadOnlyTemplate$ } from '../../store/templates';
import { selectedEnvironmentUid$, selectedWorkspace$, selectedWorkspaceEnvironment$ } from '../../store/workspace';

export const ReadmeFileDetailsContainer: React.FC = () => {
    const selectedReadmeFileUid = useObservableState(selectedReadmeFileUid$);
    const readmeFileBeingSaved = useObservableState(readmeFileBeingSaved$);
    const readmeFileHasUnsavedChanges = useObservableState(readmeFileHasUnsavedChanges$);
    const parsedReadmeFileContent = useObservableState(parsedReadmeFileContent$);
    const selectedReadOnlyTemplate = useObservableState(selectedReadOnlyTemplate$);
    const selectedWorkspaceEnvironment = useObservableState(selectedWorkspaceEnvironment$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const environmentUid = useObservableState(selectedEnvironmentUid$);

    const readOnlyMode = !!selectedReadOnlyTemplate?.templateUid;
    const environmentDeployed = selectedWorkspaceEnvironment?.deployment !== undefined;

    const readmeKey = `${selectedReadmeFileUid ?? ''}_${environmentUid ?? ''}`;

    return (
        <ReadmeFileDetails
            content={unsavedReadmeFileDetails$.value[readmeKey]?.content ?? ''}
            hasUnsavedChanges={!!readmeFileHasUnsavedChanges[selectedReadmeFileUid ?? '']}
            parsedContent={parsedReadmeFileContent[readmeKey] ?? ''}
            readmeFileUid={selectedReadmeFileUid ?? ''}
            readOnlyMode={readOnlyMode || environmentDeployed}
            saving={readmeFileBeingSaved ?? false}
            workspaceLocked={!!selectedWorkspace?.locked}
            onContentChange={(content) => selectedReadmeFileContentChangedAction$.next(content)}
            onSave={() => saveReadmeFileAction$.next()}
        />
    );
};
