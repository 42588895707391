import { useObservableState } from 'observable-hooks';
import { useMatch, useNavigate } from 'react-location';
import { UserActivationReportPage } from '../../components/report/UserActivationReportPage';
import { UserActivationRequest } from '../../data/report/userActivation';
import { userActivation$ } from '../../store/report/userActivation';

export const UserActivationReportPageContainer: React.FC = () => {
    const navigate = useNavigate();
    const match = useMatch();
    const users = useObservableState(userActivation$);

    return (
        <UserActivationReportPage
            users={users}
            userType={match.search.userType as UserActivationRequest['userType']}
            status={match.search.status as UserActivationRequest['status']}
            onSearch={(userType, status) => {
                navigate({
                    to: '../',
                });
                setTimeout(
                    () =>
                        navigate({
                            to: './users',
                            search: {
                                search: true,
                                userType,
                                status,
                            },
                        }),
                    100
                );
            }}
        />
    );
};
