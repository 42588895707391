import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    connectionCreatedAction$,
    ConnectionDetails,
    connectionCreatedFirstTimeAction$,
    connectionCreatedFirstTime$,
} from '..';
import { createConnection, saveConnection } from '../../../data/connection';
import { InformativeError } from '../../../utils/repository';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import {
    handleManageConnectionError,
    loadLoggedInUserConnectionsWhenEventIsEmitted,
    saveConnectionAllowed,
} from '../utils';
import { saveApiKey } from './save';
import { promptQuestion } from '../../confirm';
import { AYSYWTSAETCWHAI } from '../../../i18n';

interface JsmCloudAssetsSetupSaveProps {
    apiKey: string;
}

export const jsmCloudAssetsManageConnectionOpenDialogAction$ = monitor(
    'jsmCloudAssetsManageConnectionOpenDialogAction$',
    new Subject<ConnectionDetails>()
);
export const jsmCloudAssetsManageConnectionCloseDialogAction$ = monitor(
    'jsmCloudAssetsManageConnectionCloseDialogAction$',
    new Subject<void>()
);

export const jsmCloudAssetsSetupOpenDialogAction$ = monitor(
    'jsmCloudAssetsSetupOpenDialogAction$',
    new Subject<ConnectionDetails>()
);
export const jsmCloudAssetsSetupCloseDialogAction$ = monitor(
    'jsmCloudAssetsSetupCloseDialogAction$',
    new Subject<void>()
);

export const jsmCloudAssetsSetupDialogOpen$ = monitor('jsmCloudAssetsSetupDialogOpen$', new BehaviorSubject(false));
export const jsmCloudAssetsSetupAuthorizeAction$ = monitor(
    'jsmCloudAssetsSetupAuthorizeAction$',
    new Subject<JsmCloudAssetsSetupSaveProps>()
);
export const jsmCloudAssetsSetupDialogErrors$ = monitor(
    'jsmCloudAssetsSetupDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const jsmCloudAssetsSaveConnectionAction$ = monitor(
    'jsmCloudAssetsSaveConnectionAction$',
    new Subject<string>()
);
export const jsmCloudAssetsConnectionCreatedAction$ = monitor(
    'jsmCloudAssetsConnectionCreatedAction$',
    new Subject<string>()
);
export const jsmCloudAssetsConnectionSavedAction$ = monitor(
    'jsmCloudAssetsConnectionSavedAction$',
    new Subject<{ uid: string; connectionTypeUid: string }>()
);
export const jsmCloudAssetsManageConnectionInitiateSetupAction$ = monitor(
    'jsmCloudAssetsManageConnectionInitiateSetupAction$',
    new Subject<string>()
);

export const jsmCloudAssetsManageConnectionDialogOpen$ = monitor(
    'jsmCloudAssetsManageConnectionDialogOpen$',
    new BehaviorSubject(false)
);
export const jsmCloudAssetsConnectionSaving$ = monitor('jsmCloudAssetsConnectionSaving$', new BehaviorSubject(false));
export const jsmCloudAssetsManageConnectionAuthorizeLoading$ = monitor(
    'jsmCloudAssetsManageConnectionAuthorizeLoading$',
    new BehaviorSubject(false)
);
export const jsmCloudAssetsManageConnectionDetails$ = monitor(
    'jsmCloudAssetsManageConnectionDetails$',
    new BehaviorSubject<ConnectionDetails | undefined>(undefined)
);
export const jsmCloudAssetsManageConnectionDialogErrors$ = monitor(
    'jsmCloudAssetsManageConnectionDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

const JSM_CLOUD_ASSETS_CONNECTION_TYPE = 'Jira Service Management Cloud Assets';

jsmCloudAssetsSetupOpenDialogAction$.subscribe((details) => {
    jsmCloudAssetsSetupDialogErrors$.next(undefined);
    jsmCloudAssetsManageConnectionDialogErrors$.next(undefined);
    jsmCloudAssetsSetupDialogOpen$.next(true);
    jsmCloudAssetsManageConnectionDetails$.next(details);
});

jsmCloudAssetsSetupCloseDialogAction$.subscribe(() => {
    jsmCloudAssetsSetupDialogOpen$.next(false);
    jsmCloudAssetsManageConnectionAuthorizeLoading$.next(false);
});

jsmCloudAssetsManageConnectionOpenDialogAction$.subscribe((details) => {
    jsmCloudAssetsSetupDialogErrors$.next(undefined);
    jsmCloudAssetsManageConnectionDialogErrors$.next(undefined);
    jsmCloudAssetsManageConnectionDialogOpen$.next(true);
    jsmCloudAssetsManageConnectionDetails$.next(details);
});

jsmCloudAssetsManageConnectionCloseDialogAction$.subscribe(() => {
    jsmCloudAssetsManageConnectionDialogOpen$.next(false);
    jsmCloudAssetsSetupDialogOpen$.next(false);
    jsmCloudAssetsManageConnectionDetails$.next(undefined);
});

jsmCloudAssetsConnectionCreatedAction$.subscribe((uid) => connectionCreatedAction$.next(uid));

jsmCloudAssetsSetupAuthorizeAction$
    .pipe(
        map(async ({ apiKey }) => {
            jsmCloudAssetsConnectionSaving$.next(true);
            jsmCloudAssetsSetupDialogErrors$.next(undefined);

            const { uid, connectionType } = jsmCloudAssetsManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    throw Error('Jira Service Management Cloud Assets connector type not defined');
                }

                if (!uid) {
                    throw Error(
                        'Jira Service Management Cloud Assets connector has not been created so cannot configure it'
                    );
                }

                await saveApiKey({ apiKey, connectionId: uid });

                jsmCloudAssetsConnectionCreatedAction$.next(uid);
                jsmCloudAssetsConnectionSavedAction$.next({ uid, connectionTypeUid: connectionType.uid });
                jsmCloudAssetsSetupDialogOpen$.next(false);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Jira Service Management Cloud Assets connector has been configured.',
                });
            } catch (e) {
                if (e instanceof InformativeError) {
                    jsmCloudAssetsSetupDialogErrors$.next(e.message);
                } else {
                    jsmCloudAssetsSetupDialogErrors$.next(
                        'Failed to save Jira Service Management Cloud Assets connector details, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving Jira Service Management Cloud Assets connector info', e);
                }
            }

            jsmCloudAssetsConnectionSaving$.next(false);
            jsmCloudAssetsManageConnectionAuthorizeLoading$.next(false);
        })
    )
    .subscribe();

jsmCloudAssetsSaveConnectionAction$
    .pipe(
        map(async (name) => {
            jsmCloudAssetsConnectionSaving$.next(true);
            jsmCloudAssetsManageConnectionDialogErrors$.next(undefined);

            const { uid, connectionType } = jsmCloudAssetsManageConnectionDetails$.value ?? {};

            try {
                if (!connectionType) {
                    throw Error('Jira Service Management Cloud Assets connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (saveConnectionAllowed(uid)) {
                        await saveJsmCloudAssetsConnection(uid, name, connectionTypeUid);
                    } else {
                        promptQuestion({
                            message: AYSYWTSAETCWHAI,
                            onProceed: async () => {
                                await saveJsmCloudAssetsConnection(uid, name, connectionTypeUid);
                            },
                        });
                    }
                } else {
                    promptQuestion({
                        message: AYSYWTSAETCWHAI,
                        onProceed: async () => {
                            try {
                                const { uid } = await createConnection(
                                    connectionType.uid,
                                    name,
                                    connectionType.apiHandlerTypes[0]?.uid ?? '',
                                    connectionType.eventListenerTypes[0]?.uid ?? ''
                                );

                                jsmCloudAssetsConnectionCreatedAction$.next(uid);
                                jsmCloudAssetsConnectionSavedAction$.next({ uid, connectionTypeUid });
                                jsmCloudAssetsManageConnectionCloseDialogAction$.next();
                                publishLocalFeedbackEventAction$.next({
                                    level: 'SUCCESS',
                                    message: 'Connector created.',
                                });
                                connectionCreatedFirstTimeAction$.next();
                            } catch (e) {
                                handleManageConnectionError(
                                    e,
                                    jsmCloudAssetsManageConnectionDialogErrors$,
                                    JSM_CLOUD_ASSETS_CONNECTION_TYPE
                                );
                            }
                        },
                    });
                }
            } catch (e) {
                handleManageConnectionError(
                    e,
                    jsmCloudAssetsManageConnectionDialogErrors$,
                    JSM_CLOUD_ASSETS_CONNECTION_TYPE
                );
            }

            jsmCloudAssetsConnectionSaving$.next(false);
        })
    )
    .subscribe();

jsmCloudAssetsManageConnectionInitiateSetupAction$
    .pipe(
        map(async (name) => {
            jsmCloudAssetsConnectionSaving$.next(true);
            jsmCloudAssetsManageConnectionAuthorizeLoading$.next(true);
            jsmCloudAssetsManageConnectionDialogErrors$.next(undefined);

            const {
                uid,
                connectionType,
                name: currentName,
                ...restOfDetails
            } = jsmCloudAssetsManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    throw Error('Jira Service Management Cloud Assets connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (currentName !== name) {
                        await saveConnection(uid, name);
                    }

                    jsmCloudAssetsConnectionSavedAction$.next({ uid, connectionTypeUid });
                    jsmCloudAssetsSetupOpenDialogAction$.next({
                        ...restOfDetails,
                        uid,
                        connectionType,
                        name,
                    });
                } else {
                    const { uid } = await createConnection(
                        connectionType.uid,
                        name,
                        connectionType.apiHandlerTypes[0]?.uid ?? '',
                        connectionType.eventListenerTypes[0]?.uid ?? ''
                    );

                    jsmCloudAssetsConnectionCreatedAction$.next(uid);
                    jsmCloudAssetsConnectionSavedAction$.next({ uid, connectionTypeUid });

                    jsmCloudAssetsManageConnectionDetails$.next({
                        connectionType,
                        name,
                        uid,
                    });

                    jsmCloudAssetsConnectionSaving$.next(false);

                    jsmCloudAssetsSetupOpenDialogAction$.next({
                        connectionType,
                        name,
                        uid,
                    });
                    connectionCreatedFirstTime$.next(true);
                }
            } catch (e) {
                handleManageConnectionError(
                    e,
                    jsmCloudAssetsManageConnectionDialogErrors$,
                    JSM_CLOUD_ASSETS_CONNECTION_TYPE
                );
                jsmCloudAssetsManageConnectionAuthorizeLoading$.next(false);
            }

            jsmCloudAssetsConnectionSaving$.next(false);
        })
    )
    .subscribe();

loadLoggedInUserConnectionsWhenEventIsEmitted(jsmCloudAssetsConnectionSavedAction$);

const saveJsmCloudAssetsConnection = async (uid: string, name: string, connectionTypeUid: string): Promise<void> => {
    try {
        await saveConnection(uid, name);

        jsmCloudAssetsConnectionSavedAction$.next({ uid, connectionTypeUid });
        jsmCloudAssetsManageConnectionCloseDialogAction$.next();
        publishLocalFeedbackEventAction$.next({
            level: 'SUCCESS',
            message: 'Connector saved.',
        });
        if (connectionCreatedFirstTime$.value) {
            connectionCreatedFirstTimeAction$.next();
            connectionCreatedFirstTime$.next(false);
        }
    } catch (e) {
        handleManageConnectionError(e, jsmCloudAssetsManageConnectionDialogErrors$, JSM_CLOUD_ASSETS_CONNECTION_TYPE);
    }
};
