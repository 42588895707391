import { useObservableState } from 'observable-hooks';
import { AtlassianCloudInstanceSelection } from '../../../../components/connections/apps/atlassian-cloud/authorization';
import {
    atlassianCloudInstanceSelectionCloseDialogAction$,
    atlassianCloudInstanceSelectionInstances$,
    atlassianCloudInstanceSelectionOpen$,
    atlassianCloudInstanceSelectionSaving$,
    atlassianCloudSaveInstanceAction$,
} from '../../../../store/connection/atlassian-cloud/manage-connection';
import { atlassianCloudManageConnectionDetails$ } from '../../../../store/connection/atlassian-cloud/manage-connection';

export const AtlassianCloudInstanceSelectionContainer: React.FC = () => {
    const open = useObservableState(atlassianCloudInstanceSelectionOpen$);
    const saving = useObservableState(atlassianCloudInstanceSelectionSaving$);
    const instances = useObservableState(atlassianCloudInstanceSelectionInstances$);
    const details = useObservableState(atlassianCloudManageConnectionDetails$);

    return (
        <AtlassianCloudInstanceSelection
            instances={instances ?? []}
            saving={saving}
            onSave={(id, url) => atlassianCloudSaveInstanceAction$.next({ id, url })}
            open={open}
            onCancel={() => atlassianCloudInstanceSelectionCloseDialogAction$.next()}
            connectionType={details?.connectionType.name}
        />
    );
};
