import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ConsoleLogTypeIcon } from '../../../icons/ConsoleLogTypeIcon';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import { InvocationLog } from '../../../../store/invocationlogs';
import { getBasePath } from '../../../../utils/path';

interface InvocationLogsProps {
    invocationId: string;
    logs: InvocationLog[];
}

const StyledOuterContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(2, 3),
    position: 'relative',
    height: '100%',
    width: '100%',
}));

const StyledTitleContainer = styled('div')(() => ({
    height: 40,
}));

const StyledList = styled(List)({
    whiteSpace: 'pre-wrap',
});

const StyledListItem = styled(ListItem)(({ theme }) => ({
    padding: theme.spacing(2),
}));

const getLogType = (severity: string): 'WARN' | 'ERROR' | 'INFO' | 'DEBUG' => {
    switch (severity) {
        case 'warn':
            return 'WARN';
        case 'error':
            return 'ERROR';
        case 'debug':
            return 'DEBUG';
        case 'log':
        case 'info':
        default:
            return 'INFO';
    }
};

export const InvocationLogs: React.FC<InvocationLogsProps> = ({ invocationId, logs }) => {
    const renderInvocationLog = (log: InvocationLog): JSX.Element => {
        if (log.linkUrl) {
            return (
                <Link href={`${getBasePath()}${log.linkUrl}`} target="_blank">
                    View HTTP logs
                </Link>
            );
        } else if (log.largePayload) {
            return (
                <Link href={`./${invocationId}/log/${log.id}`} target="_blank">
                    {log.message}
                </Link>
            );
        } else {
            return (
                <ListItemText
                    primary={log.message}
                    secondary={log.timestamp ? new Date(log.timestamp).toLocaleString() : undefined}
                />
            );
        }
    };

    return (
        <StyledOuterContainer>
            <StyledTitleContainer>
                <Typography variant="h3">Invocation Logs for {invocationId}</Typography>
            </StyledTitleContainer>
            <StyledList>
                {logs.map((logItem) => (
                    <StyledListItem key={logItem.id}>
                        <ListItemIcon>
                            <ConsoleLogTypeIcon type={getLogType(logItem.severity)} />
                        </ListItemIcon>
                        {renderInvocationLog(logItem)}
                    </StyledListItem>
                ))}
            </StyledList>
        </StyledOuterContainer>
    );
};
