import { getApps as getAppsRpc } from '@avst-stitch/repository-lib/lib';
import { Response as Apps } from '@avst-stitch/repository-lib/lib/rpcs/getApps';
import { repositoryInvoker } from '../utils/repository';
import { Flatten } from '../utils/types';

export type { Apps };

export const getApps = async (): Promise<Apps> => {
    return await getAppsRpc(repositoryInvoker);
};

export type App = Flatten<Apps>;
export type ConnectionType = App['connectionType'];
