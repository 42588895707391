import { useEffect, useRef, useState } from 'react';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import { styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button } from '../../../common/buttons/Button';
import { DialogAlert } from '../../../for-deprecation/dialog/DialogComponents';
import {
    DragButton,
    GhostVariable,
    StyledDropArea,
    StyledEditModeActions,
    StyledEditModeContent,
    StyledReadOnlyExpandButton,
    StyledHeaderActions,
    StyledVariable,
    StyledReadOnlyVariableHeader,
    StyledVariableWrapper,
    emptyWarning,
    StyledEditModeVariableHeader,
} from '../EnvironmentVariableComponents';
import { InformationChip } from '../../../common/InformationChip';
import { EnvironmentVariable } from '../EnvironmentVariable';
import { EnvironmentVariableType } from '@avst-stitch/repository-lib/lib/types';
import {
    BaseEnvironmentVariable,
    ChangeVariableTypeEvent,
    DeleteVariableEvent,
    ToggleVariableEditModeEvent,
    ToggleVariableExpandEvent,
} from '../types';
import { autoFocus, isFocused } from '../../../../utils/autoFocus';
import {
    DESCRIPTION_MAX_LENGTH,
    environmentVariableDivider,
    getEnvironmentVariablesMissingInformation,
    getVariableHeight,
    isDescriptionTooLong,
} from '../utils';
import { handleKeyDown } from '../../../../utils/handleKeyDown';
import { isScriptOrEnvVariableNameValid } from '../../../../utils/validation';

interface UpdateFolderVariableEvent {
    description?: string;
    id: string;
    keyName: string;
    type: EnvironmentVariableType;
}

interface EnvironmentVariableFolderVariantProps {
    childVariables?: BaseEnvironmentVariable[];
    description?: string;
    dragOverlay?: boolean;
    editMode?: boolean;
    expanded?: boolean;
    hasBeenEdited?: boolean;
    id: string;
    keyName?: string;
    sameLevelKeyNames?: string[];
    templateMode?: boolean;
    workspaceLocked?: boolean;
    onAddVariable?(parentId: string): void;
    onChangeType?(event: ChangeVariableTypeEvent): void;
    onDelete?(event: DeleteVariableEvent): void;
    onToggleEditMode?(event: ToggleVariableEditModeEvent): void;
    onToggleExpand?(event: ToggleVariableExpandEvent): void;
    onUpdate?(event: UpdateFolderVariableEvent): void;
}

const StyledReadOnlyFolderMainRow = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
}));

const StyledFolderCircle = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[900],
    borderRadius: '50%',
    height: 40,
    justifyContent: 'center',
    width: 40,
    '& .MuiSvgIcon-root': {
        height: 24,
        width: 24,
    },
}));

const StyledReadOnlyFolderTextArea = styled('div')(({ theme }) => ({
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: theme.spacing(1),
    overflow: 'hidden',
    wordBreak: 'break-word',
    '& .MuiTypography-root:first-of-type': {
        fontWeight: theme.typography.fontWeightBold,
    },
    '& .MuiTypography-root:not(:first-of-type)': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.body2.fontSize,
    },
}));

const StyledSubVariableArea = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    borderTop: environmentVariableDivider(theme),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
}));

export const EnvironmentVariableFolderVariant: React.FC<EnvironmentVariableFolderVariantProps> = ({
    childVariables = [],
    description = '',
    dragOverlay = false,
    editMode = false,
    expanded = false,
    hasBeenEdited = false,
    id,
    keyName = '',
    sameLevelKeyNames = [],
    templateMode = false,
    workspaceLocked = false,
    onAddVariable,
    onChangeType,
    onDelete,
    onToggleEditMode,
    onToggleExpand,
    onUpdate,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [currentFolderName, setCurrentFolderName] = useState(keyName);
    const [currentDescription, setCurrentDescription] = useState(description);

    const variableRef = useRef<HTMLDivElement>(null);
    const keyNameInputRef = useRef<HTMLInputElement>(null);
    const descriptionInputRef = useRef<HTMLInputElement>(null);

    const draggable = useDraggable({ id, data: { height: getVariableHeight(variableRef), keyName, type: 'folder' } });
    const droppable = useDroppable({ id });

    useEffect(() => {
        if (!childVariables.length) {
            onToggleExpand?.({ expanded: false, id });
        }
    }, [childVariables.length]);

    useEffect(() => {
        if (editMode) {
            autoFocus(keyNameInputRef);
        }
    }, [editMode]);

    useEffect(() => {
        if (childVariables.length && draggable.isDragging) {
            onToggleExpand?.({ expanded: false, id });
        }
    }, [draggable.isDragging]);

    const handleCancel = (): void => {
        setCurrentDescription(description);
        setCurrentFolderName(keyName);
        onToggleEditMode?.({ editMode: false, id });
    };

    const handleUpdateFolder = (): void => {
        onUpdate?.({ description: currentDescription, id, keyName: currentFolderName ?? '', type: 'FOLDER' });
    };

    const childKeysInformation = `${childVariables.length} Key${childVariables.length === 1 ? '' : 's'}`;
    const hasChanged = currentDescription !== description || currentFolderName !== keyName;

    const isKeyNameDuplicate =
        !!currentFolderName && sameLevelKeyNames.filter((kn) => kn !== keyName).includes(currentFolderName);

    const canUpdate =
        !isKeyNameDuplicate &&
        isScriptOrEnvVariableNameValid(currentFolderName) &&
        !isDescriptionTooLong(currentDescription) &&
        (!hasBeenEdited || hasChanged);

    const childrenHaveMissingInformation = getEnvironmentVariablesMissingInformation(childVariables);

    const draggedVariable = droppable.active?.data.current;
    const dropAreaHeight = (draggedVariable?.height ?? 0) as number;

    const isDraggedVariableDuplicate =
        !!draggedVariable?.keyName &&
        !!draggedVariable.parentId &&
        sameLevelKeyNames.includes(draggedVariable?.keyName);

    const sortForbidden = draggable.isDragging || isDraggedVariableDuplicate;

    const isDraggingFolder = draggedVariable && draggedVariable.type === 'folder';

    const dropIntoFolderDuplicationError =
        childVariables.length > 0 &&
        !!draggedVariable &&
        draggedVariable.parentId !== id &&
        childVariables.map((cv) => cv.keyName).includes(draggedVariable.keyName);

    const isDropIntoFolderForbidden = dropIntoFolderDuplicationError || isDraggingFolder;

    const allowedToDisplaySubVariables = !draggable.isDragging && !editMode;
    const showDropIntoFolderArea = !!draggedVariable && !isDropIntoFolderForbidden;

    const subVariables = childVariables.map((v) => (
        <EnvironmentVariable
            key={v.id}
            choices={v.choices}
            defaultValue={v.defaultValue}
            description={v.description}
            editMode={v.editMode}
            expanded={v.expanded}
            hasBeenEdited={v.hasBeenEdited}
            id={v.id}
            keyName={v.keyName}
            parentId={id}
            passwordValueFilled={v.passwordValueFilled}
            required={v.required}
            sameLevelKeyNames={childVariables.map((ek) => ek.keyName ?? '')}
            templateMode={templateMode}
            type={v.type}
            value={v.value}
            workspaceLocked={workspaceLocked}
            onAddVariable={onAddVariable}
            onChangeType={onChangeType}
            onDelete={onDelete}
            onUpdate={onUpdate}
            onToggleEditMode={onToggleEditMode}
            onToggleExpand={onToggleExpand}
        />
    ));

    return (
        <StyledVariableWrapper ref={draggable.setNodeRef} isDragging={draggable.isDragging}>
            {!sortForbidden && <StyledDropArea height={dropAreaHeight} visible={droppable.isOver} />}
            <StyledVariable
                className={dragOverlay ? 'dragOverlay' : ''}
                ref={variableRef}
                onKeyDown={(event) =>
                    handleKeyDown({
                        event,
                        enterCondition: canUpdate && editMode && !isFocused(descriptionInputRef),
                        enterFn: handleUpdateFolder,
                        escFn: handleCancel,
                    })
                }
            >
                {editMode ? (
                    <div ref={sortForbidden ? null : droppable.setNodeRef}>
                        <StyledEditModeVariableHeader>
                            <Typography>{hasBeenEdited ? 'Edit folder' : 'New folder'}</Typography>
                            <StyledHeaderActions>
                                <IconButton onClick={() => handleCancel()}>
                                    <CloseIcon />
                                </IconButton>
                            </StyledHeaderActions>
                        </StyledEditModeVariableHeader>
                        <StyledEditModeContent>
                            <TextField
                                label="Folder name"
                                inputRef={keyNameInputRef}
                                size="small"
                                error={!isScriptOrEnvVariableNameValid(currentFolderName) || isKeyNameDuplicate}
                                helperText={
                                    !isScriptOrEnvVariableNameValid(currentFolderName)
                                        ? 'Folder name can only contain alphanumeric characters and underscores'
                                        : isKeyNameDuplicate
                                        ? 'This name is already used by another folder or variable on this level'
                                        : !currentFolderName
                                        ? 'Please enter folder name'
                                        : undefined
                                }
                                required
                                placeholder="Enter folder name"
                                value={currentFolderName}
                                onChange={(e) => setCurrentFolderName(e.target.value)}
                            />
                            <TextField
                                label="Notes"
                                inputRef={descriptionInputRef}
                                size="small"
                                error={isDescriptionTooLong(currentDescription)}
                                helperText={
                                    isDescriptionTooLong(currentDescription)
                                        ? `Notes length exceeds ${DESCRIPTION_MAX_LENGTH} characters`
                                        : `${DESCRIPTION_MAX_LENGTH - currentDescription.length} characters remaining`
                                }
                                multiline
                                rows={2}
                                placeholder="Enter notes"
                                value={currentDescription}
                                onChange={(e) => setCurrentDescription(e.target.value)}
                            />
                        </StyledEditModeContent>
                        <StyledEditModeActions>
                            <Button variant="outlined" size="small" onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button size="small" disabled={!canUpdate} onClick={handleUpdateFolder}>
                                {hasBeenEdited ? 'Update' : 'Create'}
                            </Button>
                        </StyledEditModeActions>
                    </div>
                ) : (
                    <>
                        <div ref={sortForbidden ? null : droppable.setNodeRef}>
                            <StyledReadOnlyVariableHeader>
                                <StyledHeaderActions>
                                    <DragButton
                                        disabled={workspaceLocked || templateMode}
                                        {...draggable.attributes}
                                        {...draggable.listeners}
                                    />
                                    <IconButton
                                        disabled={workspaceLocked || templateMode}
                                        onClick={() => {
                                            onAddVariable?.(id);
                                        }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                    <IconButton
                                        disabled={workspaceLocked || templateMode}
                                        onClick={() => {
                                            onToggleEditMode?.({ editMode: true, id });
                                        }}
                                    >
                                        <EditOutlinedIcon />
                                    </IconButton>
                                    <IconButton
                                        disabled={workspaceLocked || templateMode}
                                        onClick={() => onDelete?.({ id })}
                                    >
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </StyledHeaderActions>
                                {!!childVariables.length && (
                                    <StyledHeaderActions>
                                        {childrenHaveMissingInformation && (
                                            <InformationChip
                                                label="Variables have missing information"
                                                type="warning"
                                            />
                                        )}
                                        <Tooltip title={expanded ? 'Collapse' : 'Expand'}>
                                            <StyledReadOnlyExpandButton
                                                onClick={() => onToggleExpand?.({ expanded: !expanded, id })}
                                            >
                                                <Typography>{childKeysInformation}</Typography>
                                                {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </StyledReadOnlyExpandButton>
                                        </Tooltip>
                                    </StyledHeaderActions>
                                )}
                            </StyledReadOnlyVariableHeader>
                            <StyledReadOnlyFolderMainRow>
                                <StyledFolderCircle>
                                    <FolderOutlinedIcon />
                                </StyledFolderCircle>
                                <StyledReadOnlyFolderTextArea>
                                    {keyName ? <Typography>{keyName}</Typography> : emptyWarning(true, true)}
                                    {description && <Typography>{description}</Typography>}
                                </StyledReadOnlyFolderTextArea>
                            </StyledReadOnlyFolderMainRow>
                        </div>
                        {allowedToDisplaySubVariables && (
                            <>
                                {expanded ? (
                                    <StyledSubVariableArea>
                                        {subVariables}
                                        {showDropIntoFolderArea ? (
                                            <GhostVariable id={`${id}-last`} nested={true} />
                                        ) : dropIntoFolderDuplicationError ? (
                                            <DialogAlert
                                                sx={{ margin: 0 }}
                                                scrollIntoViewDisabled
                                                severity="warning"
                                                text="Duplicate key detected - Cannot add variable to this folder."
                                            />
                                        ) : null}
                                    </StyledSubVariableArea>
                                ) : showDropIntoFolderArea ? (
                                    <StyledSubVariableArea>
                                        <GhostVariable id={`${id}-last`} nested={true} />
                                    </StyledSubVariableArea>
                                ) : dropIntoFolderDuplicationError ? (
                                    <StyledSubVariableArea>
                                        <DialogAlert
                                            sx={{ margin: 0 }}
                                            scrollIntoViewDisabled
                                            severity="warning"
                                            text="Duplicate key detected - Cannot add variable to this folder."
                                        />
                                    </StyledSubVariableArea>
                                ) : null}
                            </>
                        )}
                    </>
                )}
            </StyledVariable>
        </StyledVariableWrapper>
    );
};
