// import { Link } from '@mui/material';
import { MakeGenerics, Outlet, ReactLocation, Route } from 'react-location';
import { CenteredLoadingSpinner } from './components/common/loading/CenteredLoadingSpinner';
import { ApiHandlerDetailsContainer } from './containers/workspace-resources/api-connections/ApiConnectionDetailsContainer';
import { AppMainContainer } from './containers/app-main/AppMainContainer';
import { AppSelectorContainer } from './containers/app-main/AppSelectorContainer';
import { EventListenerDetailsContainer } from './containers/workspace-resources/event-listeners/EventListenerTabsContainer';
import { ScriptDetailsContainer } from './containers/workspace-resources/scripts/ScriptDetailsContainer';
import { WorkspaceContainer } from './containers/workspace-layouts/WorkspaceContainer';
import { WorkspaceSelectorContainer } from './containers/workspace-layouts/WorkspaceSelectorContainer';
// import { useAuth } from './hooks/auth';
import { apiHandlerDetailsLoader, readOnlyApiHandlerDetailsLoader } from './loaders/workspace/api-handler';
import { appLoader } from './loaders/app';
import { eventListenerLoader, readOnlyEventListenerLoader } from './loaders/workspace/event-listener';
import { scriptDetailsLoader } from './loaders/workspace/script';
import { workspaceLoader, workspaceUnloader } from './loaders/workspace/workspace';
import { workspacesLoader } from './loaders/workspaces';
// import { wrapAsync } from './utils/react';
import { CreateNewWorkspaceEnvironmentDialogContainer } from './containers/workspace-dialogs/CreateNewEnvironmentDialogContainer';
import { ManageEnvironmentDeploymentTargetsDialogContainer } from './containers/workspace-dialogs/ManageEnvironmentDeploymentTargetsDialogContainer';
import { SlackManageConnectionDialogContainer } from './containers/connections/apps/slack/ManageConnectionDialogContainer';
import { SlackAppConfigureDialog } from './containers/connections/apps/slack/SlackAppConfigureDialogContainer';
import { SlackEventListenerSetupDialogContainer } from './containers/connections/apps/slack/EventListenerSetupDialogContainer';
import { TemplatesContainer } from './containers/templates/TemplatesContainer';
import { templatesLoader } from './loaders/templates';
import { ConfirmDialogContainer } from './containers/app-main/ConfirmDialogContainer';
import { UsersReportPageContainer } from './containers/report/UsersReportPageContainer';
import { usersReportLoader } from './loaders/report/users';
import { OrganisationsReportPageContainer } from './containers/report/OrganisationsReportPageContainer';
import { organisationsReportLoader } from './loaders/report/organisations';
import { OrganisationDetailsReportPageContainer } from './containers/report/OrganisationDetailsReportPageContainer';
import { organisationDetailsReportLoader } from './loaders/report/organisationDetails';
import { InvocationsReportPageContainer } from './containers/report/InvocationsReportPageContainer';
import { invocationsReportLoader } from './loaders/report/invocations';
import { Reports } from './components/report/Reports';
import { BitbucketCloudManageConnectionDialogContainer } from './containers/connections/apps/bitbucket-cloud/ManageConnectionDialogContainer';
import { BitbucketCloudConfigureDialog } from './containers/connections/apps/bitbucket-cloud/BitbucketCloudConfigureDialogContainer';
import { GenericEventListenerSetupDialogContainer } from './containers/connections/apps/generic/EventListenerSetupDialogContainer';
import { ServiceNowManageConnectionDialogContainer } from './containers/connections/apps/servicenow/ManageConnectionDialogContainer';
import { ServiceNowConfigureDialog } from './containers/connections/apps/servicenow/ServiceNowConfigureDialogContainer';
import { ZoomManageConnectionDialogContainer } from './containers/connections/apps/zoom/ManageConnectionDialogContainer';
import { ZoomConfigureDialog } from './containers/connections/apps/zoom/ZoomConfigureDialogContainer';
import { BitbucketOnPremiseManageConnectionDialogContainer } from './containers/connections/apps/bitbucket-on-premise/ManageConnectionDialogContainer';
import { BitbucketOnPremiseConfigureDialog } from './containers/connections/apps/bitbucket-on-premise/BitbucketOnPremiseConfigureDialogContainer';
import { SalesforceManageConnectionDialogContainer } from './containers/connections/apps/salesforce/ManageConnectionDialogContainer';
import { SalesforceConfigureDialog } from './containers/connections/apps/salesforce/SalesforceConfigureDialogContainer';
import { AtlassianCloudEventListenerSetupDialogContainer } from './containers/connections/apps/atlassian-cloud/EventListenerSetupDialogContainer';
import { AtlassianCloudManageConnectionDialogContainer } from './containers/connections/apps/atlassian-cloud/ManageConnectionDialogContainer';
import { AtlassianCloudInstanceSelectionContainer } from './containers/connections/apps/atlassian-cloud/AtlassianCloudInstanceSelectionContainer';
import { ApiConnectionImportDialogContainer } from './containers/workspace-dialogs/ApiConnectionImportDialogContainer';
import { DashboardContainer } from './containers/dashboard/DashboardContainer';
import { dashboardLoader } from './loaders/dashboard';
import { CreateNewWorkspaceDialogContainer } from './containers/workspace-dialogs/CreateNewWorkspaceDialogContainer';
import { EditWorkspaceDialogContainer } from './containers/workspace-dialogs/EditWorkspaceDialogContainer';
import { AtlassianOnPremiseManageConnectionDialogContainer } from './containers/connections/apps/atlassian-on-premise/ManageConnectionDialogContainer';
import { AtlassianOnPremiseConfigureDialog } from './containers/connections/apps/atlassian-on-premise/AtlassianOnPremiseConfigureDialogContainer';
import { AtlassianOnPremiseEventListenerSetupDialogContainer } from './containers/connections/apps/atlassian-on-premise/EventListenerSetupDialogContainer';
import { UsersWithDeployedWorkspacesReportPageContainer } from './containers/report/UsersWithDeployedWorkspacesReportPageContainer';
import { usersWithDeployedWorkspacesReportLoader } from './loaders/report/usersWithDeployedWorkspaces';
import { UserFeedbackReportPageContainer } from './containers/report/UserFeedbackReportPageContainer';
import { userFeedbackReportLoader } from './loaders/report/userFeedback';
import { DefaultWorkspaceViewContainer } from './containers/workspace-layouts/DefaultWorkspaceViewContainer';
import { GoogleManageConnectionDialogContainer } from './containers/connections/apps/google/ManageConnectionDialogContainer';
import { GithubManageConnectionDialogContainer } from './containers/connections/apps/github/ManageConnectionDialogContainer';
import { BitbucketCloudEventListenerSetupDialogContainer } from './containers/connections/apps/bitbucket-cloud/EventListenerSetupDialogContainer';
import { GitlabManageConnectionDialogContainer } from './containers/connections/apps/gitlab/ManageConnectionDialogContainer';
import { GitlabConfigureDialog } from './containers/connections/apps/gitlab/GitlabConfigureDialogContainer';
import { GitlabEventListenerSetupDialogContainer } from './containers/connections/apps/gitlab/EventListenerSetupDialogContainer';
import { GithubEventListenerSetupDialogContainer } from './containers/connections/apps/github/EventListenerSetupDialogContainer';
import { ConnectionTypesReportPageContainer } from './containers/report/ConnectionTypesReportPageContainer';
import { connectionTypesReportLoader } from './loaders/report/connectionTypes';
import { ServiceNowEventListenerSetupDialogContainer } from './containers/connections/apps/servicenow/EventListenerSetupDialogContainer';
import { NetSuiteEventListenerSetupDialogContainer } from './containers/connections/apps/netsuite/EventListenerSetupDialogContainer';
import { ZoomEventListenerSetupDialogContainer } from './containers/connections/apps/zoom/EventListenerSetupDialogContainer';
import { BitbucketOnPremiseEventListenerSetupDialogContainer } from './containers/connections/apps/bitbucket-on-premise/EventListenerSetupDialogContainer';
import { ConnectorsContainer } from './containers/connections/ConnectionsContainer';
import { connectorsLoader } from './loaders/connectors';
import { LanguageSelectorContainer } from './containers/workspace-dialogs/LanguageSelectorContainer';
import { GenericManageConnectionDialogContainer } from './containers/connections/apps/generic/ManageConnectionDialogContainer';
import { GenericAppConfigureDialog } from './containers/connections/apps/generic/GenericAppConfigureDialogContainer';
import { MondayManageConnectionDialogContainer } from './containers/connections/apps/monday/ManageConnectionDialogContainer';
import { MondayConfigureDialog } from './containers/connections/apps/monday/MondayConfigureDialogContainer';
import { MondayEventListenerSetupDialogContainer } from './containers/connections/apps/monday/EventListenerSetupDialogContainer';
import { readmeFileDetailsLoader } from './loaders/workspace/readme';
import { ReadmeFileDetailsContainer } from './containers/workspace-resources/ReadmeFileDetailsContainer';
import { PackageManagerContainer } from './containers/workspace-dialogs/PackageManagerContainer';
import { TempoCloudManageConnectionDialogContainer } from './containers/connections/apps/tempo-cloud/ManageConnectionDialogContainer';
import { TempoCloudConfigureDialog } from './containers/connections/apps/tempo-cloud/TempoCloudConfigureDialogContainer';
import { NetSuiteManageConnectionDialogContainer } from './containers/connections/apps/netsuite/ManageConnectionDialogContainer';
import { NetSuiteConfigureDialog } from './containers/connections/apps/netsuite/NetSuiteConfigureDialogContainer';
import { ScheduledTriggerDetailsContainer } from './containers/workspace-resources/scheduled-triggers/ScheduledTriggerDetailsContainer';
import { scheduledTriggerLoader } from './loaders/workspace/scheduled-trigger';
import { readOnlyTemplateLoader, readOnlyTemplateUnloader } from './loaders/template';
import { ReadOnlyTemplateContainer } from './containers/templates/ReadOnlyTemplateContainer';
import { DefaultReadOnlyTemplateViewContainer } from './containers/workspace-layouts/DefaultReadOnlyTemplateViewContainer';
import { ReadOnlyApiHandlerDetailsContainer } from './containers/workspace-resources/api-connections/ReadOnlyApiConnectionDetailsContainer';
import { ReadOnlyEventListenerDetailsContainer } from './containers/workspace-resources/event-listeners/ReadOnlyEventListenerTabsContainer';
import { ReadOnlyScheduledTriggerDetailsContainer } from './containers/workspace-resources/scheduled-triggers/ReadOnlyScheduledTriggerDetailsContainer';
import { JsmCloudAssetsManageConnectionDialogContainer } from './containers/connections/apps/jsmCloudAssets/ManageConnectionDialogContainer';
import { JsmCloudAssetsConfigureDialog } from './containers/connections/apps/jsmCloudAssets/JsmCloudAssetsConfigureDialogContainer';
import { RegisteredVsActiveUsersReportPageContainer } from './containers/report/RegisteredVsActiveUsersReportPageContainer';
import { UserActivationReportPageContainer } from './containers/report/UserActivationReportPageContainer';
import { TemplatesUsedReportPageContainer } from './containers/report/TemplatesUsedReportPageContainer';
import { registeredVsActiveUsersReportLoader } from './loaders/report/registeredVsActiveUsers';
import { EnvironmentManagerContainer } from './containers/workspace-dialogs/EnvironmentManagerContainer';
import { StartWizardDialogContainer } from './containers/wizard/StartWizardSetupDialogContainer';
import { WizardMinimizedIconContainer } from './containers/wizard/WizardMinimizedIconContainer';
import { ConfirmMinimizeWizardContainer } from './containers/wizard/ConfirmMinimizeWizardContainer';
import { UsersUtilizationPageContainer } from './containers/report/UsersUtilizationPageContainer';
import { usersUtilizationReportLoader } from './loaders/report/usersUtilization';
import { OrganisationsUtilizationPageContainer } from './containers/report/OrganisationsUtilizationReportPageContainer';
import { organisationsUtilizationReportLoader } from './loaders/report/organisationsUtilization';
import { MicrosoftManageConnectionDialogContainer } from './containers/connections/apps/microsoft/ManageConnectionDialogContainer';
import { MicrosoftEventListenerSetupDialogContainer } from './containers/connections/apps/microsoft/EventListenerSetupDialogContainer';
import { MicrosoftConfigureDialog } from './containers/connections/apps/microsoft/MicrosoftConfigureDialogContainer';
import { AzureDevopsManageConnectionDialogContainer } from './containers/connections/apps/azure-devops/ManageConnectionDialogContainer';
import { AzureDevopsConfigureDialog } from './containers/connections/apps/azure-devops/AzureDevopsConfigureDialogContainer';
import { ZenDeskManageConnectionDialogContainer } from './containers/connections/apps/zendesk/ManageConnectionDialogContainer';
import { ZenDeskConfigureDialogContainer } from './containers/connections/apps/zendesk/ZenDeskConfigureDialogContainer';
import { reportingLoader } from './loaders/reporting';
import { ReportingContainer } from './containers/reporting/InvocationsReportContainer';
import { ProfileContainer } from './containers/profile/ProfileContainer';
import { profileLoader } from './loaders/profile';
import { LoadingWizard } from './components/common/loading/LoadingWizard';
import { invocationLogsLoader } from './loaders/workspace/invocation-logs';
import { InvocationLogsContainer } from './containers/reporting/printed-logs/InvocationLogsContainer';
import { largeInvocationLogPayloadLoader } from './loaders/workspace/large-invocation-log-payload';
import { LargeInvocationLogPayloadContainer } from './containers/reporting/printed-logs/LargeInvocationLogPayloadContainer';
import { httpLogsLoader } from './loaders/workspace/http-logs';
import { HttpLogsContainer } from './containers/reporting/printed-logs/HttpLogsContainer';
import { BillingDetailsContainer } from './containers/organizations/billing/BillingDetailsContainer';
import { billingDetailsLoader } from './loaders/billing-details';
import { OrganizationsContainer } from './containers/organizations/OrganizationsContainer';
import { organizationsLoader } from './loaders/organizations';
import { CreateOrganizationWizardContainer } from './containers/organizations/CreateOrganizationWizardContainer';
import { OrganizationContainer } from './containers/organizations/OrganizationContainer';
import { templateUsedReportLoader } from './loaders/report/templatesUsed';
import { organizationBillingDetailsLoader } from './loaders/organization/billing-details';
import { ShareWorkspaceDialogContainer } from './containers/workspace-dialogs/ShareWorkspaceDialogContainer';
import { organizationLoader } from './loaders/organization/organization';
import { OrganizationUsageContainer } from './containers/organizations/usage/OrganizationUsageContainer';
import { organizationUsageLoader } from './loaders/organization/usage';
import { organizationDetailsLoader } from './loaders/organization/details';
import { OrganizationDetailsContainer } from './containers/organizations/details/OrganizationDetailsContainer';
import { OrganizationPlanContainer } from './containers/organizations/plan/OrganizationPlanContainer';
import { organizationPlanLoader } from './loaders/organization/plan';
import { AbsoluteCenteredLoadingSpinner } from './components/common/loading/AbsoluteCenteredLoadingSpinner';
import { OrganizationBillingDetailsContainer } from './containers/organizations/billing/OrganizationBillingDetailsContainer';
import { organizationMembersLoader } from './loaders/organization/members';
import { OrganizationMembersContainer } from './containers/organizations/members/OrganizationMembersContainer';
import { HelpAndSupportContainer } from './containers/app-main/HelpAndSupportContainer';
import { BillingEntityContainer } from './containers/organizations/billing/BillingEntityContainer';
import { SalesforceEventListenerSetupDialogContainer } from './containers/connections/apps/salesforce/EventListenerSetupDialogContainer';
import { billingEntityLoader } from './loaders/billing-entity';
import { StatuspageConfigureDialog } from './containers/connections/apps/statuspage/StatuspageConfigureDialogContainer';
import { StatuspageManageConnectionDialogContainer } from './containers/connections/apps/statuspage/ManageConnectionDialogContainer';
import { TrelloConfigureDialog } from './containers/connections/apps/trello/TrelloConfigureDialogContainer';
import { TrelloManageConnectionDialogContainer } from './containers/connections/apps/trello/ManageConnectionDialogContainer';
import { OpsgenieConfigureDialog } from './containers/connections/apps/opsgenie/OpsgenieConfigureDialogContainer';
import { OpsgenieManageConnectionDialogContainer } from './containers/connections/apps/opsgenie/ManageConnectionDialogContainer';
import { OpsgenieEventListenerSetupDialogContainer } from './containers/connections/apps/opsgenie/EventListenerSetupDialogContainer';
import { anonymousUsersReportLoader } from './loaders/report/anonymousUsers';
import { AnonymousUsersReportPageContainer } from './containers/report/AnonymousUsersReportPageContainer';
import { userActivationReportLoader } from './loaders/report/userActivation';
import { StatuspageEventListenerSetupDialogContainer } from './containers/connections/apps/statuspage/EventListenerSetupDialogContainer';
import { OnboardingWizardContainer } from './containers/onboarding/OnboardingWizardContainer';
import { SelectBillingDetailsDialogContainer } from './containers/organizations/billing/SelectBillingDetailsDialogContainer';
import { OrganizationPlanSuccessStateContainer } from './containers/organizations/plan/OrganizationPlanSuccessStateContainer';
import { ErrorPageContainer } from './containers/app-main/ErrorPageContainer';
import { ImpersonationPasswordDialogContainer } from './containers/report/ImpersonationPasswordDialogContainer';
import { UserOnboardingDataReportPageContainer } from './containers/report/UserOnboardingDataReportPageContainer';
import { userOnboardingDataReportLoader } from './loaders/report/userOnboardingData';
import { ChangeOrganizationPlanWizardContainer } from './containers/organizations/plan/ChangeOrganizationPlanWizardContainer';
import { CancelOrganizationPlanContainer } from './containers/organizations/plan/CancelOrganizationPlanContainer';
import { CancelOrganizationPlanSuccessStateContainer } from './containers/organizations/plan/CancelOrganizationPlanSuccessStateContainer';
import { CancelOrganizationPlanFeedbackContainer } from './containers/organizations/plan/CancelOrganizationPlanFeedbackContainer';
import { ChangeOrganizationPlanConfirmationContainer } from './containers/organizations/plan/ChangeOrganizationPlanConfirmationContainer';
import { OrganizationInvoicePlanInformationDialogContainer } from './containers/organizations/plan/OrganizationInvoicePlanInformationDialogContainer';
import { ChangeOrganizationPlanConfirmPurchaseContainer } from './containers/organizations/plan/ChangeOrganizationPlanConfirmPurchaseContainer';
import { PackageUpgradesDialogContainer } from './containers/workspace-dialogs/PackageUpgradesDialogContainer';
import { EnvironmentVariableDetailsContainer } from './containers/workspace-resources/EnvironmentVariableDetailsContainer';
import { AtlassianCloudConfigureDialog } from './containers/connections/apps/atlassian-cloud/AtlassianCloudConfigureDialogContainer';
import { environmentVariablesLoader } from './loaders/workspace/environment-variable';
import { ProfileUpdateDialogContainer } from './containers/profile/ProfileUpdateDialogContainer';
import { ProfileUpdateSuccessDialogContainer } from './containers/profile/ProfileUpdateSuccessDialogContainer';
import { DiscountCodesReportPageContainer } from './containers/report/DiscountCodesReportPageContainer';
import { discountCodesLoader } from './loaders/report/discountCodes';
import { DiscountCodeDetailsReportPageContainer } from './containers/report/DiscountCodeDetailsReportPageContainer';
import { discountCodeDetailsLoader } from './loaders/report/discountCodeDetails';
import { OnboardingTemplateSelectorContainer } from './containers/onboarding/OnboardingTemplateSelectorContainer';
import { AzureDevopsEventListenerSetupDialogContainer } from './containers/connections/apps/azure-devops/EventListenerSetupDialogContainer';
import { ZenDeskEventListenerSetupDialogContainer } from './containers/connections/apps/zendesk/EventListenerSetupDialogContainer';
import { AuditLogsPageContainer } from './containers/reporting/AuditLogsPageContainer';
import { auditLogsLoader } from './loaders/audit-logs';
import { ApiKeysContainer } from './containers/profile/api-keys/ApiKeysContainer';
import { apiKeysLoader } from './loaders/api-keys';

export const location = new ReactLocation<LocationGenerics>();

// eslint-disable-next-line @typescript-eslint/ban-types
export type LocationGenerics = MakeGenerics<{}>;

export const getRoutes = (): Route<LocationGenerics>[] => {
    /**
     * PUBLIC_URL could be . or /<git commit hash> so need to adjust the root path accordingly
     */
    const rootPath = process.env.PUBLIC_URL.substring(1).length ? process.env.PUBLIC_URL : '/';
    const errorElement = <ErrorPageContainer />;

    return [
        {
            path: rootPath,
            element: (
                <>
                    <AppMainContainer rootPath={rootPath} />
                    <OnboardingWizardContainer />
                    <AppSelectorContainer />
                    <AtlassianCloudManageConnectionDialogContainer />
                    <AtlassianCloudInstanceSelectionContainer />
                    <AtlassianCloudConfigureDialog />
                    <BitbucketCloudManageConnectionDialogContainer />
                    <BitbucketCloudConfigureDialog />
                    <AtlassianOnPremiseManageConnectionDialogContainer />
                    <AtlassianOnPremiseConfigureDialog />
                    <ServiceNowManageConnectionDialogContainer />
                    <BitbucketOnPremiseManageConnectionDialogContainer />
                    <ServiceNowConfigureDialog />
                    <BitbucketOnPremiseConfigureDialog />
                    <SlackManageConnectionDialogContainer />
                    <SlackAppConfigureDialog />
                    <GoogleManageConnectionDialogContainer />
                    <GenericManageConnectionDialogContainer />
                    <GenericAppConfigureDialog />
                    <GithubManageConnectionDialogContainer />
                    <GitlabManageConnectionDialogContainer />
                    <GitlabConfigureDialog />
                    <MondayManageConnectionDialogContainer />
                    <MondayConfigureDialog />
                    <ConfirmDialogContainer />
                    <CreateNewWorkspaceDialogContainer />
                    <EditWorkspaceDialogContainer />
                    <ShareWorkspaceDialogContainer />
                    <SelectBillingDetailsDialogContainer />
                    <ImpersonationPasswordDialogContainer />
                    <TempoCloudManageConnectionDialogContainer />
                    <TempoCloudConfigureDialog />
                    <NetSuiteManageConnectionDialogContainer />
                    <NetSuiteConfigureDialog />
                    <JsmCloudAssetsManageConnectionDialogContainer />
                    <JsmCloudAssetsConfigureDialog />
                    <StartWizardDialogContainer />
                    <WizardMinimizedIconContainer />
                    <ConfirmMinimizeWizardContainer />
                    <MicrosoftManageConnectionDialogContainer />
                    <MicrosoftEventListenerSetupDialogContainer />
                    <MicrosoftConfigureDialog />
                    <AzureDevopsManageConnectionDialogContainer />
                    <AzureDevopsEventListenerSetupDialogContainer />
                    <AzureDevopsConfigureDialog />
                    <ZoomManageConnectionDialogContainer />
                    <ZoomEventListenerSetupDialogContainer />
                    <ZoomConfigureDialog />
                    <ZenDeskManageConnectionDialogContainer />
                    <ZenDeskEventListenerSetupDialogContainer />
                    <ZenDeskConfigureDialogContainer />
                    <CreateOrganizationWizardContainer />
                    <SalesforceManageConnectionDialogContainer />
                    <SalesforceConfigureDialog />
                    <StatuspageManageConnectionDialogContainer />
                    <StatuspageConfigureDialog />
                    <StatuspageEventListenerSetupDialogContainer />
                    <HelpAndSupportContainer />
                    <TrelloManageConnectionDialogContainer />
                    <TrelloConfigureDialog />
                    <OpsgenieManageConnectionDialogContainer />
                    <OpsgenieConfigureDialog />
                    <OrganizationPlanSuccessStateContainer />
                    <ProfileUpdateDialogContainer />
                    <ProfileUpdateSuccessDialogContainer />
                    <OnboardingTemplateSelectorContainer />
                </>
            ),
            loader: appLoader,
            pendingElement: async () => <LoadingWizard />,
            errorElement,
            pendingMs: 0,
            children: [
                {
                    path: '/workspaces',
                    element: <WorkspaceSelectorContainer />,
                    loader: workspacesLoader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/workspace/:workspaceUid/environment/:environmentUid',
                    element: (
                        <>
                            <WorkspaceContainer />
                            <GenericEventListenerSetupDialogContainer />
                            <AtlassianCloudEventListenerSetupDialogContainer />
                            <SlackEventListenerSetupDialogContainer />
                            <AtlassianOnPremiseEventListenerSetupDialogContainer />
                            <BitbucketCloudEventListenerSetupDialogContainer />
                            <ServiceNowEventListenerSetupDialogContainer />
                            <NetSuiteEventListenerSetupDialogContainer />
                            <SalesforceEventListenerSetupDialogContainer />
                            <BitbucketOnPremiseEventListenerSetupDialogContainer />
                            <MondayEventListenerSetupDialogContainer />
                            <GitlabEventListenerSetupDialogContainer />
                            <GithubEventListenerSetupDialogContainer />
                            <ZoomEventListenerSetupDialogContainer />
                            <CreateNewWorkspaceEnvironmentDialogContainer />
                            <ManageEnvironmentDeploymentTargetsDialogContainer />
                            <LanguageSelectorContainer />
                            <PackageManagerContainer />
                            <EnvironmentManagerContainer />
                            <OpsgenieEventListenerSetupDialogContainer />
                            <PackageUpgradesDialogContainer />
                        </>
                    ),
                    loader: workspaceLoader,
                    unloader: workspaceUnloader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                    children: [
                        {
                            path: '/',
                            element: (
                                <>
                                    <DefaultWorkspaceViewContainer />
                                </>
                            ),
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/script/:scriptUid',
                            element: (
                                <>
                                    <ScriptDetailsContainer />
                                    <ApiConnectionImportDialogContainer />
                                </>
                            ),
                            loader: scriptDetailsLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/readme/:readmeFileUid',
                            element: <ReadmeFileDetailsContainer />,
                            loader: readmeFileDetailsLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/variables',
                            element: <EnvironmentVariableDetailsContainer />,
                            loader: environmentVariablesLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/api/:apiHandlerUid',
                            element: <ApiHandlerDetailsContainer />,
                            loader: apiHandlerDetailsLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/event/:eventListenerUid',
                            element: <EventListenerDetailsContainer />,
                            loader: eventListenerLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/scheduled/:scheduledTriggerUid',
                            element: <ScheduledTriggerDetailsContainer />,
                            loader: scheduledTriggerLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                    ],
                },
                {
                    path: '/template/:templateUid',
                    element: (
                        <>
                            <ReadOnlyTemplateContainer />
                        </>
                    ),
                    loader: readOnlyTemplateLoader,
                    unloader: readOnlyTemplateUnloader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                    children: [
                        {
                            path: '/',
                            element: (
                                <>
                                    <DefaultReadOnlyTemplateViewContainer />
                                </>
                            ),
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/script/:scriptUid',
                            element: (
                                <>
                                    <ScriptDetailsContainer />
                                </>
                            ),
                            loader: scriptDetailsLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/readme/:readmeFileUid',
                            element: (
                                <>
                                    <ReadmeFileDetailsContainer />
                                </>
                            ),
                            loader: readmeFileDetailsLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/variables',
                            element: <EnvironmentVariableDetailsContainer />,
                            loader: environmentVariablesLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/api/:apiHandlerUid',
                            element: <ReadOnlyApiHandlerDetailsContainer />,
                            loader: readOnlyApiHandlerDetailsLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/event/:eventListenerUid',
                            element: <ReadOnlyEventListenerDetailsContainer />,
                            loader: readOnlyEventListenerLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/scheduled/:scheduledTriggerUid',
                            element: <ReadOnlyScheduledTriggerDetailsContainer />,
                            loader: scheduledTriggerLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                    ],
                },
                {
                    path: '/team/:teamUid',
                    element: (
                        <>
                            <OrganizationContainer />
                        </>
                    ),
                    loader: organizationLoader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                    children: [
                        {
                            path: '/',
                            element: <OrganizationContainer />,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },

                        {
                            path: '/usage',
                            element: <OrganizationUsageContainer />,
                            loader: organizationUsageLoader,
                            pendingElement: async () => <AbsoluteCenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/details',
                            element: <OrganizationDetailsContainer />,
                            loader: organizationDetailsLoader,
                            pendingElement: async () => <AbsoluteCenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/members',
                            element: <OrganizationMembersContainer />,
                            loader: organizationMembersLoader,
                            pendingElement: async () => <AbsoluteCenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/plan',
                            element: (
                                <>
                                    <OrganizationPlanContainer />
                                    <ChangeOrganizationPlanWizardContainer />
                                    <ChangeOrganizationPlanConfirmationContainer />
                                    <ChangeOrganizationPlanConfirmPurchaseContainer />
                                    <CancelOrganizationPlanContainer />
                                    <CancelOrganizationPlanSuccessStateContainer />
                                    <CancelOrganizationPlanFeedbackContainer />
                                    <OrganizationInvoicePlanInformationDialogContainer />
                                </>
                            ),
                            loader: organizationPlanLoader,
                            pendingElement: async () => <AbsoluteCenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/billing',
                            element: <OrganizationBillingDetailsContainer />,
                            loader: organizationBillingDetailsLoader,
                            pendingElement: async () => <AbsoluteCenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                    ],
                },
                {
                    path: '/connectors',
                    element: <ConnectorsContainer />,
                    loader: connectorsLoader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/teams',
                    element: <OrganizationsContainer />,
                    loader: organizationsLoader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/templates',
                    element: <TemplatesContainer />,
                    loader: templatesLoader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/dashboard',
                    element: <DashboardContainer />,
                    loader: dashboardLoader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/reporting',
                    element: <ReportingContainer />,
                    loader: reportingLoader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/invocationlogs/workspace/:workspaceUid/invocation/:invocationId/log/:logId',
                    element: <LargeInvocationLogPayloadContainer />,
                    loader: largeInvocationLogPayloadLoader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/invocationlogs/workspace/:workspaceUid/invocation/:invocationId',
                    element: <InvocationLogsContainer />,
                    loader: invocationLogsLoader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/httplogs/workspace/:workspaceUid/invocation/:invocationId',
                    element: <HttpLogsContainer />,
                    loader: httpLogsLoader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/auditLogs',
                    element: <AuditLogsPageContainer />,
                    loader: auditLogsLoader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/reports',
                    element: <Outlet />,
                    children: [
                        {
                            path: '/',
                            element: <Reports />,
                        },
                        {
                            path: '/users',
                            element: <UsersReportPageContainer />,
                            loader: usersReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/anonymous',
                            element: <AnonymousUsersReportPageContainer />,
                            loader: anonymousUsersReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/team/:teamUid',
                            element: <OrganisationDetailsReportPageContainer />,
                            loader: organisationDetailsReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/teams',
                            element: <OrganisationsReportPageContainer />,
                            loader: organisationsReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/invocations',
                            element: <InvocationsReportPageContainer />,
                            loader: invocationsReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/usersWithDeployedWorkspaces',
                            element: <UsersWithDeployedWorkspacesReportPageContainer />,
                            loader: usersWithDeployedWorkspacesReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/userFeedback',
                            element: <UserFeedbackReportPageContainer />,
                            loader: userFeedbackReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/connectionTypes',
                            element: <ConnectionTypesReportPageContainer />,
                            loader: connectionTypesReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/registeredVsActiveUsers',
                            element: <RegisteredVsActiveUsersReportPageContainer />,
                            loader: registeredVsActiveUsersReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/usersUtilization',
                            element: <UsersUtilizationPageContainer />,
                            loader: usersUtilizationReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/teamsUtilization',
                            element: <OrganisationsUtilizationPageContainer />,
                            loader: organisationsUtilizationReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/templatesUsed',
                            element: <TemplatesUsedReportPageContainer />,
                            loader: templateUsedReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/userActivation',
                            element: <UserActivationReportPageContainer />,
                            loader: userActivationReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/userOnboardingData',
                            element: <UserOnboardingDataReportPageContainer />,
                            loader: userOnboardingDataReportLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/discountCodes',
                            element: <DiscountCodesReportPageContainer />,
                            loader: discountCodesLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },

                        {
                            path: '/discountCode/:uid',
                            element: <DiscountCodeDetailsReportPageContainer />,
                            loader: discountCodeDetailsLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                    ],
                },
                {
                    path: '/profile',
                    element: <ProfileContainer />,
                    loader: profileLoader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/apiKeys',
                    element: <ApiKeysContainer />,
                    loader: apiKeysLoader,
                    pendingElement: async () => <CenteredLoadingSpinner />,
                    errorElement,
                    pendingMs: 0,
                },
                {
                    path: '/billing',
                    element: <Outlet />,
                    children: [
                        {
                            path: '/',
                            element: <BillingDetailsContainer />,
                            loader: billingDetailsLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                        {
                            path: '/:billingEntityUid',
                            element: <BillingEntityContainer />,
                            loader: billingEntityLoader,
                            pendingElement: async () => <CenteredLoadingSpinner />,
                            errorElement,
                            pendingMs: 0,
                        },
                    ],
                },
            ],
        },
    ];
};
