import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material';
import { Button } from '../common/buttons/Button';
import Link from '@mui/material/Link';
import { tinyDialogStyles } from '../for-deprecation/dialog/dialogStyles';
import { handleKeyDown } from '../../utils/handleKeyDown';
import DialogContent from '@mui/material/DialogContent';

export interface ConfirmDialogProps {
    open?: boolean;
    link: string;
    title?: string;
    message: string;
    isReauthorization: boolean;
    onCancel(): void;
    onConfirm(): void;
}

const StyledLink = styled(Link)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2),
}));

const StyledDialog = styled(Dialog)(() => tinyDialogStyles);

export const ConfirmReAuthDialog: React.FC<ConfirmDialogProps> = ({
    open = false,
    link,
    title,
    message,
    isReauthorization,
    onCancel,
    onConfirm,
}) => {
    // use message if there is no title
    const confirmTitle = !title ? message : title;
    // if message is same as title use only title
    const confirmMsg = confirmTitle === message ? '' : message;

    return (
        <StyledDialog open={open} onKeyDown={(event) => handleKeyDown({ event, enterFn: onConfirm, escFn: onCancel })}>
            <DialogTitle sx={{ mb: 2 }} variant="h6">
                {confirmTitle}
            </DialogTitle>
            <DialogContent>
                {confirmMsg}
                <StyledLink href={link} target="_blank">
                    {' '}
                    {link.substring(8)}
                </StyledLink>
            </DialogContent>
            <DialogActions>
                <Stack spacing={2} direction="row">
                    <Button variant="outlined" type="reset" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button onClick={onConfirm}>{isReauthorization ? 'Re-authorize' : 'Authorize'}</Button>
                </Stack>
            </DialogActions>
        </StyledDialog>
    );
};
