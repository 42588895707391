import { useObservableState } from 'observable-hooks';
import { DefaultWorkspaceView } from '../../components/workspace-layouts/DefaultWorkspaceView';
import { loadingWorkspaceResources$, selectedWorkspaceResources$ } from '../../store/workspace';
import {
    navigateToEnvironmentVariablesAction$,
    savedEnvironmentVariables$,
} from '../../store/workspace/environment-variable';
import { navigateToReadmeFileAction$ } from '../../store/workspace/readme';
import { featureFlagsTopic$ } from '../../store/config';

export const DefaultReadOnlyTemplateViewContainer: React.FC = () => {
    const { apiHandlers, eventListeners, scripts, readmeFile } = useObservableState(selectedWorkspaceResources$);
    const loadingWorkspaceResources = useObservableState(loadingWorkspaceResources$);
    const environmentVariables = useObservableState(savedEnvironmentVariables$);
    const featureFlags = useObservableState(featureFlagsTopic$);
    const blank = !apiHandlers.length && !eventListeners.length && !scripts.length;

    if (!loadingWorkspaceResources) {
        if (environmentVariables?.length && featureFlags.environmentVariables) {
            navigateToEnvironmentVariablesAction$.next(true);
        } else if (!readmeFile?.isDefault) {
            navigateToReadmeFileAction$.next(readmeFile?.uid ?? '');
        }
    }

    return <DefaultWorkspaceView blank={blank} loading={loadingWorkspaceResources} />;
};
