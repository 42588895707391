import { APP } from '@avst-stitch/repository-lib/constants';
import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    connectionCreatedAction$,
    ConnectionDetails,
    connectionCreatedFirstTimeAction$,
    connectionCreatedFirstTime$,
} from '..';
import { createConnection, saveConnection } from '../../../data/connection';
import { InformativeError } from '../../../utils/repository';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import {
    handleManageConnectionError,
    loadLoggedInUserConnectionsWhenEventIsEmitted,
    saveConnectionAllowed,
} from '../utils';
import { promptQuestion } from '../../confirm';
import { AYSYWTSAETCWHAI } from '../../../i18n';
import { saveToken } from './save';

interface SlackConnectionDetails extends ConnectionDetails {
    instanceUrl?: string;
    appId?: string;
    signingSecret?: string;
    botUserOAuthAccessToken?: string;
}

interface SlackAppSetupSaveProps {
    appId: string;
    signingSecret: string;
    botUserOAuthAccessToken: string;
}

export const slackManageConnectionOpenDialogAction$ = monitor(
    'slackManageConnectionOpenDialogAction$',
    new Subject<SlackConnectionDetails>()
);
export const slackManageConnectionCloseDialogAction$ = monitor(
    'slackManageConnectionCloseDialogAction$',
    new Subject<void>()
);

export const slackAppSetupOpenDialogAction$ = monitor(
    'slackAppSetupOpenDialogAction$',
    new Subject<SlackConnectionDetails>()
);
export const slackAppSetupCloseDialogAction$ = monitor('slackAppSetupCloseDialogAction$', new Subject<void>());
export const slackAppSetupDialogOpen$ = monitor('slackAppSetupDialogOpen$', new BehaviorSubject(false));
export const slackAppSetupSaveAction$ = monitor('slackAppSetupSaveAction$', new Subject<SlackAppSetupSaveProps>());
export const slackAppSetupDialogErrors$ = monitor(
    'slackAppSetupDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const slackSaveConnectionAction$ = monitor('slackSaveConnectionAction$', new Subject<string>());
export const slackConnectionCreatedAction$ = monitor('slackConnectionCreatedAction$', new Subject<string>());
export const slackConnectionSavedAction$ = monitor(
    'slackConnectionSavedAction$',
    new Subject<{ uid: string; connectionTypeUid: string }>()
);
export const slackManageConnectionInitiateSetupAction$ = monitor(
    'slackManageConnectionInitiateSetupAction$',
    new Subject<string>()
);

export const slackManageConnectionDialogOpen$ = monitor('slackManageConnectionDialogOpen$', new BehaviorSubject(false));
export const slackConnectionSaving$ = monitor('slackConnectionSaving$', new BehaviorSubject(false));
export const slackManageConnectionAuthorizeLoading$ = monitor(
    'slackManageConnectionAuthorizeLoading$',
    new BehaviorSubject(false)
);
export const slackManageConnectionDetails$ = monitor(
    'slackManageConnectionDetails$',
    new BehaviorSubject<SlackConnectionDetails | undefined>(undefined)
);
export const slackManageConnectionDialogErrors$ = monitor(
    'slackManageConnectionDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const slackAppSetupDialogStage$ = monitor('slackAppSetupDialogStage$', new BehaviorSubject(0));

slackAppSetupOpenDialogAction$.subscribe((details) => {
    slackAppSetupDialogErrors$.next(undefined);
    slackManageConnectionDialogErrors$.next(undefined);
    slackAppSetupDialogOpen$.next(true);
    slackAppSetupDialogStage$.next(0);
    slackManageConnectionDetails$.next(details);
});

slackAppSetupCloseDialogAction$.subscribe(() => {
    slackAppSetupDialogOpen$.next(false);
    slackManageConnectionAuthorizeLoading$.next(false);
});

slackManageConnectionOpenDialogAction$.subscribe((details) => {
    slackAppSetupDialogErrors$.next(undefined);
    slackManageConnectionDialogErrors$.next(undefined);
    slackManageConnectionDialogOpen$.next(true);
    slackManageConnectionDetails$.next(details);
});

slackManageConnectionCloseDialogAction$.subscribe(() => {
    slackManageConnectionDialogOpen$.next(false);
    slackAppSetupDialogOpen$.next(false);
    slackManageConnectionDetails$.next(undefined);
});

slackConnectionCreatedAction$.subscribe((uid) => connectionCreatedAction$.next(uid));

slackAppSetupSaveAction$
    .pipe(
        map(async (appInfoProps) => {
            slackConnectionSaving$.next(true);
            slackAppSetupDialogErrors$.next(undefined);

            const { uid, connectionType } = slackManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    throw Error('Slack Connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (!uid) {
                    throw Error('Slack Connector has not been created so cannot configure it');
                }

                await saveToken({
                    ...appInfoProps,
                    connectionId: uid,
                    instanceUrl: `https://api.slack.com/apps/${appInfoProps.appId}`,
                });

                slackConnectionCreatedAction$.next(uid);
                slackConnectionSavedAction$.next({ uid, connectionTypeUid });
                slackAppSetupCloseDialogAction$.next();
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Slack app has been configured.',
                });
            } catch (e) {
                if (e instanceof InformativeError) {
                    slackAppSetupDialogErrors$.next(e.message);
                } else {
                    slackAppSetupDialogErrors$.next(
                        'Failed to save Slack application details, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving Slack application info', e);
                }
            }

            slackConnectionSaving$.next(false);
            slackManageConnectionAuthorizeLoading$.next(false);
        })
    )
    .subscribe();

slackSaveConnectionAction$
    .pipe(
        map(async (name) => {
            slackConnectionSaving$.next(true);
            slackManageConnectionDialogErrors$.next(undefined);

            const { uid, connectionType } = slackManageConnectionDetails$.value ?? {};

            try {
                if (!connectionType) {
                    throw Error('Slack Connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (saveConnectionAllowed(uid)) {
                        await saveSlackConnection(uid, name, connectionTypeUid);
                    } else {
                        promptQuestion({
                            message: AYSYWTSAETCWHAI,
                            onProceed: async () => {
                                await saveSlackConnection(uid, name, connectionTypeUid);
                            },
                        });
                    }
                } else {
                    promptQuestion({
                        message: AYSYWTSAETCWHAI,
                        onProceed: async () => {
                            try {
                                const { uid } = await createConnection(
                                    connectionType.uid,
                                    name,
                                    connectionType.apiHandlerTypes[0]?.uid ?? '',
                                    connectionType.eventListenerTypes[0]?.uid ?? ''
                                );

                                slackConnectionCreatedAction$.next(uid);
                                slackConnectionSavedAction$.next({ uid, connectionTypeUid });
                                slackManageConnectionCloseDialogAction$.next();
                                publishLocalFeedbackEventAction$.next({
                                    level: 'SUCCESS',
                                    message: 'Connector created.',
                                });
                                connectionCreatedFirstTimeAction$.next();
                            } catch (e) {
                                handleManageConnectionError(e, slackManageConnectionDialogErrors$, APP.SLACK.NAME);
                            }
                        },
                    });
                }
            } catch (e) {
                handleManageConnectionError(e, slackManageConnectionDialogErrors$, APP.SLACK.NAME);
            }
            slackConnectionSaving$.next(false);
        })
    )
    .subscribe();

slackManageConnectionInitiateSetupAction$
    .pipe(
        map(async (name) => {
            slackConnectionSaving$.next(true);
            slackManageConnectionAuthorizeLoading$.next(true);
            slackManageConnectionDialogErrors$.next(undefined);

            const {
                uid,
                connectionType,
                name: currentName,
                ...restOfDetails
            } = slackManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    throw Error('Slack Connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (currentName !== name) {
                        await saveConnection(uid, name);
                    }

                    slackConnectionSavedAction$.next({ uid, connectionTypeUid });
                    slackAppSetupOpenDialogAction$.next({
                        ...restOfDetails,
                        uid,
                        connectionType,
                        name,
                    });
                } else {
                    const { uid } = await createConnection(
                        connectionType.uid,
                        name,
                        connectionType.apiHandlerTypes[0]?.uid ?? '',
                        connectionType.eventListenerTypes[0]?.uid ?? ''
                    );

                    slackConnectionCreatedAction$.next(uid);
                    slackConnectionSavedAction$.next({ uid, connectionTypeUid });
                    slackManageConnectionDetails$.next({
                        connectionType,
                        name,
                        uid,
                    });

                    slackConnectionSaving$.next(false);

                    slackAppSetupOpenDialogAction$.next({
                        connectionType,
                        name,
                        uid,
                    });
                    connectionCreatedFirstTime$.next(true);
                }
            } catch (e) {
                handleManageConnectionError(e, slackManageConnectionDialogErrors$, APP.SLACK.NAME);
                slackManageConnectionAuthorizeLoading$.next(false);
            }

            slackConnectionSaving$.next(false);
        })
    )
    .subscribe();

loadLoggedInUserConnectionsWhenEventIsEmitted(slackConnectionSavedAction$);

const saveSlackConnection = async (uid: string, name: string, connectionTypeUid: string): Promise<void> => {
    try {
        await saveConnection(uid, name);

        slackConnectionSavedAction$.next({ uid, connectionTypeUid });
        slackManageConnectionCloseDialogAction$.next();
        publishLocalFeedbackEventAction$.next({
            level: 'SUCCESS',
            message: 'Connector saved.',
        });
        if (connectionCreatedFirstTime$.value) {
            connectionCreatedFirstTimeAction$.next();
            connectionCreatedFirstTime$.next(false);
        }
    } catch (e) {
        handleManageConnectionError(e, slackManageConnectionDialogErrors$, APP.SLACK.NAME);
    }
};
