import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const MicrosoftIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            className="productIcon"
            aria-label="Microsoft"
        >
            <path d="M0 0h15.206v15.206H0z" fill="#f25022" />
            <path d="M16.794 0H32v15.206H16.794z" fill="#7fba00" />
            <path d="M0 16.794h15.206V32H0z" fill="#00a4ef" />
            <path d="M16.794 16.794H32V32H16.794z" fill="#ffb900" />
        </SvgIcon>
    );
};
