import { useObservableState } from 'observable-hooks';
import { useMatch, useNavigate } from 'react-location';
import { UsersUtilizationPage } from '../../components/report/UsersUtilizationReport';
import { userUtilizationReportData$ } from '../../store/report/usersUtilization';

export const UsersUtilizationPageContainer: React.FC = () => {
    const navigate = useNavigate();
    const match = useMatch();
    const data = useObservableState(userUtilizationReportData$);

    return (
        <UsersUtilizationPage
            data={data}
            timePeriod={match.search.timePeriod ? (match.search.timePeriod as string).substring(1) : undefined}
            onSearch={(timePeriod) => {
                // Ugly hack to force loader to get called
                navigate({
                    to: '../',
                });
                setTimeout(
                    () =>
                        navigate({
                            to: './usersUtilization',
                            search: {
                                search: true,
                                timePeriod,
                            },
                        }),
                    100
                );
            }}
        />
    );
};
