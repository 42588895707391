import React from 'react';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';

interface LargeInvocationLogPayloadProps {
    logId: string;
    content: string;
}

const StyledOuterContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(2, 3),
    position: 'relative',
    height: '100%',
    width: '100%',
}));

const StyledDiv = styled('div')({
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap',
});

const StyledTitleContainer = styled('div')(() => ({
    height: 40,
}));

export const LargeInvocationLogPayload: React.FC<LargeInvocationLogPayloadProps> = ({ logId, content }) => {
    return (
        <StyledOuterContainer>
            <StyledTitleContainer>
                <Typography variant="h3">Invocation Log for {logId}</Typography>
            </StyledTitleContainer>
            <StyledDiv>{content}</StyledDiv>
        </StyledOuterContainer>
    );
};
