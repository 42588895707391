import { BehaviorSubject, map, Subject } from 'rxjs';
import {
    connectionCreatedAction$,
    ConnectionDetails,
    connectionCreatedFirstTimeAction$,
    connectionCreatedFirstTime$,
} from '..';
import { createConnection, saveConnection } from '../../../data/connection';
import { InformativeError } from '../../../utils/repository';
import { configTopic$ } from '../../config';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import {
    initiate,
    loadLoggedInUserConnectionsWhenEventIsEmitted,
    saveConnectionAllowed,
    openConsentWindow,
} from '../utils';
import { promptQuestion } from '../../confirm';
import { AYSYWTSAETCWHAI } from '../../../i18n';

interface AzureDevopsConnectionDetails extends ConnectionDetails {
    tenantId?: string;
    clientId?: string;
    clientSecret?: string;
}

interface AzureDevopsAppSetupSaveProps {
    tenantId: string;
}

interface AzureDevopsConnectionSavedDetails {
    uid: string;
    connectionTypeUid: string;
    tenantId: string;
}

export const azureDevopsManageConnectionOpenDialogAction$ = monitor(
    'azureDevopsManageConnectionOpenDialogAction$',
    new Subject<AzureDevopsConnectionDetails>()
);
export const azureDevopsManageConnectionCloseDialogAction$ = monitor(
    'azureDevopsManageConnectionCloseDialogAction$',
    new Subject<void>()
);

export const azureDevopsAppSetupOpenDialogAction$ = monitor(
    'azureDevopsAppSetupOpenDialogAction$',
    new Subject<AzureDevopsConnectionDetails>()
);
export const azureDevopsAppSetupCloseDialogAction$ = monitor(
    'azureDevopsAppSetupCloseDialogAction$',
    new Subject<void>()
);
export const azureDevopsAppSetupDialogOpen$ = monitor('azureDevopsAppSetupDialogOpen$', new BehaviorSubject(false));
export const azureDevopsAppSetupAuthorizeAction$ = monitor(
    'azureDevopsAppSetupAuthorizeAction$',
    new Subject<AzureDevopsAppSetupSaveProps>()
);
export const azureDevopsAppSetupDialogErrors$ = monitor(
    'azureDevopsAppSetupDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const azureDevopsSaveConnectionAction$ = monitor('azureDevopsSaveConnectionAction$', new Subject<string>());
export const azureDevopsConnectionCreatedAction$ = monitor(
    'azureDevopsConnectionCreatedAction$',
    new Subject<string>()
);
export const azureDevopsConnectionSavedAction$ = monitor(
    'azureDevopsConnectionSavedAction$',
    new Subject<AzureDevopsConnectionSavedDetails>()
);
export const azureDevopsManageConnectionInitiateSetupAction$ = monitor(
    'azureDevopsManageConnectionInitiateSetupAction$',
    new Subject<string>()
);

export const azureDevopsManageConnectionDialogOpen$ = monitor(
    'azureDevopsManageConnectionDialogOpen$',
    new BehaviorSubject(false)
);
export const azureDevopsConnectionSaving$ = monitor('azureDevopsConnectionSaving$', new BehaviorSubject(false));
export const azureDevopsManageConnectionAuthorizeLoading$ = monitor(
    'azureDevopsManageConnectionAuthorizeLoading$',
    new BehaviorSubject(false)
);
export const azureDevopsManageConnectionDetails$ = monitor(
    'azureDevopsManageConnectionDetails$',
    new BehaviorSubject<AzureDevopsConnectionDetails | undefined>(undefined)
);
export const azureDevopsManageConnectionDialogErrors$ = monitor(
    'azureDevopsManageConnectionDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const azureDevopsAppSetupDialogStage$ = monitor('azureDevopsAppSetupDialogStage$', new BehaviorSubject(0));

azureDevopsAppSetupOpenDialogAction$.subscribe((details) => {
    azureDevopsAppSetupDialogErrors$.next(undefined);
    azureDevopsManageConnectionDialogErrors$.next(undefined);
    azureDevopsAppSetupDialogOpen$.next(true);
    azureDevopsAppSetupDialogStage$.next(0);
    azureDevopsManageConnectionDetails$.next(details);
});

azureDevopsAppSetupCloseDialogAction$.subscribe(() => {
    azureDevopsAppSetupDialogOpen$.next(false);
    azureDevopsManageConnectionAuthorizeLoading$.next(false);
});

azureDevopsManageConnectionOpenDialogAction$.subscribe((details) => {
    azureDevopsAppSetupDialogErrors$.next(undefined);
    azureDevopsManageConnectionDialogErrors$.next(undefined);
    azureDevopsManageConnectionDialogOpen$.next(true);
    azureDevopsManageConnectionDetails$.next(details);
});

azureDevopsManageConnectionCloseDialogAction$.subscribe(() => {
    azureDevopsManageConnectionDialogOpen$.next(false);
    azureDevopsAppSetupDialogOpen$.next(false);
    azureDevopsManageConnectionDetails$.next(undefined);
});

azureDevopsConnectionCreatedAction$.subscribe((uid) => connectionCreatedAction$.next(uid));

azureDevopsAppSetupAuthorizeAction$
    .pipe(
        map(async (appInfoProps) => {
            azureDevopsConnectionSaving$.next(true);
            azureDevopsAppSetupDialogErrors$.next(undefined);

            const { uid, connectionType } = azureDevopsManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    throw Error('Azure Devops Connector type not defined');
                }

                if (!uid) {
                    throw Error('Azure Devops Connector has not been created so cannot configure it');
                }

                const baseUrl = configTopic$.value.connection?.azureDevops?.baseUrl;
                if (!baseUrl) {
                    throw new Error('No Azure Devops Connector URL configured in meta');
                }

                const response = await initiate(
                    {
                        ...appInfoProps,
                        connectionId: uid,
                        connectionType: 'azure-devops',
                    },
                    `${baseUrl}/initiate`
                );
                openConsentWindow(response.location, azureDevopsManageConnectionAuthorizeLoading$);

                azureDevopsConnectionCreatedAction$.next(uid);
            } catch (e) {
                if (e instanceof InformativeError) {
                    azureDevopsAppSetupDialogErrors$.next(e.message);
                } else {
                    azureDevopsAppSetupDialogErrors$.next(
                        'Failed to save Azure Devops application details, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving Azure Devops application info', e);
                }
            }

            azureDevopsConnectionSaving$.next(false);
            azureDevopsManageConnectionAuthorizeLoading$.next(false);
        })
    )
    .subscribe();

azureDevopsSaveConnectionAction$
    .pipe(
        map(async (name) => {
            azureDevopsConnectionSaving$.next(true);
            azureDevopsManageConnectionDialogErrors$.next(undefined);

            const { uid, connectionType, tenantId = '' } = azureDevopsManageConnectionDetails$.value ?? {};

            try {
                if (!connectionType) {
                    throw Error('Azure Devops Connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (saveConnectionAllowed(uid)) {
                        await saveConnection(uid, name);

                        azureDevopsConnectionSavedAction$.next({ uid, connectionTypeUid, tenantId });
                        azureDevopsManageConnectionCloseDialogAction$.next();
                        publishLocalFeedbackEventAction$.next({
                            level: 'SUCCESS',
                            message: 'Connector saved.',
                        });
                    } else {
                        promptQuestion({
                            message: AYSYWTSAETCWHAI,
                            onProceed: async () => {
                                await saveConnection(uid, name);

                                azureDevopsConnectionSavedAction$.next({ uid, connectionTypeUid, tenantId });
                                azureDevopsManageConnectionCloseDialogAction$.next();
                                publishLocalFeedbackEventAction$.next({
                                    level: 'SUCCESS',
                                    message: 'Connector saved.',
                                });
                            },
                        });
                    }
                    if (connectionCreatedFirstTime$.value) {
                        connectionCreatedFirstTimeAction$.next();
                        connectionCreatedFirstTime$.next(false);
                    }
                } else {
                    promptQuestion({
                        message: AYSYWTSAETCWHAI,
                        onProceed: async () => {
                            const { uid } = await createConnection(
                                connectionType.uid,
                                name,
                                connectionType.apiHandlerTypes[0]?.uid ?? '',
                                connectionType.eventListenerTypes[0]?.uid ?? ''
                            );

                            azureDevopsConnectionCreatedAction$.next(uid);
                            azureDevopsConnectionSavedAction$.next({ uid, connectionTypeUid, tenantId });
                            azureDevopsManageConnectionCloseDialogAction$.next();
                            publishLocalFeedbackEventAction$.next({
                                level: 'SUCCESS',
                                message: 'Connector created.',
                            });
                            connectionCreatedFirstTimeAction$.next();
                        },
                    });
                }
            } catch (e) {
                if (e instanceof InformativeError) {
                    azureDevopsManageConnectionDialogErrors$.next(e.message);
                } else {
                    azureDevopsManageConnectionDialogErrors$.next(
                        'Failed to save Connector, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving Azure Devops Connector', e);
                }
            }

            azureDevopsConnectionSaving$.next(false);
        })
    )
    .subscribe();

azureDevopsManageConnectionInitiateSetupAction$
    .pipe(
        map(async (name) => {
            azureDevopsConnectionSaving$.next(true);
            azureDevopsManageConnectionAuthorizeLoading$.next(true);
            azureDevopsManageConnectionDialogErrors$.next(undefined);

            const {
                uid,
                connectionType,
                tenantId = '',
                name: currentName,
                ...restOfDetails
            } = azureDevopsManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    throw Error('Azure Devops Connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (currentName !== name) {
                        await saveConnection(uid, name);
                    }

                    azureDevopsConnectionSavedAction$.next({ uid, connectionTypeUid, tenantId });
                    azureDevopsAppSetupOpenDialogAction$.next({
                        ...restOfDetails,
                        uid,
                        connectionType,
                        name,
                    });
                } else {
                    const { uid } = await createConnection(
                        connectionType.uid,
                        name,
                        connectionType.apiHandlerTypes[0]?.uid ?? '',
                        connectionType.eventListenerTypes[0]?.uid ?? ''
                    );

                    azureDevopsConnectionCreatedAction$.next(uid);
                    azureDevopsConnectionSavedAction$.next({ uid, connectionTypeUid, tenantId });
                    azureDevopsManageConnectionDetails$.next({
                        connectionType,
                        name,
                        uid,
                    });

                    azureDevopsConnectionSaving$.next(false);

                    azureDevopsAppSetupOpenDialogAction$.next({
                        connectionType,
                        name,
                        uid,
                    });
                    connectionCreatedFirstTime$.next(true);
                }
            } catch (e) {
                if (e instanceof InformativeError) {
                    azureDevopsManageConnectionDialogErrors$.next(e.message);
                } else {
                    azureDevopsManageConnectionDialogErrors$.next(
                        'Failed to save Connector, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving Azure Devops Connector', e);
                }
                azureDevopsManageConnectionAuthorizeLoading$.next(false);
            }

            azureDevopsConnectionSaving$.next(false);
        })
    )
    .subscribe();

loadLoggedInUserConnectionsWhenEventIsEmitted(azureDevopsConnectionSavedAction$);
