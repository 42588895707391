import { useState } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/system';

export interface BillingDetailsListProps {
    billingEntities: {
        uid: string;
        contactName: string;
        contactEmail: string;
    }[];
    selectedBillingEntity?: string;
    onSelect(uid: string): void;
    onEdit?(uid: string): void;
}

const StyledContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
}));

const StyledEmailWrapper = styled(Box)(({ theme }) => ({
    marginLeft: theme.spacing(3.5),
    marginTop: theme.spacing(-1),
}));

const StyledListItemBox = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    borderRadius: theme.constants.borderRadius,
    height: 82,
    justifyContent: 'space-between',
    padding: theme.spacing(0, 3.25),
}));

const StyledEditLink = styled(Typography)(() => ({
    cursor: 'pointer',
    '&:hover': {
        textDecoration: 'underline',
    },
}));

export const BillingDetailsList: React.FC<BillingDetailsListProps> = ({
    billingEntities,
    selectedBillingEntity,
    onSelect,
    onEdit,
}) => {
    const [currentBillingEntity, setCurrentBillingEntity] = useState(selectedBillingEntity);

    const handleUpdateBillingEntity = (uid: string): void => {
        setCurrentBillingEntity(uid);
        onSelect(uid);
    };

    return (
        <StyledContainer>
            <RadioGroup
                name="controlled-radio-buttons-group"
                value={currentBillingEntity}
                onChange={(e) => handleUpdateBillingEntity(e.target.value)}
            >
                {billingEntities.map((item, index) => (
                    <StyledListItemBox
                        sx={{
                            backgroundColor:
                                item.uid === currentBillingEntity
                                    ? (theme: Theme) => theme.palette.background.default
                                    : (theme: Theme) => theme.palette.background.paper,
                        }}
                        key={index}
                    >
                        <Box>
                            <FormControlLabel value={item.uid} control={<Radio />} label={item.contactName} />
                            <StyledEmailWrapper>
                                <Typography variant="body1" color="text.secondary">
                                    {item.contactEmail}
                                </Typography>
                            </StyledEmailWrapper>
                        </Box>
                        {!!onEdit && (
                            <StyledEditLink color="primary" onClick={() => onEdit(item.uid)}>
                                Edit
                            </StyledEditLink>
                        )}
                    </StyledListItemBox>
                ))}
            </RadioGroup>
        </StyledContainer>
    );
};
