import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Link, styled } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '../../common/buttons/Button';
import { PageContainer } from '../../app-main/PageComponents';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { Dropdown } from '../../common/dropdown/Dropdown';
import { chatGPTModel } from '@avst-stitch/repository-lib/src/utils/chatGPT';
import ReactMarkdown from 'react-markdown';
import { DialogAlert } from '../../for-deprecation/dialog/DialogComponents';
import { useTheme } from '@mui/material/styles';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs, vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Copy } from '../../common/Copy';
import { CodeProps } from 'react-markdown/lib/ast-to-react';
import ReplayIcon from '@mui/icons-material/Replay';

export interface AiAssistancePanelProps {
    loading?: boolean; // When true, show center loading icon with a message underneath that says `Priming AI`.
    asking: boolean; // Show similar loading state as in the ChatGPT UI, but since we won't be streaming back the message, just show `Asking AI` as the loading message.
    answering: boolean;
    aiGeneratedAnswer?: string;
    chat: {
        role: 'user' | 'assistant';
        message: string;
    }[];
    examples?: string[];
    onAsk(message: string, selectedGptVersion: chatGPTModel): void; // When submitted, clear the message input field.
    onClose(): void;
    onReset(): void;
    onCopy?(): void;
    errors?: string;
    gptVersions: chatGPTModel[];
    selectedGptVersion: chatGPTModel;
    aiAssistanceIsLongResponse: boolean;
}

const aiChatMinWidth = 385;

const StyledHeader = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    height: 120,
    left: 0,
    width: '100%',
    minWidth: 600,
    padding: theme.spacing(1, 4),
    position: 'absolute',
    top: 0,
    '& .MuiInputBase-root': {
        marginBottom: 0,
    },
}));

const StyledWideTitle = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    height: 'auto',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
}));

const StyledUpperButtonContainer = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    minWidth: 185,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: 'transparent',
    marginLeft: theme.spacing(2),
    '&:hover': {
        '& .MuiSvgIcon-root': {
            color: theme.palette.primary.dark,
        },
    },
}));

const StyledLoadingScreen = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexDirection: 'column',
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
}));

const StyledQuestion = styled(Typography)(({ theme }) => ({
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
    '&:hover': {
        color: theme.palette.primary.dark,
    },
}));

const StyledConversationContainer = styled('div')(({ theme }) => ({
    height: '100%',
    width: '100%',
    minWidth: 400,
    overflow: 'hidden',
    padding: theme.spacing(24, 0, 12.25),
}));

const StyledConversation = styled('div')(() => ({
    height: '100%',
    minWidth: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    '& strong': {
        fontWeight: 700,
    },
}));

const StyledUserMessage = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
}));

const StyledBotMessage = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.action.selected,
    padding: theme.spacing(2),
}));

const StyledFooter = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    bottom: 0,
    height: 114,
    left: 0,
    position: 'absolute',
    width: '100%',
    zIndex: 1,
}));

const StyledLowerInputContainer = styled('div')(({ theme }) => ({
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
    left: 0,
    minWidth: aiChatMinWidth,
    padding: theme.spacing(1, 4),
    width: '100%',
    '& .MuiTextField-root': {
        flexGrow: 1,
    },
    '& .MuiInputBase-root': {
        backgroundColor: theme.palette.background.paper,
        height: 'auto',
        width: '100%',
    },
}));

const StyledBoxCodeBlockHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0.5, 1, 0.5, 2),
}));

const CodeBlock: React.FC<CodeProps & { onCopy?(): void }> = ({ onCopy, ...props }) => {
    const darkTheme = useTheme().palette.mode === 'dark';
    const { children, className, inline } = props;
    const match = /language-(\w+)/.exec(className || '');
    const content = String(children).replace(/\n$/, '');
    return match ? (
        <>
            <StyledBoxCodeBlockHeader
                onClick={() => onCopy?.()}
                sx={(theme) => ({
                    border: darkTheme ? 'none' : `1px solid ${theme.palette.divider}`,
                    borderBottom: 'none',
                })}
            >
                <Typography variant="body2">{match[1]}</Typography>
                <Copy label="Copy code" value={content} />
            </StyledBoxCodeBlockHeader>
            <SyntaxHighlighter
                inline={inline}
                PreTag="div"
                children={content}
                language={match[1]}
                style={darkTheme ? vscDarkPlus : vs}
                codeTagProps={{
                    style: {
                        fontSize: 'inherit',
                        lineHeight: 'inherit',
                    },
                }}
                customStyle={{
                    marginTop: 0,
                    lineHeight: '1.4em',
                }}
            />
        </>
    ) : (
        <code className={className}>{children}</code>
    );
};

export const AiAssistancePanel: React.FC<AiAssistancePanelProps> = ({
    loading = false,
    asking,
    answering,
    examples = [],
    chat = [],
    aiGeneratedAnswer,
    onAsk,
    onClose,
    onReset,
    onCopy,
    gptVersions,
    selectedGptVersion,
    aiAssistanceIsLongResponse,
}) => {
    const [prompt, setPrompt] = useState('');
    const [currentlySelectedGptVersion, setCurrentlySelectedGptVersion] = useState(selectedGptVersion);
    const [isBannerOpen, setBannerOpen] = useState(true);

    const conversationRef = useRef<HTMLDivElement>(null);
    const streamRef = useRef<HTMLDivElement>(null);
    const prevScrollHeightRef = useRef(0);

    const isAtBottom = (container: HTMLDivElement, previousScrollHeight: number): boolean => {
        return container.scrollTop + container.clientHeight + 50 >= previousScrollHeight;
    };

    useLayoutEffect(() => {
        if (conversationRef.current) {
            prevScrollHeightRef.current = conversationRef.current.scrollHeight;
        }
    }, []);

    useLayoutEffect(() => {
        if (
            conversationRef.current?.lastElementChild &&
            isAtBottom(conversationRef.current, prevScrollHeightRef.current)
        ) {
            conversationRef.current.lastElementChild.scrollIntoView({
                block: 'start',
                inline: 'nearest',
            });
            prevScrollHeightRef.current = conversationRef.current.scrollHeight;
        }
    }, [chat, aiGeneratedAnswer, streamRef]);

    const conversation = useMemo(() => {
        return chat.map((c, i) => {
            return c.role === 'user' ? (
                <StyledUserMessage key={'message' + i}>
                    <ReactMarkdown>{'**User:** ' + c.message}</ReactMarkdown>
                </StyledUserMessage>
            ) : c.role === 'assistant' ? (
                <StyledBotMessage key={'message' + i}>
                    <ReactMarkdown>{'**Assistant:** '}</ReactMarkdown>
                    <ReactMarkdown
                        components={{
                            code: (props) => <CodeBlock {...props} onCopy={onCopy} />,
                            a: (props) => <Link {...props} target="_blank" />,
                        }}
                    >
                        {c.message}
                    </ReactMarkdown>
                </StyledBotMessage>
            ) : null;
        });
    }, [chat]);

    const handleSend = (prompt: string): void => {
        if (prompt) {
            onAsk(prompt, currentlySelectedGptVersion);
            setPrompt('');
        }
    };

    const exampleQuestions = examples.map((e, i) => {
        return (
            <StyledQuestion
                key={'question' + i}
                variant="body1"
                fontStyle="italic"
                onClick={() => {
                    if (!asking && !answering) {
                        onAsk(e, currentlySelectedGptVersion);
                    }
                }}
            >
                {e}
            </StyledQuestion>
        );
    });

    const emptyConversation = (
        <>
            <Typography fontWeight={700} sx={{ marginBottom: 3, marginTop: 3 }}>
                Can't figure out what to ask? Get started with these questions:
            </Typography>
            {exampleQuestions}
        </>
    );

    const loadingScreen = (
        <StyledLoadingScreen>
            <CircularProgress sx={{ marginBottom: 2 }} />
            <Typography variant="body1">
                <em>Priming AI...</em>
            </Typography>
        </StyledLoadingScreen>
    );

    const menuItems = gptVersions.map((gv) => ({ value: gv, name: gv }));

    return (
        <>
            <PageContainer
                dataTestId="ai-assistance"
                sx={{
                    bgcolor: 'background.paper',
                    overflow: 'hidden',
                    height: '100%',
                    minWidth: aiChatMinWidth,
                }}
            >
                <StyledHeader>
                    <StyledWideTitle>
                        <Typography component="h3" variant="subtitle1">
                            AI Assistance
                        </Typography>
                        <StyledUpperButtonContainer>
                            <Button disabled={!chat.length} variant="text" startIcon={<AddIcon />} onClick={onReset}>
                                Start New Chat
                            </Button>
                            <Tooltip title="Close AI assistance" placement="bottom-start" describeChild>
                                <StyledIconButton onClick={onClose}>
                                    <CloseIcon sx={{ height: 20, width: 20 }} />
                                </StyledIconButton>
                            </Tooltip>
                        </StyledUpperButtonContainer>
                    </StyledWideTitle>
                    <DialogAlert
                        text={
                            <>
                                <span>The ChatGPT integration is experimental, your milage may vary. </span>
                                <Link href="https://adaptavistlabs.atlassian.net/wiki/x/SQDs4g" target="blank">
                                    Read RFC first to see the list of supported methods and events.
                                </Link>
                            </>
                        }
                        severity="warning"
                        closeable={true}
                        onClose={() => setBannerOpen((prev) => !prev)}
                    />
                    <Dropdown
                        items={menuItems}
                        label="GPT Version"
                        selectedItem={currentlySelectedGptVersion}
                        size="small"
                        onSelectItem={(version) => setCurrentlySelectedGptVersion(version as chatGPTModel)}
                        sx={{ marginBottom: 2 }}
                    />
                    {aiAssistanceIsLongResponse && (
                        <Button
                            tooltip="Continue with the next part of the response"
                            endIcon={<ReplayIcon />}
                            variant="contained"
                            onClick={() => handleSend('Continue')}
                            sx={(theme) => ({
                                margin: theme.spacing(1, 0, 0, 2),
                            })}
                        >
                            Continue generating
                        </Button>
                    )}
                </StyledHeader>
                {loading ? (
                    loadingScreen
                ) : (
                    <StyledConversationContainer
                        sx={(theme) => ({
                            paddingTop: theme.spacing(isBannerOpen ? 24 : 13),
                        })}
                    >
                        <StyledConversation ref={conversationRef}>
                            {chat.length ? conversation : emptyConversation}
                            {aiGeneratedAnswer && (
                                <StyledBotMessage ref={streamRef} key={'message' + chat.length}>
                                    <ReactMarkdown>{'**Assistant:** '}</ReactMarkdown>
                                    <ReactMarkdown
                                        components={{
                                            code: (props) => <CodeBlock {...props} onCopy={onCopy} />,
                                            a: (props) => <Link {...props} target="_blank" />,
                                        }}
                                    >
                                        {aiGeneratedAnswer}
                                    </ReactMarkdown>
                                </StyledBotMessage>
                            )}
                            <div></div>
                        </StyledConversation>
                    </StyledConversationContainer>
                )}
                <StyledFooter>
                    <StyledLowerInputContainer>
                        <TextField
                            variant="outlined"
                            label="Ask AI assistance"
                            placeholder="Ask a question"
                            multiline
                            rows={2}
                            value={prompt}
                            onChange={(event) => setPrompt(event.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey && !!prompt.trim() && !asking && !answering) {
                                    e.preventDefault();
                                    handleSend(prompt);
                                }
                            }}
                        />
                        <Button
                            busy={asking || answering}
                            disabled={!prompt.trim() || asking || answering}
                            onClick={() => handleSend(prompt)}
                            sx={{ marginBottom: 1, marginLeft: 2 }}
                        >
                            Send
                        </Button>
                    </StyledLowerInputContainer>
                </StyledFooter>
            </PageContainer>
        </>
    );
};
