import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const OpsgenieIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            className="productIcon"
            aria-label="Opsgenie"
        >
            <path
                d="M16.0541 15.9558C19.3556 15.9558 22.032 13.2794 22.032 9.97788C22.032 6.67639 19.3556 4 16.0541 4C12.7526 4 10.0762 6.67639 10.0762 9.97788C10.0762 13.2794 12.7526 15.9558 16.0541 15.9558Z"
                fill="url(#paint0_linear_genie)"
            />
            <path
                d="M15.5504 27.8444C11.6547 25.3592 8.39705 21.9673 6.07977 17.9373C5.91185 17.6014 6.0126 17.1984 6.34843 17.0305H6.38202L10.9158 14.814C11.2181 14.6461 11.6211 14.7804 11.789 15.0827C14.0727 18.9112 17.4647 22.0009 21.5283 23.8816C20.0506 25.3928 18.3714 26.7362 16.5915 27.878C16.2556 28.0459 15.8526 28.0459 15.5504 27.8444Z"
                fill="url(#paint1_linear_genie)"
            />
            <path
                d="M16.558 27.8444C20.4537 25.3592 23.7113 21.9673 26.0286 17.9373C26.1965 17.6014 26.0957 17.1984 25.7599 17.0305H25.7263L21.1925 14.814C20.8903 14.6461 20.4873 14.7804 20.3193 15.0827C18.0357 18.9112 14.6437 22.0009 10.5801 23.8816C12.0578 25.3928 13.7369 26.7362 15.5169 27.878C15.8527 28.0459 16.2557 28.0459 16.558 27.8444Z"
                fill="#2684FF"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_genie"
                    x1="16.0567"
                    y1="5.97922"
                    x2="16.0567"
                    y2="18.271"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2684FF" />
                    <stop offset="0.82" stopColor="#0052CC" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_genie"
                    x1="12.3685"
                    y1="17.9273"
                    x2="16.4859"
                    y2="26.5447"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2684FF" />
                    <stop offset="0.62" stopColor="#0052CC" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
};
