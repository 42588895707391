import { BehaviorSubject, Subject } from 'rxjs';
import { monitor } from '../../monitor';

export const statuspageEventListenerSetupDialogOpenAction$ = monitor(
    'statuspageEventListenerSetupDialogOpenAction$',
    new Subject<StatuspageEventListenerSetupDetails>()
);
export const statuspageEventListenerSetupDialogCloseAction$ = monitor(
    'statuspageEventListenerSetupDialogCloseAction$',
    new Subject<void>()
);
export const statuspageEventListenerSetupDialogOpen$ = monitor(
    'statuspageEventListenerSetupDialogOpen$',
    new BehaviorSubject(false)
);
export const statuspageEventListenerSetupDialogDetails$ = monitor(
    'statuspageEventListenerSetupDialogDetails$',
    new BehaviorSubject<StatuspageEventListenerSetupDetails | undefined>(undefined)
);

export type StatuspageEventListenerSetupDetails = {
    uid: string;
    environmentUid: string;
    webhookUrl: string;
};

statuspageEventListenerSetupDialogOpenAction$.subscribe((details) => {
    statuspageEventListenerSetupDialogDetails$.next(details);
    statuspageEventListenerSetupDialogOpen$.next(true);
});

statuspageEventListenerSetupDialogCloseAction$.subscribe(() => {
    statuspageEventListenerSetupDialogOpen$.next(false);
    statuspageEventListenerSetupDialogDetails$.next(undefined);
});
