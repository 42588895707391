import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const JiraCloudIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40.343 42"
            className="productIcon"
            aria-label="Jira Cloud"
        >
            <g data-name="jiracloud">
                <path
                    fill="#2684ff"
                    d="M20.172 27.858L13.314 21l6.858-6.858V0L.586 19.586a2 2 0 000 2.828L20.172 42s3-2 3-7a11.639 11.639 0 00-3-7.142z"
                ></path>
                <path
                    fill="#1d78f2"
                    d="M14.385 19.667l.131.131 5.656-5.656V0L9.571 10.6a20.2 20.2 0 004.814 9.067z"
                ></path>
                <path
                    fill="#126ae5"
                    d="M15.832 18.285l.1.1 4.242-4.242V0l-8.868 8.866a18.21 18.21 0 004.526 9.419z"
                ></path>
                <path fill="#0b60da" d="M20.172 14.142V0l-7 7a15.546 15.546 0 004.171 9.97z"></path>
                <path
                    fill="#0154ce"
                    d="M15.172 7c0 4.746 3.407 8.371 3.585 8.556l1.415-1.414V0L15.35 4.822A13.161 13.161 0 0015.172 7z"
                ></path>
                <path
                    fill="#2482fd"
                    d="M20.172 14.142L27.029 21l-6.857 6.858V42l19.585-19.586a2 2 0 000-2.828L20.172 0s-3 2-3 7a11.639 11.639 0 003 7.142z"
                ></path>
                <path
                    fill="#1d78f2"
                    d="M25.958 22.333l-.131-.131-5.655 5.656V42l10.6-10.6a20.2 20.2 0 00-4.814-9.067z"
                ></path>
                <path
                    fill="#126ae5"
                    d="M24.511 23.715l-.1-.1-4.241 4.242V42l8.866-8.866a18.216 18.216 0 00-4.525-9.419z"
                ></path>
                <path fill="#0b60da" d="M20.172 27.858V42l7-7A15.545 15.545 0 0023 25.03z"></path>
                <path
                    fill="#0154ce"
                    d="M25.172 35c0-4.746-3.407-8.371-3.586-8.556l-1.414 1.414V42l4.822-4.822A13.27 13.27 0 0025.172 35z"
                ></path>
                <path
                    fill="#2684ff"
                    d="M20.172 27.858L13.314 21H2.172v3l18 18s3-2 3-7a11.639 11.639 0 00-3-7.142z"
                ></path>
            </g>
        </SvgIcon>
    );
};
