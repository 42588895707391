import { LoaderFn } from 'react-location';
import {
    getOrganizationBillingEntities,
    getOrganizationPlan,
    getOrganizationPlanCustomerPortalUrl,
} from '../../data/organization';
import { LocationGenerics } from '../../router';
import {
    organizationBillingDetails$,
    organizationBillingDetailsCustomerPortal$,
    selectedOrganizationPlan$,
} from '../../store/organization';
import { loadErrorPage } from '../../store/error';

export const organizationBillingDetailsLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const orgUid = routeMatch.params.teamUid!;
    try {
        const [plan, billingDetails] = await Promise.all([
            getOrganizationPlan({ organizationUid: orgUid, billingDetails: true }),
            getOrganizationBillingEntities(orgUid),
        ]);

        let customerPortalUrl: string | undefined = undefined;

        if (plan.paymentType === 'CARD') {
            try {
                customerPortalUrl = (await getOrganizationPlanCustomerPortalUrl(orgUid)).url;
            } catch {
                customerPortalUrl = undefined;
            }
        }

        selectedOrganizationPlan$.next(plan);
        organizationBillingDetails$.next(billingDetails);
        organizationBillingDetailsCustomerPortal$.next(customerPortalUrl);
    } catch (e) {
        loadErrorPage({
            error: e,
            background: 'paper',
            genericMessage: 'Failed to load team billing details.',
        });
        throw e;
    }
    return {};
};
