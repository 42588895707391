import { BehaviorSubject, map, Subject } from 'rxjs';
import { connectionCreatedAction$, connectionCreatedFirstTimeAction$, connectionCreatedFirstTime$ } from '..';
import { createConnection, saveConnection } from '../../../data/connection';
import { InformativeError } from '../../../utils/repository';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import {
    handleManageConnectionError,
    loadLoggedInUserConnectionsWhenEventIsEmitted,
    saveConnectionAllowed,
} from '../utils';
import { saveHeaders } from './save';
import { GenericAppConnectionDetails, GenericAppSetupSaveProps } from './types';
import { promptQuestion } from '../../confirm';
import { AYSYWTSAETCWAH } from '../../../i18n';

export const genericAppManageConnectionOpenDialogAction$ = monitor(
    'genericAppManageConnectionOpenDialogAction$',
    new Subject<GenericAppConnectionDetails>()
);
export const genericAppManageConnectionCloseDialogAction$ = monitor(
    'genericAppManageConnectionCloseDialogAction$',
    new Subject<void>()
);

export const genericAppSetupOpenDialogAction$ = monitor(
    'genericAppSetupOpenDialogAction$',
    new Subject<GenericAppConnectionDetails>()
);
export const genericAppSetupCloseDialogAction$ = monitor('genericAppSetupCloseDialogAction$', new Subject<void>());
export const genericAppSetupDialogOpen$ = monitor('genericAppSetupDialogOpen$', new BehaviorSubject(false));
export const genericAppSetupAuthorizeAction$ = monitor(
    'genericAppSetupAuthorizeAction$',
    new Subject<GenericAppSetupSaveProps>()
);
export const genericAppSetupDialogErrors$ = monitor(
    'genericAppSetupDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export const genericAppSaveConnectionAction$ = monitor('genericAppSaveConnectionAction$', new Subject<string>());
export const genericAppConnectionCreatedAction$ = monitor('genericAppConnectionCreatedAction$', new Subject<string>());
export const genericAppConnectionSavedAction$ = monitor(
    'genericAppConnectionSavedAction$',
    new Subject<{ uid: string; connectionTypeUid: string }>()
);
export const genericAppManageConnectionInitiateSetupAction$ = monitor(
    'genericAppManageConnectionInitiateSetupAction$',
    new Subject<string>()
);

export const genericAppManageConnectionDialogOpen$ = monitor(
    'genericAppManageConnectionDialogOpen$',
    new BehaviorSubject(false)
);
export const genericAppConnectionSaving$ = monitor('genericAppConnectionSaving$', new BehaviorSubject(false));
export const genericAppManageConnectionAuthorizeLoading$ = monitor(
    'genericAppManageConnectionAuthorizeLoading$',
    new BehaviorSubject(false)
);
export const genericAppManageConnectionDetails$ = monitor(
    'genericAppManageConnectionDetails$',
    new BehaviorSubject<GenericAppConnectionDetails | undefined>(undefined)
);
export const genericAppManageConnectionDialogErrors$ = monitor(
    'genericAppManageConnectionDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

genericAppSetupOpenDialogAction$.subscribe((details) => {
    genericAppSetupDialogErrors$.next(undefined);
    genericAppManageConnectionDialogErrors$.next(undefined);
    genericAppSetupDialogOpen$.next(true);
    genericAppManageConnectionDetails$.next(details);
});

genericAppSetupCloseDialogAction$.subscribe(() => {
    genericAppSetupDialogOpen$.next(false);
    genericAppManageConnectionAuthorizeLoading$.next(false);
});

genericAppManageConnectionOpenDialogAction$.subscribe((details) => {
    genericAppSetupDialogErrors$.next(undefined);
    genericAppManageConnectionDialogErrors$.next(undefined);
    genericAppManageConnectionDialogOpen$.next(true);
    genericAppManageConnectionDetails$.next(details);
});

genericAppManageConnectionCloseDialogAction$.subscribe(() => {
    genericAppManageConnectionDialogOpen$.next(false);
    genericAppSetupDialogOpen$.next(false);
    genericAppManageConnectionDetails$.next(undefined);
});

genericAppConnectionCreatedAction$.subscribe((uid) => connectionCreatedAction$.next(uid));

genericAppSetupAuthorizeAction$
    .pipe(
        map(async (appInfoProps) => {
            genericAppConnectionSaving$.next(true);
            genericAppSetupDialogErrors$.next(undefined);

            const { uid, connectionType } = genericAppManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    throw Error('Generic app connector type not defined');
                }
                if (!uid) {
                    throw Error('Generic app connector has not been created so cannot configure it');
                }

                const response = await saveHeaders({
                    ...appInfoProps,
                    connectionId: uid,
                    connectionType: 'generic',
                });

                genericAppManageConnectionDetails$.next({
                    name: response.name,
                    instanceUrl: response.instanceUrl,
                    connectionType,
                    headers: response.headers,
                    uid,
                });

                genericAppConnectionCreatedAction$.next(uid);
                genericAppConnectionSavedAction$.next({ uid, connectionTypeUid: connectionType.uid });
                genericAppSetupDialogOpen$.next(false);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Generic app has been configured.',
                });
            } catch (e) {
                if (e instanceof InformativeError) {
                    genericAppSetupDialogErrors$.next(e.message);
                } else {
                    genericAppSetupDialogErrors$.next(
                        'Failed to save Generic app application details, please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while saving Generic app application info', e);
                }
            }

            genericAppConnectionSaving$.next(false);
            genericAppManageConnectionAuthorizeLoading$.next(false);
        })
    )
    .subscribe();

genericAppSaveConnectionAction$
    .pipe(
        map(async (name) => {
            genericAppConnectionSaving$.next(true);
            genericAppManageConnectionDialogErrors$.next(undefined);

            const { uid, connectionType } = genericAppManageConnectionDetails$.value ?? {};

            try {
                if (!connectionType) {
                    throw Error('Generic app connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (saveConnectionAllowed(uid)) {
                        await saveGenericAppConnection(uid, name, connectionTypeUid);
                    } else {
                        promptQuestion({
                            message: AYSYWTSAETCWAH,
                            onProceed: async () => {
                                await saveGenericAppConnection(uid, name, connectionTypeUid);
                            },
                        });
                    }
                } else {
                    promptQuestion({
                        message: AYSYWTSAETCWAH,
                        onProceed: async () => {
                            try {
                                const { uid } = await createConnection(
                                    connectionType.uid,
                                    name,
                                    connectionType.apiHandlerTypes[0]?.uid ?? '',
                                    connectionType.eventListenerTypes[0]?.uid ?? ''
                                );

                                genericAppConnectionCreatedAction$.next(uid);
                                genericAppConnectionSavedAction$.next({ uid, connectionTypeUid });
                                genericAppManageConnectionCloseDialogAction$.next();
                                publishLocalFeedbackEventAction$.next({
                                    level: 'SUCCESS',
                                    message: 'Connector created.',
                                });
                                connectionCreatedFirstTimeAction$.next();
                            } catch (e) {
                                handleManageConnectionError(e, genericAppManageConnectionDialogErrors$, 'GenericApp');
                            }
                        },
                    });
                }
            } catch (e) {
                handleManageConnectionError(e, genericAppManageConnectionDialogErrors$, 'GenericApp');
            }

            genericAppConnectionSaving$.next(false);
        })
    )
    .subscribe();

genericAppManageConnectionInitiateSetupAction$
    .pipe(
        map(async (name) => {
            genericAppConnectionSaving$.next(true);
            genericAppManageConnectionAuthorizeLoading$.next(true);
            genericAppManageConnectionDialogErrors$.next(undefined);

            const {
                uid,
                connectionType,
                name: currentName,
                ...restOfDetails
            } = genericAppManageConnectionDetails$.value ?? {};
            try {
                if (!connectionType) {
                    throw Error('Generic app connector type not defined');
                }
                const connectionTypeUid = connectionType.uid;

                if (uid) {
                    if (currentName !== name) {
                        await saveConnection(uid, name);
                    }

                    genericAppConnectionSavedAction$.next({ uid, connectionTypeUid });
                    genericAppSetupOpenDialogAction$.next({
                        ...restOfDetails,
                        uid,
                        connectionType,
                        name,
                    });
                } else {
                    const { uid } = await createConnection(
                        connectionType.uid,
                        name,
                        connectionType.apiHandlerTypes[0]?.uid ?? '',
                        connectionType.eventListenerTypes[0]?.uid ?? ''
                    );

                    genericAppConnectionCreatedAction$.next(uid);
                    genericAppConnectionSavedAction$.next({ uid, connectionTypeUid });

                    genericAppManageConnectionDetails$.next({
                        connectionType,
                        name,
                        uid,
                    });

                    genericAppConnectionSaving$.next(false);

                    genericAppSetupOpenDialogAction$.next({
                        connectionType,
                        name,
                        uid,
                    });
                    connectionCreatedFirstTime$.next(true);
                }
            } catch (e) {
                handleManageConnectionError(e, genericAppManageConnectionDialogErrors$, 'GenericApp');
                genericAppManageConnectionAuthorizeLoading$.next(false);
            }

            genericAppConnectionSaving$.next(false);
        })
    )
    .subscribe();

loadLoggedInUserConnectionsWhenEventIsEmitted(genericAppConnectionSavedAction$);

const saveGenericAppConnection = async (uid: string, name: string, connectionTypeUid: string): Promise<void> => {
    try {
        await saveConnection(uid, name);

        genericAppConnectionSavedAction$.next({ uid, connectionTypeUid });
        genericAppManageConnectionCloseDialogAction$.next();
        publishLocalFeedbackEventAction$.next({
            level: 'SUCCESS',
            message: 'Connector saved.',
        });
        if (connectionCreatedFirstTime$.value) {
            connectionCreatedFirstTimeAction$.next();
            connectionCreatedFirstTime$.next(false);
        }
    } catch (e) {
        handleManageConnectionError(e, genericAppManageConnectionDialogErrors$, 'GenericApp');
    }
};
