import { styled } from '@mui/system';
import React from 'react';

const Centered = styled('div')(() => ({
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    '& img': {
        height: 110,
        width: 110,
    },
}));

export const LoadingWizard: React.FC = () => {
    return (
        <Centered>
            <img src={process.env.PUBLIC_URL + '/wizard-wave-slow.gif'} alt="Loading Wizard" />
        </Centered>
    );
};
