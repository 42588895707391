import { BehaviorSubject, map, Subject } from 'rxjs';
import { getEventListenerSetupDetails } from '../../../data/event-listener';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';

export const serviceNowEventListenerSetupDialogOpenAction$ = monitor(
    'serviceNowEventListenerSetupDialogOpenAction$',
    new Subject<ServiceNowEventListenerSetupBaseDetails>()
);
export const serviceNowEventListenerSetupDialogCloseAction$ = monitor(
    'serviceNowEventListenerSetupDialogCloseAction$',
    new Subject<void>()
);

export const serviceNowEventListenerSetupDialogLoading$ = monitor(
    'serviceNowEventListenerSetupDialogLoading$',
    new BehaviorSubject(false)
);
export const serviceNowEventListenerSetupDialogOpen$ = monitor(
    'serviceNowEventListenerSetupDialogOpen$',
    new BehaviorSubject(false)
);
export const serviceNowEventListenerSetupDialogDetails$ = monitor(
    'serviceNowEventListenerSetupDialogDetails$',
    new BehaviorSubject<ServiceNowEventListenerSetupDetails | undefined>(undefined)
);

export type ServiceNowEventListenerSetupBaseDetails = {
    uid: string;
    environmentUid: string;
    webhookUrl: string;
};

export type ServiceNowEventListenerSetupDetails = {
    setupBaseUrl?: string;
} & ServiceNowEventListenerSetupBaseDetails;

serviceNowEventListenerSetupDialogOpenAction$
    .pipe(
        map(async (details) => {
            serviceNowEventListenerSetupDialogOpen$.next(true);
            serviceNowEventListenerSetupDialogLoading$.next(true);

            try {
                const { connectionBaseUrl } = await getEventListenerSetupDetails(details.uid, details.environmentUid);

                if (connectionBaseUrl) {
                    serviceNowEventListenerSetupDialogDetails$.next({
                        ...details,
                        setupBaseUrl: connectionBaseUrl,
                    });
                } else {
                    serviceNowEventListenerSetupDialogDetails$.next(details);
                }

                serviceNowEventListenerSetupDialogLoading$.next(false);
            } catch (e) {
                console.error('Error while loading Event Listener setup instructions', e);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to load Event Listener setup instructions, please try again, if the issue persists please contact support.`,
                    toastOptions: {
                        autoClose: false,
                    },
                });

                serviceNowEventListenerSetupDialogCloseAction$.next();
            }
        })
    )
    .subscribe();

serviceNowEventListenerSetupDialogCloseAction$.subscribe(() => {
    serviceNowEventListenerSetupDialogOpen$.next(false);
    serviceNowEventListenerSetupDialogDetails$.next(undefined);
});
