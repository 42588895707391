import { useObservableState } from 'observable-hooks';
import { ZoomManageConnectionDialog } from '../../../../components/connections/apps/zoom/ManageConnectionDialog';
import {
    zoomConnectionSaving$,
    zoomManageConnectionAuthorizeLoading$,
    zoomManageConnectionCloseDialogAction$,
    zoomManageConnectionDetails$,
    zoomManageConnectionDialogErrors$,
    zoomManageConnectionDialogOpen$,
    zoomManageConnectionInitiateSetupAction$,
    zoomSaveConnectionAction$,
} from '../../../../store/connection/zoom/setup-connection';
import { loggedInUserConnections$ } from '../../../../store/connections';

export const ZoomManageConnectionDialogContainer: React.FC = () => {
    const open = useObservableState(zoomManageConnectionDialogOpen$);
    const saving = useObservableState(zoomConnectionSaving$);
    const authorizeLoading = useObservableState(zoomManageConnectionAuthorizeLoading$);
    const details = useObservableState(zoomManageConnectionDetails$);
    const errors = useObservableState(zoomManageConnectionDialogErrors$);
    const connection = useObservableState(loggedInUserConnections$).find(
        (connection) => connection.uid === details?.uid
    );

    return (
        <ZoomManageConnectionDialog
            open={open}
            saving={saving}
            errors={errors}
            authorizeLoading={authorizeLoading}
            name={connection?.name ?? ''}
            authorized={!!connection?.authorized}
            onCancel={() => zoomManageConnectionCloseDialogAction$.next()}
            onSave={(name) => zoomSaveConnectionAction$.next(name)}
            onAuthorize={(name) => zoomManageConnectionInitiateSetupAction$.next(name)}
            connectionType={details?.connectionType.name}
        />
    );
};
