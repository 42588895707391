import { useObservableState } from 'observable-hooks';
import { SalesforceEventListenerSetupDialog } from '../../../../components/connections/apps/salesforce/EventListenerSetupDialog';
import { configTopic$ } from '../../../../store/config';
import {
    salesforceEventListenerSetupDialogCloseAction$,
    salesforceEventListenerSetupDialogDetails$,
    salesforceEventListenerSetupDialogLoading$,
    salesforceEventListenerSetupDialogOpen$,
} from '../../../../store/connection/salesforce/setup-event-listener';

export const SalesforceEventListenerSetupDialogContainer: React.FC = () => {
    const loading = useObservableState(salesforceEventListenerSetupDialogLoading$);
    const open = useObservableState(salesforceEventListenerSetupDialogOpen$);
    const details = useObservableState(salesforceEventListenerSetupDialogDetails$);
    const config = useObservableState(configTopic$);

    return (
        <SalesforceEventListenerSetupDialog
            loading={loading}
            open={open}
            webhookBaseUrl={config.trigger?.srcExternalUrl ?? ''}
            webhookUrlId={details?.webhookUrl ?? ''}
            setupBaseUrl={details?.setupBaseUrl}
            onClose={() => salesforceEventListenerSetupDialogCloseAction$.next()}
        />
    );
};
