// This file should be deleted when Chips get an overhaul in the theme and can be used directly
import { styled, Theme } from '@mui/material';
import Chip from '@mui/material/Chip';

type ChipType = 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'error';

const getChipBackgroundColor = (theme: Theme, type: ChipType): { backgroundColor: string; color: string } => {
    switch (type) {
        case 'secondary':
            return {
                backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800], // Change this
                color: theme.palette.text.primary,
            };
        case 'info':
            return {
                backgroundColor: theme.palette.info.main,
                color: theme.palette.info.contrastText,
            };
        case 'success':
            return {
                backgroundColor: theme.palette.success.main,
                color: theme.palette.success.contrastText,
            };
        case 'warning':
            return {
                backgroundColor: theme.palette.warning.main,
                color: theme.palette.warning.contrastText,
            };
        case 'error':
            return {
                backgroundColor: theme.palette.error.main,
                color: theme.palette.error.contrastText,
            };
        default:
        case 'primary':
            return {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
            };
    }
};

const StyledChip = styled(Chip)<{ type?: ChipType }>(({ type = 'primary', theme }) => ({
    ...getChipBackgroundColor(theme, type),
    height: 'unset',
    padding: theme.spacing(0.25, 0),
}));

export const InformationChip: React.FC<{ type: ChipType; label: string }> = ({ type, label }) => {
    return <StyledChip label={label} type={type} />;
};
