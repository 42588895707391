import { useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import DataUsageOutlinedIcon from '@mui/icons-material/DataUsageOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import {
    StyledLink,
    StyledListItemButton,
    StyledMenu,
    StyledMenuItem,
    StyledMenuItemContent,
    StyledMenuItemText,
    StyledOrganizationAvatar,
} from './SidenavComponents';

export type SidenavOrganizationSettingsSubRoute = 'details' | 'usage' | 'members' | 'plan' | 'billing';

interface SidenavOrganizationSettingsNavigatorProps {
    collapsed: boolean;
    isSelected: boolean;
    organizationName: string;
    organizationUid: string;
    ownedDefaultOrganization?: boolean;
    useRouter?: boolean;
    basePath: string;
}

export const SidenavOrganizationSettingsNavigator: React.FC<SidenavOrganizationSettingsNavigatorProps> = ({
    collapsed,
    isSelected,
    organizationName,
    organizationUid,
    ownedDefaultOrganization = false,
    useRouter = false,
    basePath,
}) => {
    const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);

    const menuItems = [
        {
            icon: (
                <StyledOrganizationAvatar ownedDefaultOrganization={ownedDefaultOrganization}>
                    {organizationName.slice(0, 2).toLocaleUpperCase()}
                </StyledOrganizationAvatar>
            ),
            label: 'General',
            value: 'details' as const,
        },
        { icon: <DataUsageOutlinedIcon />, label: 'Usage', value: 'usage' as const },
        { icon: <PeopleOutlineOutlinedIcon />, label: 'Members', value: 'members' as const },
        { icon: <StyleOutlinedIcon />, label: 'Plans', value: 'plan' as const },
        { icon: <CreditCardOutlinedIcon />, label: 'Billing', value: 'billing' as const },
    ].map((m) => (
        <StyledLink to={useRouter ? basePath + `/team/${organizationUid}/${m.value}` : null} key={m.value}>
            <StyledMenuItem onClick={() => setAnchor(null)}>
                <StyledMenuItemContent>
                    {m.icon}
                    <StyledMenuItemText>{m.label}</StyledMenuItemText>
                </StyledMenuItemContent>
            </StyledMenuItem>
        </StyledLink>
    ));

    return (
        <>
            <StyledListItemButton
                collapsed={collapsed}
                selected={!!anchor || isSelected}
                onClick={(event) => {
                    event.stopPropagation();
                    setAnchor(anchor ? null : event.currentTarget);
                }}
            >
                <Tooltip title="Team settings">
                    <SettingsOutlinedIcon />
                </Tooltip>
                <Typography>Team settings</Typography>
            </StyledListItemButton>
            <ClickAwayListener
                onClickAway={() => {
                    setAnchor(null);
                }}
            >
                <StyledMenu
                    open={!!anchor}
                    anchorEl={anchor}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={() => setAnchor(null)}
                >
                    {menuItems}
                </StyledMenu>
            </ClickAwayListener>
        </>
    );
};
