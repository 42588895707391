import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React from 'react';
import { OpsgenieConnection } from './OpsgenieAppConfigureDialog';

export enum OpsgenieWizardStage {
    CHOOSEINSTANCEREGION,
    ADDURL,
    CREATE,
    INPUT_API_KEY,
}

export interface OpsgenieWizardStepsProps {
    readonly stage: OpsgenieWizardStage;
}

export const OpsgenieWizardSteps: React.FC<OpsgenieWizardStepsProps> = ({ stage }) => {
    return (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={OpsgenieWizardStage.CHOOSEINSTANCEREGION}>
                <StepLabel>Add Opsgenie instance region</StepLabel>
            </Step>
            <Step key={OpsgenieWizardStage.ADDURL}>
                <StepLabel>Add Opsgenie instance url</StepLabel>
            </Step>
            <Step key={OpsgenieWizardStage.CREATE}>
                <StepLabel>Create API Integration in Opsgenie</StepLabel>
            </Step>
            <Step key={OpsgenieWizardStage.INPUT_API_KEY}>
                <StepLabel>Enter API Key</StepLabel>
            </Step>
        </Stepper>
    );
};

export type StageType = React.FC<
    OpsgenieConnection & {
        stage: OpsgenieWizardStage;
        setStage: (stage: OpsgenieWizardStage) => void;
    }
>;
