import { useObservableState } from 'observable-hooks';
import { selectedOrganizationPlan$, selectedOrganizationUid$ } from '../../../store/organization';
import {
    cancelOrganizationPlanAction$,
    closeCancelOrganizationPlanFlowDialogAction$,
    cancelOrganizationPlanFlowDialogOpen$,
    cancelOrganizationPlanFlowDialogError$,
    cancelOrganizationPlanFlowDialogSaving$,
} from '../../../store/organization/cancelOrganizationPlan';
import { supportPortalUrl } from '../../../utils/documentation';
import { CancelOrganizationPlanConfirmationDialog } from '../../../components/organizations/plan/cancel-organization-plan/CancelOrganizationPlanConfirmationDialog';

export const CancelOrganizationPlanContainer: React.FC = () => {
    const plan = useObservableState(selectedOrganizationPlan$);
    const orgUid = useObservableState(selectedOrganizationUid$);
    const open = useObservableState(cancelOrganizationPlanFlowDialogOpen$);
    const saving = useObservableState(cancelOrganizationPlanFlowDialogSaving$);
    const errors = useObservableState(cancelOrganizationPlanFlowDialogError$);

    if (!plan) {
        return null;
    }

    const handelProceed = (): void => {
        if (orgUid) {
            cancelOrganizationPlanAction$.next({ organizationUid: orgUid });
        }
    };

    return (
        <CancelOrganizationPlanConfirmationDialog
            open={open}
            saving={saving}
            currentPlan={plan}
            planEndDate={plan.endDate}
            errors={errors}
            supportPortalUrl={supportPortalUrl}
            onCancel={() => closeCancelOrganizationPlanFlowDialogAction$.next()}
            onProceed={handelProceed}
        />
    );
};
