import { useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Typography from '@mui/material/Typography';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined';
import {
    StyledLink,
    StyledListItemButton,
    StyledMenu,
    StyledMenuItem,
    StyledMenuItemContent,
} from './SidenavComponents';

interface SidenavReportingNavigatorProps {
    collapsed: boolean;
    isSelected: boolean;
    useRouter?: boolean;
    basePath: string;
}

export const SidenavReportingNavigator: React.FC<SidenavReportingNavigatorProps> = ({
    collapsed,
    isSelected,
    useRouter = false,
    basePath,
}) => {
    const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);

    const menuItems = [
        <StyledLink to={useRouter ? basePath + '/reporting' : null} key="invocationLogs">
            <StyledMenuItem onClick={() => setAnchor(null)}>
                <StyledMenuItemContent>
                    <QueryStatsOutlinedIcon />
                    Script Invocation Logs
                </StyledMenuItemContent>
            </StyledMenuItem>
        </StyledLink>,
        <StyledLink to={useRouter ? basePath + '/auditLogs' : null} key="auditLogs">
            <StyledMenuItem onClick={() => setAnchor(null)}>
                <StyledMenuItemContent>
                    <AssessmentOutlinedIcon />
                    Audit Logs
                </StyledMenuItemContent>
            </StyledMenuItem>
        </StyledLink>,
    ];

    return (
        <>
            <StyledListItemButton
                collapsed={collapsed}
                selected={!!anchor || isSelected}
                onClick={(event) => {
                    event.stopPropagation();
                    setAnchor(anchor ? null : event.currentTarget);
                }}
            >
                <TroubleshootOutlinedIcon />
                <Typography>Reporting</Typography>
            </StyledListItemButton>
            <ClickAwayListener
                onClickAway={() => {
                    setAnchor(null);
                }}
            >
                <StyledMenu
                    open={!!anchor}
                    anchorEl={anchor}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={() => setAnchor(null)}
                >
                    {menuItems}
                </StyledMenu>
            </ClickAwayListener>
        </>
    );
};
