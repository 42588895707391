import type { IDisposable } from 'monaco-editor';
import React, { useEffect, useRef } from 'react';
import { useEditor } from './useEditor';
import { useMonaco } from './useMonaco';

interface SetModelProps {
    readonly scriptName?: string;
    readonly readmeFileName?: string;
}

/**
 * Set the active model for the editor
 */
export const SetModel: React.FC<SetModelProps> = ({ scriptName, readmeFileName }) => {
    const monaco = useMonaco();
    const editor = useEditor();

    const modelEventRef = useRef<IDisposable>();

    useEffect(() => {
        if (monaco && editor) {
            const fileName = readmeFileName ?? `/${scriptName}.ts`;
            const uri = monaco.Uri.file(fileName);
            const model = monaco.editor.getModel(uri);

            if (!model) {
                // Listen for the model being registered with Monaco
                modelEventRef.current?.dispose();
                modelEventRef.current = monaco.editor.onDidCreateModel(() => {
                    const model = monaco.editor.getModel(uri);
                    if (model) {
                        editor.setModel(model);
                        modelEventRef.current?.dispose();
                        modelEventRef.current = undefined;
                    }
                });
            } else {
                editor.setModel(model);
            }
        }

        return () => {
            modelEventRef.current?.dispose();
            modelEventRef.current = undefined;
        };
    }, [monaco, editor, scriptName, readmeFileName]);

    return null;
};
