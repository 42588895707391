import React from 'react';
import { styled, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import DirectionsOutlinedIcon from '@mui/icons-material/DirectionsOutlined';
import { Button } from '../../common/buttons/Button';
import { stepByStepSetupDialogStyles } from '../../for-deprecation/dialog/dialogStyles';
import { IconCircle } from '../../for-deprecation/IconCircle';
import { DialogCloseButton } from '../../for-deprecation/dialog/DialogComponents';
import { handleKeyDown } from '../../../utils/handleKeyDown';

export interface StartWizardDialogProps {
    open?: boolean;
    onClose(): void;
    onStart(): void;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    ...stepByStepSetupDialogStyles(theme),
}));

const StyledTitleContainer = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
}));

export const StartWizardDialog: React.FC<StartWizardDialogProps> = ({ open = false, onClose, onStart }) => {
    const theme = useTheme();
    return (
        <StyledDialog open={open} onKeyDown={(event) => handleKeyDown({ event, enterFn: onStart, escFn: onClose })}>
            <DialogCloseButton onClick={onClose} />
            <StyledTitleContainer>
                <IconCircle icon={<DirectionsOutlinedIcon />} background={theme.palette.background.paper} />
                <DialogTitle variant="h6">Let's create your first Workspace</DialogTitle>
            </StyledTitleContainer>
            <DialogContent>
                <Typography>We'll have you up and running in no time.</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onStart}>Start</Button>
            </DialogActions>
        </StyledDialog>
    );
};
