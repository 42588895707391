import { useObservableState } from 'observable-hooks';
import { HelpAndSupportDialog } from '../../components/app-main/side-navigation/HelpAndSupportDialog';
import { closeHelpAndSupportModalAction$, helpAndSupportModalOpen$ } from '../../store/helpAndSupport';
import {
    documentationUrl,
    feedbackBoardUrl,
    requestScriptingHelpUrl,
    slackCommunityUrl,
    supportPortalUrl,
    // youtubeChannelUrl,
} from '../../utils/documentation';
import { loggedInUserDetails$ } from '../../store/user';
import { segmentAnalyticsTrack } from '../../data/segment-analytics';

export const HelpAndSupportContainer: React.FC = () => {
    const open = useObservableState(helpAndSupportModalOpen$);
    const userDetails = useObservableState(loggedInUserDetails$);

    return (
        <HelpAndSupportDialog
            open={open}
            onClose={() => closeHelpAndSupportModalAction$.next()}
            content={[
                {
                    title: 'Slack community',
                    description: 'Stay up to date with the latest announcements, and benefit from premium support.',
                    link: slackCommunityUrl,
                    linkText: 'Join Slack',
                },
                {
                    title: 'Feedback board',
                    description:
                        'Help us identify what we should build next. Vote on existing ideas or suggest new ones!',
                    link: feedbackBoardUrl,
                    linkText: 'See Nolt',
                    onClick: () =>
                        segmentAnalyticsTrack('Feedback Accessed', {
                            userId: userDetails?.uid,
                            stitchTeamMember: userDetails?.stitchTeamMember,
                            userOrigin: userDetails?.userOrigin,
                        }),
                },
                {
                    title: 'Support portal',
                    description: "Report issues, make suggestions, and more. Whatever you need, we're here to help!",
                    link: supportPortalUrl,
                    linkText: 'Contact us',
                    onClick: () =>
                        segmentAnalyticsTrack('Support Accessed', {
                            userId: userDetails?.uid,
                            stitchTeamMember: userDetails?.stitchTeamMember,
                            userOrigin: userDetails?.userOrigin,
                        }),
                },
                {
                    title: 'ScriptRunner Connect product documentation',
                    description: 'A growing collection of technical and how to docs to help you use the app.',
                    link: documentationUrl,
                    linkText: 'Visit documentation',
                    onClick: () =>
                        segmentAnalyticsTrack('Documentation Accessed', {
                            userId: userDetails?.uid,
                            stitchTeamMember: userDetails?.stitchTeamMember,
                            userOrigin: userDetails?.userOrigin,
                        }),
                },
                {
                    title: 'Scripting help',
                    description: 'Our team of experts are here to assist you with all your scripting needs.',
                    link: requestScriptingHelpUrl,
                    linkText: 'Contact us',
                    onClick: () =>
                        segmentAnalyticsTrack('ScriptingSupport Requested', {
                            userId: userDetails?.uid,
                            stitchTeamMember: userDetails?.stitchTeamMember,
                            userOrigin: userDetails?.userOrigin,
                        }),
                },
            ]}
        />
    );
};
