import { useObservableState } from 'observable-hooks';
import { ReadOnlyScheduledTriggerDetails } from '../../../components/workspace-resources/scheduled-triggers/ReadOnlyScheduledTriggerDetails';
import { selectedWorkspaceResources$ } from '../../../store/workspace';
import { selectedScheduledTriggerDetails$ } from '../../../store/workspace/scheduled-trigger';

export const ReadOnlyScheduledTriggerDetailsContainer: React.FC = () => {
    const details = useObservableState(selectedScheduledTriggerDetails$);
    const workspaceResources = useObservableState(selectedWorkspaceResources$);

    return (
        <ReadOnlyScheduledTriggerDetails
            cronExpression={details?.cronExpression}
            scripts={workspaceResources.scripts}
            selectedScriptUid={details?.scriptUid}
            nextScheduledDates={[]}
        />
    );
};
