import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const ConfluenceIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            className="productIcon"
            aria-label="Confluence"
        >
            <linearGradient
                id="~hhCEjI~ovLo7bTsUTFpDa"
                x1="2"
                x2="44"
                y1="34.5"
                y2="34.5"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stopColor="#2684ff"></stop>
                <stop offset="0.28" stopColor="#1f7bf6"></stop>
                <stop offset="0.742" stopColor="#0c62dd"></stop>
                <stop offset="0.994" stopColor="#0052cc"></stop>
            </linearGradient>
            <path
                fill="url(#~hhCEjI~ovLo7bTsUTFpDa)"
                d="M3.589 35.049c-.453.738-.962 1.595-1.394 2.277a1.392 1.392 0 00.467 1.894l9.063 5.572a1.396 1.396 0 001.919-.453l.012-.02c.363-.606.83-1.393 1.338-2.236 3.59-5.92 7.201-5.196 13.713-2.089l8.986 4.269a1.394 1.394 0 001.875-.696l4.315-9.75a1.393 1.393 0 00-.697-1.825c-1.896-.891-5.668-2.667-9.063-4.304-12.214-5.928-22.594-5.545-30.534 7.361z"
            ></path>
            <linearGradient
                id="~hhCEjI~ovLo7bTsUTFpDb"
                x1="4"
                x2="46"
                y1="12.5"
                y2="12.5"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stopColor="#0052cc"></stop>
                <stop offset="0.044" stopColor="#0255cf"></stop>
                <stop offset="0.665" stopColor="#1c77f2"></stop>
                <stop offset="1" stopColor="#2684ff"></stop>
            </linearGradient>
            <path
                fill="url(#~hhCEjI~ovLo7bTsUTFpDb)"
                d="M44.412 11.968c.453-.737.961-1.593 1.393-2.274a1.39 1.39 0 00-.467-1.892l-9.057-5.564a1.394 1.394 0 00-1.985.459c-.362.605-.829 1.391-1.338 2.233-3.588 5.912-7.197 5.189-13.703 2.087l-8.952-4.243a1.395 1.395 0 00-1.857.658l-.017.037-4.312 9.738a1.39 1.39 0 00.697 1.822c1.895.89 5.664 2.664 9.057 4.299 12.233 5.911 22.606 5.515 30.541-7.36z"
            ></path>
        </SvgIcon>
    );
};
