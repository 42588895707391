/* eslint-disable sonarjs/cognitive-complexity */
import { useEffect, useState } from 'react';
import { styled, Theme, useTheme } from '@mui/material/styles';
import { Button } from '../../../common/buttons/Button';
import { DialogAlert } from '../../../for-deprecation/dialog/DialogComponents';
import { CenteredLoadingSpinner } from '../../../common/loading/CenteredLoadingSpinner';
import { Dropdown } from '../../../common/dropdown/Dropdown';
import Editor from '@monaco-editor/react';

interface EventListenerPayload {
    uid: string;
    name: string;
}

interface EventListenerPayloadWithContent {
    uid: string;
    content: string;
}

export interface SaveEventListenerPayloadEvent extends EventListenerPayloadWithContent {
    feedbackMessage: string;
}

interface EventListenerPayloadsProps {
    activePayloadUid?: string;
    content: string;
    errors?: string;
    eventListenerPayloads?: EventListenerPayload[];
    hasUnsavedChanges?: boolean;
    loadingContent?: boolean;
    requiresSetup?: boolean;
    saving?: boolean;
    selectedPayloadUid: string;
    workspaceLocked?: boolean;
    onCancel(): void;
    onContentChange(payload: EventListenerPayloadWithContent): void;
    onCreateNewPayload(): void;
    onSave(payload: SaveEventListenerPayloadEvent): void;
    onSelectTestPayload(uid: string): void;
}

const iconBorder = (theme: Theme) => `2px dotted ${theme.palette.text.primary}` as const;

const StyledFlexButtonWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    paddingTop: theme.spacing(3),
    justifyContent: 'flex-end',
    '&>button:last-of-type': {
        marginLeft: theme.spacing(2),
    },
}));

const StyledDropdownDiv = styled('div')(({ theme }) => ({
    padding: theme.spacing(1, 0),
}));

export const StyledWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '& section': {
        backgroundColor: theme.palette.background.default,
        border: `1px solid ${theme.palette.action.focus}`,
        borderRadius: '4px',
        flexGrow: 1,
        overflow: 'hidden',
        padding: theme.spacing(2),
        '& .slider': {
            backgroundColor: `${theme.palette.action.active} !important`,
            borderRadius: '2px',
            position: 'absolute',
            '&:hover': {
                backgroundColor: `${theme.palette.text.disabled} !important`,
            },
        },
        '& .scroll-decoration': {
            display: 'none',
        },
        '& .monaco-editor-background, .margin, .minimap, .canvas, .scrollbar, .monaco-editor': {
            backgroundColor: theme.palette.background.default + ' !important',
        },
        '& .vertical.scrollbar': {
            width: `${theme.spacing(1.75)} !important`,
        },
        '& .horizontal.scrollbar': {
            height: `${theme.spacing(1.75)} !important`,
        },
        '& .vertical>.slider': {
            width: `${theme.spacing(1.75)} !important`,
            '&:after': {
                borderLeft: iconBorder,
                borderRight: iconBorder,
                content: '""',
                height: '10px',
                left: '50%',
                position: 'absolute',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                width: '7px',
            },
        },
        '& .horizontal>.slider': {
            height: `${theme.spacing(1.75)} !important`,
            '&:after': {
                borderBottom: iconBorder,
                borderTop: iconBorder,
                content: '""',
                height: '7px',
                left: '50%',
                position: 'absolute',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                width: '10px',
            },
        },
    },
}));

const StyledEditorLoaderDiv = styled('div')(() => ({
    height: '50%',
    width: '70%',
}));

export const EventListenerPayloads: React.FC<EventListenerPayloadsProps> = ({
    content,
    activePayloadUid,
    errors,
    eventListenerPayloads = [],
    hasUnsavedChanges,
    loadingContent = false,
    requiresSetup = false,
    saving = false,
    selectedPayloadUid,
    workspaceLocked = false,
    onCancel,
    onContentChange,
    onCreateNewPayload,
    onSave,
    onSelectTestPayload,
}) => {
    const [currentContent, setCurrentContent] = useState(content);

    const darkTheme = useTheme().palette.mode === 'dark';

    const canSelect = selectedPayloadUid !== activePayloadUid;
    const canSave = !!currentContent && !saving && !workspaceLocked && (hasUnsavedChanges || canSelect);

    const feedbackMessage =
        canSelect && hasUnsavedChanges
            ? 'saved and selected.'
            : canSelect && !hasUnsavedChanges
            ? 'selected.'
            : 'saved.';

    const selectMessage =
        'Select this test event payload to use it when emulating the event in the current environment.';
    const saveMessage =
        'Save this updated test event payload to use it when emulating the event in the current environment.';
    const saveAndSelectMessage =
        'Save and select this updated test event payload to use it when emulating the event in the current environment.';
    const saveButtonText = canSelect && hasUnsavedChanges ? 'Save & Select' : canSelect ? 'Select' : 'Save';

    const handleSelect = (uid: string): void => {
        onSelectTestPayload(uid);
    };

    //Going through local state here to prevent monaco from emitting content-related events before the whole component is rendered
    useEffect(() => {
        onContentChange({ uid: selectedPayloadUid, content: currentContent ?? '' });
    }, [currentContent]);

    return (
        <StyledWrapper>
            <>
                {requiresSetup ? (
                    <DialogAlert
                        severity="info"
                        alertTitle="Information"
                        text="You must save the Event Listener first before you can start configuring Test Event Payloads."
                    />
                ) : (
                    <>
                        {errors && <DialogAlert severity="error" alertTitle="Error" text={errors} />}
                        {canSelect && hasUnsavedChanges && (
                            <DialogAlert severity="info" alertTitle="Information" text={saveAndSelectMessage} />
                        )}
                        {canSelect && !hasUnsavedChanges && (
                            <DialogAlert severity="info" alertTitle="Information" text={selectMessage} />
                        )}
                        {!canSelect && hasUnsavedChanges && (
                            <DialogAlert severity="info" alertTitle="Information" text={saveMessage} />
                        )}
                        <StyledDropdownDiv>
                            <Dropdown
                                disabled={workspaceLocked}
                                items={eventListenerPayloads.map((p) => ({
                                    value: p.uid,
                                    name: p.uid === activePayloadUid ? `${p.name} (default)` : p.name,
                                }))}
                                label="Test Event Payload"
                                selectedItem={selectedPayloadUid}
                                size="small"
                                onCreateNew={onCreateNewPayload}
                                onSelectItem={handleSelect}
                            />
                        </StyledDropdownDiv>
                        {loadingContent ? (
                            <StyledEditorLoaderDiv>
                                <CenteredLoadingSpinner />
                            </StyledEditorLoaderDiv>
                        ) : (
                            <>
                                <Editor
                                    theme={darkTheme ? 'vs-dark' : 'vs'}
                                    options={{
                                        minimap: { enabled: false },
                                        readOnly: workspaceLocked,
                                    }}
                                    language="json"
                                    defaultValue=""
                                    value={content}
                                    onChange={(editorContent) => setCurrentContent(editorContent ?? '')}
                                />

                                <StyledFlexButtonWrapper>
                                    <Button onClick={onCancel} variant="outlined" disabled={workspaceLocked}>
                                        Cancel
                                    </Button>
                                    <Button
                                        busy={saving}
                                        disabled={!canSave}
                                        onClick={() => onSave({ uid: selectedPayloadUid, content, feedbackMessage })}
                                    >
                                        {saveButtonText}
                                    </Button>
                                </StyledFlexButtonWrapper>
                            </>
                        )}
                    </>
                )}
            </>
        </StyledWrapper>
    );
};
