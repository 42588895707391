import { styled, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Button } from '../common/buttons/Button';
import { IconCircle } from '../for-deprecation/IconCircle';
import { DialogTitleMain } from '../for-deprecation/dialog/DialogComponents';
import { handleKeyDown } from '../../utils/handleKeyDown';

const StyledConsent = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    lineHeight: '30px',
    margin: theme.spacing(-0.5, 0, 0, 0.5),
}));

const StyledConsentBox = styled('div')(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(3),
}));

interface VerifyAccountDialogProperties {
    onRetry(): void;
    onCancel(): void;
    isRetryBusy?: boolean;
}

export const VerifyAccountDialog: React.FC<VerifyAccountDialogProperties> = ({
    onRetry,
    onCancel,
    isRetryBusy = false,
}) => {
    const theme = useTheme();
    return (
        <Dialog open={true} onKeyDown={(event) => handleKeyDown({ event, enterFn: onRetry, escFn: onCancel })}>
            <DialogTitleMain
                title="Email Verification Required"
                icon={<IconCircle icon={<WarningAmberIcon />} color={theme.palette.warning.light} />}
            />
            <DialogContent>
                <StyledConsentBox>
                    <StyledConsent>
                        Please check your email and verify your new account then click <strong>Done</strong> below.
                    </StyledConsent>
                </StyledConsentBox>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} variant="outlined">
                    Cancel
                </Button>

                <Button busy={isRetryBusy} onClick={onRetry} variant="contained" color="primary">
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
};
