import { LoaderFn } from 'react-location';
import { LocationGenerics } from '../../router';
import { getDiscountCodeDetailsReport } from '../../data/discountCodeDetails';
import { discountCodeDetails$ } from '../../store/discountCode';

export const discountCodeDetailsLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    if (routeMatch.params.uid) {
        const discountCountUid = routeMatch.params.uid;
        const details = await getDiscountCodeDetailsReport({ discountCountUid });

        discountCodeDetails$.next(details);
    }
    return {};
};
