import { LoaderFn } from 'react-location';
import { getAnonymousUsers, AnonymousUsersReportRequest } from '../../data/report/users';
import { LocationGenerics } from '../../router';
import { anonymousUsers$ } from '../../store/report/users';

export const anonymousUsersReportLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    const anonymousUsers = await getAnonymousUsers({
        referrerSite: routeMatch.search.referrerSite as string,
        referrerSiteComparator: routeMatch.search
            .referrerSiteComparator as AnonymousUsersReportRequest['referrerSiteComparator'],
        ip: routeMatch.search.ip as string,
        origin: routeMatch.search.origin as string,
        utmMedium: routeMatch.search.utmMedium as string,
        utmSource: routeMatch.search.utmSource as string,
        utmCampaign: routeMatch.search.utmCampaign as string,
        orderByField: routeMatch.search.orderByField as AnonymousUsersReportRequest['orderByField'],
        orderByDirection: routeMatch.search.orderByDirection as AnonymousUsersReportRequest['orderByDirection'],
    });

    anonymousUsers$.next(anonymousUsers);

    return {};
};
