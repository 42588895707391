/* eslint-disable sonarjs/cognitive-complexity */
import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { Button } from '../../common/buttons/Button';
import { DialogAlert, DialogTitleMain } from '../../for-deprecation/dialog/DialogComponents';
import { Dropdown } from '../../common/dropdown/Dropdown';
import { InfoIcon } from '../../icons/InfoIcon';
import { PageContainer } from '../../app-main/PageComponents';
import { WorkspaceEnvironments } from '../../../data/workspace';

export interface VendorApiVersion {
    deprecated: boolean;
    name: string;
    recommended: boolean;
    value: string;
}

export interface ApiConnectionDetailsProps {
    connections?: {
        authorized?: boolean;
        icon: JSX.Element;
        name: string;
        value: string;
    }[];
    createdConnectionUid?: string;
    errors?: string;
    hasImplicitlySharedConnectionAttached?: boolean;
    path?: string;
    selectedEnvironment?: WorkspaceEnvironments[number];
    remnantEnvironments?: {
        environmentName: string;
        deploymentVersion: string;
    }[];
    saving?: boolean;
    selectedConnectionUid?: string;
    uid: string;
    vendorApiVersions?: VendorApiVersion[];
    wizardWarning?: boolean;
    workspaceLocked?: boolean;
    selectedApiHandlerLibraryUid?: string;
    onCancel: () => void;
    onNewConnection: () => void;
    onSave: (saveEvent: SaveApiConnectionEvent) => void;
    onWizardWarningClose: () => void;
}

export interface SaveApiConnectionEvent {
    apiHandlerLibraryUid?: string;
    connectionUid?: string;
    path?: string;
    uid: string;
}

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
    marginLeft: theme.spacing(1.5),
}));

const StyledInnerContainer = styled('div')(({ theme }) => ({
    maxWidth: '340px',
    paddingTop: theme.spacing(1),
}));

const StyledInputAdornment = styled(InputAdornment)({
    height: 20.125,
    lineHeight: 1.5,
    marginRight: 0,
    padding: 0,
    '& p': {
        alignItems: 'center',
        display: 'flex',
        height: 20.125,
    },
});

const StyledInputContainer = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
}));

export const ApiConnectionDetails: React.FC<ApiConnectionDetailsProps> = ({
    connections = [],
    createdConnectionUid,
    errors,
    hasImplicitlySharedConnectionAttached = false,
    path,
    remnantEnvironments = [],
    saving = false,
    selectedConnectionUid,
    selectedEnvironment,
    uid,
    vendorApiVersions = [],
    wizardWarning = false,
    workspaceLocked = false,
    selectedApiHandlerLibraryUid,
    onCancel,
    onNewConnection,
    onSave,
    onWizardWarningClose,
}) => {
    const [currentSelectedConnectionUid, setCurrentSelectedConnectionUid] = useState<string | undefined>(
        createdConnectionUid ?? selectedConnectionUid
    );
    const [currentSelectedApiHandlerLibraryUid, setCurrentSelectedApiHandlerLibraryUid] = useState<string | undefined>(
        selectedApiHandlerLibraryUid
    );
    const [currentPath, setCurrentPath] = useState('');

    useEffect(() => setCurrentPath(path ?? ''), [path]);
    useEffect(() => setCurrentSelectedConnectionUid(createdConnectionUid), [createdConnectionUid]);
    useEffect(() => setCurrentSelectedConnectionUid(selectedConnectionUid), [selectedConnectionUid]);
    useEffect(
        () => setCurrentSelectedApiHandlerLibraryUid(selectedApiHandlerLibraryUid),
        [selectedApiHandlerLibraryUid]
    );

    const foundConnection = connections.find((con) => con.value === currentSelectedConnectionUid);
    const isConnectionUnauthorized = !!foundConnection && !foundConnection.authorized;

    const remnantEnvironment = remnantEnvironments
        .map(({ environmentName, deploymentVersion }) => `${environmentName} (${deploymentVersion})`)
        .join(', ');

    const multipleApiVersions = vendorApiVersions.length > 1;
    const selectedLibrary = vendorApiVersions.find((v) => v.value === currentSelectedApiHandlerLibraryUid);
    const defaultLibraryUid = vendorApiVersions.length === 1 ? vendorApiVersions[0]?.value : '';
    const existingRecommendedApiVersion = vendorApiVersions.some((v) => v.recommended);

    const hasUnsavedChanges =
        currentSelectedConnectionUid !== selectedConnectionUid ||
        currentPath !== path ||
        currentSelectedApiHandlerLibraryUid !== selectedApiHandlerLibraryUid;

    const canSave =
        !!currentSelectedConnectionUid &&
        !!currentPath &&
        !workspaceLocked &&
        !saving &&
        hasUnsavedChanges &&
        (!multipleApiVersions || !!currentSelectedApiHandlerLibraryUid);

    const PATH_TOOLTIP =
        'Path is a unique identifier and is also used to import API Connection in scripts for consumption.';
    const CONNECTION_TOOLTIP =
        'Connector is used to make sure proper authentication headers are passed along when you use API Connection.';
    const API_VERSION_TOOLTIP = 'Choose the Vendor API Version you want the Connector to use.';
    const USES_CONNECTION_TOOLTIP =
        connections && connections.find((c) => c.value === currentSelectedConnectionUid)?.name;

    const environmentDeployed = selectedEnvironment?.deployment !== undefined;

    return (
        <>
            <PageContainer dataTestId="api-handler-details" sx={{ bgcolor: 'background.paper', minWidth: 500 }}>
                <DialogTitleMain title="Edit API Connection" />
                {errors && <DialogAlert severity="error" alertTitle="Error" text={errors} />}
                {path &&
                    !!selectedConnectionUid &&
                    selectedConnectionUid !== currentSelectedConnectionUid &&
                    environmentDeployed && (
                        <DialogAlert
                            severity="warning"
                            alertTitle="Warning"
                            text="Changing the Connector will take effect immediately after saving."
                        />
                    )}
                {isConnectionUnauthorized && (
                    <DialogAlert severity="warning" alertTitle="Warning" text="Selected Connector is not authorized." />
                )}
                {multipleApiVersions && selectedLibrary?.deprecated && (
                    <DialogAlert
                        severity="warning"
                        alertTitle="Warning"
                        text="You are using deprecated Vendor API version, please consider upgrading to recommended version as soon as possible."
                    />
                )}
                {remnantEnvironments.length > 0 && (
                    <DialogAlert
                        severity="info"
                        alertTitle="Information"
                        text={`This is a remnant API Connection which means that it has been deleted but is still active in the following
                            Environments: ${remnantEnvironment}. You can continue editing the Connector mapping for this remnant API Connection.`}
                    />
                )}
                {hasImplicitlySharedConnectionAttached && (
                    <DialogAlert
                        severity="info"
                        alertTitle="Information"
                        text={`This API Connection is currently using implicitly shared connector owned by someone else, if you change it, you won't be able to change it back on your own.`}
                    />
                )}
                {wizardWarning && (
                    <DialogAlert
                        severity="info"
                        onClose={() => onWizardWarningClose()}
                        alertTitle="Information"
                        text="Please configure and save your API Connection to complete the step."
                    />
                )}
                {multipleApiVersions &&
                    selectedLibrary &&
                    existingRecommendedApiVersion &&
                    !selectedLibrary?.recommended &&
                    !selectedLibrary?.deprecated && (
                        <DialogAlert
                            severity="info"
                            alertTitle="Information"
                            text="Recommended Vendor API Version is available, please consider selecting the recommended option."
                        />
                    )}
                <StyledInnerContainer>
                    {remnantEnvironments.length === 0 && (
                        <>
                            <StyledInputContainer>
                                <TextField
                                    required
                                    disabled={workspaceLocked || environmentDeployed}
                                    InputProps={{
                                        startAdornment: (
                                            <StyledInputAdornment position="start">./api/</StyledInputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        '& .MuiInputBase-input': {
                                            height: 20.125,
                                            lineHeight: 1.5,
                                            padding: '0 !important',
                                        },
                                    }}
                                    variant="outlined"
                                    label="Path"
                                    placeholder="path"
                                    value={currentPath}
                                    onChange={(event) => setCurrentPath(event.target.value)}
                                ></TextField>
                                <Tooltip title={PATH_TOOLTIP} placement="top">
                                    <StyledInfoIcon aria-label="info" />
                                </Tooltip>
                            </StyledInputContainer>
                        </>
                    )}
                    <StyledInputContainer>
                        <Tooltip title={USES_CONNECTION_TOOLTIP} placement="top">
                            <div>
                                <Dropdown
                                    disabled={workspaceLocked}
                                    items={connections}
                                    label="Uses Connector"
                                    required
                                    selectedItem={currentSelectedConnectionUid}
                                    onCreateNew={onNewConnection}
                                    onSelectItem={(value) => setCurrentSelectedConnectionUid(value)}
                                />
                            </div>
                        </Tooltip>
                        <Tooltip title={CONNECTION_TOOLTIP} placement="top">
                            <StyledInfoIcon aria-label="info" />
                        </Tooltip>
                    </StyledInputContainer>
                    {remnantEnvironments.length === 0 && multipleApiVersions && (
                        <StyledInputContainer>
                            <Dropdown
                                disabled={workspaceLocked || environmentDeployed}
                                items={vendorApiVersions}
                                label="Vendor API Version"
                                required
                                selectedItem={currentSelectedApiHandlerLibraryUid}
                                onSelectItem={(value) => setCurrentSelectedApiHandlerLibraryUid(value)}
                            />
                            <Tooltip title={API_VERSION_TOOLTIP} placement="top">
                                <StyledInfoIcon aria-label="info" />
                            </Tooltip>
                        </StyledInputContainer>
                    )}
                    <DialogActions>
                        <Button
                            disabled={workspaceLocked}
                            onClick={() => onCancel()}
                            variant="outlined"
                            data-hotspot="cancel"
                        >
                            Cancel
                        </Button>
                        <Button
                            busy={saving}
                            disabled={!canSave}
                            onClick={() => {
                                onSave({
                                    uid: uid,
                                    connectionUid: currentSelectedConnectionUid,
                                    path: currentPath,
                                    apiHandlerLibraryUid: currentSelectedApiHandlerLibraryUid ?? defaultLibraryUid,
                                });
                            }}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </StyledInnerContainer>
            </PageContainer>
        </>
    );
};
