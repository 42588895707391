import { getTemplatesUsedReport as getTemplatesUsedReportRpc } from '@avst-stitch/repository-lib/lib';
import {
    Request as TemplatesUsedRequest,
    Response as TemplatesUsed,
} from '@avst-stitch/repository-lib/lib/rpcs/report/getTemplatesUsed';
import { repositoryInvoker } from '../../utils/repository';
export type { TemplatesUsedRequest, TemplatesUsed };

export const getTemplatesUsed = async (request: TemplatesUsedRequest): Promise<TemplatesUsed> => {
    return await getTemplatesUsedReportRpc(repositoryInvoker, request);
};
