import { useObservableState, useSubscription } from 'observable-hooks';
import { useNavigate } from 'react-location';
import { EditOrganization, OrgDetailsUpdateEvent } from '../../../components/organizations/details/EditOrganization';
import {
    organizationDetailsUpdatedAction$,
    organizationDetailsUpdating$,
    organizationSwitchedAction$,
    saveOrganizationDetailsAction$,
    saveOrganizationDetailsValidationError$,
    selectedOrganizationDetails$,
    selectedOrganizationUid$,
    selectedOrganizationViewDetails$,
} from '../../../store/organization';
import { getBasePath } from '../../../utils/path';

export const OrganizationDetailsContainer: React.FC = () => {
    const navigate = useNavigate();

    const orgUid = useObservableState(selectedOrganizationUid$);
    const { name, description } = useObservableState(selectedOrganizationDetails$) || {};
    const updating = useObservableState(organizationDetailsUpdating$);
    const errors = useObservableState(saveOrganizationDetailsValidationError$);
    const orgViewDetails = useObservableState(selectedOrganizationViewDetails$);

    useSubscription(organizationDetailsUpdatedAction$, (orgName) => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        if (orgViewDetails) {
            selectedOrganizationViewDetails$.next({ ...orgViewDetails, name: orgName });
        }
        navigate({ to: '../' });
        setTimeout(() => navigate({ to: `${getBasePath()}team/${orgUid}/details` }), 100);
    });

    useSubscription(organizationSwitchedAction$, (uid) => {
        navigate({ to: `${getBasePath()}team/${uid}/details` }), 100;
    });

    const handleUpdate = (event: OrgDetailsUpdateEvent): void => {
        saveOrganizationDetailsAction$.next({ ...event, organizationUid: orgUid || '' });
    };

    const handleCancel = (): void => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        navigate({ to: '../' });
        setTimeout(() => navigate({ to: `${getBasePath()}team/${orgUid}/details` }), 100);
    };

    return (
        <EditOrganization
            userCanUpdateDetails={!!orgViewDetails?.canUpdateDetails}
            name={name || ''}
            errors={errors}
            description={description}
            teamId={orgUid}
            industryTypes={[]}
            onCancel={handleCancel}
            onAvatarUpload={() => alert('Feature not yet implemented')}
            onUpdate={handleUpdate}
            updating={updating}
        />
    );
};
