import { useObservableState } from 'observable-hooks';
import {
    cancelOrganizationPlanFeedbackDialogOpen$,
    saveOrganizationPlanCancellationFeedbackAction$,
    saveOrganizationPlanCancellationFeedbackSaving$,
    saveOrganizationPlanCancellationFeedbackError$,
} from '../../../store/organization/cancelOrganizationPlan';
import { CancelOrganizationPlanFeedbackDialog } from '../../../components/organizations/plan/cancel-organization-plan/CancelOrganizationPlanFeedbackDialog';

export const CancelOrganizationPlanFeedbackContainer: React.FC = () => {
    const open = useObservableState(cancelOrganizationPlanFeedbackDialogOpen$);
    const saving = useObservableState(saveOrganizationPlanCancellationFeedbackSaving$);
    const errors = useObservableState(saveOrganizationPlanCancellationFeedbackError$);

    return (
        <CancelOrganizationPlanFeedbackDialog
            open={open}
            saving={saving}
            errors={errors}
            onCancel={() => cancelOrganizationPlanFeedbackDialogOpen$.next(false)}
            onSubmit={(event) => saveOrganizationPlanCancellationFeedbackAction$.next(event)}
        />
    );
};
