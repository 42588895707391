import { useObservableState } from 'observable-hooks';
import { TrelloManageConnectionDialog } from '../../../../components/connections/apps/trello/ManageConnectionDialog';
import {
    trelloConnectionSaving$,
    trelloManageConnectionAuthorizeLoading$,
    trelloManageConnectionCloseDialogAction$,
    trelloManageConnectionDetails$,
    trelloManageConnectionDialogErrors$,
    trelloManageConnectionDialogOpen$,
    trelloManageConnectionInitiateSetupAction$,
    trelloSaveConnectionAction$,
} from '../../../../store/connection/trello/setup-connection';
import { loggedInUserConnections$ } from '../../../../store/connections';

export const TrelloManageConnectionDialogContainer: React.FC = () => {
    const open = useObservableState(trelloManageConnectionDialogOpen$);
    const saving = useObservableState(trelloConnectionSaving$);
    const authorizeLoading = useObservableState(trelloManageConnectionAuthorizeLoading$);
    const details = useObservableState(trelloManageConnectionDetails$);
    const errors = useObservableState(trelloManageConnectionDialogErrors$);
    const connection = useObservableState(loggedInUserConnections$).find(
        (connection) => connection.uid === details?.uid
    );

    return (
        <TrelloManageConnectionDialog
            open={open}
            saving={saving}
            errors={errors}
            authorizeLoading={authorizeLoading}
            name={connection?.name ?? ''}
            authorized={!!connection?.authorized}
            onCancel={() => trelloManageConnectionCloseDialogAction$.next()}
            onSave={(name) => trelloSaveConnectionAction$.next(name)}
            onAuthorize={(name) => trelloManageConnectionInitiateSetupAction$.next(name)}
            connectionType={details?.connectionType.name}
        />
    );
};
