import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const TrelloIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 73.323 64"
            className="productIcon"
            aria-label="Trello"
        >
            <defs>
                <linearGradient id="A" x1="31.52" y1="64.56" x2="31.52" y2="1.51" gradientUnits="userSpaceOnUse">
                    <stop offset=".18" stopColor="#0052cc" />
                    <stop offset="1" stopColor="#2684ff" />
                </linearGradient>
            </defs>
            <path
                d="M55.16 1.5H7.88a7.88 7.88 0 0 0-5.572 2.308A7.88 7.88 0 0 0 0 9.39v47.28a7.88 7.88 0 0 0 7.88 7.88h47.28A7.88 7.88 0 0 0 63 56.67V9.4a7.88 7.88 0 0 0-7.84-7.88zM27.42 49.26A3.78 3.78 0 0 1 23.64 53H12a3.78 3.78 0 0 1-3.8-3.74V13.5A3.78 3.78 0 0 1 12 9.71h11.64a3.78 3.78 0 0 1 3.78 3.78zM54.85 33.5a3.78 3.78 0 0 1-3.78 3.78H39.4a3.78 3.78 0 0 1-3.78-3.78v-20a3.78 3.78 0 0 1 3.78-3.79h11.67a3.78 3.78 0 0 1 3.78 3.78z"
                fill="url(#A)"
                fillRule="evenodd"
                transform="matrix(1.163111 0 0 1.163111 .023263 -6.417545)"
            />
        </SvgIcon>
    );
};
