import { LoaderFn } from 'react-location';
import { getUsersWithDeployedWorkspaces } from '../../data/report/usersWithDeployedWorkspaces';
import { LocationGenerics } from '../../router';
import { usersWithDeployedWorkspaces$ } from '../../store/report/usersWithDeployedWorkspaces';

export const usersWithDeployedWorkspacesReportLoader: LoaderFn<LocationGenerics> = async () => {
    const users = await getUsersWithDeployedWorkspaces();
    usersWithDeployedWorkspaces$.next(users);

    return {};
};
