import { getFetchOptions } from '../../../utils/fetch';
import { configTopic$, stitchSession$ } from '../../config';

interface SaveApiKeyProps {
    connectionId: string;
    apiKey: string;
    token: string;
}

export const saveToken = async (request: SaveApiKeyProps): Promise<void> => {
    const connectorUrl = configTopic$.value.connection?.trello?.baseUrl;
    if (!connectorUrl) {
        throw new Error('No Trello connector url configured in meta');
    }

    const fetchOptions = getFetchOptions({ Authorization: stitchSession$.value?.jwt ?? '' }, request);

    const response = await fetch(`${connectorUrl}/save`, fetchOptions);
    if (!response.ok) {
        console.error('Error while saving token');

        const message = await response.text();
        if (message) {
            throw new Error(message);
        } else {
            throw Error(`Unexpected error while saving token to Trello Connector Service: ${response.status}`);
        }
    }
};
