import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { ProductIcon } from '../../icons/ProductIcon';
import Tooltip from '@mui/material/Tooltip';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';

export interface FiltersProps {
    filters: FilterItemProps[];
    count: number;
    reset(): void;
}

export interface FilterItemProps {
    filterName: string;
    options: string[];
    activeFilters: string[];
    handler(item: string | boolean): void;
    icons: boolean;
}

const StyledTypography = styled(Typography)(() => ({
    display: 'flex',
}));

const StyledFilterOptionTypography = styled(Typography)(() => ({
    display: 'inline-block',
    width: '100%',
}));

const StyledBox = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    color: theme.palette.text.secondary,
    cursor: 'pointer',
}));

const StyledResetFilterBox = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    flexShrink: 0,
    height: 36,
    padding: theme.spacing(1, 0, 1, 2),
    '& .MuiTypography-root': {
        fontWeight: 500,
    },
}));

const StyledBoxCounter = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    ...theme.typography.radiusCircle,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
    height: 22,
    justifyContent: 'center',
    marginLeft: theme.spacing(1),
    width: 22,
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    paddingRight: theme.spacing(0.8),
    '& .MuiSvgIcon-root': {
        height: 16,
        width: 16,
    },
}));

const StyledRoundedBox = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.action.active}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    cursor: 'pointer',
    height: 32,
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    padding: theme.spacing(0, 0.5),
    '& .counterRound': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
    '&:hover': {
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
        '& .MuiTypography-root': {
            color: theme.palette.secondary.contrastText,
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.secondary.contrastText,
        },
        '& .counterRound': {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.secondary,
        },
    },
}));

const StyledRoundedBoxHighlighted = styled(StyledRoundedBox)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.text.secondary}`,
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '& .MuiTypography-root': {
        color: theme.palette.secondary.contrastText,
    },
    '& .MuiSvgIcon-root': {
        color: theme.palette.secondary.contrastText,
    },
    '& .counterRound': {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.secondary.main,
    },
}));

const StyledPopper = styled(Popper)(({ theme }) => ({
    zIndex: 4,
    '& .MuiPaper-root': {
        boxShadow: theme.constants.boxShadow,
    },
    '& .MuiList-root': {
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.constants.borderRadius,
        display: 'flex',
        flexDirection: 'column',
        height: 395,
        overflowY: 'scroll',
        width: 290,
        '& .MuiPaper-root': {
            boxShadow: 'none',
        },
    },
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    width: '100%',
    height: 36,
    ':hover': {
        backgroundColor: theme.palette.action.hover,
    },
    paddingLeft: theme.spacing(2),
}));

const StyledFilterListIcon = styled(FilterListRoundedIcon)(({ theme }) => ({
    marginRight: theme.spacing(1),
}));

const StyledIconBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    placeContent: 'center',
    paddingRight: theme.spacing(1),
}));

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
    () => ({
        '& .MuiFormControlLabel-root': {
            margin: 0,
        },
        '&:before': {
            display: 'none',
        },
    })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
    ':hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));

export const Filter: React.FC<FiltersProps> = ({ filters, count, reset }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);
    const prevOpen = useRef(open);

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current?.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const handleToggle = (): void => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event | React.SyntheticEvent): void => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <Box
                onClick={handleToggle}
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-haspopup="true"
            >
                {open ? (
                    <StyledRoundedBoxHighlighted>
                        <StyledFilterListIcon />
                        <StyledTypography>Filters</StyledTypography>
                        {count > 0 && <StyledBoxCounter className="counterRound">{count}</StyledBoxCounter>}
                    </StyledRoundedBoxHighlighted>
                ) : (
                    <StyledRoundedBox>
                        <StyledFilterListIcon />
                        <StyledTypography>Filters</StyledTypography>
                        {count > 0 && <StyledBoxCounter className="counterRound">{count}</StyledBoxCounter>}
                    </StyledRoundedBox>
                )}
            </Box>
            <StyledPopper
                open={open}
                anchorEl={anchorRef.current}
                placement="bottom-end"
                transition
                disablePortal
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [-8, 2],
                        },
                    },
                    {
                        name: 'flip',
                        options: {
                            rootBoundary: 'document',
                        },
                    },
                ]}
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: 'right top',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                >
                                    <StyledResetFilterBox role="menuitem">
                                        <Typography onClick={() => reset()}>Reset Filters</Typography>
                                    </StyledResetFilterBox>
                                    {filters.map((filter, i) => (
                                        <FilterItem
                                            key={i}
                                            filterName={filter.filterName}
                                            options={filter.options}
                                            activeFilters={filter.activeFilters}
                                            handler={filter.handler}
                                            icons={filter.icons}
                                        />
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </StyledPopper>
        </>
    );
};

export const FilterItem: React.FC<FilterItemProps> = ({ filterName, options, activeFilters, handler, icons }) => {
    return (
        <Accordion role="menuitem" TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary>
                <Typography>{filterName}</Typography>
            </AccordionSummary>
            {options.map((item, index) => {
                const isChecked = activeFilters.some((a) => a === item);
                return (
                    <StyledFormControlLabel
                        key={index}
                        control={<StyledCheckbox checked={isChecked} onChange={() => handler(item)} />}
                        label={
                            <StyledBox>
                                {icons && (
                                    <Tooltip placement="top" title={item} describeChild>
                                        <StyledIconBox>
                                            <ProductIcon name={item} />
                                        </StyledIconBox>
                                    </Tooltip>
                                )}
                                <StyledFilterOptionTypography noWrap>{item}</StyledFilterOptionTypography>
                            </StyledBox>
                        }
                    />
                );
            })}
        </Accordion>
    );
};
