import loader from '@monaco-editor/loader';
import { scriptDependants$, scriptHasTriggerImport$ } from '../../store/workspace/script';
import { MonacoScripts, TypeScriptWorker } from './types';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const addScriptDependencies = async (scripts: MonacoScripts): Promise<void> => {
    const worker = await getMonacoWorker(scripts);
    // Script UID -> imported script UIDs
    const scriptDependencies: Record<string, string[]> = {};
    // Script UID -> script UIDs that depend on it
    const dependantGraph: Record<string, string[]> = {};

    if (worker) {
        let scriptHasTriggerImport = false;
        for (const script of scripts) {
            const filename = script.uri.toString();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const scriptImports: string[] = (await (worker as any).findImports?.(filename)) ?? [];
            const filepath = script.name.substring(0, script.name.lastIndexOf('/') + 1);

            for (const scriptImport of scriptImports) {
                if (scriptImport === '@sr-connect/trigger') {
                    scriptHasTriggerImport = true;
                }

                const dependentModule = scripts.find((mod) =>
                    scriptImport.replace('./', `./${filepath}`).endsWith(`/${mod.name}`)
                );
                if (dependentModule) {
                    scriptDependencies[script.uid] = Array.from(
                        new Set([...(scriptDependencies[script.uid] ?? []), dependentModule.uid])
                    );
                }
            }
        }

        for (const script of scripts) {
            const dependants: string[] = [];
            for (const [uid, dependencies] of Object.entries(scriptDependencies)) {
                if (dependencies.includes(script.uid)) {
                    dependants.push(uid);
                }
            }
            dependantGraph[script.uid] = Array.from(new Set(dependants));
        }

        scriptHasTriggerImport$.next(scriptHasTriggerImport);
        scriptDependants$.next(dependantGraph);
    }
};

const getMonacoWorker = async (scripts: MonacoScripts): Promise<TypeScriptWorker | null> => {
    const monaco = await loader.init();
    const uris = scripts.map(({ uri }) => uri);
    if (uris.length > 0) {
        try {
            const createWorker = await monaco.languages.typescript.getTypeScriptWorker();
            return await createWorker(...uris);
        } catch (e) {
            // May throw an 'TypeScript not registered!' error if TS support hasn't yet loaded due to
            // no TS models being registered with Monaco.
            // This is fine, we'll just fall through and return no worker.
            console.error(e);
        }
    }
    return null;
};
