import { pendoAnalyticsTrack } from './pendo-analytics';
import { segmentAnalyticsTrack } from './segment-analytics';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const segmentAndPendoAnalyticsTrack = (event: string, properties?: any): void => {
    segmentAnalyticsTrack(event, properties);
    if (event !== 'User Registered') {
        pendoAnalyticsTrack(event, properties);
    }
};
