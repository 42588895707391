import { useObservableState } from 'observable-hooks';
import { NetSuiteConnectionAppConfigureDialog } from '../../../../components/connections/apps/netsuite/NetSuiteAppConfigureDialog';
import {
    netSuiteConnectionSaving$,
    netSuiteManageConnectionDetails$,
    netSuiteAppSetupDialogErrors$,
    netSuiteAppSetupDialogOpen$,
    netSuiteAppSetupCloseDialogAction$,
    netSuiteAppSetupAuthorizeAction$,
    netSuiteAppSetupDialogStage$,
} from '../../../../store/connection/netsuite/setup-connection';

export const NetSuiteConfigureDialog: React.FC = () => {
    const open = useObservableState(netSuiteAppSetupDialogOpen$);
    const saving = useObservableState(netSuiteConnectionSaving$);
    const details = useObservableState(netSuiteManageConnectionDetails$);
    const errors = useObservableState(netSuiteAppSetupDialogErrors$);
    const currentStage = useObservableState(netSuiteAppSetupDialogStage$);

    return (
        <NetSuiteConnectionAppConfigureDialog
            open={open}
            onClose={() => {
                netSuiteAppSetupCloseDialogAction$.next();
                netSuiteAppSetupDialogStage$.next(0);
            }}
            currentStage={currentStage}
            setStage={(stage) => netSuiteAppSetupDialogStage$.next(stage)}
            clientInfo={{
                netSuiteAccountId: details?.netSuiteAccountId ?? '',
                clientId: details?.clientId ?? '',
                publicCertificate: details?.publicCertificate ?? '',
                certificateId: details?.certificateId ?? '',
            }}
            saving={saving}
            errors={errors}
            setError={(error) => netSuiteAppSetupDialogErrors$.next(error)}
            clearErrors={() => netSuiteAppSetupDialogErrors$.next(undefined)}
            onSave={(appInfoProps) => netSuiteAppSetupAuthorizeAction$.next(appInfoProps)}
        />
    );
};
