import { useObservableState } from 'observable-hooks';
import { BitbucketCloudAppConfigureDialog } from '../../../../components/connections/apps/bitbucket-cloud/BitbucketCloudAppConfigureDialog';
import { configTopic$ } from '../../../../store/config';
import {
    bitbucketCloudConnectionSaving$,
    bitbucketCloudManageConnectionDetails$,
    bitbucketCloudAppSetupDialogErrors$,
    bitbucketCloudAppSetupDialogOpen$,
    bitbucketCloudAppSetupCloseDialogAction$,
    bitbucketCloudAppSetupAuthorizeAction$,
    bitbucketCloudAppSetupDialogStage$,
} from '../../../../store/connection/bitbucket-cloud/setup-connection';

export const BitbucketCloudConfigureDialog: React.FC = () => {
    const open = useObservableState(bitbucketCloudAppSetupDialogOpen$);
    const saving = useObservableState(bitbucketCloudConnectionSaving$);
    const details = useObservableState(bitbucketCloudManageConnectionDetails$);
    const errors = useObservableState(bitbucketCloudAppSetupDialogErrors$);
    const currentStage = useObservableState(bitbucketCloudAppSetupDialogStage$);
    const baseUrl = configTopic$.value.connection?.atlassianCloud?.baseUrl;

    return (
        <BitbucketCloudAppConfigureDialog
            open={open}
            onClose={() => {
                bitbucketCloudAppSetupCloseDialogAction$.next();
                bitbucketCloudAppSetupDialogStage$.next(0);
            }}
            currentStage={currentStage}
            setStage={(stage) => bitbucketCloudAppSetupDialogStage$.next(stage)}
            clientInfo={{
                workspaceUrl: details?.instanceUrl ?? '',
                clientId: details?.clientId ?? '',
                clientSecret: details?.clientSecret ?? '',
            }}
            saving={saving}
            errors={errors}
            setError={(error) => bitbucketCloudAppSetupDialogErrors$.next(error)}
            clearErrors={() => bitbucketCloudAppSetupDialogErrors$.next(undefined)}
            onSave={(appInfoProps) => bitbucketCloudAppSetupAuthorizeAction$.next(appInfoProps)}
            callbackUrl={`${baseUrl}/bitbucket/callback`}
        />
    );
};
