import loader from '@monaco-editor/loader';

interface TypeDeclaration {
    readonly filePath: string;
    readonly content: string;
}

export const setTypeDeclarations = async (
    declarationFiles: readonly TypeDeclaration[],
    overwrite = false
): Promise<void> => {
    const monaco = await loader.init();

    const copiedDeclarationFiles = [...declarationFiles];

    if (overwrite) {
        monaco.languages.typescript.typescriptDefaults.setExtraLibs(copiedDeclarationFiles);
    } else {
        copiedDeclarationFiles.forEach(({ content, filePath }) => {
            monaco.languages.typescript.typescriptDefaults.addExtraLib(content, filePath);
        });
    }
};
