import { LoaderFn } from 'react-location';
import { LocationGenerics } from '../../router';
import { createReadmeFile, selectReadmeFileAction$ } from '../../store/workspace/readme';
import { loadReadmeFile } from '../../store/workspace/utils';
import { loadErrorPage } from '../../store/error';
import { getTemplate } from '../../data/template';
import { selectedWorkspaceResources$ } from '../../store/workspace';
import { getWorkspaceResources } from '../../data/workspace';
import { selectedReadOnlyTemplate$ } from '../../store/templates';

export const readmeFileDetailsLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    try {
        const workspaceUid = routeMatch.params.workspaceUid;
        const environmentUid = routeMatch.params.environmentUid;
        const templateUid = routeMatch.params.templateUid;
        //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const readmeUid = routeMatch.params.readmeFileUid!;

        const isTemplate = !workspaceUid;

        const template = isTemplate
            ? selectedReadOnlyTemplate$.value ?? (await getTemplate(templateUid ?? ''))
            : undefined;

        const currentWorkspaceUid = isTemplate ? template?.workspaceUid : workspaceUid;
        const currentEnvironmentUid = isTemplate ? template?.environmentUid : environmentUid;

        const resources =
            selectedWorkspaceResources$.value && !selectedWorkspaceResources$.value.default
                ? selectedWorkspaceResources$.value
                : await getWorkspaceResources(currentWorkspaceUid ?? '', currentEnvironmentUid ?? '');

        if (!resources.readmeFile?.uid && readmeUid === '/') {
            // Legacy code for the unlikely event that some workspaces might not have a readme file, if they are created earlier than the latest readme change and the user hasn't ever clicked on the readme
            await createReadmeFile();
        } else if (resources.readmeFile?.uid) {
            await loadReadmeFile(resources.readmeFile?.uid, currentWorkspaceUid ?? '', currentEnvironmentUid ?? '');
            selectReadmeFileAction$.next(resources.readmeFile?.uid);
        }
    } catch (e) {
        loadErrorPage({
            error: e,
            background: 'paper',
            genericMessage: 'Failed to load README file.',
        });

        throw e;
    }

    return {};
};
