import { BehaviorSubject, map, Subject } from 'rxjs';
import { getEventListenerSetupDetails } from '../../../data/event-listener';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';

export const slackEventListenerSetupDialogOpenAction$ = monitor(
    'slackEventListenerSetupDialogOpenAction$',
    new Subject<SlackEventListenerSetupBaseDetails>()
);
export const slackEventListenerSetupDialogCloseAction$ = monitor(
    'slackEventListenerSetupDialogCloseAction$',
    new Subject<void>()
);

export const slackEventListenerSetupDialogLoading$ = monitor(
    'slackEventListenerSetupDialogLoading$',
    new BehaviorSubject(false)
);
export const slackEventListenerSetupDialogOpen$ = monitor(
    'slackEventListenerSetupDialogOpen$',
    new BehaviorSubject(false)
);
export const slackEventListenerSetupDialogDetails$ = monitor(
    'slackEventListenerSetupDialogDetails$',
    new BehaviorSubject<SlackEventListenerSetupDetails | undefined>(undefined)
);

export type SlackEventListenerSetupBaseDetails = {
    uid: string;
    environmentUid: string;
    webhookUrl: string;
    eventTypeName: string;
    eventTypeCategory?: string;
};

export type SlackEventListenerSetupDetails = {
    setupBaseUrl?: string;
} & SlackEventListenerSetupBaseDetails;

slackEventListenerSetupDialogOpenAction$
    .pipe(
        map(async (details) => {
            slackEventListenerSetupDialogOpen$.next(true);
            slackEventListenerSetupDialogLoading$.next(true);

            try {
                const { connectionBaseUrl } = await getEventListenerSetupDetails(details.uid, details.environmentUid);

                if (connectionBaseUrl) {
                    slackEventListenerSetupDialogDetails$.next({
                        ...details,
                        setupBaseUrl: connectionBaseUrl,
                    });
                } else {
                    slackEventListenerSetupDialogDetails$.next(details);
                }

                slackEventListenerSetupDialogLoading$.next(false);
            } catch (e) {
                console.error('Error while loading Event Listener setup instructions', e);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to load Event Listener setup instructions, please try again, if the issue persists please contact support.`,
                    toastOptions: {
                        autoClose: false,
                    },
                });

                slackEventListenerSetupDialogCloseAction$.next();
            }
        })
    )
    .subscribe();

slackEventListenerSetupDialogCloseAction$.subscribe(() => {
    slackEventListenerSetupDialogOpen$.next(false);
    slackEventListenerSetupDialogDetails$.next(undefined);
});
