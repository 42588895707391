import { BehaviorSubject, Subject } from 'rxjs';
import { monitor } from '../monitor';
import { Config, Env, FeatureFlags, StitchSession } from './types';

const auth0StgConfig = {
    auth0: {
        domain: 'stitch-avst.eu.auth0.com',
        clientId: 'kCkdXlRMXWCbph8FRUjDL7kSwoWEEQHi',
        apiUrl: 'https://stitch-stg.adaptavist.com',
    },
};
const initialConfigs: Record<Env, Config> = {
    local: auth0StgConfig,
    staging: auth0StgConfig,
    production: auth0StgConfig,
};

export const env = (process.env.BITBUCKET_DEPLOYMENT_ENVIRONMENT ?? process.env.REACT_APP_CONFIG_ENV ?? 'local') as Env;

export const configTopic$ = monitor('configTopic$', new BehaviorSubject<Config>(initialConfigs[env]));
export const stitchSession$ = monitor('stitchSession$', new BehaviorSubject<StitchSession | null>(null));
export const featureFlagsTopic$ = monitor('featureFlagsTopic$', new BehaviorSubject<FeatureFlags>({}));
export const sessionExpired$ = monitor('sessionExpired$', new BehaviorSubject<boolean | undefined>(undefined));
export const showSessionExpiredWarning$ = monitor('showSessionExpiredWarning$', new BehaviorSubject<boolean>(false));
export const loadTokens$ = monitor('loadTokens$', new BehaviorSubject<boolean | undefined>(undefined));

export const startSessionAction$ = monitor('startSessionAction$', new Subject<number>());
export const expireSessionAction$ = monitor('expireSessionAction$', new Subject<number>());

startSessionAction$.subscribe((expiry: number) => {
    if (expiry > 0) {
        setTimeout(() => {
            sessionExpired$.next(true);
            expireSessionAction$.next(7000);
        }, expiry);
    }
});

expireSessionAction$.subscribe((expiry: number) => {
    if (expiry > 0) {
        setTimeout(() => {
            if (sessionExpired$.value) {
                showSessionExpiredWarning$.next(true);
            }
        }, expiry);
    }
});
