import { useEffect, useState } from 'react';

export type ThemeMode = 'light' | 'dark' | 'system';

/**
 * Return either a light or dark theme based on the given theme mode,
 * which can just be 'light' or 'dark', boringly returning the appropriate theme.
 *
 * BUT, it can also be 'system', and this will return the theme based on the user's
 * OS or browser settings, well actually it's the 'prefers-color-scheme' media query.
 *
 * But that's not all, whilst in 'system' mode it'll listen for a change to the media
 * query, and dynamically switch themes (via a useState hook to cause the re-render).
 *
 * The theme objects are totally generic, so this will work will any UI/Theme library.
 *
 * @param mode 'light', 'dark', or 'system'
 * @param lightTheme your light theme object
 * @param darkTheme your dark theme object
 */
export const useThemeMode = <T>(mode: ThemeMode, lightTheme: T, darkTheme: T): T => {
    const [dark, setDark] = useState<boolean>(mode === 'system' ? isDark() : mode === 'dark');

    useEffect(() => {
        if (mode === 'system') {
            const handler = (e: MediaQueryListEvent): void => {
                setDark(e.matches);
            };

            const query = darkQuery();
            query.addEventListener('change', handler);

            setDark(query.matches);

            return () => {
                query.removeEventListener('change', handler);
            };
        } else {
            setDark(mode === 'dark');
        }
    }, [mode]);

    return dark ? darkTheme : lightTheme;
};

const darkQuery = (): MediaQueryList => window.matchMedia('(prefers-color-scheme: dark)');

export const isDark = (): boolean => darkQuery().matches;
