import { BehaviorSubject, map, Subject } from 'rxjs';
import { getEventListenerSetupDetails } from '../../../data/event-listener';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';

export const bitbucketCloudEventListenerSetupDialogOpenAction$ = monitor(
    'bitbucketCloudEventListenerSetupDialogOpenAction$',
    new Subject<BitbucketCloudEventListenerSetupBaseDetails>()
);
export const bitbucketCloudEventListenerSetupDialogCloseAction$ = monitor(
    'bitbucketCloudEventListenerSetupDialogCloseAction$',
    new Subject<void>()
);

export const bitbucketCloudEventListenerSetupDialogLoading$ = monitor(
    'bitbucketCloudEventListenerSetupDialogLoading$',
    new BehaviorSubject(false)
);
export const bitbucketCloudEventListenerSetupDialogOpen$ = monitor(
    'bitbucketCloudEventListenerSetupDialogOpen$',
    new BehaviorSubject(false)
);
export const bitbucketCloudEventListenerSetupDialogDetails$ = monitor(
    'bitbucketCloudEventListenerSetupDialogDetails$',
    new BehaviorSubject<BitbucketCloudEventListenerSetupDetails | undefined>(undefined)
);

export type BitbucketCloudEventListenerSetupBaseDetails = {
    uid: string;
    environmentUid: string;
    webhookUrl: string;
    eventTypeName: string;
};

export type BitbucketCloudEventListenerSetupDetails = {
    setupBaseUrl?: string;
} & BitbucketCloudEventListenerSetupBaseDetails;

bitbucketCloudEventListenerSetupDialogOpenAction$
    .pipe(
        map(async (details) => {
            bitbucketCloudEventListenerSetupDialogOpen$.next(true);
            bitbucketCloudEventListenerSetupDialogLoading$.next(true);

            try {
                const { connectionBaseUrl } = await getEventListenerSetupDetails(details.uid, details.environmentUid);

                if (connectionBaseUrl) {
                    bitbucketCloudEventListenerSetupDialogDetails$.next({
                        ...details,
                        setupBaseUrl: connectionBaseUrl,
                    });
                } else {
                    bitbucketCloudEventListenerSetupDialogDetails$.next(details);
                }

                bitbucketCloudEventListenerSetupDialogLoading$.next(false);
            } catch (e) {
                console.error('Error while loading Event Listener setup instructions', e);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to load Event Listener setup instructions, please try again, if the issue persists please contact support.`,
                    toastOptions: {
                        autoClose: false,
                    },
                });

                bitbucketCloudEventListenerSetupDialogCloseAction$.next();
            }
        })
    )
    .subscribe();

bitbucketCloudEventListenerSetupDialogCloseAction$.subscribe(() => {
    bitbucketCloudEventListenerSetupDialogOpen$.next(false);
    bitbucketCloudEventListenerSetupDialogDetails$.next(undefined);
});
