import { SESSION_ID } from '..';
import { configTopic$, stitchSession$ } from '../store/config';
import { publishLocalFeedbackEventAction$ } from '../store/feedback';
import { getFetchOptions } from './fetch';

type ExecuteScriptResponse = Record<string, string>;

export const executeScript = async (
    environmentUid: string,
    scriptUid: string,
    request = {}
): Promise<ExecuteScriptResponse> => {
    const fetchOptions = getFetchOptions(
        { Authorization: stitchSession$.value?.jwt ?? '', 'x-stitch-session-id': SESSION_ID },
        request
    );
    const triggerUrl = configTopic$.value.trigger?.executeUrl;

    if (!triggerUrl) {
        throw new Error('No trigger service url configured in meta');
    }

    const response = await fetch(`${triggerUrl}/${environmentUid}/${scriptUid}`, fetchOptions);

    if (!response.ok) {
        console.error('Error while processing error');

        const message = await response.text();
        if (message) {
            throw new Error(message);
        } else {
            throw Error(`Unexpected error while communicating with trigger service: ${response.status}`);
        }
    }

    const respBody = await response.text();

    if (respBody) {
        return JSON.parse(respBody);
    } else {
        return {};
    }
};

export const abortInvocation = async (abortInvocationUrlPath: string): Promise<void> => {
    const fetchOptions = getFetchOptions({ Authorization: stitchSession$.value?.jwt ?? '' });
    const abortInvocationBaseUrl = configTopic$.value.trigger?.abortInvocationUrl;

    if (!abortInvocationBaseUrl) {
        throw new Error('No abort invocation service url configured in meta');
    }

    const invocationId = abortInvocationUrlPath.split('/')[2];

    const response = await fetch(`${abortInvocationBaseUrl}${abortInvocationUrlPath}`, fetchOptions);
    publishLocalFeedbackEventAction$.next({
        level: 'INFO',
        message: 'Invocation abort request has been sent for invocation ID: ' + invocationId,
        noToast: true,
    });

    if (!response.ok) {
        const message = await response.json();
        if (message.code && message.error) {
            console.error('Error from abort request: ' + message);
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message: `${message.error} - Status ${message.code}`,
                noToast: true,
            });
        } else {
            console.error(`Unexpected error while aborting invocation service: ${response.status}`);
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message:
                    `Failed to abort invocation: ${invocationId}. If the script is still being executed, ` +
                    'try again. If the issue persists please contact support.',
                noToast: true,
            });
        }
    }
};
