import { useObservableState } from 'observable-hooks';
import { ZenDeskEventListenerSetupDialog } from '../../../../components/connections/apps/zendesk/EventListenerSetupDialog';
import { configTopic$ } from '../../../../store/config';
import {
    zenDeskEventListenerSetupDialogCloseAction$,
    zenDeskEventListenerSetupDialogDetails$,
    zenDeskEventListenerSetupDialogLoading$,
    zenDeskEventListenerSetupDialogOpen$,
} from '../../../../store/connection/zendesk/setup-event-listener';

export const ZenDeskEventListenerSetupDialogContainer: React.FC = () => {
    const loading = useObservableState(zenDeskEventListenerSetupDialogLoading$);
    const open = useObservableState(zenDeskEventListenerSetupDialogOpen$);
    const details = useObservableState(zenDeskEventListenerSetupDialogDetails$);
    const config = useObservableState(configTopic$);

    return (
        <ZenDeskEventListenerSetupDialog
            loading={loading}
            open={open}
            webhookBaseUrl={config.trigger?.srcExternalUrl ?? ''}
            zendeskUrl={details?.setupBaseUrl}
            webhookUrlId={details?.webhookUrl ?? ''}
            onClose={() => {
                zenDeskEventListenerSetupDialogCloseAction$.next();
            }}
        />
    );
};
