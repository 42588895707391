/* eslint-disable sonarjs/no-duplicate-string */
import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export const StyledBox = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    flexDirection: 'column',
    maxWidth: 688,
    // Remove after WS overhaul and write to the theme
    '& .MuiFormControl-root, & .MuiInputBase-root': {
        margin: 0,
        width: '100%',
    },
}));

export const StyledBoxHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
}));

export const StyledBoxTitleContainer = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1.5),
}));

export const StyledBoxContent = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
}));

// Eventually switch to an app-wide Actions component
export const StyledMainActions = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
}));

export const StyledListItem = styled('div')<{ needsSetup?: boolean }>(({ theme, needsSetup = false }) => ({
    alignItems: 'flex-start',
    border: needsSetup ? `2px solid ${theme.palette.warning.main}` : `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 688,
    padding: theme.spacing(1.5),
    // Just for storybook at the moment, needs attention in the theme
    '& .MuiFormControl-root, & .MuiInputBase-root': {
        width: '100%',
    },
}));

export const StyledListItemLeftContainer = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'center',
}));

export const StyledListItemRightContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexBasis: 220,
    alignItems: 'flex-end',
    alignSelf: 'stretch',
    justifyContent: 'center',
    gap: theme.spacing(1),
}));

export const StyledListItemActions = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
}));

export const StyledListItemIndicatorContainer = styled('div')(() => ({
    alignSelf: 'stretch',
    display: 'flex',
}));

export const StyledListItemIndicator = styled('div')<{ authorized?: boolean; needsSetup?: boolean }>(
    ({ authorized, needsSetup = false, theme }) => ({
        ...theme.typography.flexAlignCenter,
        height: 40,
        justifyContent: 'center',
        width: 40,
        '&.left': {
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[900],
            borderBottom: `1px solid ${theme.palette.divider}`,
            borderLeft: `1px solid ${theme.palette.divider}`,
            borderTop: `1px solid ${theme.palette.divider}`,
            borderRadius: `${theme.constants.borderRadius}px 0 0 ${theme.constants.borderRadius}px`,
        },
        '&.right': {
            backgroundColor: authorized
                ? alpha(theme.palette.success.light, 0.1)
                : needsSetup
                ? alpha(theme.palette.warning.light, 0.1)
                : undefined,
            borderRadius: `0 ${theme.constants.borderRadius}px ${theme.constants.borderRadius}px 0`,
            border: authorized
                ? `1px solid ${theme.palette.success.main}`
                : needsSetup
                ? `1px solid ${theme.palette.warning.main}`
                : `1px solid ${theme.palette.divider}`,
        },
        '&.center': {
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[900],
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.constants.borderRadius,
        },
        '& img': {
            height: 24,
        },
    })
);

export const StyledListItemBottomTextContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(0.5),
    ...theme.typography.flexAlignCenter,
    '& .MuiTypography-root:last-of-type': {
        color: theme.palette.primary.main,
    },
}));

export const StyledListItemWarningContainer = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
    '& .MuiTypography-root': {
        color: theme.palette.text.secondary,
        fontStyle: 'italic',
    },
    '& .MuiSvgIcon-root': {
        color: theme.palette.warning.main,
    },
}));

export const StyledListItemIncompleteChip = styled(Chip)(({ theme }) => ({
    borderRadius: theme.constants.borderRadius,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800],
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
}));

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    color: theme.palette.text.secondary,
    flexGrow: 1,
    gap: theme.spacing(0.5),
    height: 40,
    maxWidth: 196,
    textTransform: 'none',
    '&.Mui-selected': {
        backgroundColor: theme.palette.action.selected,
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.action.focus,
        },
    },
    '& .MuiSvgIcon-root': {
        height: 16,
        width: 16,
    },
}));

export const StyledInstructionsBoxHeader = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: alpha(theme.palette.info.light, 0.2), // TODO: Need a new theme colour, info.background
    height: 68,
    padding: theme.spacing(1, 1.5),
}));

export const StyledInstructionsIconContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 44,
    height: 44,
    backgroundColor: theme.palette.info.main,
    borderRadius: theme.constants.borderRadius,
    '& .MuiSvgIcon-root': {
        color: theme.palette.primary.contrastText,
    },
}));

export const StyledInstructionsOrderedList = styled(List)(({ theme }) => ({
    counterReset: 'step-counter',
    listStyle: 'none',
    margin: 0,
    padding: theme.spacing(2, 2, 0, 2),
    '& li': {
        counterIncrement: 'step-counter',
        marginBottom: theme.spacing(1.5),
        '&::before': {
            display: 'inline-flex',
            flexShrink: 0,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800], // TODO: Need new color in theme
            borderRadius: theme.constants.radiusCircle,
            content: 'counter(step-counter)',
            fontSize: theme.typography.subtitle2.fontSize,
            height: theme.spacing(3),
            width: theme.spacing(3),
            lineHeight: '22px',
            marginRight: theme.spacing(1.5),
            fontWeight: theme.typography.fontWeightBold,
        },
    },
}));

export const StyledSummaryBoxContent = styled('div')(({ theme }) => ({
    alignItems: 'flex-start',
    backgroundColor: theme.palette.background.paper,
    borderRadius: `0 0 ${theme.constants.borderRadius}px ${theme.constants.borderRadius}px`,
    borderTop: `1px solid ${theme.palette.info.light}`,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
}));

export const StyledIconContainer = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
}));

export const StyledTextContainer = styled('div')(({ theme }) => ({
    '& .MuiTypography-root:first-of-type': {
        color: theme.palette.text.secondary,
    },
    '& .MuiTypography-root:last-of-type': {
        fontSize: theme.typography.htmlFontSize,
        fontWeight: theme.typography.fontWeightBold,
    },
}));

export const StyledTestScreenListItemContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 0),
}));

export const StyledTestScreenListHeaderContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    width: '100%',
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const StyledAuthorizedChip = styled(Chip)(({ theme }) => ({
    borderRadius: theme.constants.borderRadius,
    backgroundColor: alpha(theme.palette.success.main, 0.075),
    color: theme.palette.success.main,
}));

export const StyledSetupGuideReviewScreenListContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
}));

export const StyledCardMediaBox = styled(Box)(() => ({
    height: '24px',
    width: '24px',
    borderRadius: '2px',
    overflow: 'hidden',
}));
