import { LoaderFn } from 'react-location';
import { getConnectionTypes, ConnectionTypesReportRequest } from '../../data/report/connectionTypes';
import { LocationGenerics } from '../../router';
import { connectionTypes$ } from '../../store/report/connectionTypes';

export const connectionTypesReportLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    const connectionTypes = await getConnectionTypes({
        userType: routeMatch.search.userType as ConnectionTypesReportRequest['userType'],
    });

    connectionTypes$.next(connectionTypes);

    return {};
};
