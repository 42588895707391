import { BehaviorSubject } from 'rxjs';
import { refreshBillingEntitiesAction$ } from './billing-details';

export const openSalableCheckoutWindow = (
    location: string,
    loadingObs: BehaviorSubject<boolean>[],
    errorObs: BehaviorSubject<string | undefined>[] | BehaviorSubject<string>[]
): void => {
    const consentWindow = window.open(location);
    // Close checkout screen after 10 min
    const timeoutNr = window.setTimeout(() => {
        consentWindow?.close();
        errorObs.forEach((error$) => error$.next('Team Plan payment timed out.'));
        loadingObs.forEach((loading$) => loading$.next(false));
    }, 600000);
    // When consent screen has been closed stop authorization loading
    // stops the endless loading indicator when the user closes the screen
    const intervalNr = window.setInterval(() => {
        // !== is required for compatibility with Opera
        if (consentWindow?.closed !== false) {
            window.clearInterval(intervalNr);
            window.clearTimeout(timeoutNr);
            setTimeout(() => {
                errorObs.forEach((error$) => {
                    if (!error$.value) {
                        error$.next('Team Plan payment failed.');
                        refreshBillingEntitiesAction$.next();
                    }
                });
                loadingObs.forEach((loading$) => loading$.next(false));
            }, 2000);
        }
    }, 2000);
};
