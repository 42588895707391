import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export interface SavedBillingDetailsListProps {
    billingEntities: {
        uid: string;
        name: string;
        email: string;
        organizations: {
            uid: string;
            name: string;
            avatar?: string;
        }[];
    }[];
    onEditBillingDetails(uid: string): void;
}

export interface UpdateBillingEntityEvent {
    uid: string;
    name: string;
    email: string;
}

interface SavedBillingDetailsListItemProps {
    uid: string;
    name: string;
    email: string;
    onEditBillingDetails(uid: string): void;
}

const StyledContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
}));

const StyledItemContainerBox = styled(Box)(({ theme }) => ({
    width: 518,
    padding: theme.spacing(0, 2, 2, 2),
    borderRadius: theme.constants.borderRadius,
}));

const StyledListItemBox = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    borderRadius: theme.constants.borderRadius,
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 0),
}));

const StyledEditLink = styled(Typography)(() => ({
    cursor: 'pointer',
    '&:hover': {
        textDecoration: 'underline',
    },
}));

export const SavedBillingDetailsList: React.FC<SavedBillingDetailsListProps> = ({
    billingEntities,
    onEditBillingDetails,
}) => {
    return (
        <StyledContainer>
            {billingEntities.map(({ uid, name, email }, index) => (
                <SavedBillingDetailsListItem
                    key={index}
                    uid={uid}
                    name={name}
                    email={email}
                    onEditBillingDetails={onEditBillingDetails}
                />
            ))}
        </StyledContainer>
    );
};

export const SavedBillingDetailsListItem: React.FC<SavedBillingDetailsListItemProps> = ({
    uid,
    name,
    email,
    onEditBillingDetails,
}) => {
    return (
        <StyledItemContainerBox>
            <StyledListItemBox>
                <Box>
                    <Typography fontWeight={500}>{name}</Typography>
                    <Typography variant="body1" color="text.secondary">
                        {email}
                    </Typography>
                </Box>
                <StyledEditLink color="primary" onClick={() => onEditBillingDetails(uid)}>
                    Edit
                </StyledEditLink>
            </StyledListItemBox>
        </StyledItemContainerBox>
    );
};
