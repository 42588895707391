import { getFetchOptions } from '../../../utils/fetch';
import { configTopic$, stitchSession$ } from '../../config';
import { GenericAppConnectionDetails, SaveHeaderProps } from './types';

export const saveHeaders = async (request: SaveHeaderProps): Promise<GenericAppConnectionDetails> => {
    const connectorUrl = configTopic$.value.connection?.generic?.baseUrl;
    if (!connectorUrl) {
        throw new Error('No Generic app connector url configured in meta');
    }

    const fetchOptions = getFetchOptions({ Authorization: stitchSession$.value?.jwt ?? '' }, request);

    const response = await fetch(`${connectorUrl}/save`, fetchOptions);
    if (!response.ok) {
        console.error('Error while saving headers');

        const message = await response.text();
        if (message) {
            throw new Error(message);
        } else {
            throw Error(`Unexpected error while saving headers to Generic Connector Service: ${response.status}`);
        }
    }

    const respBody = await response.text();
    if (!respBody) {
        throw new Error('Missing response body from Connector request');
    }

    return JSON.parse(respBody);
};
