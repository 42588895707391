import { useObservableState } from 'observable-hooks';
import { StartWizardDialog } from '../../components/wizard/dialogs/StartWizardDialog';
import { updateWizardStateAction$, updateWizardStepAction$, wizardState$, wizardStep$ } from '../../store/wizard';
import { WizardState, WizardStep } from '../../components/wizard/dialogs/types';

export const StartWizardDialogContainer: React.FC = () => {
    const wizardState = useObservableState(wizardState$);
    const wizardStep = useObservableState(wizardStep$);

    const open = wizardState === WizardState.ACTIVE && wizardStep === WizardStep.START;
    return (
        <StartWizardDialog
            open={open}
            onClose={() => updateWizardStateAction$.next(WizardState.CONFIRM)}
            onStart={() => updateWizardStepAction$.next(WizardStep.CREATE_WORKSPACE)}
        />
    );
};
