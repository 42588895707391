import { getReplayInvocationPayload as getReplayInvocationPayloadRpc } from '@avst-stitch/repository-lib/lib';
import { Response as ReplayInvocationPayload } from '@avst-stitch/repository-lib/lib/rpcs/getReplayInvocationPayload';
import { repositoryInvoker } from '../utils/repository';
import { ReplayInvocationDetails } from '../store/workspace/replay-invocation';
export type { ReplayInvocationPayload };

export const getReplayInvocationPayload = async (details: ReplayInvocationDetails): Promise<string> => {
    const response = await getReplayInvocationPayloadRpc(repositoryInvoker, details);

    return response.payload;
};
