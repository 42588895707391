import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DeveloperBoardOutlinedIcon from '@mui/icons-material/DeveloperBoardOutlined';
import { AbsoluteCenteredLoadingSpinner } from '../../common/loading/AbsoluteCenteredLoadingSpinner';
import { Button } from '../../common/buttons/Button';
import { DialogAlert, StyledDialogTitle } from '../../for-deprecation/dialog/DialogComponents';
import { Environment } from './Environment';
import { IconCircle } from '../../for-deprecation/IconCircle';
import { handleKeyDown } from '../../../utils/handleKeyDown';

interface ManageEnvironmentDeploymentTargetsDialogProps {
    errors?: string;
    deployments: {
        uid: string;
        name: string;
    }[];
    loading?: boolean;
    mappings: {
        environment: {
            uid: string;
            name: string;
        };
        deploymentUid?: string;
    }[];
    open?: boolean;
    saving?: boolean;
    onSave(event: EnvironmentDeploymentMapping[]): void;
    onCancel(): void;
}

export interface EnvironmentDeploymentMapping {
    environmentUid: string;
    deploymentUid: string;
}

let processing = false;

export const ManageEnvironmentDeploymentTargetsDialog: React.FC<ManageEnvironmentDeploymentTargetsDialogProps> = ({
    deployments,
    errors,
    loading = false,
    mappings: mapping,
    open = false,
    saving = false,
    onSave,
    onCancel,
}) => {
    const [targetList, updateTargetList] = useState<EnvironmentDeploymentMapping[]>([]);

    useEffect(() => {
        processing = false;
    }, [targetList]);

    const handleUpdate = (option: EnvironmentDeploymentMapping): void => {
        if (!processing) {
            let isFound = false;
            const updatedList = targetList.map((x) => {
                if (x.environmentUid === option.environmentUid) {
                    isFound = true;
                    x.deploymentUid = option.deploymentUid;
                }
                return x;
            });
            if (isFound) {
                updateTargetList(() => [...updatedList]);
            } else {
                updateTargetList((state) => [...state, option]);
            }
            processing = true;
        }
    };

    return (
        <Dialog
            open={open}
            onKeyDown={(event) =>
                handleKeyDown({
                    event,
                    enterCondition: !saving && !loading,
                    enterFn: () => onSave(targetList),
                    escFn: onCancel,
                })
            }
        >
            <StyledDialogTitle>
                <IconCircle icon={<DeveloperBoardOutlinedIcon />} />
                <DialogTitle sx={{ mb: 2 }} variant="h6">
                    Deployment Manager
                </DialogTitle>
            </StyledDialogTitle>
            {loading ? (
                <AbsoluteCenteredLoadingSpinner />
            ) : (
                <>
                    <DialogContent>
                        {errors && <DialogAlert severity="error" alertTitle="Error" text={errors} />}
                        {mapping.map((env, i) => (
                            <Environment key={i} optionList={deployments} envItem={env} handleUpdate={handleUpdate} />
                        ))}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onCancel()} variant="outlined">
                            Cancel
                        </Button>
                        <Button
                            busy={saving}
                            disabled={saving && loading}
                            variant="contained"
                            color="primary"
                            onClick={() => onSave(targetList)}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};
