import { saveWorkspacePackages as saveWorkspacePackagesRpc } from '@avst-stitch/repository-lib/lib';
import { getWorkspacePackages as getWorkspacePackagesRpc } from '@avst-stitch/repository-lib/lib';
import {
    Response as WorkspacePackages,
    WorkspacePackage,
} from '@avst-stitch/repository-lib/lib/rpcs/getWorkspacePackages';
import { repositoryInvoker } from '../utils/repository';
export type { WorkspacePackages, WorkspacePackage };

export const saveWorkspacePackages = async (workspaceUid: string, packages: WorkspacePackages): Promise<void> => {
    return await saveWorkspacePackagesRpc(repositoryInvoker, {
        workspaceUid,
        packages,
    });
};

export const getWorkspacePackages = async (workspaceUid: string): Promise<WorkspacePackages> => {
    return await getWorkspacePackagesRpc(repositoryInvoker, {
        workspaceUid,
    });
};
