import { useObservableState } from 'observable-hooks';
import { GithubManageConnectionDialog } from '../../../../components/connections/apps/github/ManageConnectionDialog';
import {
    githubConnectionSaving$,
    githubManageConnectionAuthorizeLoading$,
    githubManageConnectionCloseDialogAction$,
    githubManageConnectionDetails$,
    githubManageConnectionDialogErrors$,
    githubManageConnectionDialogOpen$,
    githubSaveConnectionAction$,
} from '../../../../store/connection/github/authorize-connection';
import { loggedInUserConnections$ } from '../../../../store/connections';

export const GithubManageConnectionDialogContainer: React.FC = () => {
    const open = useObservableState(githubManageConnectionDialogOpen$);
    const saving = useObservableState(githubConnectionSaving$);
    const authorizeLoading = useObservableState(githubManageConnectionAuthorizeLoading$);
    const details = useObservableState(githubManageConnectionDetails$);
    const connection = useObservableState(loggedInUserConnections$).find(
        (connection) => connection.uid === details?.uid
    );
    const existingGithubConnection = useObservableState(loggedInUserConnections$).find(
        (x) => x.connectionType.name.toLowerCase() === 'github'
    );

    const errors = useObservableState(githubManageConnectionDialogErrors$);

    return (
        <GithubManageConnectionDialog
            isFirstConnection={!existingGithubConnection}
            open={open}
            saving={saving}
            errors={errors}
            authorizeLoading={authorizeLoading}
            name={connection?.name ?? ''}
            authorized={!!connection?.authorized}
            onCancel={() => githubManageConnectionCloseDialogAction$.next()}
            onSave={(name) => githubSaveConnectionAction$.next(name)}
            connectionType={details?.connectionType.name}
        />
    );
};
