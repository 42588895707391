import loader from '@monaco-editor/loader';

/**
 * Remove all modules from the editor/compiler
 */
export const clearSourceModules = async (): Promise<void> => {
    const monaco = await loader.init();
    monaco.editor.getModels().forEach((model) => model.dispose());
};

interface RenameModule {
    readonly name: string;
    readonly newName: string;
}

/**
 * Rename a SourceModule from the editor/compiler
 */
export const renameSourceModule = async ({ name, newName }: RenameModule): Promise<void> => {
    const monaco = await loader.init();

    const uri = monaco.Uri.file(`/${name}.ts`);
    const model = monaco.editor.getModel(uri);

    if (model) {
        const newUri = monaco.Uri.file(`/${newName}.ts`);
        const newModel = monaco.editor.getModel(newUri);

        if (!newModel) {
            const content = model.getValue();
            model.dispose();
            monaco.editor.createModel(content, undefined, newUri);
        }
    }
};
