import { useState } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/system';

export interface ExistingBillingDetailsListProps {
    billingEntities: {
        uid: string;
        contactName: string;
        contactEmail: string;
    }[];
    selectedBillingEntity?: string;
    onSelect(uid: string): void;
}

const StyledContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
}));

const StyledListItemBox = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    borderRadius: theme.constants.borderRadius,
    height: 82,
    justifyContent: 'space-between',
    backgroundColor: `${theme.palette.background.paper} !important`,
    '&:hover': {
        border: theme.constants.borderSelected,
    },
}));

const StyledFormControlLabelTitle = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
}));

const StyledFormControlLabelText = styled('div')(({ theme }) => ({
    paddingLeft: theme.spacing(1),
}));

const StyledFormControlLabelSecondaryText = styled('div')(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    color: theme.palette.secondary.main,
}));

const StyledBox = styled(Box)(() => ({
    display: 'flex',
    width: '100%',
    height: '100%',
}));

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
    margin: 0,
    width: '100%',
}));

const StyledRadio = styled(Radio)(() => ({
    '&:hover': {
        backgroundColor: 'transparent',
    },
}));

export const ExistingBillingDetailsList: React.FC<ExistingBillingDetailsListProps> = ({
    billingEntities,
    selectedBillingEntity,
    onSelect,
}) => {
    const [currentBillingEntity, setCurrentBillingEntity] = useState(selectedBillingEntity);

    const handleUpdateBillingEntity = (uid: string): void => {
        setCurrentBillingEntity(uid);
        onSelect(uid);
    };

    return (
        <StyledContainer>
            <RadioGroup
                name="controlled-radio-buttons-group"
                value={currentBillingEntity}
                onChange={(e) => handleUpdateBillingEntity(e.target.value)}
            >
                {billingEntities.map((item, index) => (
                    <StyledListItemBox
                        sx={{
                            backgroundColor:
                                item.uid === currentBillingEntity
                                    ? (theme: Theme) => theme.palette.background.default
                                    : (theme: Theme) => theme.palette.background.paper,
                        }}
                        key={index}
                    >
                        <StyledBox>
                            <StyledFormControlLabel
                                value={item.uid}
                                control={<StyledRadio />}
                                label={
                                    <>
                                        <StyledFormControlLabelTitle>
                                            <StyledFormControlLabelText>{item.contactName}</StyledFormControlLabelText>
                                            <StyledFormControlLabelSecondaryText>
                                                <Typography variant="body1" color="text.secondary">
                                                    {item.contactEmail}
                                                </Typography>
                                            </StyledFormControlLabelSecondaryText>
                                        </StyledFormControlLabelTitle>
                                    </>
                                }
                            />
                        </StyledBox>
                    </StyledListItemBox>
                ))}
            </RadioGroup>
        </StyledContainer>
    );
};
