import { getUserOnboardingData as getUserOnboardingDataRpc } from '@avst-stitch/repository-lib/lib';
import {
    Request as GetUserOnboardingDataRequest,
    Response as UsersWithOnboardingData,
} from '@avst-stitch/repository-lib/lib/rpcs/report/getUserOnboardingData';
import { repositoryInvoker } from '../../utils/repository';
export type { UsersWithOnboardingData, GetUserOnboardingDataRequest };

export const getUserOnboardingData = async (
    request: GetUserOnboardingDataRequest
): Promise<UsersWithOnboardingData> => {
    return await getUserOnboardingDataRpc(repositoryInvoker, request);
};
