import Link from '@mui/material/Link';
import OpenInNew from '@mui/icons-material/OpenInNew';
import React from 'react';

interface OpenInNewLinkProps {
    url?: string;
    children?: React.ReactNode;
}

export const OpenInNewLink: React.FC<OpenInNewLinkProps> = ({ url, children }) => (
    <Link href={url} target="_blank">
        {children}&#8239;
        <OpenInNew fontSize="inherit" />
    </Link>
);
