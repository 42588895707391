import { useObservableState } from 'observable-hooks';
import { userOnboardingData$ } from '../../store/report/userOnboardingData';
import { UserOnboardingDataReportPage } from '../../components/report/UserOnboardingDataReportPage';
import { GetUserOnboardingDataRequest } from '../../data/report/userOnboardingData';
import { useMatch, useNavigate } from 'react-location';

export const UserOnboardingDataReportPageContainer: React.FC = () => {
    const navigate = useNavigate();
    const match = useMatch();
    const users = useObservableState(userOnboardingData$);
    return (
        <UserOnboardingDataReportPage
            users={users}
            userType={match.search.userType as GetUserOnboardingDataRequest['userType']}
            status={match.search.status as GetUserOnboardingDataRequest['status']}
            termsAccepted={match.search.termsAccepted as GetUserOnboardingDataRequest['termsAccepted']}
            onSearch={(userType, status, termsAccepted) => {
                navigate({
                    to: '../',
                });
                setTimeout(
                    () =>
                        navigate({
                            to: './userOnboardingData',
                            search: {
                                search: true,
                                userType,
                                status,
                                termsAccepted,
                            },
                        }),
                    100
                );
            }}
        />
    );
};
