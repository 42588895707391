import { getEnvironmentVariables as getEnvironmentVariablesRpc } from '@avst-stitch/repository-lib/lib';
import { saveEnvironmentVariables as saveEnvironmentVariablesRpc } from '@avst-stitch/repository-lib/lib';
import { Response as EnvironmentVariables } from '@avst-stitch/repository-lib/lib/rpcs/getEnvironmentVariables';
import { Request as saveEnvironmentVariablesRequest } from '@avst-stitch/repository-lib/lib/rpcs/saveEnvironmentVariables';
import { repositoryInvoker } from '../utils/repository';
export type { EnvironmentVariables, saveEnvironmentVariablesRequest };

export const getEnvironmentVariables = async (
    workspaceUid: string,
    environmentUid: string
): Promise<EnvironmentVariables> => {
    return await getEnvironmentVariablesRpc(repositoryInvoker, {
        workspaceUid,
        environmentUid,
    });
};

export const saveEnvironmentVariables = async (request: saveEnvironmentVariablesRequest): Promise<void> => {
    return await saveEnvironmentVariablesRpc(repositoryInvoker, request);
};
