import { useObservableState } from 'observable-hooks';
import {
    organizationBillingDetails$,
    selectedOrganizationPlan$,
    selectedOrganizationUid$,
} from '../../../store/organization';
import {
    changeOrganizationPlanActionV2$,
    changeOrganizationPlanConfirmPurchaseDialogError$,
    changeOrganizationPlanConfirmPurchaseDialogLoading$,
    changeOrganizationPlanConfirmPurchaseDialogNextPlan$,
    closeChangeOrganizationPlanConfirmPurchaseDialogAction$,
    changeOrganizationPlanConfirmPurchaseDialogOpen$,
} from '../../../store/organization/changeOrganizationPlan';
import { ChangeOrganizationPlanConfirmPurchaseDialog } from '../../../components/organizations/plan/change-organization-plan-wizard/ChangeOrganizationPlanConfirmPurchaseDialog';

export const ChangeOrganizationPlanConfirmPurchaseContainer: React.FC = () => {
    const open = useObservableState(changeOrganizationPlanConfirmPurchaseDialogOpen$);
    const loading = useObservableState(changeOrganizationPlanConfirmPurchaseDialogLoading$);
    const error = useObservableState(changeOrganizationPlanConfirmPurchaseDialogError$);
    const nextPlan = useObservableState(changeOrganizationPlanConfirmPurchaseDialogNextPlan$);
    const currentPlan = useObservableState(selectedOrganizationPlan$);
    const orgUid = useObservableState(selectedOrganizationUid$);
    const billingDetails = useObservableState(organizationBillingDetails$);

    if (!currentPlan || !nextPlan) {
        return null;
    }

    const billingEmail = billingDetails?.billingEntities.find(
        (be) => be.uid === billingDetails.selectedBillingEntityUid
    )?.contactEmail;

    return (
        <ChangeOrganizationPlanConfirmPurchaseDialog
            open={open}
            currentPaymentType={currentPlan.paymentType}
            billingEmail={billingEmail}
            nextPlan={nextPlan}
            errors={error}
            loading={loading}
            onCancel={() => closeChangeOrganizationPlanConfirmPurchaseDialogAction$.next()}
            onConfirm={() =>
                changeOrganizationPlanActionV2$.next({
                    plan: nextPlan,
                    organizationUid: orgUid || '',
                    existingBillingEntityUid: billingDetails?.selectedBillingEntityUid,
                    originalReferrer: window.location.origin,
                })
            }
        />
    );
};
