import React from 'react';
import { styled } from '@mui/material';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { SxProps, Theme } from '@mui/material/styles';

interface PageContainerProps {
    readonly children: React.ReactNode;
    readonly dataTestId?: string;
    readonly sx?: SxProps<Theme>;
}

interface PageTitleProps {
    readonly label?: string;
    readonly title: string;
}

interface PageGridProps {
    readonly children: React.ReactNode;
    readonly sx?: SxProps<Theme>;
}

const StyledOuterContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    overflow: 'auto',
    padding: theme.spacing(2, 4),
}));

const StyledUpperContainer = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
}));

const StyledUpperGroup = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
}));

const StyledViewButtonGroup = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    marginRight: theme.spacing(3),
}));

export const StyledPageTitleWrap = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    height: 'auto',
    marginBottom: theme.spacing(2),
    '& .MuiSvgIcon-root': {
        marginLeft: theme.spacing(1.5),
    },
}));

export const StyledChip = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.action.selected,
    height: 22,
    fontSize: theme.typography.body2.fontSize,
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    '& .MuiChip-label': {
        padding: theme.spacing(0.75, 1),
    },
}));

const StyledSubTitleWrap = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(3),
}));

const StyledGridWrap = styled('div')(({ theme }) => ({
    columnGap: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(3, 0.5, 3),
    rowGap: theme.spacing(2),
}));

const StyledImageInfoTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
}));

export const PageContainer: React.FC<PageContainerProps> = ({ sx = [], dataTestId, children }) => {
    return (
        <StyledOuterContainer sx={sx} data-test-id={dataTestId ? dataTestId : 'page-container'}>
            {children}
        </StyledOuterContainer>
    );
};

export const PageUpperContainer: React.FC = ({ children }) => {
    return <StyledUpperContainer>{children}</StyledUpperContainer>;
};

export const PageUpperGroup: React.FC = ({ children }) => {
    return <StyledUpperGroup className="pageuppergroup">{children}</StyledUpperGroup>;
};

export const PageViewButtonGroup: React.FC = ({ children }) => {
    return <StyledViewButtonGroup>{children}</StyledViewButtonGroup>;
};

export const PageTitle: React.FC<PageTitleProps> = ({ title, label }) => {
    return (
        <StyledPageTitleWrap className="pagetitle">
            <Typography component="h2" variant="h6">
                {title}
            </Typography>
            {label && <StyledChip label={label} />}
        </StyledPageTitleWrap>
    );
};

export const PageSubTitle: React.FC<TypographyProps> = ({ children, variant = 'subtitle1' }) => {
    return (
        <StyledSubTitleWrap>
            <Typography component="h3" variant={variant}>
                {children}
            </Typography>
        </StyledSubTitleWrap>
    );
};

export const PageGridContainer: React.FC<PageGridProps> = ({ sx = [], children }) => {
    return <StyledGridWrap sx={sx}>{children}</StyledGridWrap>;
};

export const PageItalicText: React.FC<TypographyProps> = ({ children, ...props }) => {
    return (
        <StyledImageInfoTypography {...props}>
            <em>{children}</em>
        </StyledImageInfoTypography>
    );
};
