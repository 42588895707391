import { LoaderFn } from 'react-location';
import { LocationGenerics } from '../../router';
import { getUserOnboardingData, GetUserOnboardingDataRequest } from '../../data/report/userOnboardingData';
import { userOnboardingData$ } from '../../store/report/userOnboardingData';

export const userOnboardingDataReportLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    const userOnboardingData = await getUserOnboardingData({
        userType: routeMatch.search.userType as GetUserOnboardingDataRequest['userType'],
        status: routeMatch.search.status as GetUserOnboardingDataRequest['status'],
        termsAccepted: routeMatch.search.termsAccepted as GetUserOnboardingDataRequest['termsAccepted'],
    });

    userOnboardingData$.next(userOnboardingData);

    return {};
};
