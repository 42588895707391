import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const JiraOnPremiseIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="4 4 24 24"
            className="productIcon"
            aria-label="Jira On Premise"
        >
            <path
                d="M27.0055 4H15.4512C15.4512 6.88 17.7826 9.21143 20.6626 9.21143H22.7883V11.2686C22.7883 14.1486 25.1197 16.48 27.9997 16.48V4.99429C27.9997 4.44571 27.554 4 27.0055 4Z"
                fill="#2684FF"
            />
            <path
                d="M21.2799 9.76001H9.72559C9.72559 12.64 12.057 14.9714 14.937 14.9714H17.0627V17.0286C17.0627 19.9086 19.3942 22.24 22.2742 22.24V10.7543C22.2742 10.2057 21.8284 9.76001 21.2799 9.76001Z"
                fill="url(#paint0_linear_jira_onpremise)"
            />
            <path
                d="M15.5543 15.52H4C4 18.4 6.33143 20.7314 9.21143 20.7314H11.3371V22.7886C11.3371 25.6686 13.6686 28 16.5486 28V16.5143C16.5486 15.9657 16.1029 15.52 15.5543 15.52Z"
                fill="url(#paint1_linear_jira_onpremise)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_jira_onpremise"
                    x1="22.0343"
                    y1="9.77256"
                    x2="17.1181"
                    y2="14.8424"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.176" stopColor="#0052CC" />
                    <stop offset="1" stopColor="#2684FF" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_jira_onpremise"
                    x1="16.6411"
                    y1="15.5637"
                    x2="10.9568"
                    y2="21.0943"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.176" stopColor="#0052CC" />
                    <stop offset="1" stopColor="#2684FF" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
};
