import { useAuth } from '../../hooks/auth';
import { AppMain } from '../../components/app-main/AppMain';
import { wrapAsync } from '../../utils/react';
import { useObservableState, useSubscription } from 'observable-hooks';
import {
    acceptInviteAction$,
    loggedInUserDetails$,
    logoutFromAppAction$,
    stopImpersonationAction$,
    userLoggedOutFromApp$,
} from '../../store/user';
import { useBeforeunload } from 'react-beforeunload';
import { useLocation, useNavigate } from 'react-location';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { ConsentDialog } from '../../components/onboarding/ConsentDialog';
import { getLoggedInUserDetailsAndInitialize } from '../../data/user';
import { VerifyAccountDialog } from '../../components/onboarding/VerifyAccountDialog';
import { updateUserConsent, isAccountVerified } from '../../utils/manageAccount';
import {
    configTopic$,
    sessionExpired$,
    showSessionExpiredWarning$,
    loadTokens$,
    featureFlagsTopic$,
} from '../../store/config';
import { hotjar } from 'react-hotjar';
import { selectedWorkspace$ } from '../../store/workspace';
import { HtmlHead } from '../../components/htmlMeta/Head';
import { navigateAction$ } from '../../store/navigation';
import { workspaceHasUnsavedChanges } from '../../store/workspace/utils';
import { helpAndSupportModalOpen$, openHelpAndSupportModalAction$ } from '../../store/helpAndSupport';
import { themeMode$ } from '../../store/theme';
import { feedbackConnectionStatus$, notificationBannerDetails$ } from '../../store/feedback';
import { readLocalStorage, saveLocalStorage } from '../../utils/localStorage';
import { segmentAnalyticsTrack } from '../../data/segment-analytics';
import { segmentAndPendoAnalyticsTrack } from '../../data/analytics';
import { getBasePath, getPathName } from '../../utils/path';
import { selectedOrganizationUid$, switchOrganizationAction$ } from '../../store/organization';
import { openCreateOrganizationWizardDialogAction$ } from '../../store/organizations';
import { stitchThemeModeKey, ThemeMode } from '../../theme';
import { openOnboardingWizardAction$ } from '../../store/onboarding';

interface AppMainContainerProps {
    rootPath: string;
}

export const AppMainContainer: React.FC<AppMainContainerProps> = ({ rootPath }) => {
    const [consentBusy, setConsentBusy] = useState(false);
    const [retryBusy, setRetryBusy] = useState(false);
    const [logOutBusy, setLogOutBusy] = useState(false);
    const { operations } = useAuth();

    const location = useLocation();
    const navigate = useNavigate();

    useSubscription(navigateAction$, (to) => navigate({ to }));

    useBeforeunload((event) => {
        if (workspaceHasUnsavedChanges()) {
            event.preventDefault();
            return 'Workspaces has unsaved changes, are you sure you want to quit?';
        }
    });

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.current.pathname,
        });
    }, [location.current.pathname]);

    const loggedInUserDetails = useObservableState(loggedInUserDetails$);
    const sessionExpired = useObservableState(sessionExpired$);
    const showSessionExpiredWarning = useObservableState(showSessionExpiredWarning$);
    const config = useObservableState(configTopic$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const termsAccepted = loggedInUserDetails?.termsAccepted;
    const accountVerified = loggedInUserDetails?.accountVerified;
    const isConnectedToFeedback = useObservableState(feedbackConnectionStatus$);
    const notificationBanner = useObservableState(notificationBannerDetails$);
    const userLoggedOutFromApp = useObservableState(userLoggedOutFromApp$);
    const themeMode = useObservableState(themeMode$);
    const helpAndSupportOpen = useObservableState(helpAndSupportModalOpen$);
    const selectedOrganizationUid = useObservableState(selectedOrganizationUid$);
    const featureFlags = useObservableState(featureFlagsTopic$);

    const inviteId = new URLSearchParams(window.location.search).get('inviteId');

    const handleLogOut = async (): Promise<void> => {
        setLogOutBusy(true);
        logoutFromAppAction$.next();
    };

    useEffect(() => {
        if (userLoggedOutFromApp === true) {
            setLogOutBusy(false);
            operations.logout({ redirectUrl: window.location.origin });
        }
    }, [userLoggedOutFromApp]);

    useEffect(() => {
        if (loggedInUserDetails && loggedInUserDetails.uid) {
            if (config.analytics?.hotjar && hotjar.initialized()) {
                hotjar.identify(loggedInUserDetails.uid, {
                    uid: loggedInUserDetails.uid,
                });
            }
            if (config.analytics?.segment?.writeKey) {
                const isLoginEvent: boolean = readLocalStorage('isLoginEvent', false);

                if (isLoginEvent) {
                    if (loggedInUserDetails.publishUserRegisteredEvent) {
                        segmentAndPendoAnalyticsTrack('User Registered', {
                            userId: loggedInUserDetails.uid,
                            loggedInWithIdp: loggedInUserDetails.loggedInWithIdp,
                            stitchTeamMember: loggedInUserDetails.stitchTeamMember,
                            userOrigin: loggedInUserDetails.userOrigin,
                        });
                    }

                    localStorage.removeItem('isLoginEvent');
                    segmentAnalyticsTrack('User Logged In', {
                        userId: loggedInUserDetails.uid,
                        loggedInWithIdp: loggedInUserDetails.loggedInWithIdp,
                        stitchTeamMember: loggedInUserDetails.stitchTeamMember,
                        userOrigin: loggedInUserDetails.userOrigin,
                    });
                }
            }
        }
    }, [loggedInUserDetails?.uid]);

    const handleAgreeConsent = async (): Promise<void> => {
        setConsentBusy(true);
        await updateUserConsent(true);
        const userDetails = await getLoggedInUserDetailsAndInitialize();
        if (inviteId) {
            acceptInviteAction$.next(inviteId);
        }
        loggedInUserDetails$.next(userDetails);
        setConsentBusy(false);
        openOnboardingWizardAction$.next();
    };

    const handleRetry = async (): Promise<void> => {
        setRetryBusy(true);

        const accountVerified = await isAccountVerified();
        if (accountVerified) {
            setRetryBusy(false);
            const userDetails = await getLoggedInUserDetailsAndInitialize();
            loggedInUserDetails$.next(userDetails);
        } else {
            setRetryBusy(false);
        }
    };

    const handleChangeThemeMode = (mode: ThemeMode): void => {
        themeMode$.next(mode);
        saveLocalStorage(stitchThemeModeKey, mode);
    };

    if (loggedInUserDetails && accountVerified === false) {
        return (
            <VerifyAccountDialog
                onRetry={wrapAsync(handleRetry)}
                onCancel={wrapAsync(handleLogOut)}
                isRetryBusy={retryBusy}
            />
        );
    }

    if (loggedInUserDetails && !termsAccepted) {
        return (
            <ConsentDialog
                onAgree={wrapAsync(handleAgreeConsent)}
                onDisagree={wrapAsync(handleLogOut)}
                isConsentBusy={consentBusy}
            />
        );
    }

    const title = selectedWorkspace ? `ScriptRunner Connect - ${selectedWorkspace?.name}` : 'ScriptRunner Connect';

    const selectedOrganization = loggedInUserDetails?.organizations?.find((org) => org.uid === selectedOrganizationUid);

    const selectedPath = getPathName();
    const basePath = getBasePath();

    return (
        <>
            <HtmlHead name={title} />
            <AppMain
                helpAndSupportOpen={helpAndSupportOpen}
                impersonating={loggedInUserDetails?.impersonating}
                isConnectedToFeedback={isConnectedToFeedback}
                isLogOutBusy={logOutBusy}
                notificationBanner={notificationBanner}
                organizations={loggedInUserDetails?.organizations ?? []}
                rootPath={rootPath}
                showSessionExpiredWarning={showSessionExpiredWarning}
                selectedOrganizationUid={selectedOrganizationUid}
                selectedPath={selectedPath}
                sessionExpired={sessionExpired}
                showUpgradePlan={selectedOrganization?.showUpgradePlan}
                themeMode={themeMode}
                userCredentials={{
                    firstName: loggedInUserDetails?.firstName ?? '',
                    lastName: loggedInUserDetails?.lastName ?? '',
                    email: loggedInUserDetails?.email ?? '',
                }}
                useRouter={true}
                displayApiKeys={!!featureFlags.apiKeys}
                basePath={basePath}
                onLogOut={wrapAsync(handleLogOut)}
                onChangeThemeMode={handleChangeThemeMode}
                onCreateNewOrganization={() => openCreateOrganizationWizardDialogAction$.next()}
                onManageAllOrganizations={() => navigate({ to: `${basePath}/teams` })}
                onOpenHelpAndSupport={() => {
                    openHelpAndSupportModalAction$.next();
                    segmentAnalyticsTrack('Connect & Support Accessed', {
                        userId: loggedInUserDetails?.uid,
                        stitchTeamMember: loggedInUserDetails?.stitchTeamMember,
                        userOrigin: loggedInUserDetails?.userOrigin,
                    });
                }}
                onSelectOrganization={(uid) => switchOrganizationAction$.next(uid)}
                onStopImpersonation={() => stopImpersonationAction$.next()}
                onSetLoadTokens={(loadTokens) => loadTokens$.next(loadTokens)}
            />
        </>
    );
};
