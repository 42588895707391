import { LoaderFn } from 'react-location';
import { LocationGenerics } from '../router';
import { loadLoggedInUserConnections } from '../store/connection/utils';
import { loadErrorPage } from '../store/error';

export const connectorsLoader: LoaderFn<LocationGenerics> = async () => {
    try {
        await loadLoggedInUserConnections(undefined, 'connectors');
    } catch (e) {
        loadErrorPage({
            error: e,
            pageTitle: 'Connectors',
            genericMessage: 'Failed to load your connectors.',
        });

        throw e;
    }

    return {};
};
