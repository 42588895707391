import { useState } from 'react';
import { DialogTitle, styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Button } from '../../../common/buttons/Button';
import { DialogAlert, DialogCloseButton } from '../../../for-deprecation/dialog/DialogComponents';
import { SaveCancellationFeedbackRequest } from '../../../../data/organization';

interface CancelOrganizationPlanFeedbackDialogProps {
    open?: boolean;
    saving?: boolean;
    errors?: string;
    onCancel?: () => void;
    onSubmit?: (event: Omit<SaveCancellationFeedbackRequest, 'organizationUid'>) => void;
}

const StyledDialogActions = styled(DialogActions)(() => ({
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
    margin: 0,
}));

const StyledTitleContainer = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexDirection: 'column',
    padding: theme.spacing(2, 0, 3, 0),
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    paddingBottom: theme.spacing(1),
}));

const StyledDialogInstructions = styled(Typography)(() => ({
    display: 'flex',
    justifyContent: 'center',
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    margin: theme.spacing(0, 0, 1, 0),
    width: '100%',
    borderRadius: theme.constants.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
    },
}));

const StyledFormControl = styled(FormControl)(() => ({
    width: '100%',
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        padding: theme.spacing(2),
    },
}));

const StyledTextField = styled(TextField)(() => ({
    '& .MuiInputBase-root': {
        width: '100%',
    },
}));

const FEEDBACK_OPTIONS = {
    COSTS_TOO_MUCH: 'I want to cancel because it costs too much.',
    FEATURE_NOT_AVAILABLE: 'I need features or things that are not available.',
    NOT_SATISFIED: 'I am not satisfied with the product/service.',
    FOUND_BETTER: 'I have found something better.',
    NO_NEED: "I don't need the service any more.",
    OTHER: 'Other reason',
};

export const CancelOrganizationPlanFeedbackDialog: React.FC<CancelOrganizationPlanFeedbackDialogProps> = ({
    open = false,
    saving = false,
    errors,
    onCancel,
    onSubmit,
}) => {
    const [explanation, setExplanation] = useState<string | undefined>(undefined);
    const [reason, setReason] = useState<keyof typeof FEEDBACK_OPTIONS>('COSTS_TOO_MUCH');

    return (
        <StyledDialog open={open}>
            <StyledTitleContainer>
                <div>
                    <DialogCloseButton disabled={saving} onClick={onCancel} />
                    <StyledDialogTitle variant="h4">We are sorry to see you go</StyledDialogTitle>
                    <StyledDialogInstructions variant="body1">
                        Please tell us why you decided to cancel your plan?
                    </StyledDialogInstructions>
                </div>
            </StyledTitleContainer>
            {errors && <DialogAlert severity="error" text={errors} alertTitle="Error" />}
            <DialogContent>
                <StyledFormControl>
                    <RadioGroup
                        value={reason}
                        onChange={(e) => setReason(e.target.value as keyof typeof FEEDBACK_OPTIONS)}
                    >
                        {Object.entries(FEEDBACK_OPTIONS).map(([option, label], idx) => (
                            <StyledFormControlLabel
                                sx={(theme) => ({
                                    border: reason === option ? `1px solid ${theme.palette.primary.main}` : undefined,
                                })}
                                key={idx}
                                value={option}
                                control={<Radio />}
                                label={label}
                            />
                        ))}
                    </RadioGroup>
                </StyledFormControl>
                <StyledTextField
                    value={explanation}
                    onChange={(e) => setExplanation(e.target.value)}
                    label="Please explain why"
                    multiline
                    fullWidth
                    placeholder="Provide a short description"
                />
            </DialogContent>
            <StyledDialogActions>
                <Button
                    busy={saving}
                    variant="contained"
                    onClick={() => onSubmit?.({ reason: FEEDBACK_OPTIONS[reason], explanation })}
                >
                    Continue
                </Button>
                <Button disabled={saving} variant="text" onClick={onCancel}>
                    Skip
                </Button>
            </StyledDialogActions>
        </StyledDialog>
    );
};
