import { useObservableState } from 'observable-hooks';
import { AzureDevopsAppConfigureDialog } from '../../../../components/connections/apps/azure-devops/AzureDevopsAppConfigureDialog';
import { configTopic$ } from '../../../../store/config';
import {
    azureDevopsConnectionSaving$,
    azureDevopsManageConnectionDetails$,
    azureDevopsAppSetupDialogErrors$,
    azureDevopsAppSetupDialogStage$,
    azureDevopsAppSetupDialogOpen$,
    azureDevopsAppSetupCloseDialogAction$,
    azureDevopsAppSetupAuthorizeAction$,
} from '../../../../store/connection/azure-devops/setup-connection';

export const AzureDevopsConfigureDialog: React.FC = () => {
    const open = useObservableState(azureDevopsAppSetupDialogOpen$);
    const saving = useObservableState(azureDevopsConnectionSaving$);
    const details = useObservableState(azureDevopsManageConnectionDetails$);
    const errors = useObservableState(azureDevopsAppSetupDialogErrors$);
    const currentStage = useObservableState(azureDevopsAppSetupDialogStage$);
    const callbackUrl = configTopic$.value.connection?.azureDevops?.restBaseUrl;
    const adminConsentUrl = configTopic$.value.connection?.azureDevops?.adminConsentUrl;

    return (
        <AzureDevopsAppConfigureDialog
            name={details?.name ?? ''}
            open={open}
            onClose={() => {
                azureDevopsAppSetupCloseDialogAction$.next();
                azureDevopsAppSetupDialogStage$.next(0);
            }}
            currentStage={currentStage}
            setStage={(stage) => azureDevopsAppSetupDialogStage$.next(stage)}
            clientInfo={{
                tenantId: details?.tenantId ?? '',
                clientId: details?.clientId ?? '',
                clientSecret: details?.clientSecret ?? '',
            }}
            saving={saving}
            errors={errors}
            setError={(error) => azureDevopsAppSetupDialogErrors$.next(error)}
            clearErrors={() => azureDevopsAppSetupDialogErrors$.next(undefined)}
            onSave={(appInfoProps) => azureDevopsAppSetupAuthorizeAction$.next(appInfoProps)}
            callbackUrl={`${callbackUrl}`}
            adminConsentUrl={`${adminConsentUrl}`}
        />
    );
};
