import { useObservableState } from 'observable-hooks';
import { GitlabManageConnectionDialog } from '../../../../components/connections/apps/gitlab/ManageConnectionDialog';
import {
    gitlabConnectionSaving$,
    gitlabManageConnectionAuthorizeLoading$,
    gitlabManageConnectionCloseDialogAction$,
    gitlabManageConnectionDetails$,
    gitlabManageConnectionDialogErrors$,
    gitlabManageConnectionDialogOpen$,
    gitlabManageConnectionInitiateSetupAction$,
    gitlabSaveConnectionAction$,
} from '../../../../store/connection/gitlab/setup-connection';
import { loggedInUserConnections$ } from '../../../../store/connections';

export const GitlabManageConnectionDialogContainer: React.FC = () => {
    const open = useObservableState(gitlabManageConnectionDialogOpen$);
    const saving = useObservableState(gitlabConnectionSaving$);
    const authorizeLoading = useObservableState(gitlabManageConnectionAuthorizeLoading$);
    const details = useObservableState(gitlabManageConnectionDetails$);
    const connection = useObservableState(loggedInUserConnections$).find(
        (connection) => connection.uid === details?.uid
    );
    const errors = useObservableState(gitlabManageConnectionDialogErrors$);

    return (
        <GitlabManageConnectionDialog
            open={open}
            saving={saving}
            errors={errors}
            instanceUrl={details?.instanceUrl}
            authorizeLoading={authorizeLoading}
            name={connection?.name ?? ''}
            authorized={!!connection?.authorized}
            onCancel={() => gitlabManageConnectionCloseDialogAction$.next()}
            onSave={(name) => gitlabSaveConnectionAction$.next(name)}
            onAuthorize={(name) => gitlabManageConnectionInitiateSetupAction$.next(name)}
            connectionType={details?.connectionType.name}
        />
    );
};
