import { useObservableState } from 'observable-hooks';
import { ZoomConnectionAppConfigureDialog } from '../../../../components/connections/apps/zoom/ZoomAppConfigureDialog';
import { configTopic$ } from '../../../../store/config';
import {
    zoomConnectionSaving$,
    zoomManageConnectionDetails$,
    zoomAppSetupDialogErrors$,
    zoomAppSetupDialogOpen$,
    zoomAppSetupCloseDialogAction$,
    zoomAppSetupAuthorizeAction$,
    zoomAppSetupDialogStage$,
} from '../../../../store/connection/zoom/setup-connection';

export const ZoomConfigureDialog: React.FC = () => {
    const open = useObservableState(zoomAppSetupDialogOpen$);
    const saving = useObservableState(zoomConnectionSaving$);
    const details = useObservableState(zoomManageConnectionDetails$);
    const errors = useObservableState(zoomAppSetupDialogErrors$);
    const currentStage = useObservableState(zoomAppSetupDialogStage$);
    const callbackUrl = configTopic$.value.connection?.zoom?.restBaseUrl;

    return (
        <ZoomConnectionAppConfigureDialog
            open={open}
            onClose={() => {
                zoomAppSetupCloseDialogAction$.next();
                zoomAppSetupDialogStage$.next(0);
            }}
            currentStage={currentStage}
            setStage={(stage) => zoomAppSetupDialogStage$.next(stage)}
            clientInfo={{
                clientId: details?.clientId ?? '',
                clientSecret: details?.clientSecret ?? '',
            }}
            saving={saving}
            errors={errors}
            setError={(error) => zoomAppSetupDialogErrors$.next(error)}
            clearErrors={() => zoomAppSetupDialogErrors$.next(undefined)}
            onSave={(appInfoProps) => zoomAppSetupAuthorizeAction$.next(appInfoProps)}
            callbackUrl={`${callbackUrl}`}
        />
    );
};
