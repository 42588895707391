import { useObservableState } from 'observable-hooks';
import { AtlassianOnPremiseConnectionAppConfigureDialog } from '../../../../components/connections/apps/atlassian-on-premise/AtlassianOnPremiseAppConfigureDialog';
import { configTopic$ } from '../../../../store/config';
import {
    atlassianOnPremiseConnectionSaving$,
    atlassianOnPremiseManageConnectionDetails$,
    atlassianOnPremiseAppSetupDialogErrors$,
    atlassianOnPremiseAppSetupDialogOpen$,
    atlassianOnPremiseAppSetupCloseDialogAction$,
    atlassianOnPremiseAppSetupAuthorizeAction$,
    atlassianOnPremiseAppSetupDialogStage$,
} from '../../../../store/connection/atlassian-on-premise/setup-connection';

export const AtlassianOnPremiseConfigureDialog: React.FC = () => {
    const open = useObservableState(atlassianOnPremiseAppSetupDialogOpen$);
    const saving = useObservableState(atlassianOnPremiseConnectionSaving$);
    const details = useObservableState(atlassianOnPremiseManageConnectionDetails$);
    const errors = useObservableState(atlassianOnPremiseAppSetupDialogErrors$);
    const currentStage = useObservableState(atlassianOnPremiseAppSetupDialogStage$);
    const {
        baseUrl,
        nonLegacyConsumerKey = '',
        consumerName = '',
    } = configTopic$.value.connection?.atlassianOnPremise ?? {};

    return (
        <AtlassianOnPremiseConnectionAppConfigureDialog
            open={open}
            onClose={() => {
                atlassianOnPremiseAppSetupCloseDialogAction$.next();
                atlassianOnPremiseAppSetupDialogStage$.next(0);
            }}
            currentStage={currentStage}
            setStage={(stage) => atlassianOnPremiseAppSetupDialogStage$.next(stage)}
            atlassianOnPremiseUrl={details?.instanceUrl ?? ''}
            saving={saving}
            errors={errors}
            setError={(error) => atlassianOnPremiseAppSetupDialogErrors$.next(error)}
            clearErrors={() => atlassianOnPremiseAppSetupDialogErrors$.next(undefined)}
            onSave={(appInfoProps) => atlassianOnPremiseAppSetupAuthorizeAction$.next(appInfoProps)}
            callbackUrl={`${baseUrl}/callback`}
            setupDetails={{
                nonLegacyConsumerKey,
                consumerName,
                publicKey: details?.publicKey ?? '',
            }}
            connectionType={details?.connectionType.name}
        />
    );
};
