import { LoaderFn } from 'react-location';
import { getUserActivation, UserActivationRequest } from '../../data/report/userActivation';
import { LocationGenerics } from '../../router';
import { userActivation$ } from '../../store/report/userActivation';

export const userActivationReportLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    const users = await getUserActivation({
        status: routeMatch.search.status as UserActivationRequest['status'],
        userType: routeMatch.search.userType as UserActivationRequest['userType'],
    });

    userActivation$.next(users);

    return {};
};
