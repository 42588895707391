import { BehaviorSubject, Subject } from 'rxjs';
import { monitor } from './monitor';

interface ConfirmDialogOptions {
    cancelLabel?: string;
    message?: string;
    proceedLabel?: string;
    title?: string;
    onCancel?: () => void | Promise<void>;
    onClose?: () => void | Promise<void>;
    onProceed?: () => void | Promise<void>;
}

export const confirmDialogDetails$ = monitor(
    'confirmDialogDetails$',
    new BehaviorSubject<ConfirmDialogOptions | undefined>(undefined)
);

export const confirmDialogOpen$ = monitor('confirmDialogOpen$', new BehaviorSubject(false));

export const openConfirmDialogAction$ = monitor('openConfirmDialogAction$', new Subject<ConfirmDialogOptions>());
export const closeConfirmDialogAction$ = monitor('closeConfirmDialogAction$', new Subject<void>());

openConfirmDialogAction$.subscribe((details) => {
    confirmDialogDetails$.next(details);
    confirmDialogOpen$.next(true);
});

closeConfirmDialogAction$.subscribe(() => {
    confirmDialogOpen$.next(false);
    confirmDialogDetails$.next(undefined);
});

export const promptMessage = (message: string, title?: string): void => {
    openConfirmDialogAction$.next({
        title,
        message,
        proceedLabel: 'OK',
        onProceed: () => {
            closeConfirmDialogAction$.next();
        },
    });
    confirmDialogOpen$.next(true);
};

export const promptQuestion = (details: ConfirmDialogOptions): void => {
    openConfirmDialogAction$.next({
        title: details.title,
        message: details.message,
        cancelLabel: details.cancelLabel ?? 'No',
        proceedLabel: details.proceedLabel ?? 'Yes',
        onProceed: () => {
            closeConfirmDialogAction$.next();
            details.onProceed?.();
        },
        onCancel: () => {
            closeConfirmDialogAction$.next();
            details.onCancel?.();
        },
        onClose: () => {
            closeConfirmDialogAction$.next();
        },
    });
};
