import { useObservableState } from 'observable-hooks';
import { OrganisationsReportPage } from '../../components/report/OrganisationsReportPage';
import { organisations$ } from '../../store/report/organisations';
import { OrganisationsRequest } from '../../data/report/organisations';
import { useMatch, useNavigate } from 'react-location';

export const OrganisationsReportPageContainer: React.FC = () => {
    const organisations = useObservableState(organisations$);
    const navigate = useNavigate();
    const match = useMatch();
    return (
        <OrganisationsReportPage
            organisations={organisations}
            userType={match.search.userType as OrganisationsRequest['userType']}
            status={match.search.status as OrganisationsRequest['status']}
            plan={match.search.plan as OrganisationsRequest['plan']}
            planPeriod={match.search.planPeriod as OrganisationsRequest['planPeriod']}
            onSearch={(userType, status, plan, planPeriod) => {
                navigate({
                    to: '../',
                });
                setTimeout(
                    () =>
                        navigate({
                            to: './teams',
                            search: {
                                search: true,
                                userType,
                                status,
                                plan,
                                planPeriod,
                            },
                        }),
                    100
                );
            }}
        />
    );
};
