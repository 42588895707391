import { useObservableState } from 'observable-hooks';
import { AtlassianCloudAppConfigureDialog } from '../../../../components/connections/apps/atlassian-cloud/AtlassianCloudAppConfigureDialog';
import { configTopic$ } from '../../../../store/config';
import {
    atlassianCloudConnectionSaving$,
    atlassianCloudManageConnectionDetails$,
    atlassianCloudAppSetupDialogErrors$,
    atlassianCloudAppSetupDialogOpen$,
    atlassianCloudAppSetupCloseDialogAction$,
    atlassianCloudAppSetupAuthorizeAction$,
    atlassianCloudAppSetupDialogStage$,
    atlassianCloudAppSetupDialogInstanceType$,
} from '../../../../store/connection/atlassian-cloud/manage-connection';

export const AtlassianCloudConfigureDialog: React.FC = () => {
    const open = useObservableState(atlassianCloudAppSetupDialogOpen$);
    const saving = useObservableState(atlassianCloudConnectionSaving$);
    const details = useObservableState(atlassianCloudManageConnectionDetails$);
    const errors = useObservableState(atlassianCloudAppSetupDialogErrors$);
    const currentStage = useObservableState(atlassianCloudAppSetupDialogStage$);
    const currentInstanceType = useObservableState(atlassianCloudAppSetupDialogInstanceType$);
    const baseUrl = configTopic$.value.connection?.atlassianCloud?.baseUrl;

    return (
        <AtlassianCloudAppConfigureDialog
            open={open}
            onClose={() => {
                atlassianCloudAppSetupCloseDialogAction$.next();
                atlassianCloudAppSetupDialogStage$.next(0);
            }}
            currentStage={currentStage}
            setStage={(stage) => atlassianCloudAppSetupDialogStage$.next(stage)}
            currentInstanceType={currentInstanceType}
            setInstanceType={(instanceType) => atlassianCloudAppSetupDialogInstanceType$.next(instanceType)}
            saving={saving}
            errors={errors}
            setError={(error) => atlassianCloudAppSetupDialogErrors$.next(error)}
            clearErrors={() => atlassianCloudAppSetupDialogErrors$.next(undefined)}
            onSave={(appInfoProps) => atlassianCloudAppSetupAuthorizeAction$.next(appInfoProps)}
            callbackUrl={`${baseUrl}/callback`}
            connectionType={details?.connectionType.name}
            proxyAccount={details?.proxyAccount}
            clientInfo={{
                scopes: details?.scopes,
                clientId: details?.clientId,
                clientSecret: details?.clientSecret,
            }}
        />
    );
};
