import type * as monacoApi from 'monaco-editor/esm/vs/editor/editor.api';
import React, { useEffect } from 'react';
import { useEditor } from './useEditor';

export interface ListenerProps {
    readonly onChange: (value: string, event: monacoApi.editor.IModelContentChangedEvent) => void;
}

/**
 * Add a content change listener to the Editor
 */
export const Listener: React.FC<ListenerProps> = ({ onChange }) => {
    const editor = useEditor();

    useEffect(() => {
        const disposable = editor?.onDidChangeModelContent((event) => {
            onChange?.(editor.getValue(), event);
        });

        return () => {
            disposable?.dispose();
        };
    }, [editor, onChange]);

    return null;
};
