import { useObservableState } from 'observable-hooks';
import { AtlassianCloudEventListenerSetupDialog } from '../../../../components/connections/apps/atlassian-cloud/EventListenerSetupDialog';
import { configTopic$ } from '../../../../store/config';
import {
    atlassianCloudEventListenerSetupDialogCloseAction$,
    atlassianCloudEventListenerSetupDialogDetails$,
    atlassianCloudEventListenerSetupDialogLoading$,
    atlassianCloudEventListenerSetupDialogOpen$,
} from '../../../../store/connection/atlassian-cloud/setup-event-listener';

export const AtlassianCloudEventListenerSetupDialogContainer: React.FC = () => {
    const loading = useObservableState(atlassianCloudEventListenerSetupDialogLoading$);
    const open = useObservableState(atlassianCloudEventListenerSetupDialogOpen$);
    const details = useObservableState(atlassianCloudEventListenerSetupDialogDetails$);
    const config = useObservableState(configTopic$);

    return (
        <AtlassianCloudEventListenerSetupDialog
            loading={loading}
            open={open}
            eventTypeName={details?.eventTypeName ?? ''}
            webhookBaseUrl={config.trigger?.srcExternalUrl ?? ''}
            webhookUrlId={details?.webhookUrl ?? ''}
            setupBaseUrl={details?.setupBaseUrl}
            onClose={() => atlassianCloudEventListenerSetupDialogCloseAction$.next()}
            connectionType={details?.connectionType}
        />
    );
};
