import { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { Button } from '../../common/buttons/Button';
import { DialogAlert, DialogTitleMain } from '../../for-deprecation/dialog/DialogComponents';
import { IconCircle } from '../../for-deprecation/IconCircle';
import { handleKeyDown } from '../../../utils/handleKeyDown';
import { autoFocus } from '../../../utils/autoFocus';
import isEmail from 'validator/lib/isEmail';

interface AddNewMemberDialogProps {
    errors?: string;
    open?: boolean;
    saving?: boolean;
    onCancel(): void;
    onSend(event: AddMemberEvent): void;
}

interface AddMemberEvent {
    email: string;
    message?: string;
}

export const AddNewMemberDialog: React.FC<AddNewMemberDialogProps> = ({
    errors,
    open = false,
    saving = false,
    onCancel,
    onSend,
}) => {
    const [email, setEmail] = useState('');
    // const [message, setMessage] = useState(''); // TODO: add functionality to rpc

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setEmail('');
        autoFocus(inputRef);
    }, [open]);

    const emailValidationError = !!email && !isEmail(email);
    const sendDisabled = !email || saving || emailValidationError;

    return (
        <Dialog
            open={open}
            onKeyDown={(event) =>
                handleKeyDown({
                    event,
                    enterCondition: !sendDisabled,
                    enterFn: () => onSend({ email }),
                    escFn: onCancel,
                })
            }
        >
            <DialogTitleMain title="Invite new member" icon={<IconCircle icon={<PersonAddAltOutlinedIcon />} />} />
            {errors && <DialogAlert severity="error" alertTitle="Error" text={errors} />}
            <DialogContent>
                <TextField
                    id="name-text-field"
                    inputRef={inputRef}
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                    error={emailValidationError}
                    variant="outlined"
                    label="Enter an email"
                    value={email}
                    fullWidth
                    required
                    placeholder="Enter an email"
                />
                {/* TODO: add functionality to rpc */}
                {/* <TextField
                    onChange={(e) => setMessage(e.target.value)}
                    variant="outlined"
                    label="Message"
                    value={message}
                    fullWidth
                    multiline
                    maxRows={2}
                    placeholder="Type here"
                /> */}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} variant="outlined">
                    Cancel
                </Button>
                <Button
                    busy={saving}
                    disabled={sendDisabled}
                    onClick={() => onSend({ email })}
                    variant="contained"
                    color="primary"
                >
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    );
};
