import { marked } from 'marked';
import sanitizeMarkdown from 'sanitize-markdown';

const renderer = {
    link(href: string, title: string, text: string) {
        return `<a href="${href}" target="_blank" title="${title}">
                ${text}
                </a>`;
    },
};
marked.use({ renderer });

export const getSanitizedParsedMarkdown = (content: string): string => {
    return sanitizeMarkdown(marked.parse(content));
};
