import React from 'react';
import { styled } from '@mui/material/styles';
import ReactHtmlParser from 'react-html-parser';

interface PreviewEditorProps {
    readonly parsedContent: string;
}

const StyledCodePreview = styled('div')(({ theme }) => ({
    height: '100%',
    minHeight: '60px',
    position: 'relative',
    overflow: 'auto',
    width: '100%',
    zIndex: 1101,
    '& a': {
        color: theme.palette.primary.main,
    },
}));

const StyledEditorWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
}));

export const PreviewEditor: React.FC<PreviewEditorProps> = ({ parsedContent }) => {
    return (
        <StyledCodePreview>
            <StyledEditorWrapper>{ReactHtmlParser(parsedContent)}</StyledEditorWrapper>
        </StyledCodePreview>
    );
};
