import { BehaviorSubject, map, Subject } from 'rxjs';
import { getEventListenerSetupDetails } from '../../../data/event-listener';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';

export const atlassianCloudEventListenerSetupDialogOpenAction$ = monitor(
    'atlassianCloudEventListenerSetupDialogOpenAction$',
    new Subject<AtlassianCloudEventListenerSetupBaseDetails>()
);
export const atlassianCloudEventListenerSetupDialogCloseAction$ = monitor(
    'atlassianCloudEventListenerSetupDialogCloseAction$',
    new Subject<void>()
);

export const atlassianCloudEventListenerSetupDialogLoading$ = monitor(
    'atlassianCloudEventListenerSetupDialogLoading$',
    new BehaviorSubject(false)
);
export const atlassianCloudEventListenerSetupDialogOpen$ = monitor(
    'atlassianCloudEventListenerSetupDialogOpen$',
    new BehaviorSubject(false)
);
export const atlassianCloudEventListenerSetupDialogDetails$ = monitor(
    'atlassianCloudEventListenerSetupDialogDetails$',
    new BehaviorSubject<AtlassianCloudEventListenerSetupDetails | undefined>(undefined)
);

export type AtlassianCloudEventListenerSetupBaseDetails = {
    uid: string;
    environmentUid: string;
    webhookUrl: string;
    eventTypeName: string;
    connectionType: 'Jira' | 'Confluence' | 'Jira Service Management';
};

export type AtlassianCloudEventListenerSetupDetails = {
    setupBaseUrl?: string;
} & AtlassianCloudEventListenerSetupBaseDetails;

atlassianCloudEventListenerSetupDialogOpenAction$
    .pipe(
        map(async (details) => {
            atlassianCloudEventListenerSetupDialogOpen$.next(true);
            atlassianCloudEventListenerSetupDialogLoading$.next(true);

            try {
                const { connectionBaseUrl } = await getEventListenerSetupDetails(details.uid, details.environmentUid);

                if (connectionBaseUrl) {
                    atlassianCloudEventListenerSetupDialogDetails$.next({
                        ...details,
                        setupBaseUrl: connectionBaseUrl,
                    });
                } else {
                    atlassianCloudEventListenerSetupDialogDetails$.next(details);
                }

                atlassianCloudEventListenerSetupDialogLoading$.next(false);
            } catch (e) {
                console.error('Error while loading Event Listener setup instructions', e);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to load Event Listener setup instructions, please try again, if the issue persists please contact support.`,
                    toastOptions: {
                        autoClose: false,
                    },
                });

                atlassianCloudEventListenerSetupDialogCloseAction$.next();
            }
        })
    )
    .subscribe();

atlassianCloudEventListenerSetupDialogCloseAction$.subscribe(() => {
    atlassianCloudEventListenerSetupDialogOpen$.next(false);
    atlassianCloudEventListenerSetupDialogDetails$.next(undefined);
});
