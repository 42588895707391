import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const JiraServiceManagementIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="4 4 24 24"
            className="productIcon"
            aria-label="Jira Service Management"
        >
            <path
                d="M18.1623 13.4629L24.6766 13.4972C25.4994 13.4972 25.9794 14.4572 25.4994 15.1429L15.8652 28C12.848 25.7372 12.2309 21.4515 14.4937 18.4L18.1623 13.4629Z"
                fill="url(#paint0_linear_service)"
            />
            <path
                d="M14.4939 18.4L8.01389 18.3657C7.19103 18.3657 6.71104 17.4057 7.19104 16.7543L16.7225 4C19.7396 6.26286 20.3567 10.5486 18.0939 13.6L14.4939 18.4Z"
                fill="#2684FF"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_service"
                    x1="17.914"
                    y1="14.4393"
                    x2="20.6591"
                    y2="22.7374"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0052CC" />
                    <stop offset="1" stopColor="#2684FF" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
};
