import { useObservableState } from 'observable-hooks';
import { GenericManageConnectionDialog } from '../../../../components/connections/apps/generic/ManageConnectionDialog';
import {
    genericAppConnectionSaving$,
    genericAppManageConnectionAuthorizeLoading$,
    genericAppManageConnectionCloseDialogAction$,
    genericAppManageConnectionDetails$,
    genericAppManageConnectionDialogErrors$,
    genericAppManageConnectionDialogOpen$,
    genericAppManageConnectionInitiateSetupAction$,
    genericAppSaveConnectionAction$,
} from '../../../../store/connection/generic/setup-connection';
import { loggedInUserConnections$ } from '../../../../store/connections';

export const GenericManageConnectionDialogContainer: React.FC = () => {
    const open = useObservableState(genericAppManageConnectionDialogOpen$);
    const saving = useObservableState(genericAppConnectionSaving$);
    const authorizeLoading = useObservableState(genericAppManageConnectionAuthorizeLoading$);
    const details = useObservableState(genericAppManageConnectionDetails$);
    const errors = useObservableState(genericAppManageConnectionDialogErrors$);
    const connection = useObservableState(loggedInUserConnections$).find(
        (connection) => connection.uid === details?.uid
    );

    return (
        <GenericManageConnectionDialog
            open={open}
            saving={saving}
            errors={errors}
            authorizeLoading={authorizeLoading}
            name={connection?.name ?? ''}
            authorized={!!connection?.authorized}
            instanceUrl={details?.instanceUrl}
            onCancel={() => genericAppManageConnectionCloseDialogAction$.next()}
            onSave={(name) => genericAppSaveConnectionAction$.next(name)}
            onAuthorize={(name) => genericAppManageConnectionInitiateSetupAction$.next(name)}
            connectionType={details?.connectionType.name}
        />
    );
};
