import loader from '@monaco-editor/loader';
import { ApiHandlerModel } from '../../store/editor/types';

export const updateApiHandlerModules = async (apiHandlers: ApiHandlerModel[]): Promise<void> => {
    const monaco = await loader.init();

    monaco.editor
        .getModels()
        .filter((model) => model.uri.path.startsWith('/api/'))
        .forEach((model) => model.dispose());

    apiHandlers.forEach((apiHandler) => {
        const apiModuleContent = generateStaticConnectionModule(apiHandler);
        if (apiModuleContent) {
            const uri = monaco.Uri.file(`/${apiHandler.path}.ts`);
            const model = monaco.editor.getModel(uri);
            if (!model) {
                monaco.editor.createModel(apiModuleContent, 'typescript', uri);
            }
        }
    });

    monaco.editor
        .getModels()
        .filter((model) => !model.uri.path.startsWith('/api/'))
        .forEach((model) => model.setValue(model.getValue()));
};

const generateStaticConnectionModule = (apiHandler: ApiHandlerModel): string =>
    `import { ${apiHandler.spec.namespace} } from '${apiHandler.spec.apiModule}';

export default new ${apiHandler.spec.namespace}('${apiHandler.handlerId}');
`;
