import { useObservableState } from 'observable-hooks';
import { ZoomEventListenerSetupDialog } from '../../../../components/connections/apps/zoom/EventListenerSetupDialog';
import { configTopic$ } from '../../../../store/config';
import {
    zoomEventListenerSetupDialogCloseAction$,
    zoomEventListenerSetupDialogDetails$,
    zoomEventListenerSetupDialogErrors$,
    zoomEventListenerSetupDialogLoading$,
    zoomEventListenerSetupDialogOpen$,
    zoomEventListenerSetupDialogSaveTokenAction$,
    zoomEventListenerSetupDialogSaving$,
    zoomEventListenerSetupDialogStage$,
} from '../../../../store/connection/zoom/setup-event-listener';

export const ZoomEventListenerSetupDialogContainer: React.FC = () => {
    const loading = useObservableState(zoomEventListenerSetupDialogLoading$);
    const open = useObservableState(zoomEventListenerSetupDialogOpen$);
    const details = useObservableState(zoomEventListenerSetupDialogDetails$);
    const config = useObservableState(configTopic$);
    const errors = useObservableState(zoomEventListenerSetupDialogErrors$);
    const saving = useObservableState(zoomEventListenerSetupDialogSaving$);
    const stage = useObservableState(zoomEventListenerSetupDialogStage$);

    return (
        <ZoomEventListenerSetupDialog
            loading={loading}
            open={open}
            eventTypeName={details?.eventTypeName ?? ''}
            webhookBaseUrl={config.trigger?.srcExternalUrl ?? ''}
            webhookUrlId={details?.webhookUrl ?? ''}
            errors={errors}
            onSave={(token) =>
                zoomEventListenerSetupDialogSaveTokenAction$.next({
                    token,
                    uid: details?.uid ?? '',
                    environmentUid: details?.environmentUid ?? '',
                })
            }
            onSetStage={(stage) => zoomEventListenerSetupDialogStage$.next(stage)}
            saving={saving}
            stage={stage}
            onClose={() => zoomEventListenerSetupDialogCloseAction$.next()}
        />
    );
};
