import { LoaderFn } from 'react-location';
import { getIndustryRoles } from '../data/user';
import { LocationGenerics } from '../router';
import { industryRoles$ } from '../store/roles';
import { loadErrorPage } from '../store/error';
import { scriptingFamiliarityOptions$ } from '../store/onboarding';
import { getScriptingFamiliarityOptions } from '../data/onboarding';
import { loadApps } from '../store/apps';

export const profileLoader: LoaderFn<LocationGenerics> = async () => {
    try {
        if (industryRoles$.value.length === 0) {
            const roles = await getIndustryRoles();
            industryRoles$.next(roles);
        }
        if (!scriptingFamiliarityOptions$.value) {
            const scriptingFamiliarityOptions = await getScriptingFamiliarityOptions();
            scriptingFamiliarityOptions$.next(scriptingFamiliarityOptions);
        }
        await loadApps();
    } catch (e) {
        loadErrorPage({
            error: e,
            pageTitle: 'Profile settings',
            background: 'paper',
            genericMessage: 'Failed to load user profile.',
        });

        throw e;
    }

    return {};
};
