import { useObservableState, useSubscription } from 'observable-hooks';
import { ApiKeysPage } from '../../../components/profile/api-keys';
import {
    apiKeyDeletedAction$,
    apiKeys$,
    deleteApiKeyAction$,
    generateNewApiKeyAction$,
    newApiKeyGeneratedAction$,
} from '../../../store/user';
import { getBasePath } from '../../../utils/path';
import { useNavigate } from 'react-location';

export const ApiKeysContainer: React.FC = () => {
    const apiKeys = useObservableState(apiKeys$);
    const navigate = useNavigate();

    useSubscription(newApiKeyGeneratedAction$, () => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        navigate({ to: '../' });
        setTimeout(() => navigate({ to: `${getBasePath()}/apiKeys` }), 100);
    });

    useSubscription(apiKeyDeletedAction$, () => {
        // Ugly hack to refresh the page: https://github.com/TanStack/react-location/discussions/269
        navigate({ to: '../' });
        setTimeout(() => navigate({ to: `${getBasePath()}/apiKeys` }), 100);
    });

    return (
        <ApiKeysPage
            apiKeys={apiKeys}
            onCreateNewApiKey={(keyName) => generateNewApiKeyAction$.next(keyName)}
            onDeleteApiKey={(uid) => deleteApiKeyAction$.next(uid)}
        />
    );
};
