import { LoaderFn } from 'react-location';
import { getRegisteredVsActiveUsers, RegisteredVsActiveUsersRequest } from '../../data/report/registeredVsActiveUsers';
import { LocationGenerics } from '../../router';
import { registeredVsActiveUsers$ } from '../../store/report/registeredVsActiveUsers';

export const registeredVsActiveUsersReportLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    const users = await getRegisteredVsActiveUsers({
        status: routeMatch.search.status as RegisteredVsActiveUsersRequest['status'],
        userType: routeMatch.search.userType as RegisteredVsActiveUsersRequest['userType'],
    });

    registeredVsActiveUsers$.next(users);

    return {};
};
