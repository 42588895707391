import { AbsoluteCenteredLoadingSpinner } from '../../../common/loading/AbsoluteCenteredLoadingSpinner';
import { Button } from '../../../common/buttons/Button';
import { APP } from '@avst-stitch/repository-lib/constants';
import { handleKeyDown } from '../../../../utils/handleKeyDown';
import Link from '@mui/material/Link';
import { useState } from 'react';
import {
    DialogCloseButton,
    StyledDialog,
    StyledDialogActions,
    StyledDialogContent,
    StyledDialogHeader,
    StyledDialogTitle,
    StyledDialogTitleContainer,
} from '../../../common/dialog/DialogComponents';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';
import { StyledInstructionsOrderedList } from '../../../setup-guide/SetupGuideComponents';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import Radio from '@mui/material/Radio';
import { OutlinedReadOnlyTextField } from '../../../common/textfield/OutlinedReadOnlyTextField';

interface ZenDeskEventListenerSetupDialogProps {
    loading?: boolean;
    open?: boolean;
    webhookBaseUrl: string;
    zendeskUrl?: string;
    webhookUrlId: string;
    onClose: () => void;
}

const StyledInstructionsBox = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    width: '100%',
    marginTop: theme.spacing(2),
}));

const StyledInstructionsBoxHeader = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    height: 53,
    padding: theme.spacing(1, 1.5),
    '& .MuiButtonBase-root': {
        marginLeft: theme.spacing(-0.5),
        padding: theme.spacing(1),
    },
    cursor: 'pointer',
}));

const StyledInstructionsBoxContent = styled(Box)(({ theme }) => ({
    alignItems: 'flex-start',
    borderRadius: `0 0 ${theme.constants.borderRadius}px ${theme.constants.borderRadius}px`,
    borderTop: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
}));

export const ZenDeskEventListenerSetupDialog: React.FC<ZenDeskEventListenerSetupDialogProps> = ({
    loading = false,
    open = false,
    webhookBaseUrl,
    zendeskUrl,
    webhookUrlId,
    onClose,
}) => {
    const appName = APP.ZENDESK.NAME;

    const [webhookType, setWebhookType] = useState<'events' | 'automation'>('events');

    return (
        <>
            <StyledDialog
                maxWidth="sm"
                open={open}
                onKeyUp={(event) => handleKeyDown({ event, enterCondition: !loading, escFn: onClose })}
            >
                <StyledDialogHeader>
                    <StyledDialogTitleContainer>
                        <StyledDialogTitle>Event Listener setup instructions for {appName}</StyledDialogTitle>
                    </StyledDialogTitleContainer>
                    <DialogCloseButton onClose={onClose} />
                </StyledDialogHeader>
                <Divider />
                <StyledDialogContent>
                    {loading ? (
                        <Box height={80} width={600}>
                            <AbsoluteCenteredLoadingSpinner />
                        </Box>
                    ) : (
                        <>
                            <Typography variant="subtitle1" pb={0.5}>
                                To listen to events, setup an outgoing webhook in your Zendesk project
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                                Do you want to use a Zendesk Event or Trigger/Automation?
                            </Typography>
                            <StyledInstructionsBox
                                aria-label="Zendesk Events"
                                role="radio"
                                onClick={() => setWebhookType('events')}
                                tabIndex={0}
                                onKeyDown={(event) =>
                                    handleKeyDown({
                                        event,
                                        enterFn: () => setWebhookType('events'),
                                    })
                                }
                            >
                                <StyledInstructionsBoxHeader>
                                    <Radio checked={webhookType === 'events'} />
                                    <Typography variant="body1">Method 1: Zendesk Events</Typography>
                                </StyledInstructionsBoxHeader>
                                {webhookType === 'events' && (
                                    <StyledInstructionsBoxContent>
                                        <StyledInstructionsOrderedList>
                                            {zendeskUrl && (
                                                <ListItem disablePadding>
                                                    <Typography>
                                                        Visit your {appName}{' '}
                                                        <Link target="_blank" href={zendeskUrl}>
                                                            account
                                                        </Link>{' '}
                                                        page.
                                                    </Typography>
                                                </ListItem>
                                            )}
                                            {!zendeskUrl && (
                                                <ListItem disablePadding>
                                                    Open https://[YOUR_ZENDESK.COM_ACCOUNT] URL in your browser.
                                                </ListItem>
                                            )}
                                            <ListItem disablePadding>
                                                <Typography>
                                                    In <strong>Admin Centre</strong>, click{' '}
                                                    <strong>Apps and integrations</strong> in the sidebar, then select{' '}
                                                    <strong>Webhooks</strong>.
                                                </Typography>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <Typography>
                                                    Click the <strong>Create webhook</strong> button on the top right of
                                                    the page.
                                                </Typography>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <Typography>
                                                    Select the <strong>Zendesk events</strong> option.
                                                </Typography>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                Subscribe to one or more Zendesk webhook events from the dropdown
                                                options.
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <Typography>
                                                    Click the <strong>Next</strong> button on the bottom right of the
                                                    page.
                                                </Typography>
                                            </ListItem>
                                            <ListItem disablePadding>Enter a Name for the webhook.</ListItem>
                                            <ListItem disablePadding>
                                                <Typography>
                                                    Paste the URL from this page into the <strong>Endpoint URL</strong>{' '}
                                                    field.
                                                </Typography>
                                            </ListItem>
                                            <OutlinedReadOnlyTextField
                                                label="Webhook URL"
                                                value={`${webhookBaseUrl}/${webhookUrlId}`}
                                            />
                                            <ListItem disablePadding>
                                                <Typography>
                                                    Click on <strong>Create webhook</strong>.
                                                </Typography>
                                            </ListItem>
                                        </StyledInstructionsOrderedList>
                                    </StyledInstructionsBoxContent>
                                )}
                            </StyledInstructionsBox>
                            <StyledInstructionsBox
                                aria-label="Trigger or Automation"
                                role="radio"
                                onClick={() => setWebhookType('automation')}
                                tabIndex={0}
                                onKeyDown={(event) =>
                                    handleKeyDown({
                                        event,
                                        enterFn: () => setWebhookType('automation'),
                                    })
                                }
                            >
                                <StyledInstructionsBoxHeader>
                                    <Radio checked={webhookType === 'automation'} />
                                    <Typography variant="body1">Method 2: Trigger or Automation</Typography>
                                </StyledInstructionsBoxHeader>
                                {webhookType === 'automation' && (
                                    <StyledInstructionsBoxContent>
                                        <StyledInstructionsOrderedList>
                                            {zendeskUrl && (
                                                <ListItem disablePadding>
                                                    <Typography>
                                                        Visit your {appName}{' '}
                                                        <Link target="_blank" href={zendeskUrl}>
                                                            account
                                                        </Link>{' '}
                                                        page.
                                                    </Typography>
                                                </ListItem>
                                            )}
                                            {!zendeskUrl && (
                                                <ListItem disablePadding>
                                                    Open https://[YOUR_ZENDESK.COM_ACCOUNT] URL in your browser.
                                                </ListItem>
                                            )}
                                            <ListItem disablePadding>
                                                <Typography>
                                                    In <strong>Admin Centre</strong>, click{' '}
                                                    <strong>Apps and integrations</strong> in the sidebar, then select{' '}
                                                    <strong>Webhooks</strong>.
                                                </Typography>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <Typography>
                                                    Click the <strong>Create webhook</strong> button on the top right of
                                                    the page.
                                                </Typography>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <Typography>
                                                    Select the <strong>Trigger or automation</strong> option.
                                                </Typography>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <Typography>
                                                    Click the <strong>Next</strong> button on the bottom right of the
                                                    page.
                                                </Typography>
                                            </ListItem>
                                            <ListItem disablePadding>Enter a Name for the webhook.</ListItem>
                                            <ListItem disablePadding>
                                                <Typography>
                                                    Paste the URL from this page into the <strong>Endpoint URL</strong>{' '}
                                                    field.
                                                </Typography>
                                            </ListItem>
                                            <OutlinedReadOnlyTextField
                                                label="Webhook URL"
                                                value={`${webhookBaseUrl}/${webhookUrlId}`}
                                            />
                                            <ListItem disablePadding>
                                                <Typography>
                                                    Click on <strong>Create webhook</strong>.
                                                </Typography>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <Typography>
                                                    Click on{' '}
                                                    {zendeskUrl ? (
                                                        <Link
                                                            target="_blank"
                                                            href={`${zendeskUrl}/admin/objects-rules/rules/triggers`}
                                                        >
                                                            Select a trigger or automation in Admin Centre
                                                        </Link>
                                                    ) : (
                                                        <strong>
                                                            Objects and Rules {`>`} Business Rules {`>`} Triggers
                                                        </strong>
                                                    )}
                                                    .
                                                </Typography>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <Typography>
                                                    Click the <strong>Create Trigger</strong> button on the top right of
                                                    the page or edit an existing trigger.
                                                </Typography>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <Typography>
                                                    Enter a <strong>name</strong> and <strong>category</strong> for the
                                                    trigger.
                                                </Typography>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <Typography>
                                                    Under <strong>Conditions</strong> provide a condition that must be
                                                    met for the trigger to run.
                                                </Typography>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <Typography>
                                                    Under <strong>Actions</strong> click the <strong>Add action</strong>{' '}
                                                    button.
                                                </Typography>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <Typography>
                                                    From the dropdown select the <strong>Notify by</strong> option, then
                                                    select <strong>Active webhook</strong>.
                                                </Typography>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                From the second dropdown select the webhook created in the previous
                                                steps.
                                            </ListItem>
                                            <ListItem disablePadding>
                                                Edit the JSON body with events you would like to send.
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <Typography>
                                                    Click on <strong>Create</strong>.
                                                </Typography>
                                            </ListItem>
                                        </StyledInstructionsOrderedList>
                                    </StyledInstructionsBoxContent>
                                )}
                            </StyledInstructionsBox>
                        </>
                    )}
                </StyledDialogContent>
                <Divider />
                <StyledDialogActions>
                    <Button disabled={loading} onClick={onClose} color="success">
                        Mark as complete
                    </Button>
                </StyledDialogActions>
            </StyledDialog>
        </>
    );
};
