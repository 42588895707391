import { BehaviorSubject, map, Subject } from 'rxjs';
import { App, Apps, getApps } from '../data/apps';
import { monitor } from './monitor';

export const openAppSelectorAction$ = monitor(
    'openAppSelectorAction$',
    new Subject<{ nextAction: AppSelectorNextAction; mode: AppSelectorMode }>()
);

export const loadingAppSelector$ = monitor('loadingApps$', new BehaviorSubject(false));
export const apps$ = monitor('apps$', new BehaviorSubject<Apps>([]));
export const appSelectorOpen$ = monitor('appSelectorOpen$', new BehaviorSubject(false));
export const appSelectorMode$ = monitor(
    'appSelectorMode$',
    new BehaviorSubject<AppSelectorMode | undefined>(undefined)
);
export const appSelectorNextAction$ = monitor(
    'appSelectorNextAction$',
    new BehaviorSubject<AppSelectorNextAction | undefined>(undefined)
);

openAppSelectorAction$
    .pipe(
        map(async ({ nextAction, mode }) => {
            appSelectorNextAction$.next(nextAction);
            appSelectorMode$.next(mode);
            appSelectorOpen$.next(true);
            loadingAppSelector$.next(true);

            await loadApps();

            loadingAppSelector$.next(false);
        })
    )
    .subscribe();

export const loadApps = async (): Promise<void> => {
    if (apps$.value.length === 0) {
        const apps = await getApps();
        apps$.next(apps);
    }
};

export type AppSelectorNextAction = (selectedApp: App) => void;
export type AppSelectorMode = 'EVENT_LISTENER' | 'API_HANDLER' | 'CONNECTOR';
