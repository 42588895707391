import { FeedbackEvent } from '@avst-stitch/feedback-lib';
import { FEEDBACK_INTERCEPTORS } from './interceptors';
import { FEEDBACK_RENDERERS } from './renderers';
import { FeedbackInterceptor, FeedbackRenderer } from './types';

export const getFeedbackInterceptor = (event: FeedbackEvent): FeedbackInterceptor | undefined => {
    return FEEDBACK_INTERCEPTORS.find(({ type }) => event.type === type);
};

export const getFeedbackRenderer = (event: FeedbackEvent): FeedbackRenderer | undefined =>
    FEEDBACK_RENDERERS.find(({ type }) => event.type === type);

export const getJSXElementForFeedbackEvent = (event: FeedbackEvent): JSX.Element => {
    const renderer = getFeedbackRenderer(event);

    if (renderer && renderer.getJSXElement) {
        return renderer.getJSXElement(event);
    } else if (renderer && renderer.getPlainText) {
        return <>{renderer.getPlainText(event)}</>;
    } else if (event.message) {
        return <>{event.message}</>;
    } else {
        return (
            <>
                {event.type}: {JSON.stringify(event.payload ?? {})}
            </>
        );
    }
};
