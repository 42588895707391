import { useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CodeIcon from '@mui/icons-material/Code';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LinkIcon from '@mui/icons-material/Link';
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import { ProductIcon } from '../../icons/ProductIcon';
import {
    ResourceActionButton,
    StyledResource,
    StyledResourceActionButtons,
    StyledResourceIcon,
    StyledResourceInfo,
    StyledResourceSubtitle,
    StyledResourceTitle,
} from './ResourceTreeComponents';
import { APP } from '@avst-stitch/repository-lib/constants';
import { remnantStyles, selectedStyles, wizardIncompleteStyles } from './resourceTreeStyles';

interface ResourceTreeEventListenerProps {
    appName?: string;
    deleting?: boolean;
    deployedEnvironmentMode?: boolean;
    eventType?: string;
    executing?: boolean;
    externalTriggerBaseUrl?: string;
    inactive?: boolean;
    needsSetup?: boolean;
    readOnlyMode?: boolean;
    remnant?: boolean;
    scriptName?: string;
    selectedNode?: string;
    uid: string;
    urlId?: string;
    wizardIncomplete?: boolean;
    disabled?: boolean;
    onDelete(uid: string): void;
    onOpen(uid: string): void;
    onTrigger?(uid: string): void;
    onUrlCopy?(uid: string): void;
}

export const ResourceTreeEventListener: React.FC<ResourceTreeEventListenerProps> = ({
    appName,
    deleting = false,
    deployedEnvironmentMode = false,
    eventType,
    executing = false,
    externalTriggerBaseUrl,
    inactive = false,
    needsSetup = false,
    readOnlyMode = false,
    remnant = false,
    scriptName,
    selectedNode,
    uid,
    urlId,
    wizardIncomplete = false,
    disabled = false,
    onDelete,
    onOpen,
    onTrigger,
    onUrlCopy,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const theme = useTheme();

    const selected = selectedNode === uid;
    const isGeneric = appName === APP.GENERIC.NAME;
    const showUrlId = !needsSetup && !readOnlyMode && !!urlId && isGeneric;
    const showScriptName = !needsSetup && !!scriptName;
    const showSetupWarning = !readOnlyMode && !remnant && needsSetup;
    const showInactiveWarning = inactive && !needsSetup && !readOnlyMode && !remnant;
    const showDisabled = disabled && !inactive && !needsSetup && !readOnlyMode && !remnant;
    const canCopyPath = isGeneric && !readOnlyMode && !!urlId;
    const canTrigger = !deleting && !needsSetup && !readOnlyMode && !remnant;
    const canDelete = !deployedEnvironmentMode && !readOnlyMode && !remnant;

    return (
        <StyledResource
            key={uid}
            onClick={() => onOpen(uid)}
            sx={selected ? selectedStyles(theme) : wizardIncomplete ? wizardIncompleteStyles(theme) : null}
        >
            <StyledResourceInfo>
                <StyledResourceTitle sx={remnant ? remnantStyles(theme) : null}>
                    <Tooltip title="Event Listener" placement="top">
                        <StyledResourceIcon>
                            <ProductIcon name={appName} />
                        </StyledResourceIcon>
                    </Tooltip>
                    <Typography>{eventType ?? 'New Event Listener'}</Typography>
                </StyledResourceTitle>
                {showUrlId && (
                    <Tooltip title={`${externalTriggerBaseUrl}/${urlId}`} placement="top-start">
                        <StyledResourceSubtitle sx={remnant ? remnantStyles(theme) : null}>
                            <LinkIcon />
                            <Typography>{urlId}</Typography>
                        </StyledResourceSubtitle>
                    </Tooltip>
                )}
                {showScriptName && (
                    <StyledResourceSubtitle sx={remnant ? remnantStyles(theme) : null}>
                        <CodeIcon />
                        <Typography>{scriptName}</Typography>
                    </StyledResourceSubtitle>
                )}
                {showSetupWarning && (
                    <StyledResourceSubtitle className="warning">
                        <WarningOutlinedIcon />
                        <Typography>Complete Event Listener setup</Typography>
                    </StyledResourceSubtitle>
                )}
                {showInactiveWarning && (
                    <StyledResourceSubtitle className="warning">
                        <WarningOutlinedIcon />
                        <Typography>Inactive</Typography>
                    </StyledResourceSubtitle>
                )}
                {showDisabled && (
                    <StyledResourceSubtitle className="warning">
                        <WarningOutlinedIcon />
                        <Typography>Disabled</Typography>
                    </StyledResourceSubtitle>
                )}
            </StyledResourceInfo>
            <StyledResourceActionButtons>
                {canCopyPath && (
                    <ResourceActionButton
                        icon={<ContentCopyIcon sx={{ padding: 0.25 }} />}
                        tooltipTitle="Copy URL"
                        uid={uid}
                        onClick={onUrlCopy}
                    />
                )}
                {canTrigger && (
                    <ResourceActionButton
                        busy={executing}
                        icon={<PlayCircleOutlineRoundedIcon />}
                        tooltipTitle="Emulate event with test payload"
                        uid={uid}
                        onClick={onTrigger}
                    />
                )}
                {canDelete && (
                    <ResourceActionButton
                        busy={deleting}
                        icon={<DeleteOutlineIcon />}
                        tooltipTitle="Delete"
                        uid={uid}
                        onClick={onDelete}
                    />
                )}
            </StyledResourceActionButtons>
        </StyledResource>
    );
};
