import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React from 'react';
import { SalesforceConnection } from './SalesforceAppConfigureDialog';

export enum SalesforceWizardStage {
    ADDURL,
    CREATE,
    DETAILS,
    AUTHORIZE,
    SUCCESS,
}

export interface SalesforceWizardStepsProps {
    readonly stage: SalesforceWizardStage;
}

export const SalesforceWizardSteps: React.FC<SalesforceWizardStepsProps> = ({ stage }) => {
    return (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={SalesforceWizardStage.ADDURL}>
                <StepLabel>Add Salesforce Instance URL</StepLabel>
            </Step>
            <Step key={SalesforceWizardStage.CREATE}>
                <StepLabel>Create Connected App in Salesforce</StepLabel>
            </Step>
            <Step key={SalesforceWizardStage.DETAILS}>
                <StepLabel>Enter App's Details</StepLabel>
            </Step>
            <Step key={SalesforceWizardStage.AUTHORIZE}>
                <StepLabel>Authorize</StepLabel>
            </Step>
        </Stepper>
    );
};

export type StageType = React.FC<
    SalesforceConnection & {
        stage: SalesforceWizardStage;
        setStage: (stage: SalesforceWizardStage) => void;
    }
>;
