import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AllInboxOutlinedIcon from '@mui/icons-material/AllInboxOutlined';
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined';
import CodeRoundedIcon from '@mui/icons-material/CodeRounded';
import DeveloperBoardOutlinedIcon from '@mui/icons-material/DeveloperBoardOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import { Button } from '../common/buttons/Button';
import { ChatGptIcon } from '../icons/ChatGptIcon';
import { Dropdown } from '../common/dropdown/Dropdown';
import { DropdownMenu } from '../common/dropdown/DropdownMenu';

export interface WorkspaceHeaderProps {
    aiAssistanceOpen?: boolean;
    deploymentButtonDisabled?: boolean;
    environments?: Environment[];
    isDraft?: boolean;
    isTemplate: boolean;
    loadingEnvironments?: boolean;
    locked?: boolean;
    manageEnvironmentDeploymentTargetsButtonDisabled?: boolean;
    organizationName?: string;
    selectedEnvironmentUid?: string;
    selectedWorkspaceUid?: string;
    showAiAssistance?: boolean;
    templateActionsInProgress?: boolean;
    workspaces?: Workspace[];
    onChangeEnvironment(uid: string): void;
    onCloseAiAssistance(): void;
    onCreateNewEnvironment(): void;
    onDeploy(): void;
    onEditWorkspace(): void;
    onOpenAiAssistance(): void;
    onOpenEnvironmentManager(): void;
    onOpenLanguageSelector(): void;
    onOpenManageEnvironmentDeploymentTargets(): void;
    onOpenPackageManager(): void;
    onPublishTemplate(): void;
    onShareWorkspace(workspaceUid: string): void;
    onSwitchWorkspace(workspaceUid: string, environmentUid: string): void;
    onUnpublishTemplate(): void;
}

export interface SelectableItem {
    uid: string;
    name: string;
}

export interface Workspace extends SelectableItem {
    environmentUid: string;
}

interface Environment extends SelectableItem {
    deployed?: boolean;
    deploymentVersion?: string;
}

const IconSizing = {
    height: 20,
    width: 20,
};

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    alignItems: 'center',
    borderBottom: '1px solid ' + theme.palette.divider,
    justifyContent: 'flex-start',
    '&.MuiToolbar-root': {
        padding: theme.spacing(1, 2),
        minHeight: 60,
    },
}));

const StyledStack = styled(Stack)(() => ({
    // bit of magic that aligns this to the right
    marginLeft: 'auto',
}));

const StyledDiv = styled('div')(({ theme }) => ({
    margin: theme.spacing(0, 1, 0, 2),
}));

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
    marginLeft: theme.spacing(2),
}));

const StyledHubOutlinedIcon = styled(HubOutlinedIcon)(({ theme }) => ({
    color: theme.palette.secondary.main,
    ...IconSizing,
}));

export const WorkspaceHeader: React.FC<WorkspaceHeaderProps> = ({
    aiAssistanceOpen = false,
    deploymentButtonDisabled = false,
    environments = [],
    isDraft = false,
    isTemplate,
    loadingEnvironments,
    locked = false,
    manageEnvironmentDeploymentTargetsButtonDisabled = false,
    organizationName,
    selectedWorkspaceUid,
    selectedEnvironmentUid,
    showAiAssistance = false,
    templateActionsInProgress = false,
    workspaces = [],
    onChangeEnvironment,
    onCloseAiAssistance,
    onCreateNewEnvironment,
    onDeploy,
    onEditWorkspace,
    onOpenAiAssistance,
    onOpenEnvironmentManager,
    onOpenLanguageSelector,
    onOpenManageEnvironmentDeploymentTargets,
    onOpenPackageManager,
    onPublishTemplate,
    onShareWorkspace,
    onSwitchWorkspace,
    onUnpublishTemplate,
}) => {
    const settingsDropdownItems = [
        {
            disabled: locked,
            name: 'Edit',
            label: 'Edit Workspace',
            action: onEditWorkspace,
            startIcon: <WorkspacesOutlinedIcon />,
        },
        {
            disabled: locked,
            name: 'Language',
            label: 'Switch Scripting Language',
            startIcon: <CodeRoundedIcon />,
            action: onOpenLanguageSelector,
        },
        {
            name: 'Package',
            label: 'Package Manager',
            startIcon: <AllInboxOutlinedIcon />,
            action: onOpenPackageManager,
        },
        {
            name: 'Deployment',
            label: 'Release and Deploy',
            startIcon: <FileDownloadOutlinedIcon sx={{ transform: 'rotate(180deg)' }} />,
            disabled: locked || deploymentButtonDisabled,
            action: onDeploy,
        },
        {
            name: 'Environment',
            label: 'Deployment Manager',
            startIcon: <DeveloperBoardOutlinedIcon />,
            disabled: manageEnvironmentDeploymentTargetsButtonDisabled || locked,
            action: onOpenManageEnvironmentDeploymentTargets,
        },
        ...(isTemplate
            ? [
                  isDraft
                      ? {
                            disabled: locked || templateActionsInProgress,
                            name: 'Publish',
                            label: 'Publish template',
                            startIcon: <CloudOutlinedIcon />,
                            action: onPublishTemplate,
                        }
                      : {
                            disabled: locked || templateActionsInProgress,
                            name: 'Unpublish',
                            label: 'Unpublish template',
                            startIcon: <CloudOffOutlinedIcon />,
                            action: onUnpublishTemplate,
                        },
              ]
            : []),
    ];

    const handleToggleAiAssistance = (): void => {
        if (aiAssistanceOpen) {
            onCloseAiAssistance();
        } else {
            onOpenAiAssistance();
        }
    };

    return (
        <StyledToolbar data-test-id="workspace-header">
            <Stack direction="row" spacing={1} alignItems="center">
                <Dropdown
                    size="small"
                    items={workspaces.map(({ uid, name }) => {
                        return { name, value: uid };
                    })}
                    label="Workspace"
                    selectedItem={selectedWorkspaceUid}
                    onSelectItem={(value) =>
                        onSwitchWorkspace(value, workspaces.find((w) => w.uid === value)?.environmentUid ?? '')
                    }
                />
            </Stack>
            <Stack direction="row" alignItems="center">
                {!loadingEnvironments && (
                    <>
                        <StyledDiv>
                            <Dropdown
                                actionsDisabled={locked}
                                items={environments.map(({ name, uid }) => ({ name: name, value: uid }))}
                                label="Environment"
                                selectedItem={selectedEnvironmentUid}
                                size="small"
                                onCreateNew={onCreateNewEnvironment}
                                onManageEnvironments={onOpenEnvironmentManager}
                                onSelectItem={(uid) => onChangeEnvironment(uid)}
                            />
                        </StyledDiv>
                    </>
                )}
                {loadingEnvironments && <StyledCircularProgress />}
            </Stack>
            {organizationName && (
                <Stack paddingLeft={2} direction="row" alignItems="center" textOverflow="ellipsis" minWidth={150}>
                    <StyledHubOutlinedIcon />
                    <Typography paddingLeft={1} noWrap>
                        {organizationName}
                    </Typography>
                </Stack>
            )}
            <StyledStack direction="row" spacing={2} alignItems="center">
                <>
                    <Button
                        onClick={() => onShareWorkspace(selectedWorkspaceUid ?? '')}
                        variant="text"
                        startIcon={<PersonAddAltOutlinedIcon />}
                        sx={{
                            textTransform: 'none',
                            paddingLeft: 2,
                            ':disabled': {
                                backgroundColor: (theme) => theme.palette.background.paper,
                                color: (theme) => theme.palette.text.disabled,
                            },
                        }}
                    >
                        Share
                    </Button>
                    <Divider orientation="vertical" flexItem />
                </>
                {showAiAssistance && (
                    <Tooltip
                        title={aiAssistanceOpen ? 'Close AI assistance' : 'Get help from AI'}
                        placement="top"
                        role="button"
                    >
                        <IconButton onClick={handleToggleAiAssistance}>
                            <ChatGptIcon />
                        </IconButton>
                    </Tooltip>
                )}
                <Tooltip title="Workspace Actions" role="button" placement="top">
                    <>
                        <DropdownMenu dropdownItems={settingsDropdownItems}>
                            <MoreVertIcon />
                        </DropdownMenu>
                    </>
                </Tooltip>
            </StyledStack>
        </StyledToolbar>
    );
};
