import { BehaviorSubject, map, Subject } from 'rxjs';
import { sendMessageToAIAssistance } from '../../data/ai-assistance';
import { publishLocalFeedbackEventAction$ } from '../feedback';
import { monitor } from '../monitor';
import { chatGPTModel } from '@avst-stitch/repository-lib/src/utils/chatGPT';
import { InformativeError } from '../../utils/repository';
import { ulid } from 'ulid';
import { pendoAnalyticsTrack } from '../../data/pendo-analytics';
import { loggedInUserDetails$ } from '../user';

export const aiAssistanceOpen$ = monitor('aiAssistanceOpen$', new BehaviorSubject(false));
export const loadingAiAssistanceDialog$ = monitor('loadingAiAssistanceDialog$', new BehaviorSubject(false));
export const aiAssistanceChat$ = monitor(
    'aiAssistanceChat$',
    new BehaviorSubject<{ role: 'user' | 'assistant'; message: string }[]>([])
);
export const aiAssistanceGeneratedMessage$ = monitor(
    'aiAssistanceGeneratedMessage$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const aiAssistanceGeneratedMessagePieces$ = monitor(
    'aiAssistanceGeneratedMessagePieces$',
    new BehaviorSubject<{ message: string; index: number; isLast?: boolean }[]>([])
);
export const aiAssistanceGeneratedMessageLength$ = monitor(
    'aiAssistanceGeneratedMessageLength$',
    new BehaviorSubject<number | undefined>(undefined)
);
export const aiAssistanceAnswering$ = monitor('aiAssistanceAnswering$', new BehaviorSubject(false));
export const aiAssistanceStreamId$ = monitor(
    'aiAssistanceStreamId$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const aiAssistanceConversationUid$ = monitor(
    'aiAssistanceConversationUid$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const aiAssistanceIsLongResponse$ = monitor('aiAssistanceIsLongResponse$', new BehaviorSubject<boolean>(false));

export const openAiAssistanceAction$ = monitor('openAiAssistanceAction$', new Subject<void>());
export const closeAiAssistanceAction$ = monitor('closeAiAssistanceAction$', new Subject<void>());

export const sendMessageToAiAssistanceAction$ = monitor(
    'sendMessageToAiAssistanceAction$',
    new Subject<{ message: string; workspaceUid: string; model: chatGPTModel; environmentUid: string }>()
);
export const createAiAssistanceGeneratedMessageAction$ = monitor(
    'createAiAssistanceGeneratedMessageAction$',
    new Subject<{
        message: string;
        index: number;
        isLast?: boolean;
        workspaceUid: string;
        id: string;
        finish_reason?: string;
    }>()
);
export const resetAiAssistanceChatAction$ = monitor('resetAiAssistanceChatAction$', new Subject<void>());
export const copyAiAssistanceCodeSnippetAction$ = monitor('copyAiAssistanceCodeSnippetAction$', new Subject<void>());

openAiAssistanceAction$.subscribe(() => {
    aiAssistanceOpen$.next(true);
});

closeAiAssistanceAction$.subscribe(() => {
    aiAssistanceOpen$.next(false);
});

resetAiAssistanceChatAction$.subscribe(() => {
    loadingAiAssistanceDialog$.next(false);
    aiAssistanceAnswering$.next(false);
    aiAssistanceGeneratedMessage$.next(undefined);
    aiAssistanceGeneratedMessagePieces$.next([]);
    aiAssistanceGeneratedMessageLength$.next(undefined);
    aiAssistanceChat$.next([]);
    aiAssistanceStreamId$.next(undefined);
    aiAssistanceIsLongResponse$.next(false);
    pendoAnalyticsTrack('New conversation started', {
        userId: loggedInUserDetails$.value?.uid,
        userOrigin: loggedInUserDetails$.value?.userOrigin,
    });
});

copyAiAssistanceCodeSnippetAction$.subscribe(() => {
    pendoAnalyticsTrack('Code copied', {
        userId: loggedInUserDetails$.value?.uid,
        userOrigin: loggedInUserDetails$.value?.userOrigin,
    });
});

sendMessageToAiAssistanceAction$
    .pipe(
        map(async (event) => {
            loadingAiAssistanceDialog$.next(true);
            aiAssistanceAnswering$.next(true);
            aiAssistanceGeneratedMessage$.next(undefined);
            aiAssistanceGeneratedMessagePieces$.next([]);
            aiAssistanceGeneratedMessageLength$.next(undefined);
            aiAssistanceIsLongResponse$.next(false);
            const chat = aiAssistanceChat$.value;
            try {
                const isFirst = chat.length === 0;
                if (isFirst) {
                    aiAssistanceConversationUid$.next(ulid());
                }
                await sendMessageToAIAssistance({
                    message: event.message,
                    workspaceUid: event.workspaceUid,
                    first: isFirst,
                    conversationUid: aiAssistanceConversationUid$.value ?? '',
                    model: event.model,
                    environmentUid: event.environmentUid,
                });
                aiAssistanceChat$.next([...chat, { role: 'user', message: event.message }]);
                pendoAnalyticsTrack('New question asked', {
                    userId: loggedInUserDetails$.value?.uid,
                    userOrigin: loggedInUserDetails$.value?.userOrigin,
                });
            } catch (e) {
                aiAssistanceAnswering$.next(false);
                if (e instanceof InformativeError) {
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: e.message,
                    });
                } else {
                    console.error('Error while getting AI generated response.', e);

                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: `Failed to get a response from AI assistant.`,
                        toastOptions: {
                            autoClose: false,
                        },
                    });
                }
            }

            loadingAiAssistanceDialog$.next(false);
        })
    )
    .subscribe();

createAiAssistanceGeneratedMessageAction$
    .pipe(
        map(async (event) => {
            aiAssistanceStreamId$.next(event.id);

            if (event.isLast) {
                aiAssistanceGeneratedMessageLength$.next(event.index);
                aiAssistanceAnswering$.next(false);
            }

            if (event.finish_reason === 'length') {
                aiAssistanceIsLongResponse$.next(true);
            }

            const currentPieces = aiAssistanceGeneratedMessagePieces$.value;
            const length = aiAssistanceGeneratedMessageLength$.value;
            const sortedPieces = [...currentPieces, event].sort((pieceA, pieceB) => pieceA.index - pieceB.index);
            const message = sortedPieces.map((piece) => piece.message).join('');
            aiAssistanceGeneratedMessagePieces$.next(sortedPieces);
            aiAssistanceGeneratedMessage$.next(message);

            if (currentPieces.length === length) {
                const chat = aiAssistanceChat$.value;
                aiAssistanceChat$.next([...chat, { role: 'assistant', message }]);
                aiAssistanceGeneratedMessagePieces$.next([]);
                aiAssistanceGeneratedMessage$.next(undefined);
            }
        })
    )
    .subscribe();
