import loader from '@monaco-editor/loader';

type Language = 'ts-strict' | 'ts' | 'js';

export const init = async (language: Language, inlineSourceMap: boolean): Promise<string> => {
    const monaco = await loader.init();
    const sourceMapProperty = inlineSourceMap ? { inlineSourceMap } : { sourceMap: true };

    monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
        // These options should reflect the capabilities of the Node/v8 version in the runtime lambda
        target: monaco.languages.typescript.ScriptTarget.ES2020,
        module: monaco.languages.typescript.ModuleKind.ES2015,
        moduleResolution: monaco.languages.typescript.ModuleResolutionKind.NodeJs,
        allowSyntheticDefaultImports: true,
        lib: ['es2020'],
        forceConsistentCasingInFileNames: true,

        allowNonTsExtensions: true,
        ...sourceMapProperty,
        removeComments: false,
        skipLibCheck: true,
        strict: language === 'ts-strict',
    });

    monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
        noSemanticValidation: language === 'js',
        noSuggestionDiagnostics: language === 'js',
        noSyntaxValidation: language === 'js',
    });

    console.debug(`TypeScript version: ${monaco.languages.typescript.typescriptVersion} - Language: ${language}`);

    return monaco.languages.typescript.typescriptVersion;
};
