import {
    getUsersReport as getUsersReportRpc,
    getAnonymousUsersReport as getAnonymousUsersReportRpc,
} from '@avst-stitch/repository-lib/lib';
import { Request as UsersReportRequest, Response as Users } from '@avst-stitch/repository-lib/lib/rpcs/report/getUsers';
import {
    Request as AnonymousUsersReportRequest,
    Response as AnonymousUsers,
} from '@avst-stitch/repository-lib/lib/rpcs/report/getAnonymousUsers';
import { repositoryInvoker } from '../../utils/repository';
export type { UsersReportRequest, Users, AnonymousUsersReportRequest, AnonymousUsers };

export const getUsers = async (request: UsersReportRequest): Promise<Users> => {
    return await getUsersReportRpc(repositoryInvoker, request);
};

export const getAnonymousUsers = async (request: AnonymousUsersReportRequest): Promise<AnonymousUsers> => {
    return await getAnonymousUsersReportRpc(repositoryInvoker, request);
};
