import { LoaderFn } from 'react-location';
import { getBillingEntities } from '../data/billing-details';
import { LocationGenerics } from '../router';
import { billingEntities$ } from '../store/billing-details';
import { loadErrorPage } from '../store/error';

export const billingDetailsLoader: LoaderFn<LocationGenerics> = async () => {
    try {
        const entities = await getBillingEntities();
        billingEntities$.next(entities);
    } catch (e) {
        loadErrorPage({
            error: e,
            pageTitle: 'Billing details',
            background: 'paper',
            genericMessage: 'Failed to load billing entities.',
        });

        throw e;
    }

    return {};
};
