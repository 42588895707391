import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const GoogleCalendarIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            version="1.1"
            viewBox="0 0 200 200"
            xmlSpace="preserve"
            className="productIcon"
            aria-label="Google Calendar"
        >
            <path
                fill="#FFF"
                d="M148.882 43.618l-47.368-5.263-57.895 5.263-5.264 52.632 5.263 52.632 52.632 6.579 52.632-6.579 5.263-53.947-5.263-51.317z"
                transform="translate(3.75 3.75)"
            ></path>
            <path
                fill="#1A73E8"
                d="M65.211 125.276c-3.934-2.658-6.658-6.539-8.145-11.671l9.132-3.763c.829 3.158 2.276 5.605 4.342 7.342 2.053 1.737 4.553 2.592 7.474 2.592 2.987 0 5.553-.908 7.697-2.724s3.224-4.132 3.224-6.934c0-2.868-1.132-5.211-3.395-7.026s-5.105-2.724-8.5-2.724h-5.276v-9.039H76.5c2.921 0 5.382-.789 7.382-2.368 2-1.579 3-3.737 3-6.487 0-2.447-.895-4.395-2.684-5.855s-4.053-2.197-6.803-2.197c-2.684 0-4.816.711-6.395 2.145s-2.724 3.197-3.447 5.276l-9.039-3.763c1.197-3.395 3.395-6.395 6.618-8.987 3.224-2.592 7.342-3.895 12.342-3.895 3.697 0 7.026.711 9.974 2.145 2.947 1.434 5.263 3.421 6.934 5.947 1.671 2.539 2.5 5.382 2.5 8.539 0 3.224-.776 5.947-2.329 8.184-1.553 2.237-3.461 3.947-5.724 5.145v.539a17.379 17.379 0 017.342 5.724c1.908 2.566 2.868 5.632 2.868 9.211s-.908 6.776-2.724 9.579c-1.816 2.803-4.329 5.013-7.513 6.618-3.197 1.605-6.789 2.421-10.776 2.421-4.618.013-8.881-1.316-12.815-3.974zM121.25 79.961l-9.974 7.25-5.013-7.605 17.987-12.974h6.895v61.197h-9.895V79.961z"
                transform="translate(3.75 3.75)"
            ></path>
            <path
                fill="#EA4335"
                d="M148.882 196.25l47.368-47.368-23.684-10.526-23.684 10.526-10.526 23.684 10.526 23.684z"
                transform="translate(3.75 3.75)"
            ></path>
            <path
                fill="#34A853"
                d="M33.092 172.566l10.526 23.684h105.263v-47.368H43.618l-10.526 23.684z"
                transform="translate(3.75 3.75)"
            ></path>
            <path
                fill="#4285F4"
                d="M12.039-3.75C3.316-3.75-3.75 3.316-3.75 12.039v136.842l23.684 10.526 23.684-10.526V43.618h105.263l10.526-23.684L148.882-3.75H12.039z"
                transform="translate(3.75 3.75)"
            ></path>
            <path
                fill="#188038"
                d="M-3.75 148.882v31.579c0 8.724 7.066 15.789 15.789 15.789h31.579v-47.368H-3.75z"
                transform="translate(3.75 3.75)"
            ></path>
            <path
                fill="#FBBC04"
                d="M148.882 43.618v105.263h47.368V43.618l-23.684-10.526-23.684 10.526z"
                transform="translate(3.75 3.75)"
            ></path>
            <path
                fill="#1967D2"
                d="M196.25 43.618V12.039c0-8.724-7.066-15.789-15.789-15.789h-31.579v47.368h47.368z"
                transform="translate(3.75 3.75)"
            ></path>
        </SvgIcon>
    );
};
