import React from 'react';
import { styled, TableCell } from '@mui/material';
import Typography from '@mui/material/Typography';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ProductIcon } from '../../icons/ProductIcon';
import { ThreeDotsButton } from '../../common/buttons/ThreeDotsButton';
import { SelectorAuthorized, SelectorTableRow } from '../../app-main/SelectorComponents';
import Tooltip from '@mui/material/Tooltip';

interface ConnectorSelectorTableItemProps {
    authorized?: boolean;
    description?: string;
    onDelete(uid: string): void;
    onSelect(uid: string): void;
    title: string;
    type: string;
    uid: string;
}

export const StyledTextCell = styled(TableCell)(() => ({
    minWidth: 200,
    width: '50%',
}));

export const StyledIconCell = styled(TableCell)(() => ({
    minWidth: 190,
    width: '23%',
    '& .MuiTableCell-head': {
        alignItems: 'center',
        display: 'inline-flex',
        flexDirection: 'row',
    },
}));

export const StyledShortCell = styled(TableCell)(() => ({
    textAlign: 'center',
    width: '4%',
}));

const StyledAppInfo = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    '& .MuiSvgIcon-root': {
        height: 24,
        marginRight: theme.spacing(1),
        width: 24,
    },
}));

export const ConnectorSelectorTableItem: React.FC<ConnectorSelectorTableItemProps> = ({
    authorized = false,
    onDelete,
    onSelect,
    title,
    type,
    uid,
}) => {
    return (
        <SelectorTableRow onClick={() => onSelect(uid)}>
            <StyledTextCell>
                <Typography variant="subtitle2">{title}</Typography>
            </StyledTextCell>
            <StyledIconCell>
                <StyledAppInfo>
                    <Tooltip placement="bottom" title={type}>
                        <span>
                            <ProductIcon name={type} />
                        </span>
                    </Tooltip>
                    <Typography variant="subtitle2" noWrap>
                        {type}
                    </Typography>
                </StyledAppInfo>
            </StyledIconCell>
            <StyledIconCell>{authorized && <SelectorAuthorized />}</StyledIconCell>
            <StyledShortCell>
                <ThreeDotsButton
                    uid={uid}
                    actions={[{ name: 'Delete', onSelect: onDelete, icon: <DeleteOutlineIcon /> }]}
                />
            </StyledShortCell>
        </SelectorTableRow>
    );
};
