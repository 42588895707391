import { useObservableState } from 'observable-hooks';
import { getBasePath } from '../../utils/path';
import { useNavigate } from 'react-location';
import { closeProfileUpdateSuccessDialogAction$, profileUpdateSuccessDialogOpen$ } from '../../store/onboarding';
import { ProfileUpdateSuccessDialog } from '../../components/profile/ProfileUpdateSuccessDialog';

export const ProfileUpdateSuccessDialogContainer: React.FC = () => {
    const navigate = useNavigate();
    const open = useObservableState(profileUpdateSuccessDialogOpen$);

    const onViewProfile = (): void => {
        closeProfileUpdateSuccessDialogAction$.next();
        navigate({ to: `${getBasePath()}/profile` });
    };

    return (
        <ProfileUpdateSuccessDialog
            open={open}
            onClose={() => closeProfileUpdateSuccessDialogAction$.next()}
            onViewProfile={onViewProfile}
        />
    );
};
