// Start deprecating this file as new styles will be implemented for dialogs
import { styled } from '@mui/material';
import DialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Alert, AlertProps } from '../../common/alerts/Alert';

interface DialogTitleMainProps extends DialogTitleProps {
    readonly tooltipElement?: JSX.Element;
    readonly icon?: JSX.Element;
    readonly title: string;
}

interface DialogCloseButtonProps {
    readonly onClick?: () => void;
    readonly disabled?: boolean;
}

export const StyledDialogTitle = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    marginBottom: theme.spacing(3),
    '& .MuiTypography-root': {
        marginRight: theme.spacing(1),
    },
    '&>.MuiSvgIcon-root': {
        height: 22,
        marginRight: theme.spacing(1),
        width: 22,
        '&.stitchIcon': {
            height: 30,
            width: 30,
        },
    },
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: theme.spacing(1.5),
    top: theme.spacing(1.5),
    '& .MuiSvgIcon-root': {
        height: 22,
        width: 22,
    },
}));

export const StyledDialogInstructions = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    marginBottom: theme.spacing(2),
    '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1),
    },
}));

export const DialogTitleMain: React.FC<DialogTitleMainProps> = ({
    title,
    icon,
    tooltipElement,
    sx,
    variant = 'h6',
}) => {
    return (
        <StyledDialogTitle sx={sx}>
            {icon}
            <DialogTitle mb={0} variant={variant}>
                {title}
            </DialogTitle>
            {tooltipElement ? tooltipElement : []}
        </StyledDialogTitle>
    );
};

export const DialogAlert: React.FC<AlertProps> = ({ severity, alertTitle, text, closeable, onClose, ...props }) => {
    return (
        <StyledAlert
            severity={severity}
            alertTitle={alertTitle}
            text={text}
            closeable={closeable}
            onClose={onClose}
            {...props}
        />
    );
};

export const DialogCloseButton: React.FC<DialogCloseButtonProps> = ({ onClick, disabled }) => {
    return (
        <StyledCloseButton disabled={disabled} onClick={onClick} title="Close">
            <CloseIcon />
        </StyledCloseButton>
    );
};
