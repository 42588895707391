import { styled } from '@mui/material';
import Accordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {
    absoluteCenterStyles,
    dashedBorderStyle,
    resourceTreeAccordionSummaryHeight,
    resourceTreeIconSize,
    resourceTreeIconWrapperSize,
} from './resourceTreeStyles';

export const StyledAccordion = styled((props: AccordionProps) => (
    <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    '&:before': {
        display: 'none',
    },
    '& .MuiTypography-root': {
        ...theme.typography.overflowLine,
    },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    flexDirection: 'row-reverse',
    height: resourceTreeAccordionSummaryHeight,
    minHeight: 0,
    padding: theme.spacing(0, 0.5, 0, 2),
    position: 'relative',
    '& .MuiAccordionSummary-content': {
        ...theme.typography.flexAlignCenter,
        justifyContent: 'space-between',
        margin: theme.spacing(0, 0, 0, 1),
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        height: resourceTreeIconWrapperSize,
        backgroundColor: theme.palette.background.default,
        width: resourceTreeIconWrapperSize,
        zIndex: 20,
        '& .MuiSvgIcon-root': {
            color: theme.palette.text.primary,
            height: resourceTreeIconSize,
            width: resourceTreeIconSize,
        },
    },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    padding: theme.spacing(0, 0, 0, 4),
    '&.folder:after': {
        borderLeft: dashedBorderStyle(theme),
        content: '""',
        height: `calc(100% - 34px)`,
        left: 59,
        position: 'absolute',
        top: 26,
        width: 1,
        zIndex: 10,
    },
}));

export const StyledResource = styled('div')(({ theme }) => ({
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'space-between',
    padding: theme.spacing(0.5, 0.5, 0.5, 2),
    position: 'relative',
    width: '100%',
    '&.warning .MuiSvgIcon-root': {
        color: theme.palette.warning.light,
    },
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
        '& .MuiSvgIcon-root': {
            opacity: 1,
        },
    },
}));

export const StyledResourceInfo = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    zIndex: 20,
}));

export const StyledResourceIcon = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    height: resourceTreeIconWrapperSize,
    justifyContent: 'center',
    marginRight: theme.spacing(1.5),
    minWidth: resourceTreeIconWrapperSize,
    position: 'relative',
    width: resourceTreeIconWrapperSize,
    '& .MuiSvgIcon-root, img': {
        ...absoluteCenterStyles,
        height: resourceTreeIconSize,
        width: resourceTreeIconSize,
    },
    '& img': {
        padding: 1,
    },
}));

export const StyledResourceTitle = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
}));

export const StyledResourceSubtitle = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    marginLeft: theme.spacing(3),
    '& .MuiTypography-root': {
        color: theme.palette.primary.main,
    },
    '& .MuiSvgIcon-root': {
        height: resourceTreeIconSize,
        marginRight: theme.spacing(1),
        width: resourceTreeIconSize,
    },
    '&.warning': {
        '& .MuiTypography-root': {
            color: theme.palette.text.secondary,
            fontStyle: 'italic',
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.warning.light,
        },
    },
    '&.info': {
        '& .MuiTypography-root': {
            color: theme.palette.text.secondary,
            fontStyle: 'italic',
        },
    },
}));

export const StyledResourceActionButtons = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    marginLeft: theme.spacing(1),
}));

const StyledResourceActionButton = styled(IconButton)(({ theme }) => ({
    '&:not(:first-of-type)': {
        marginLeft: theme.spacing(1),
    },
    '& .MuiSvgIcon-root': {
        opacity: 0,
    },
}));

const StyledLoadingSpinner = styled(CircularProgress)(() => ({
    height: `calc(${resourceTreeIconWrapperSize}px + 2px) !important`,
    position: 'absolute',
    width: `calc(${resourceTreeIconWrapperSize}px + 2px) !important`,
}));

interface ResourceActionButtonProps {
    busy?: boolean;
    icon: JSX.Element;
    tooltipTitle: string;
    uid: string;
    onClick?(uid: string): void;
}

export const ResourceActionButton: React.FC<ResourceActionButtonProps> = ({
    busy = false,
    icon,
    tooltipTitle,
    uid,
    onClick,
}) => {
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation();
        onClick?.(uid);
    };

    return (
        <Tooltip title={tooltipTitle} placement="top">
            <StyledResourceActionButton disabled={busy} onClick={handleClick}>
                {icon}
                {busy && <StyledLoadingSpinner />}
            </StyledResourceActionButton>
        </Tooltip>
    );
};
