import { useObservableState } from 'observable-hooks';
import { useMatch, useNavigate } from 'react-location';
import { RegisteredVsActiveUsersReportPage } from '../../components/report/RegisteredVsActiveUsersReportPage';
import { RegisteredVsActiveUsersRequest } from '../../data/report/registeredVsActiveUsers';
import { registeredVsActiveUsers$ } from '../../store/report/registeredVsActiveUsers';

export const RegisteredVsActiveUsersReportPageContainer: React.FC = () => {
    const navigate = useNavigate();
    const match = useMatch();
    const users = useObservableState(registeredVsActiveUsers$);

    return (
        <RegisteredVsActiveUsersReportPage
            users={users}
            userType={match.search.userType as RegisteredVsActiveUsersRequest['userType']}
            status={match.search.status as RegisteredVsActiveUsersRequest['status']}
            onSearch={(userType, status) => {
                navigate({
                    to: '../',
                });
                setTimeout(
                    () =>
                        navigate({
                            to: './users',
                            search: {
                                search: true,
                                userType,
                                status,
                            },
                        }),
                    100
                );
            }}
        />
    );
};
