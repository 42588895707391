import { getOrganisationsReport as getOrganisationsReportRpc } from '@avst-stitch/repository-lib/lib';
import {
    Response as Organisations,
    Request as OrganisationsRequest,
} from '@avst-stitch/repository-lib/lib/rpcs/report/getOrganisations';
import { repositoryInvoker } from '../../utils/repository';
export type { Organisations, OrganisationsRequest };

export const getOrganisations = async (request: OrganisationsRequest): Promise<Organisations> => {
    return await getOrganisationsReportRpc(repositoryInvoker, request);
};
