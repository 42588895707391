import { map, Observable } from 'rxjs';
import { getScript, getScripts, Script, ScriptWithUrl, ScriptsWithUrl } from '../data/script';
import { savedScriptDetails$, unsavedScriptDetails$ } from '../store/workspace/script';
import { getBundledOutputsFromSelectedWorkspace } from './bundler';
import { ScriptWithUid } from './types';
import { currentTypeDeclarationsLoaded$, totalTypeDeclarationsLoaded$ } from '../store/editor/editor';
import { wrapAsync } from './react';
import { refetch } from './refetch';
import { publishLocalFeedbackEventAction$ } from '../store/feedback';

export const getUnsavedScriptCached = async (scriptUid: string, environmentUid?: string): Promise<Script> => {
    const scriptKey = `${scriptUid}_${environmentUid ?? ''}`;
    let script = unsavedScriptDetails$.value[scriptKey];

    if (!script) {
        script = await getScript(scriptUid, environmentUid);

        savedScriptDetails$.next({ ...savedScriptDetails$.value, [scriptKey]: script });
        unsavedScriptDetails$.next({ ...unsavedScriptDetails$.value, [scriptKey]: script });
    }

    return script;
};

export const getUnsavedScriptWithUidCached = async (
    scriptUid: string,
    environmentUid?: string
): Promise<ScriptWithUid> => {
    const script = await getUnsavedScriptCached(scriptUid, environmentUid);

    return {
        ...script,
        uid: scriptUid,
    };
};

export const getScriptFromUrlWithUidCached = async (
    { uid, name, lastModifiedBy, lastModifiedDate, signedUrl }: ScriptWithUrl,
    environmentUid?: string
): Promise<ScriptWithUid> => {
    const scriptKey = `${uid}${environmentUid ? '_' : ''}${environmentUid}`;
    let script = unsavedScriptDetails$.value[scriptKey];

    if (!script) {
        const content = await (
            await refetch(signedUrl, {
                retryDelay: 100,
                retries: 5,
                afterFetch: async (_time, response) => {
                    if (!response?.ok) {
                        publishLocalFeedbackEventAction$.next({
                            level: 'ERROR',
                            message: `${name} could not be loaded. Please reload the page, if the issue persists please contact support`,
                            noToast: true,
                        });
                    }
                },
            })
        ).text();
        script = {
            name,
            lastModifiedBy,
            lastModifiedDate,
            content,
        };

        savedScriptDetails$.next({ ...savedScriptDetails$.value, [scriptKey]: script });
        unsavedScriptDetails$.next({ ...unsavedScriptDetails$.value, [scriptKey]: script });
    }

    return {
        ...script,
        uid,
    };
};

export const getScriptsSignedUrls = async (
    scriptUids: string[],
    workspaceUid?: string,
    environmentUid?: string
): Promise<ScriptsWithUrl> => {
    if (!workspaceUid) {
        console.debug('Request to load scripts without a selected workspace so discarding');
        return [];
    }
    return await getScripts(scriptUids, workspaceUid, environmentUid);
};

export const saveScriptBundledOutput = async (
    scriptUid: string,
    externallyTriggerableScriptUids: string[],
    manual?: boolean
): Promise<void> => {
    await getBundledOutputsFromSelectedWorkspace({
        scriptUid,
        manual,
        externallyTriggerableScriptUids,
    });
};

export const bundleScriptWhenEventIsEmitted = (observable: Observable<{ uid: string }>): void => {
    observable
        .pipe(
            map(async ({ uid }) => {
                const bundleScriptIfTypesAreLoaded = async (): Promise<void> => {
                    try {
                        if (currentTypeDeclarationsLoaded$.value === totalTypeDeclarationsLoaded$.value) {
                            await saveScriptBundledOutput(uid, []);
                        } else {
                            setTimeout(wrapAsync(bundleScriptIfTypesAreLoaded), 100);
                        }
                    } catch (e) {
                        console.error(`Failed to try to bundle newly created script: ${uid}`, e);
                    }
                };

                bundleScriptIfTypesAreLoaded();
            })
        )
        .subscribe();
};

export const legacyImportsRegex = /import\s*\{?\s*.*\s*\}?\s*from\s*('|").*stitch-it.*('|")/gm;
