import React from 'react';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import { Button } from '../../common/buttons/Button';

interface HttpLogsProps {
    invocationId: string;
    logs: string[];
    logsUrl?: string;
}

const StyledOuterContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(2, 3),
    position: 'relative',
    height: '100%',
    width: '100%',
}));

const StyledTitleContainer = styled('div')(() => ({
    height: 40,
}));

const StyledList = styled(List)({
    whiteSpace: 'pre-wrap',
});

const StyledListItem = styled(ListItem)(({ theme }) => ({
    padding: theme.spacing(2),
}));

export const HttpLogs: React.FC<HttpLogsProps> = ({ invocationId, logs, logsUrl }) => {
    return (
        <StyledOuterContainer>
            <StyledTitleContainer>
                <Typography variant="h3">HTTP Logs for Invocation {invocationId}</Typography>
            </StyledTitleContainer>
            <div>
                <Button variant="contained" href={logsUrl}>
                    Download HTTP Logs
                </Button>
            </div>
            <StyledList>
                {logs.map((httpLog, i) => (
                    <StyledListItem key={i}>
                        <ListItemText primary={httpLog} />;
                    </StyledListItem>
                ))}
            </StyledList>
        </StyledOuterContainer>
    );
};
