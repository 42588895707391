import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React from 'react';
import { TrelloConnection } from './TrelloAppConfigureDialog';

export enum TrelloWizardStage {
    CREATE,
    INPUT_API_KEY,
    INPUT_TOKEN,
}

export interface TrelloWizardStepsProps {
    readonly stage: TrelloWizardStage;
}

export const TrelloWizardSteps: React.FC<TrelloWizardStepsProps> = ({ stage }) => {
    return (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={TrelloWizardStage.CREATE}>
                <StepLabel>Create Power-Up in Trello</StepLabel>
            </Step>
            <Step key={TrelloWizardStage.INPUT_API_KEY}>
                <StepLabel>Enter API Key</StepLabel>
            </Step>
            <Step key={TrelloWizardStage.INPUT_TOKEN}>
                <StepLabel>Enter Token</StepLabel>
            </Step>
        </Stepper>
    );
};

export type StageType = React.FC<
    TrelloConnection & {
        stage: TrelloWizardStage;
        setStage: (stage: TrelloWizardStage) => void;
    }
>;
