import { BehaviorSubject, map, Subject } from 'rxjs';
import { getFetchOptions } from '../../../utils/fetch';
import { InformativeError } from '../../../utils/repository';
import { configTopic$, stitchSession$ } from '../../config';
import { publishLocalFeedbackEventAction$ } from '../../feedback';
import { monitor } from '../../monitor';
import { SESSION_ID } from '../../..';

export const azureDevopsEventListenerSetupDialogOpenAction$ = monitor(
    'azureDevopsEventListenerSetupDialogOpenAction$',
    new Subject<AzureDevopsEventListenerSetupBaseDetails>()
);
export const azureDevopsEventListenerSetupDialogCloseAction$ = monitor(
    'azureDevopsEventListenerSetupDialogCloseAction$',
    new Subject<void>()
);
export const azureDevopsEventListenerSetupDialogSaveTokenAction$ = monitor(
    'azureDevopsEventListenerSetupDialogSaveTokenAction$',
    new Subject<AzureDevopsEventListenerSaveTokenDetails>()
);

export const azureDevopsEventListenerSetupDialogSaving$ = monitor(
    'azureDevopsEventListenerSetupDialogSaving$',
    new BehaviorSubject(false)
);

export const azureDevopsEventListenerSetupDialogLoading$ = monitor(
    'azureDevopsEventListenerSetupDialogLoading$',
    new BehaviorSubject(false)
);
export const azureDevopsEventListenerSetupDialogOpen$ = monitor(
    'azureDevopsEventListenerSetupDialogOpen$',
    new BehaviorSubject(false)
);
export const azureDevopsEventListenerSetupDialogDetails$ = monitor(
    'azureDevopsEventListenerSetupDialogDetails$',
    new BehaviorSubject<AzureDevopsEventListenerSetupBaseDetails | undefined>(undefined)
);
export const azureDevopsEventListenerSetupDialogErrors$ = monitor(
    'azureDevopsEventListenerSetupDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);

export type AzureDevopsEventListenerSetupBaseDetails = {
    uid: string;
    environmentUid: string;
    webhookUrl: string;
    token: string;
};

interface AzureDevopsEventListenerSaveTokenDetails {
    uid: string;
    environmentUid: string;
    token: string;
}

azureDevopsEventListenerSetupDialogOpenAction$
    .pipe(
        map(async (details) => {
            azureDevopsEventListenerSetupDialogLoading$.next(true);
            azureDevopsEventListenerSetupDialogOpen$.next(true);

            try {
                azureDevopsEventListenerSetupDialogDetails$.next(details);
                azureDevopsEventListenerSetupDialogLoading$.next(false);
            } catch (e) {
                console.error('Error while loading Event Listener setup instructions', e);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to load Event Listener setup instructions, please try again, if the issue persists please contact support.`,
                    toastOptions: {
                        autoClose: false,
                    },
                });

                azureDevopsEventListenerSetupDialogCloseAction$.next();
            }
        })
    )
    .subscribe();

azureDevopsEventListenerSetupDialogSaveTokenAction$
    .pipe(
        map(async (details) => {
            azureDevopsEventListenerSetupDialogSaving$.next(true);
            try {
                const baseUrl = configTopic$.value.connection?.azureDevops?.baseUrl;
                if (!baseUrl) {
                    throw new Error('No Azure Devops Connector URL configured in meta');
                }
                const saveTokenUrl = `${baseUrl}/save-token`;

                const fetchOptions = getFetchOptions(
                    { Authorization: stitchSession$.value?.jwt ?? '', 'x-stitch-session-id': SESSION_ID },
                    details
                );
                await fetch(saveTokenUrl, fetchOptions);
                azureDevopsEventListenerSetupDialogCloseAction$.next();
            } catch (e) {
                if (e instanceof InformativeError) {
                    azureDevopsEventListenerSetupDialogErrors$.next(e.message);
                } else {
                    console.error('Error while saving Event Listener token', e);
                    azureDevopsEventListenerSetupDialogErrors$.next(
                        'Failed to save Azure Devops Webhook event token. Please try again, if the issue persists please contact support.'
                    );
                }
            }
            azureDevopsEventListenerSetupDialogSaving$.next(false);
        })
    )
    .subscribe();

azureDevopsEventListenerSetupDialogCloseAction$.subscribe(() => {
    azureDevopsEventListenerSetupDialogOpen$.next(false);
    azureDevopsEventListenerSetupDialogSaving$.next(false);
    azureDevopsEventListenerSetupDialogDetails$.next(undefined);
});
