import { useObservableState } from 'observable-hooks';
import { useMatch, useNavigate } from 'react-location';
import { TemplatesUsedReportPage } from '../../components/report/TemplatesUsedReportPage';
import { TemplatesUsedRequest } from '../../data/report/templatesUsed';
import { templatesUsed$ } from '../../store/report/templatesUsed';

export const TemplatesUsedReportPageContainer: React.FC = () => {
    const navigate = useNavigate();
    const match = useMatch();
    const templates = useObservableState(templatesUsed$);

    return (
        <TemplatesUsedReportPage
            templates={templates}
            userType={match.search.userType as TemplatesUsedRequest['userType']}
            onSearch={(userType) => {
                navigate({
                    to: '../',
                });
                setTimeout(
                    () =>
                        navigate({
                            to: './templates',
                            search: {
                                search: true,
                                userType,
                            },
                        }),
                    100
                );
            }}
        />
    );
};
