import { useObservableState } from 'observable-hooks';
import { StatuspageManageConnectionDialog } from '../../../../components/connections/apps/statuspage/ManageConnectionDialog';
import {
    statuspageConnectionSaving$,
    statuspageManageConnectionAuthorizeLoading$,
    statuspageManageConnectionCloseDialogAction$,
    statuspageManageConnectionDetails$,
    statuspageManageConnectionDialogErrors$,
    statuspageManageConnectionDialogOpen$,
    statuspageManageConnectionInitiateSetupAction$,
    statuspageSaveConnectionAction$,
} from '../../../../store/connection/statuspage/setup-connection';
import { loggedInUserConnections$ } from '../../../../store/connections';

export const StatuspageManageConnectionDialogContainer: React.FC = () => {
    const open = useObservableState(statuspageManageConnectionDialogOpen$);
    const saving = useObservableState(statuspageConnectionSaving$);
    const authorizeLoading = useObservableState(statuspageManageConnectionAuthorizeLoading$);
    const details = useObservableState(statuspageManageConnectionDetails$);
    const errors = useObservableState(statuspageManageConnectionDialogErrors$);
    const connection = useObservableState(loggedInUserConnections$).find(
        (connection) => connection.uid === details?.uid
    );

    return (
        <StatuspageManageConnectionDialog
            open={open}
            saving={saving}
            errors={errors}
            authorizeLoading={authorizeLoading}
            name={connection?.name ?? ''}
            authorized={!!connection?.authorized}
            onCancel={() => statuspageManageConnectionCloseDialogAction$.next()}
            onSave={(name) => statuspageSaveConnectionAction$.next(name)}
            onAuthorize={(name) => statuspageManageConnectionInitiateSetupAction$.next(name)}
            connectionType={details?.connectionType.name}
        />
    );
};
