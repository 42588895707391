import { useObservableState } from 'observable-hooks';
import { ReadOnlyApiConnectionDetails } from '../../../components/workspace-resources/api-connections/ReadOnlyApiConnectionDetails';
import { selectedApiHandlerDetails$ } from '../../../store/workspace/api-handler';
import { selectedWorkspaceResources$ } from '../../../store/workspace';

export const ReadOnlyApiHandlerDetailsContainer: React.FC = () => {
    const selectedApiHandlerDetails = useObservableState(selectedApiHandlerDetails$);
    const workspaceResources = useObservableState(selectedWorkspaceResources$);

    const vendorApiVersions = workspaceResources.apiHandlers.flatMap((handler) =>
        handler.uid === selectedApiHandlerDetails?.uid
            ? handler.libraries.map((library) => ({
                  value: library.uid,
                  name: library.deprecated
                      ? `${library.label} (deprecated)`
                      : library.recommended
                      ? `${library.label} (recommended)`
                      : library.label || '',
              }))
            : []
    );

    return (
        <ReadOnlyApiConnectionDetails
            selectedApiHandlerLibraryUid={selectedApiHandlerDetails?.apiHandlerLibraryUid ?? ''}
            path={selectedApiHandlerDetails?.path ?? ''}
            vendorApiVersions={vendorApiVersions}
        />
    );
};
