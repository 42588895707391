import { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CodeRoundedIcon from '@mui/icons-material/CodeRounded';
import { Button } from '../common/buttons/Button';
import { DialogAlert, DialogTitleMain, StyledDialogInstructions } from '../for-deprecation/dialog/DialogComponents';
import { IconCircle } from '../for-deprecation/IconCircle';
import { InfoIcon } from '../icons/InfoIcon';
import { autoFocus } from '../../utils/autoFocus';
import { handleKeyDown } from '../../utils/handleKeyDown';

interface NewScriptDialogProps {
    errors?: string;
    open?: boolean;
    saving?: boolean;
    onCancel: () => void;
    onCreate: (name?: string) => void;
}

export const NewScriptDialog: React.FC<NewScriptDialogProps> = ({
    errors,
    open = false,
    saving = false,
    onCancel,
    onCreate,
}) => {
    //const [description, setDescription] = useState('');
    const [name, setName] = useState('');

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setName('');
        autoFocus(inputRef);
    }, [open]);

    const canCreate = !saving && !!name;

    return (
        <>
            <Dialog
                open={open}
                onKeyDown={(event) =>
                    handleKeyDown({
                        event,
                        enterCondition: canCreate,
                        enterFn: () => onCreate(name),
                        escFn: onCancel,
                    })
                }
            >
                <DialogTitleMain title="New Script" icon={<IconCircle icon={<CodeRoundedIcon />} />} />
                {errors && <DialogAlert severity="error" alertTitle="Error" text={errors} />}
                <DialogContent>
                    <StyledDialogInstructions>
                        <InfoIcon />
                        <Typography>To set up a folder please create a path using '/'</Typography>
                    </StyledDialogInstructions>
                    <TextField
                        inputRef={inputRef}
                        variant="outlined"
                        label="Script name"
                        placeholder="Enter a name"
                        required
                        value={name}
                        onChange={(event) => {
                            setName(event.target.value);
                        }}
                    />
                    {/* <StyledTextField
                                fullWidth
                                variant="outlined"
                                label="Script description (optional)"
                                placeholder="Enter a description"
                                multiline
                                rows={3}
                                value={description}
                                onChange={(event) => setDescription(event.target.value)}
                            ></StyledTextField> */}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" data-hotspot="cancel" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button busy={saving} disabled={!canCreate} onClick={() => onCreate(name)}>
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
