import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React from 'react';
import { AtlassianOnPremiseConnection } from './AtlassianOnPremiseAppConfigureDialog';

export enum AtlassianOnPremiseWizardStage {
    ADDURL,
    CREATE,
    DETAILS,
    AUTHORIZE,
    SUCCESS,
}

export interface AtlassianOnPremiseWizardStepsProps {
    readonly stage: AtlassianOnPremiseWizardStage;
    readonly connectionType?: string;
}

export const AtlassianOnPremiseWizardSteps: React.FC<AtlassianOnPremiseWizardStepsProps> = ({
    stage,
    connectionType,
}) => {
    return (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={AtlassianOnPremiseWizardStage.ADDURL}>
                <StepLabel>Add {connectionType} Instance URL</StepLabel>
            </Step>
            <Step key={AtlassianOnPremiseWizardStage.CREATE}>
                <StepLabel>Create Application Link in {connectionType ?? 'Your Instance'}</StepLabel>
            </Step>
            <Step key={AtlassianOnPremiseWizardStage.DETAILS}>
                <StepLabel>Enter Details</StepLabel>
            </Step>
            <Step key={AtlassianOnPremiseWizardStage.AUTHORIZE}>
                <StepLabel>Authorize</StepLabel>
            </Step>
        </Stepper>
    );
};

export type StageType = React.FC<
    AtlassianOnPremiseConnection & {
        stage: AtlassianOnPremiseWizardStage;
        setStage: (stage: AtlassianOnPremiseWizardStage) => void;
    }
>;
