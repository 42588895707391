import React from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import VerifiedIcon from '@mui/icons-material/Verified';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material';
import { Plan, prices } from '../../create-organization-wizard/CreateOrganizationWizardDialog';
import { DialogAlert } from '../../../for-deprecation/dialog/DialogComponents';
import { getPlanData } from '../../create-organization-wizard/BillingPlanCard';
import { AlertProps } from '../../../common/alerts/Alert';
import { OrganizationPlan } from '../../../../data/organization';
import { formatBytesToString } from '../../../../utils/formatBytes';

export interface ChangeOrganizationPlanConfirmationContentProps {
    plan: Plan | OrganizationPlan;
    featureMessage?: string;
    listIcon?: JSX.Element;
    listIconColor?: string;
    cancellationDate?: Date;
    cancellationDateIconColor?: string;
    finalAlert?: AlertProps;
}

const StyledPlanTierAndPriceContainer = styled('div')(({ theme }) => ({
    paddingBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'capitalize',
}));

const StyledPriceContainer = styled('div')(() => ({
    alignItems: 'flex-end',
    display: 'flex',
    height: 36,
    justifyContent: 'center',
    textTransform: 'none',
}));

const StyledCardContent = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
}));

const StyledList = styled(List)(({ theme }) => ({
    padding: theme.spacing(2, 0, 0, 0),
}));

const StyledListItem = styled(ListItem)(() => ({
    padding: 0,
}));

const StyledCancellationDateHeaderListItem = styled(ListItem)(({ theme }) => ({
    padding: theme.spacing(3, 0, 0, 0),
}));

const StyledCancellationDateTextListItem = styled(ListItem)(() => ({
    padding: 0,
}));

const StyledCancellationDateListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    minWidth: 'unset',
    paddingRight: theme.spacing(1),
    color: theme.palette.info.main,
}));

const StyledBoldListItemText = styled(ListItemText)(({ theme }) => ({
    '& .MuiListItemText-primary': {
        fontWeight: theme.typography.h2.fontWeight,
    },
}));

const StyledSubtitleListItemText = styled(ListItemText)(({ theme }) => ({
    '& .MuiListItemText-primary': {
        color: theme.palette.text.secondary,
    },
}));

const StyledBoldSpan = styled('span')(({ theme }) => ({
    fontWeight: theme.typography.h1.fontWeight,
    color: theme.palette.text.secondary,
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    minWidth: 'unset',
    paddingRight: theme.spacing(1),
}));

const StyledWarningAlert = styled(DialogAlert)(({ theme }) => ({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0),
}));

export const ChangeOrganizationPlanConfirmationContent: React.FC<ChangeOrganizationPlanConfirmationContentProps> = ({
    plan,
    featureMessage,
    listIcon = <VerifiedIcon />,
    listIconColor,
    cancellationDate,
    cancellationDateIconColor,
    finalAlert,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const currentPlanPrice =
        'cost' in plan ? plan.cost : plan.tier === 'free' ? '0' : prices[plan.tier]?.[plan.period] ?? '0';
    const currentPlanData =
        'cost' in plan
            ? {
                  connectors: `${plan.connectorsLimit === -1 ? 'Unlimited' : plan.connectorsLimit}`,
                  functionInvocations: `${
                      plan.invocationsLimit === -1 ? 'Unlimited' : plan.invocationsLimit.toLocaleString('en-US')
                  }`,
                  storageCapacity: `${formatBytesToString(plan.recordStorageCapacityLimit).replace(' ', '')}`,
              }
            : getPlanData(plan.subscriptionStatus === 'SUSPENDED' ? 'suspended' : plan.tier);

    let cancellationDateString: string | undefined = undefined;
    if (cancellationDate) {
        const date = new Date(cancellationDate);
        const day = date.getDate();
        const dayOrdinalEnd =
            day > 0 ? ['th', 'st', 'nd', 'rd'][(day > 3 && day < 21) || day % 10 > 3 ? 0 : day % 10] : '';
        cancellationDateString = `${day}${dayOrdinalEnd} `;
        cancellationDateString += date.toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
        });
    }

    return (
        <StyledCardContent>
            <StyledPlanTierAndPriceContainer>
                <Typography variant="h4" className="extra-bold">
                    {plan.tier}
                </Typography>
                <StyledPriceContainer>
                    <Typography
                        component="p"
                        sx={{ placeSelf: 'flex-start' }}
                        color="text.secondary"
                        variant="subtitle1"
                    >
                        $
                    </Typography>
                    <Typography variant="h4" className="extra-bold">
                        {currentPlanPrice}
                    </Typography>
                    <Typography component="p" color="text.secondary" variant="subtitle1">
                        {`/ ${plan.period.toLowerCase() === 'monthly' ? 'monthly' : 'yearly'}`}
                    </Typography>
                </StyledPriceContainer>
            </StyledPlanTierAndPriceContainer>
            <Divider />
            <StyledList>
                <StyledListItem>
                    <StyledBoldListItemText>Features</StyledBoldListItemText>
                </StyledListItem>
                {featureMessage && (
                    <StyledListItem>
                        <StyledSubtitleListItemText>{featureMessage}</StyledSubtitleListItemText>
                    </StyledListItem>
                )}
                <StyledListItem>
                    <StyledListItemIcon sx={{ color: listIconColor }}>{listIcon}</StyledListItemIcon>
                    <StyledSubtitleListItemText>
                        <StyledBoldSpan>{currentPlanData.connectors}</StyledBoldSpan> connectors
                    </StyledSubtitleListItemText>
                </StyledListItem>
                <StyledListItem>
                    <StyledListItemIcon sx={{ color: listIconColor }}>{listIcon}</StyledListItemIcon>
                    <StyledSubtitleListItemText>
                        <StyledBoldSpan>{currentPlanData.functionInvocations}</StyledBoldSpan> script invocations
                    </StyledSubtitleListItemText>
                </StyledListItem>
                {cancellationDateString && plan.tier !== 'free' && (
                    <>
                        <StyledCancellationDateHeaderListItem>
                            <StyledBoldListItemText>Cancellation date</StyledBoldListItemText>
                        </StyledCancellationDateHeaderListItem>
                        <StyledCancellationDateTextListItem>
                            <StyledCancellationDateListItemIcon sx={{ color: cancellationDateIconColor }}>
                                <EventNoteOutlinedIcon />
                            </StyledCancellationDateListItemIcon>
                            <StyledSubtitleListItemText>
                                Your plan and features will become inactive from{' '}
                                <StyledBoldSpan>{cancellationDateString}</StyledBoldSpan>
                            </StyledSubtitleListItemText>
                        </StyledCancellationDateTextListItem>
                    </>
                )}
            </StyledList>
            {finalAlert && <StyledWarningAlert {...finalAlert} />}
        </StyledCardContent>
    );
};
