import { LoaderFn } from 'react-location';
import { LocationGenerics } from '../../router';
import { selectedWorkspaceResources$, selectedWorkspaceSelectedResource$ } from '../../store/workspace';
import { selectedReadOnlyTemplate$ } from '../../store/templates';
import { loadReadmeFile } from '../../store/workspace/utils';
import { getTemplate } from '../../data/template';
import { getWorkspaceResources } from '../../data/workspace';
import { environmentVariablesFormErrors$ } from '../../store/workspace/environment-variable';

export const environmentVariablesLoader: LoaderFn<LocationGenerics> = async (routeMatch) => {
    const workspaceUid = routeMatch.params.workspaceUid;
    const environmentUid = routeMatch.params.environmentUid;
    const templateUid = routeMatch.params.templateUid;

    const isTemplate = !routeMatch.params.workspaceUid;

    const template = isTemplate ? selectedReadOnlyTemplate$.value ?? (await getTemplate(templateUid ?? '')) : undefined;

    const currentWorkspaceUid = isTemplate ? template?.workspaceUid : workspaceUid;
    const currentEnvironmentUid = isTemplate ? template?.environmentUid : environmentUid;

    environmentVariablesFormErrors$.next(undefined);

    const resources =
        selectedWorkspaceResources$.value && !selectedWorkspaceResources$.value.default
            ? selectedWorkspaceResources$.value
            : await getWorkspaceResources(currentWorkspaceUid ?? '', currentEnvironmentUid ?? '');

    const readmeUid = resources.readmeFile?.uid;
    await loadReadmeFile(readmeUid ?? '', currentWorkspaceUid ?? '', currentEnvironmentUid ?? '');

    selectedWorkspaceSelectedResource$.next({
        resourceType: 'ENVIRONMENT_VARIABLES',
    });

    return {};
};
