/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { ReflexElement, ReflexElementProps, ReflexSplitter } from 'react-reflex';
import { Editor } from './scripts/editor/Editor';
import { Listener } from './scripts/editor/Listener';
import { PreviewEditor } from './scripts/editor/PreviewEditor';
import { SetModel } from './scripts/editor/SetModel';
import { StyledReflexContainer, StyledReflexElement } from '../reflex/ReflexComponents';
import { readLocalStorage, saveLocalStorage } from '../../utils/localStorage';

export interface ReadmeFileDetailsProps {
    content: string;
    hasUnsavedChanges: boolean;
    parsedContent: string;
    readmeFileUid: string;
    readOnlyMode: boolean;
    saving: boolean;
    workspaceLocked?: boolean;
    onContentChange(content: string): void;
    onSave(): void;
}

export type HandlerProps = {
    domElement: Element | Text;
    component: React.ComponentElement<ReflexElementProps, ReflexElement>;
};

const editorFlexGrowKey = 'stitchReadmeEditorWidth';
const editorDefaultFlexGrow = 0.75;

const minColWidth = 60;

const StyledLoadingSpinner = styled(CircularProgress)(({ theme }) => ({
    color: theme.palette.text.disabled,
    height: '26px !important',
    position: 'absolute',
    width: '26px !important',
}));

const StyledReadmeFileEditorToolbarIcon = styled(IconButton)(({ theme }) => ({
    marginRight: theme.spacing(1.5),
    '& .MuiSvgIcon-root': {
        color: theme.palette.text.secondary,
        height: 22,
        width: 22,
    },
}));

const StyledReadmeFileEditorToolbarIconContainer = styled('div')(({ theme }) => ({
    borderRadius: 0,
    marginLeft: theme.spacing(3),
}));

const StyledReadmeFileName = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(1),
}));

const StyledReadmeFileNameContainer = styled('div')(() => ({
    alignItems: 'center',
    display: 'flex',
}));

const StyledSaveIcon = styled(SaveOutlinedIcon)(({ theme }) => ({
    border: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: theme.constants.borderRadius,
}));

const StyledToolbar = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    backgroundColor: theme.palette.background.default,
    boxShadow: '0px 0.5px 2px rgba(0, 0, 0, 0.16)',
    height: theme.spacing(6),
    justifyContent: 'flex-start',
    width: '100%',
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const platform = (navigator as any).userAgentData ? (navigator as any).userAgentData.platform : navigator.platform;

const SAVE_TOOLTIP = `Save README file (${platform.toLowerCase().includes('mac') ? 'COMMAND + S' : 'CTRL + S'})`;
const OPEN_EDITOR_TOOLTIP = 'Open editor';
const CLOSE_EDITOR_TOOLTIP = 'Close editor';

export const ReadmeFileDetails: React.FC<ReadmeFileDetailsProps> = ({
    content,
    hasUnsavedChanges,
    parsedContent,
    readmeFileUid,
    readOnlyMode,
    saving,
    workspaceLocked = false,
    onContentChange,
    onSave,
}) => {
    const [editorOpen, setEditorOpen] = useState(false);
    const [editorFlexGrow, setEditorFlexGrow] = useState(
        editorOpen ? readLocalStorage(editorFlexGrowKey, editorDefaultFlexGrow) : 0.15
    );

    useEffect(() => {
        if (editorOpen) {
            setEditorFlexGrow(readLocalStorage(editorFlexGrowKey, editorDefaultFlexGrow));
        }
    }, []);

    const handleResizeEditor = (e: HandlerProps): void => {
        if (e.domElement instanceof Element) {
            const flexGrowCurrent = +window.getComputedStyle(e.domElement).flexGrow;
            saveLocalStorage(editorFlexGrowKey, flexGrowCurrent);
        }
    };

    const handleContentChange = (content: string): void => {
        onContentChange(content);
    };

    const handleCollapseEditor = (): void => {
        setEditorOpen(false);
        setEditorFlexGrow(0.15);
    };

    const handleExpandEditor = (): void => {
        setEditorOpen(true);
        setEditorFlexGrow(readLocalStorage(editorFlexGrowKey, editorDefaultFlexGrow));
    };

    const readmeFileName = 'README';

    const icons = (
        <StyledReadmeFileEditorToolbarIconContainer>
            <Tooltip title={editorOpen ? CLOSE_EDITOR_TOOLTIP : OPEN_EDITOR_TOOLTIP}>
                {editorOpen ? (
                    <StyledReadmeFileEditorToolbarIcon onClick={handleCollapseEditor}>
                        <CloseIcon />
                    </StyledReadmeFileEditorToolbarIcon>
                ) : (
                    <StyledReadmeFileEditorToolbarIcon onClick={handleExpandEditor}>
                        <EditOutlinedIcon />
                    </StyledReadmeFileEditorToolbarIcon>
                )}
            </Tooltip>
            <Tooltip title={SAVE_TOOLTIP}>
                <span>
                    <StyledReadmeFileEditorToolbarIcon
                        onClick={onSave}
                        disabled={saving || !hasUnsavedChanges || workspaceLocked}
                        sx={(theme) => {
                            return {
                                '&:disabled .MuiSvgIcon-root': {
                                    color: theme.palette.text.disabled,
                                },
                                '& .MuiSvgIcon-root': {
                                    border: saving || !hasUnsavedChanges ? 'none' : undefined,
                                },
                            };
                        }}
                    >
                        <StyledSaveIcon />
                        {saving && <StyledLoadingSpinner />}
                    </StyledReadmeFileEditorToolbarIcon>
                </span>
            </Tooltip>
        </StyledReadmeFileEditorToolbarIconContainer>
    );

    return (
        <StyledReflexContainer sx={{ overflowY: 'hidden' }} orientation="vertical" data-test-id="readme-details">
            <StyledReflexElement sx={{ overflowY: 'hidden !important' }} minSize={minColWidth}>
                <StyledToolbar>
                    {!readOnlyMode && icons}
                    <StyledReadmeFileNameContainer>
                        <StyledReadmeFileName>{readmeFileName}</StyledReadmeFileName>
                    </StyledReadmeFileNameContainer>
                </StyledToolbar>

                {!readOnlyMode && editorOpen ? (
                    <StyledReflexContainer orientation="vertical" data-test-id="readme-details">
                        <StyledReflexElement
                            sx={{ overflowY: 'hidden' }}
                            minSize={minColWidth}
                            flex={editorFlexGrow}
                            onStopResize={handleResizeEditor}
                        >
                            <Editor
                                hasUnsavedChanges={hasUnsavedChanges}
                                scriptUid={readmeFileUid}
                                value={content}
                                name={`${readmeFileName}.md`}
                                uid={readmeFileUid}
                                readOnly={workspaceLocked}
                                readme={true}
                                onSave={onSave}
                            >
                                {readmeFileName && <SetModel readmeFileName={`${readmeFileName}.md`} />}
                                <Listener onChange={handleContentChange} />
                            </Editor>
                        </StyledReflexElement>
                        <ReflexSplitter>
                            <DragIndicatorIcon aria-label="Vertical resize" />
                        </ReflexSplitter>
                        <StyledReflexElement sx={{ paddingBottom: 7 }}>
                            <PreviewEditor parsedContent={parsedContent ?? ''} />
                        </StyledReflexElement>
                    </StyledReflexContainer>
                ) : (
                    <StyledReflexContainer>
                        <StyledReflexElement sx={{ paddingBottom: 7 }}>
                            <PreviewEditor parsedContent={parsedContent ?? ''} />
                        </StyledReflexElement>
                    </StyledReflexContainer>
                )}
            </StyledReflexElement>
        </StyledReflexContainer>
    );
};
