import { horizonDark, horizonLight, ThemeMode, useThemeMode } from './index';
import CssBaseline from '@mui/material/CssBaseline';
import { Theme, ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export const stitchThemeModeKey = 'stitchThemeMode';

export interface AppThemeProps {
    /**
     * Select an alternative light theme
     * Defaults to the Horizon theme
     * null will explicitly exclude the ThemeProvider
     */
    readonly theme?: Partial<Theme> | ((theme: Theme) => Theme);

    /**
     * Select an alternative dark theme
     * Defaults to the Horizon theme
     */
    readonly darkTheme?: Partial<Theme> | ((theme: Theme) => Theme);

    /**
     * Select the theme mode: 'light', 'dark', or 'system'
     * Defaults to 'system' (which defaults to 'light' if not detectable)
     */
    readonly themeMode: ThemeMode;

    /**
     * Children of the component
     */
    readonly children?: React.ReactNode;

    /**
     * add stylesprovider - lets us use the theme wrapper in multiple places
     */
    readonly stylesInject?: boolean;
}

export const AppTheme: React.FC<AppThemeProps> = ({
    theme = horizonLight,
    darkTheme = horizonDark,
    themeMode,
    children,
    stylesInject = false,
}) => {
    const actualTheme = useThemeMode(themeMode, theme, darkTheme);

    return (
        <StylesProvider injectFirst={stylesInject}>
            <ThemeProvider theme={actualTheme}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CssBaseline />
                    {children}
                </LocalizationProvider>
            </ThemeProvider>
        </StylesProvider>
    );
};
