import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';
import { useTheme } from '@mui/material';

export const SRConnectIcon: React.FC<SvgIconProps> = (props) => {
    const theme = useTheme();

    return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" style={{ isolation: 'isolate' }} viewBox="0 0 140 140">
            <defs>
                <clipPath id="_clipPath_9ZkOOhtt9XJgmzUTBmcXw2wkjJMol71G">
                    <path d="M0 0H140V140H0z"></path>
                </clipPath>
            </defs>
            <g clipPath="url(#_clipPath_9ZkOOhtt9XJgmzUTBmcXw2wkjJMol71G)">
                <circle
                    cx="70"
                    cy="70"
                    r="65.7"
                    fill={theme.palette.brand.main}
                    vectorEffect="non-scaling-stroke"
                ></circle>
                <path
                    fill={theme.palette.brand.contrastText}
                    d="M85.8 80.7c-3.3 4-8.1 6.4-13.3 6.4-7.3 0-13.8-4.8-16.1-11.6C59.7 74 62 70.8 62 67c0-5.2-4.2-9.4-9.4-9.4s-9.4 4.2-9.4 9.4c0 4 2.5 7.4 6 8.7 2.6 10.7 12.2 18.4 23.3 18.4 6.1 0 11.9-2.3 16.3-6.4L101.7 98c-7.6 8-18.1 12.5-29.2 12.5-5.4 0-10.8-1.1-15.8-3.2.1-.5.1-1 .1-1.5 0-5.2-4.2-9.4-9.4-9.4-1.7 0-3.2.4-4.6 1.2-7-7.5-10.9-17.2-10.9-27.6 0-10.8 4.2-20.9 11.8-28.6 7.8-7.5 18-11.7 28.8-11.7 6.4 0 12.8 1.6 18.5 4.5 4.5 2.3 8.5 5.5 11.8 9.2l-13.3 9.8c-1.5-1.5-3.1-2.8-5-3.9-.2-5-4.3-8.9-9.3-8.9-5.2 0-9.4 4.2-9.4 9.4s4.2 9.4 9.4 9.4c2.7 0 5.2-1.2 6.9-3.1 1.5 1.1 2.9 2.4 4.1 3.9l2.1 2.8 24.5-18.1-2.1-2.8c-4.3-5.8-10-10.7-16.4-14-6.7-3.4-14.2-5.3-21.7-5.3-12.7 0-24.6 4.9-33.5 13.9-9 9-13.9 20.9-13.9 33.5 0 12.5 4.8 24.2 13.5 33.1-.2.8-.4 1.7-.4 2.6 0 5.2 4.2 9.4 9.4 9.4 2.1 0 4-.7 5.5-1.8 6 2.7 12.8 4.1 19.5 4.1 14.5 0 27.9-6.5 37-17.8l2.2-2.7-23.7-19-2.4 2.8z"
                ></path>
            </g>
        </SvgIcon>
    );
};
