import { useObservableState } from 'observable-hooks';
import { useMatch, useNavigate } from 'react-location';
import { AnonymousUsersReportPage } from '../../components/report/AnonymousUsersReportPage';
import { AnonymousUsersReportRequest } from '../../data/report/users';
import { anonymousUsers$ } from '../../store/report/users';

export const AnonymousUsersReportPageContainer: React.FC = () => {
    const navigate = useNavigate();
    const match = useMatch();
    const users = useObservableState(anonymousUsers$);

    return (
        <AnonymousUsersReportPage
            users={users}
            referrerSite={match.search.referrerSite as string}
            referrerSiteComparator={
                match.search.referrerSiteComparator as AnonymousUsersReportRequest['referrerSiteComparator']
            }
            ip={match.search.ip as string}
            origin={match.search.origin as string}
            utmCampaign={match.search.utmCampaign as string}
            utmMedium={match.search.utmMedium as string}
            utmSource={match.search.utmSource as string}
            orderByField={match.search.orderByField as AnonymousUsersReportRequest['orderByField']}
            orderByDirection={match.search.orderByDirection as AnonymousUsersReportRequest['orderByDirection']}
            onSearch={(
                referrerSite,
                referrerSiteComparator,
                ip,
                origin,
                utmMedium,
                utmSource,
                utmCampaign,
                orderByField,
                orderByDirection
            ) => {
                // Ugly hack to force loader to get called
                navigate({
                    to: '../',
                });
                setTimeout(
                    () =>
                        navigate({
                            to: './anonymous',
                            search: {
                                search: true,
                                referrerSite,
                                referrerSiteComparator,
                                ip,
                                origin,
                                utmMedium,
                                utmSource,
                                utmCampaign,
                                orderByField,
                                orderByDirection,
                            },
                        }),
                    100
                );
            }}
        />
    );
};
