// Used for Monday.com dialogs and some team dialogs

import { styled } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { ProductIcon } from '../icons/ProductIcon';

interface DialogConnectionNameInputProps extends BoxProps {
    readonly connectionType: string;
    readonly value: string;
    readonly onChangeInputValue: (value: string) => void;
}

interface StyledDialogBadgeContainerProps extends BoxProps {
    readonly centered?: boolean;
    readonly color: string;
    readonly icon: JSX.Element;
}

export const StyledMondayDialog = styled(Dialog)(({ theme }) => ({
    '&.centered': {
        justifyContent: 'center',
        textAlign: 'center',
    },
    '& .MuiDialog-paper': {
        padding: theme.spacing(2),
    },
    '& .MuiFormControl-root': {
        margin: 0,
    },
}));

export const StyledMondayDialogTitle = styled(DialogTitle)(({ theme }) => ({
    letterSpacing: '-1.5px',
    marginBottom: theme.spacing(1),
}));

export const StyledMondayDialogActions = styled(DialogActions)(({ theme }) => ({
    margin: theme.spacing(1, 0, 0),
    '&.centered': {
        justifyContent: 'center',
    },
    '&.left': {
        justifyContent: 'flex-start',
    },
}));

export const StyledMondayDialogContent = styled(DialogContent)(() => ({
    margin: 0,
    '&.centered': {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
}));

export const StyledMondayDialogContentText = styled(DialogContentText)(({ theme }) => ({
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
}));

export const StyledMondayDialogOrderedList = styled('ol')(({ theme }) => ({
    counterReset: 'step-counter',
    listStyle: 'none',
    margin: 0,
    paddingInlineStart: '0 !important',
    '& li': {
        counterIncrement: 'step-counter',
        marginBottom: theme.spacing(1),
        '&::before': {
            backgroundColor: theme.palette.text.primary,
            borderRadius: theme.constants.radiusCircle,
            color: theme.palette.primary.contrastText,
            content: 'counter(step-counter)',
            display: 'inline-block',
            fontSize: '14px',
            height: theme.spacing(3),
            lineHeight: '22px',
            marginRight: theme.spacing(1),
            textAlign: 'center',
            width: theme.spacing(3),
        },
    },
}));

export const StyledMondayDialogContentBox = styled(Box)(({ theme }) => ({
    boxShadow: theme.constants.boxShadow,
    padding: theme.spacing(1, 2, 2),
    textAlign: 'left',
    '&.centered': {
        textAlign: 'center',
    },
    '& .MuiSvgIcon-root': {
        height: 32,
        width: 32,
    },
}));

const StyledConnectionIconBox = styled(Box)(({ theme }) => ({
    justifyContent: 'center',
    display: 'flex',
    width: 55,
    height: 55,
    alignItems: 'center',
    fontSize: 40,
    borderRadius: 12,
    boxShadow: theme.constants.boxShadow,
    marginRight: theme.spacing(2),
    position: 'relative',
    '& .MuiSvgIcon-root': {
        height: 32,
        width: 32,
    },
}));

const StyledMondayDialogBadgeContainer = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    '&.centered': {
        display: 'flex',
        justifyContent: 'center',
    },
}));

const StyledMondayDialogBadge = styled('div')<{ color: string }>(({ theme, color }) => ({
    alignItems: 'center',
    backgroundColor: color,
    borderRadius: theme.constants.radiusCircle,
    display: 'flex',
    justifyContent: 'center',
    height: 72,
    width: 72,
    '& .MuiSvgIcon-root': {
        color: theme.palette.getContrastText(color),
        height: 48,
        width: 48,
    },
}));

export const MondayDialogBadgeContainer: React.FC<StyledDialogBadgeContainerProps> = ({
    centered = false,
    color,
    icon,
    ...props
}) => {
    return (
        <StyledMondayDialogBadgeContainer className={centered ? 'centered' : ''} {...props}>
            <StyledMondayDialogBadge color={color}>{icon}</StyledMondayDialogBadge>
        </StyledMondayDialogBadgeContainer>
    );
};

export const MondayDialogTitleLarge: React.FC<{ title: string } & DialogTitleProps> = ({ title, ...props }) => {
    return (
        <StyledMondayDialogTitle className="extra-bold" component="h2" variant="h4" {...props}>
            {title}
        </StyledMondayDialogTitle>
    );
};

export const MondayDialogSubtitle: React.FC<{ title: string } & DialogTitleProps> = ({ title, ...props }) => {
    return (
        <DialogContentText
            fontSize={(theme) => theme.typography.htmlFontSize}
            {...props}
            color={(theme) => theme.palette.text.secondary}
        >
            {title}
        </DialogContentText>
    );
};

export const MondayDialogConnectionNameInput: React.FC<DialogConnectionNameInputProps> = ({
    connectionType,
    value,
    onChangeInputValue,
    ...props
}) => {
    return (
        <Box display="flex" {...props}>
            <StyledConnectionIconBox>{<ProductIcon name={connectionType} />}</StyledConnectionIconBox>
            <TextField required value={value} onChange={(e) => onChangeInputValue(e.target.value)} />
        </Box>
    );
};
