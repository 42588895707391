import {
    getMyOrganizations as getMyOrganizationsRpc,
    createOrganization as createOrganizationRpc,
    deleteOrganization as deleteOrganizationRpc,
} from '@avst-stitch/repository-lib/lib';
import { Response as UserOrganizations } from '@avst-stitch/repository-lib/lib/rpcs/getMyOrganizations';
import {
    Response as CreatedOrganization,
    Request as CreateOrganizationRequest,
} from '@avst-stitch/repository-lib/lib/rpcs/createOrganization';
import {
    Response as CreatedOrganizationV2,
    Request as CreateOrganizationRequestV2,
    createOrganizationV2 as createOrganizationV2Rpc,
} from '@avst-stitch/repository-lib/lib/rpcs/createOrganizationV2';
import { repositoryInvoker } from '../utils/repository';
export type {
    UserOrganizations,
    CreatedOrganization,
    CreateOrganizationRequest,
    CreatedOrganizationV2,
    CreateOrganizationRequestV2,
};

export const getLoggedInUserOrganizations = async (): Promise<UserOrganizations> => {
    return await getMyOrganizationsRpc(repositoryInvoker);
};

export const createOrganization = async (
    createOrganizationProps: CreateOrganizationRequest
): Promise<CreatedOrganization> => {
    return await createOrganizationRpc(repositoryInvoker, createOrganizationProps);
};

export const deleteOrganization = async (uid: string): Promise<void> => {
    return await deleteOrganizationRpc(repositoryInvoker, { uid });
};

export const createOrganizationV2 = async (request: CreateOrganizationRequestV2): Promise<CreatedOrganizationV2> => {
    return await createOrganizationV2Rpc(repositoryInvoker, request);
};
