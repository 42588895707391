import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React from 'react';

export interface MicrosoftConnection {
    clientInfo: ClientInfo;
    name: string;
    open: boolean;
    onClose: () => void;
    saving: boolean;
    currentStage: MicrosoftWizardStage;
    setStage: (stage: MicrosoftWizardStage) => void;
    onSave: (props: ClientInfo) => void;
    errors?: string;
    setError: (error: string) => void;
    clearErrors: () => void;
    callbackUrl: string;
    adminConsentUrl: string;
}

export interface ClientInfo {
    clientId: string;
    clientSecret: string;
    tenantId: string;
}

export type InstanceType = 'FULLY-MANAGED' | 'SELF-MANAGED';

export enum MicrosoftWizardStage {
    CHOOSEINSTANCETYPE,
    CREATE,
    CONFIGURE,
    SECRET,
    SCOPES,
    CONSENT,
    AUTHORIZE,
    SUCCESS,
}

export interface MicrosoftWizardStepsProps {
    readonly stage: MicrosoftWizardStage;
    readonly instanceType: 'FULLY-MANAGED' | 'SELF-MANAGED';
}

export const MicrosoftWizardSteps: React.FC<MicrosoftWizardStepsProps> = ({ stage, instanceType }) => {
    const isFullyManaged = instanceType === 'FULLY-MANAGED';
    return (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={MicrosoftWizardStage.CHOOSEINSTANCETYPE}>
                <StepLabel>Choose instance type</StepLabel>
            </Step>
            <Step
                key={MicrosoftWizardStage.CREATE}
                completed={stage >= MicrosoftWizardStage.CONFIGURE && !isFullyManaged}
                disabled={isFullyManaged}
            >
                <StepLabel>Create Application</StepLabel>
            </Step>
            <Step
                key={MicrosoftWizardStage.CONFIGURE}
                completed={stage >= MicrosoftWizardStage.SECRET && !isFullyManaged}
                disabled={isFullyManaged}
            >
                <StepLabel>Configure Application</StepLabel>
            </Step>
            <Step
                key={MicrosoftWizardStage.SECRET}
                completed={stage >= MicrosoftWizardStage.SCOPES && !isFullyManaged}
                disabled={isFullyManaged}
            >
                <StepLabel>Generate Application Secret</StepLabel>
            </Step>
            <Step
                key={MicrosoftWizardStage.SCOPES}
                completed={stage >= MicrosoftWizardStage.AUTHORIZE && !isFullyManaged}
                disabled={isFullyManaged}
            >
                <StepLabel>Configure Permissions</StepLabel>
            </Step>
            <Step
                key={MicrosoftWizardStage.CONSENT}
                completed={stage === MicrosoftWizardStage.AUTHORIZE && isFullyManaged}
                disabled={instanceType === 'SELF-MANAGED'}
            >
                <StepLabel>Obtain Administrator Consent</StepLabel>
            </Step>
            <Step key={MicrosoftWizardStage.AUTHORIZE}>
                <StepLabel>Authorize</StepLabel>
            </Step>
        </Stepper>
    );
};
