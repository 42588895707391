import { styled, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { UsageWidget } from './usage-widget';
import format from 'format-number';
import { formatBytes } from '../../../utils/formatBytes';

interface OrganizationUsageDetailsProps {
    connectors?: UsageData;
    invocations?: UsageData;
    recordStorage?: UsageData;
}

interface UsageData {
    limit: number;
    consumed: number;
}

interface TitleCollection {
    mainTitle: string;
    counterTitle: string;
    subtitle: string;
}

interface CardTitles {
    connectors?: TitleCollection;
    invocations?: TitleCollection;
    recordStorage?: TitleCollection;
}

const StyledContainer = styled('div')(() => ({
    minHeight: '100%',
}));

const StyledWidgets = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    paddingTop: theme.spacing(3),
    '& > div:not(:last-of-type)': {
        marginRight: theme.spacing(2),
    },
}));

export const OrganizationUsageDetails: React.FC<OrganizationUsageDetailsProps> = ({
    connectors,
    invocations,
    recordStorage,
}) => {
    const theme = useTheme();

    const calculatePercentage = (data?: UsageData): number => {
        return data ? (data.consumed / data.limit) * 100 : 0;
    };

    const getConnectorsPercentage = (): number => {
        return connectors?.limit === -1 ? 0 : calculatePercentage(connectors);
    };

    const getCardTexts = (): CardTitles => {
        const consumedBytes = formatBytes(recordStorage?.consumed ?? 0, 'B');
        const limitBytes = formatBytes(recordStorage?.limit ?? 0);
        return {
            connectors: connectors && {
                subtitle: 'Connectors used',
                counterTitle:
                    connectors.limit === -1
                        ? connectors.consumed.toString()
                        : `${connectors.consumed} of ${connectors.limit}`,
                mainTitle: connectors.limit === -1 ? 'Unlimited Connectors' : `${connectors.limit} Connectors`,
            },
            invocations: invocations && {
                subtitle: 'Invocations',
                counterTitle: `${format()(invocations.consumed)}`,
                mainTitle: `${format()(invocations.limit)} Script Invocations`,
            },
            recordStorage: recordStorage && {
                subtitle: `${consumedBytes.unit}${consumedBytes.value !== 1 ? 's' : ''}`,
                counterTitle: consumedBytes.value.toString(),
                mainTitle: `${limitBytes.value} ${limitBytes.unit} of Record Storage`,
            },
        };
    };

    const cardTexts = getCardTexts();

    return (
        <StyledContainer>
            <Typography>The following resources are included in your current plan.</Typography>
            <StyledWidgets>
                <UsageWidget
                    color={theme.palette.secondary.main}
                    mainTitle={cardTexts.connectors?.mainTitle || ''}
                    counterTitle={cardTexts.connectors?.counterTitle || ''}
                    showViewReport={false}
                    percentage={getConnectorsPercentage()}
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    onClick={() => alert('Feature not yet implemented')}
                    id="1"
                    subtitle={cardTexts.connectors?.subtitle || ''}
                />
                <UsageWidget
                    color={theme.palette.primary.dark}
                    mainTitle={cardTexts.invocations?.mainTitle || ''}
                    counterTitle={cardTexts.invocations?.counterTitle || ''}
                    showViewReport={false}
                    percentage={calculatePercentage(invocations)}
                    onClick={() => alert('Feature not yet implemented')}
                    id="2"
                    subtitle={cardTexts.invocations?.subtitle || ''}
                />
            </StyledWidgets>
        </StyledContainer>
    );
};
