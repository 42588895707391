import { Button, Link, styled } from '@mui/material';
import { PageContainer, PageTitle } from '../../app-main/PageComponents';
import AddIcon from '@mui/icons-material/Add';
import { ApiKeys } from '../../../data/user';

interface ApiKeysProps {
    apiKeys: ApiKeys;
    onCreateNewApiKey(keyName: string): void;
    onDeleteApiKey(uid: string): void;
}

const StyledUpperContainer = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
}));

export const ApiKeysPage: React.FC<ApiKeysProps> = ({ apiKeys, onCreateNewApiKey, onDeleteApiKey }) => {
    const createNew = (): void => {
        const keyName = prompt('API key name:');
        onCreateNewApiKey(keyName ?? 'Default');
    };

    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <StyledUpperContainer>
                <PageTitle title="API Keys" />
                <Button onClick={createNew} startIcon={<AddIcon />}>
                    Create New
                </Button>
            </StyledUpperContainer>
            {/* TODO: add proper styling */}
            <ul>
                {apiKeys.map((apiKey) => (
                    <li key={apiKey.uid}>
                        [{apiKey.createdDate}] {apiKey.keyName} (
                        <Link onClick={() => onDeleteApiKey(apiKey.uid)}>DELETE</Link>)
                    </li>
                ))}
            </ul>
        </PageContainer>
    );
};
