import { useObservableState } from 'observable-hooks';
import { LargeInvocationLogPayload } from '../../../components/reporting/printed-logs/invocation-logs/LargeInvocationLogPayload';
import { selectedInvocationLogId$, selectedInvocationLogContent$ } from '../../../store/invocationlogs';

export const LargeInvocationLogPayloadContainer: React.FC = () => {
    const logId = useObservableState(selectedInvocationLogId$);
    const content = useObservableState(selectedInvocationLogContent$);

    return <LargeInvocationLogPayload logId={logId ?? ''} content={content ?? ''} />;
};
