import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React from 'react';

export enum CreateOrganizationWizardStage {
    BASIC_INFO,
    SELECT_PLAN,
    PAYMENT_METHOD,
    INVITE_MEMBERS,
    BILLING_DETAILS,
}

export interface CreateOrganizationWizardStepsProps {
    readonly stage: CreateOrganizationWizardStage;
    readonly freeTier?: boolean;
    readonly period?: 'monthly' | 'annually';
}

export const CreateOrganizationWizardSteps: React.FC<CreateOrganizationWizardStepsProps> = ({
    stage,
    freeTier = false,
    period = 'monthly',
}) => {
    return (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={CreateOrganizationWizardStage.BASIC_INFO}>
                <StepLabel>Team details</StepLabel>
            </Step>
            <Step key={CreateOrganizationWizardStage.SELECT_PLAN}>
                <StepLabel>Select plan</StepLabel>
            </Step>
            {period === 'annually' && !freeTier && (
                <Step key={CreateOrganizationWizardStage.PAYMENT_METHOD}>
                    <StepLabel>Payment method</StepLabel>
                </Step>
            )}
            <Step key={CreateOrganizationWizardStage.INVITE_MEMBERS}>
                <StepLabel>Invite members</StepLabel>
            </Step>
            {!freeTier && (
                <Step key={CreateOrganizationWizardStage.BILLING_DETAILS}>
                    <StepLabel>Billing information</StepLabel>
                </Step>
            )}
        </Stepper>
    );
};
