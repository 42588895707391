import { LoaderFn } from 'react-location';
import { LocationGenerics } from '../router';
import { getApiKeys } from '../data/user';
import { apiKeys$ } from '../store/user';
import { loadErrorPage } from '../store/error';

export const apiKeysLoader: LoaderFn<LocationGenerics> = async () => {
    try {
        const apiKeys = await getApiKeys();

        apiKeys$.next(apiKeys);
    } catch (e) {
        loadErrorPage({
            error: e,
            pageTitle: 'API Keys',
            genericMessage: 'Failed to load API keys.',
        });

        throw e;
    }

    return {};
};
