import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React from 'react';
import { BitbucketOnPremiseConnection } from './BitbucketOnPremiseAppConfigureDialog';

export enum BitbucketOnPremiseWizardStage {
    ADDURL,
    CREATE,
    DETAILS,
    AUTHORIZE,
    SUCCESS,
}

export interface BitbucketOnPremiseWizardStepsProps {
    readonly stage: BitbucketOnPremiseWizardStage;
}

export const BitbucketOnPremiseWizardSteps: React.FC<BitbucketOnPremiseWizardStepsProps> = ({ stage }) => {
    return (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={BitbucketOnPremiseWizardStage.ADDURL}>
                <StepLabel>Add Bitbucket On-Premise Instance URL</StepLabel>
            </Step>
            <Step key={BitbucketOnPremiseWizardStage.CREATE}>
                <StepLabel>Create Application Link in Bitbucket On-Premise</StepLabel>
            </Step>
            <Step key={BitbucketOnPremiseWizardStage.DETAILS}>
                <StepLabel>Enter New Application Link Details</StepLabel>
            </Step>
            <Step key={BitbucketOnPremiseWizardStage.AUTHORIZE}>
                <StepLabel>Authorize</StepLabel>
            </Step>
        </Stepper>
    );
};

export type StageType = React.FC<
    BitbucketOnPremiseConnection & {
        stage: BitbucketOnPremiseWizardStage;
        setStage: (stage: BitbucketOnPremiseWizardStage) => void;
    }
>;
