import React from 'react';
import { styled } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import { selectorCardStyle } from '../../app-main/SelectorComponents';

interface LanguageSelectorCardProps {
    icon: string;
    title: string;
    isSelected?: boolean;
    value: string;
    checked: boolean;
    onSelect(value: string): void;
}

const StyledCard = styled(Card)(({ theme }) => ({
    ...selectorCardStyle(theme),
    boxShadow: theme.constants.boxShadow,
    height: 346,
    padding: theme.spacing(2, 2, 2.5, 2),
    width: 230,
}));

const CardMediaContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    '& .MuiCardMedia-root': {
        height: 100,
        width: 100,
        borderRadius: theme.constants.borderRadius,
    },
}));
const StyledCheckboxContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    paddingRight: theme.spacing(0.5),
    '& .MuiCheckbox-root': {
        padding: 0,
        width: 18,
        height: 18,
    },
}));
const StyledList = styled(List)(({ theme }) => ({
    color: theme.palette.text.secondary,
    padding: theme.spacing(0.5, 0, 0, 0),
    '& .MuiSvgIcon-root': {
        height: 24,
        width: 24,
    },
    '& .MuiListItem-root': {
        display: 'flex',
        flexDirection: 'flex-start',
        padding: 0,
        letterSpacing: '-0.4px',
        '& .MuiListItemIcon-root': {
            minWidth: 24,
            marginRight: theme.spacing(0.4),
        },
    },
}));

const StyledDisabledIcon = styled(DisabledByDefaultOutlinedIcon)(({ theme }) => ({
    color: theme.palette.error.main,
}));

const StyledCheckedBox = styled(CheckBoxOutlinedIcon)(({ theme }) => ({
    color: theme.palette.primary.dark,
}));

export const LanguageSelectorCard: React.FC<LanguageSelectorCardProps> = ({
    icon,
    title,
    checked,
    onSelect,
    value,
}) => {
    return (
        <>
            <StyledCard
                elevation={1}
                sx={checked ? (theme) => ({ outline: theme.constants.borderSelected }) : { outline: 'none' }}
                onClick={() => onSelect(value)}
            >
                <CardContent>
                    <CardMediaContainer>
                        <CardMedia component="img" image={icon} />
                    </CardMediaContainer>
                    <Typography variant="subtitle2">{title}</Typography>
                    <StyledList>
                        <ListItem>
                            <ListItemIcon>
                                <StyledCheckedBox />
                            </ListItemIcon>
                            <ListItemText>Auto-complete suggestions</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                {value === 'js' ? <StyledDisabledIcon /> : <StyledCheckedBox />}
                            </ListItemIcon>
                            <ListItemText>Import suggestions</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                {value === 'js' ? <StyledDisabledIcon /> : <StyledCheckedBox />}
                            </ListItemIcon>
                            <ListItemText>Syntax checking</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                {value === 'ts-strict' ? <StyledCheckedBox /> : <StyledDisabledIcon />}
                            </ListItemIcon>
                            <ListItemText>Strict mode</ListItemText>
                        </ListItem>
                    </StyledList>
                    <StyledCheckboxContainer>
                        <FormControl>
                            <Checkbox checked={checked} />
                        </FormControl>
                    </StyledCheckboxContainer>
                </CardContent>
            </StyledCard>
        </>
    );
};
