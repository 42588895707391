import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const ServiceNowIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 65 65"
            className="productIcon"
            aria-label="ServiceNow"
        >
            <path
                fill="#81b5a1"
                fillRule="evenodd"
                d="M32.195 3.312A32.267 32.267 0 009.949 58.883a6.346 6.346 0 008.264.43 23.035 23.035 0 0127.445 0 6.364 6.364 0 008.389-.43A32.267 32.267 0 0032.195 3.312m-.18 48.275a15.632 15.632 0 01-16.133-16.026 16.044 16.044 0 1132.07 0 15.614 15.614 0 01-16.026 16.026"
            ></path>
        </SvgIcon>
    );
};
