import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Button } from '../common/buttons/Button';
import { DialogAlert, DialogTitleMain } from '../for-deprecation/dialog/DialogComponents';
import { IconCircle } from '../for-deprecation/IconCircle';

interface ImpersonationPasswordDialogProps {
    errors?: string;
    open: boolean;
    saving?: boolean;
    onCancel(): void;
    onProceed(password: string): void;
}

export const ImpersonationPasswordDialog: React.FC<ImpersonationPasswordDialogProps> = ({
    errors,
    open,
    saving,
    onProceed,
    onCancel,
}) => {
    const [password, setPassword] = useState('');

    useEffect(() => {
        setPassword('');
    }, [open]);

    return (
        <Dialog
            open={open}
            onKeyDown={(event) => {
                if (event.key === 'Enter' && password && !saving) {
                    onProceed(password);
                }
            }}
        >
            <DialogTitleMain title={`What's the magic word?`} icon={<IconCircle icon={<LockOutlinedIcon />} />} />
            {errors && <DialogAlert severity="error" alertTitle="Error" text={errors} />}
            <DialogContent>
                <TextField
                    variant="outlined"
                    label="Password"
                    placeholder="Enter password"
                    required
                    onChange={(event) => setPassword(event.target.value)}
                    value={password}
                    type="password"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onCancel()} variant="outlined">
                    Cancel
                </Button>
                <Button
                    busy={saving}
                    disabled={!password || saving}
                    variant="contained"
                    onClick={() => onProceed(password)}
                >
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};
