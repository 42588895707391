import { useObservableState } from 'observable-hooks';
import { HttpLogs } from '../../../components/reporting/printed-logs/HttpLogs';
import { selectedHttpLogsInvocationId$, selectedHttpLogs$, selectedHttpLogsUrl$ } from '../../../store/httpLogs';

export const HttpLogsContainer: React.FC = () => {
    const invocationId = useObservableState(selectedHttpLogsInvocationId$);
    const httpLogsUrl = useObservableState(selectedHttpLogsUrl$);
    const httpLogs = useObservableState(selectedHttpLogs$);

    return <HttpLogs invocationId={invocationId ?? ''} logs={httpLogs} logsUrl={httpLogsUrl} />;
};
