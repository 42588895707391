import { useObservableState } from 'observable-hooks';
import { PackageUpgradeDialog } from '../../components/workspace-dialogs/PackageUpgradesDialog';
import {
    packageUpgradesDialogErrors$,
    packageUpgradesDialogOpen$,
    packageUpgradesDialogPackages$,
    packageUpgradesDialogSaving$,
    upgradeWorkspacePackagesAction$,
    workspacesSnoozingPackageUpgrades$,
} from '../../store/workspace/packages';
import { segmentAnalyticsTrack } from '../../data/segment-analytics';
import { loggedInUserDetails$ } from '../../store/user';
import { selectedWorkspace$, selectedWorkspaceEnvironment$, selectedWorkspaceUid$ } from '../../store/workspace';
import { currentTypeDeclarationsLoaded$, totalTypeDeclarationsLoaded$ } from '../../store/editor/editor';

export const PackageUpgradesDialogContainer: React.FC = () => {
    const open = useObservableState(packageUpgradesDialogOpen$);
    const saving = useObservableState(packageUpgradesDialogSaving$);
    const errors = useObservableState(packageUpgradesDialogErrors$);
    const packages = useObservableState(packageUpgradesDialogPackages$);
    const workspaceUid = useObservableState(selectedWorkspaceUid$);
    const loggedInUserDetails = useObservableState(loggedInUserDetails$);
    const selectedEnvironment = useObservableState(selectedWorkspaceEnvironment$);
    const workspacesSnoozingPackageUpgrades = useObservableState(workspacesSnoozingPackageUpgrades$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const currentTypeDeclarationsLoaded = useObservableState(currentTypeDeclarationsLoaded$);
    const totalTypeDeclarationsLoaded = useObservableState(totalTypeDeclarationsLoaded$);

    const displayDialog =
        !!workspaceUid && !workspacesSnoozingPackageUpgrades.includes(workspaceUid) && !selectedWorkspace?.locked;
    const loadingTypesMessage =
        currentTypeDeclarationsLoaded > 0
            ? `Loading type declarations: ${Math.round(
                  totalTypeDeclarationsLoaded > 0
                      ? (currentTypeDeclarationsLoaded * 100) / totalTypeDeclarationsLoaded
                      : 0
              )}% (${currentTypeDeclarationsLoaded}/${totalTypeDeclarationsLoaded})`
            : undefined;

    return (
        <PackageUpgradeDialog
            deployedEnvironmentMode={!!selectedEnvironment?.deployment}
            packages={packages}
            errors={errors}
            open={displayDialog && open}
            saving={saving}
            loadingTypeDeclarations={totalTypeDeclarationsLoaded !== 0}
            loadingTypesMessage={loadingTypesMessage}
            onApplyChanges={(event) => {
                upgradeWorkspacePackagesAction$.next({
                    changelogClickCount: event.changelogClickCount,
                    packages: event.packages,
                    workspaceUid: workspaceUid ?? '',
                });
            }}
            onCancel={(clicks) => {
                if (!workspacesSnoozingPackageUpgrades.includes(workspaceUid ?? '')) {
                    workspacesSnoozingPackageUpgrades$.next([
                        ...(workspacesSnoozingPackageUpgrades ?? []),
                        workspaceUid ?? '',
                    ]);
                }
                packageUpgradesDialogOpen$.next(false);
                segmentAnalyticsTrack('Package Upgrades Modal Closed', {
                    userId: loggedInUserDetails?.uid,
                    stitchTeamMember: loggedInUserDetails?.stitchTeamMember,
                    userOrigin: loggedInUserDetails?.userOrigin,
                    upgradesNeeded: packages.length,
                    upgradesCompleted: 0,
                    uniqueChangelogClicks: clicks,
                });
            }}
        />
    );
};
