import { getUserActivationReport as getUserActivationReportRpc } from '@avst-stitch/repository-lib/lib';
import {
    Request as UserActivationRequest,
    Response as Users,
} from '@avst-stitch/repository-lib/lib/rpcs/report/getUserActivation';
import { repositoryInvoker } from '../../utils/repository';
export type { UserActivationRequest, Users };

export const getUserActivation = async (request: UserActivationRequest): Promise<Users> => {
    return await getUserActivationReportRpc(repositoryInvoker, request);
};
