import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';
import { useTheme } from '@mui/material';

export const TempoIcon: React.FC<SvgIconProps> = (props) => {
    const theme = useTheme();
    const themeMode = theme.palette.mode;

    return (
        <SvgIcon
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            style={{ isolation: 'isolate' }}
            viewBox="0 0 520 520"
            className="productIcon"
            aria-label="Tempo"
        >
            <defs>
                <clipPath id="_clipPath_tNH2KStNwfOjpDatqHhjW6jUGceg7Cf2T">
                    <path d="M0 0H520V520H0z"></path>
                </clipPath>
            </defs>
            <g clipPath="url(#_clipPath_tNH2KStNwfOjpDatqHhjW6jUGceg7Cf2T)">
                <path
                    fill="#F89B46"
                    d="M516 260.863C516 402.324 401.473 517 260.196 517c-3.551 0-7.085-.072-10.601-.216L225 474.12a217.186 217.186 0 0035.196 2.859c119.202 0 215.835-96.759 215.835-216.116 0-18.15-2.235-35.778-6.444-52.623L496.249 162C508.972 192.42 516 225.82 516 260.863z"
                ></path>
                <path
                    fill="#00C3F2"
                    d="M43.992 260.91c0 99.735 67.677 183.671 159.634 208.435L230 515C102.755 500.176 4 392.07 4 260.91 4 210.973 18.315 164.377 43.068 125h49.099c-30.124 37.107-48.175 84.402-48.175 135.91z"
                ></path>
                <path
                    fill="#00B47E"
                    d="M260.007 5C359.418 5 445.595 61.519 488 144.099L463.167 187c-30.079-82.88-109.686-142.103-203.16-142.103-60.018 0-114.318 24.415-153.462 63.832H54C100.626 45.804 175.539 5 260.007 5z"
                ></path>
                <path
                    fill={themeMode === 'light' ? '#004976' : theme.palette.text.primary}
                    d="M224.169 359.83l-84.995-84.711a3.984 3.984 0 010-5.648l39.664-39.532a4.017 4.017 0 015.666 0l42.498 42.356L337.496 162.17a4.015 4.015 0 015.666 0l39.664 39.532a3.983 3.983 0 010 5.647L229.835 359.83a4.015 4.015 0 01-5.666 0z"
                ></path>
            </g>
        </SvgIcon>
    );
};
