import { ReactNode } from 'react';
import { styled } from '@mui/material';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import { AvatarContainer } from '../../common/Avatars';
import { ProductIcon } from '../../icons/ProductIcon';
import {
    SelectorItemDescription,
    selectorCardIconWrapStyle,
    selectorCardStyle,
    SelectorItemTitle,
    selectorCardUserAvatarsStyle,
    SelectorImageBox,
    StyledStatus,
} from '../../app-main/SelectorComponents';
import { ThreeDotsButton } from '../../common/buttons/ThreeDotsButton';
import { handleDisplayName } from '../../../utils/displayName';

interface WorkspaceSelectorCardProps {
    description?: string;
    environments: {
        deployment?: {
            uid: string;
            version: string;
        };
        name: string;
        selected: boolean;
        uid: string;
    }[];
    incoming?: string[];
    orgName?: string; // Don't show in the card if it's not defined
    outgoing?: string[];
    shared?: boolean;
    // status?: 'ACTIVE' | 'INACTIVE';
    template: boolean;
    title: string;
    uid: string;
    users: {
        avatar?: string;
        email: string;
        firstName?: string;
        lastName?: string;
    }[];
    onDelete(uid: string): void;
    onDuplicate(uid: string): void;
    onSelect(uid: string): void;
}

// card container
const StyledCard = styled(Card)(({ theme }) => ({
    ...selectorCardStyle(theme),
    height: 210,
    padding: theme.spacing(1, 2, 1),
    width: 380,
}));

// card upper - user avatars, status
const StyledCardUpper = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    height: 46,
    padding: theme.spacing(0, 2.5, 2, 0),
}));

export const StyledUserAvatars = styled('div')(({ theme }) => ({
    ...selectorCardUserAvatarsStyle(theme),
    paddingRight: theme.spacing(1),
}));

// card middle - connection icons
const StyledMediaContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    border: `1px dashed ${theme.palette.action.focus}`,
    borderRadius: theme.constants.borderRadius,
    height: 64,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
}));

const StyledLabel = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    color: theme.palette.text.primary,
    cursor: 'inherit',
    height: '100%',
    marginRight: theme.spacing(2),
    '& .MuiSvgIcon-root': {
        color: theme.palette.secondary.main,
        height: 14,
        margin: theme.spacing(0, 0.5, 0, 0),
        width: 14,
    },
}));

const StyledRow = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    height: 22,
    '&:not(:first-of-type)': {
        marginTop: theme.spacing(0.5),
    },
}));

// card lower - title, description, environments
const StyledCardContent = styled(CardContent)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: 74,
    justifyContent: 'space-between',
    padding: theme.spacing(0.5, 0, 0) + ' !important',
}));

const StyledFooter = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
}));

const StyledEnvironmentsContainer = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    '& .MuiSvgIcon-root': {
        color: theme.palette.text.secondary,
        height: 16,
        width: 16,
    },
}));

const StyledEnvironmentsNumber = styled(Badge)(({ theme }) => ({
    marginRight: theme.spacing(2),
}));

const StyledOrgInformation = styled('div')(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    ...theme.typography.overflowLine,
    marginRight: theme.spacing(1),
    width: 245,
    '& .MuiSvgIcon-root': {
        color: theme.palette.secondary.main,
        height: 14,
        marginRight: theme.spacing(0.5),
        width: 14,
    },
}));

const StyledIconContainer = styled('div')(({ theme }) => ({
    ...selectorCardIconWrapStyle(theme),
    '& .MuiSvgIcon-root': {
        height: 20,
        width: 20,
    },
}));

export const WorkspaceSelectorCard: React.FC<WorkspaceSelectorCardProps> = ({
    description,
    environments,
    incoming = [],
    orgName,
    outgoing = [],
    shared = false,
    // status = 'INACTIVE',
    template,
    title,
    uid,
    users,
    onDelete,
    onDuplicate,
    onSelect,
}) => {
    const ENVIRONMENTS_TOOLTIP = environments
        .map((environment) => {
            return `${environment.name}${environment.deployment ? ' - v' + environment.deployment?.version : ''}`;
        })
        .join(', ');

    const iconList = (connections: string[]): ReactNode[] => {
        return connections.map((connection, index) => {
            return (
                <Tooltip key={index} title={connection} describeChild>
                    <StyledIconContainer key={index}>
                        <ProductIcon name={connection} />
                    </StyledIconContainer>
                </Tooltip>
            );
        });
    };
    const MediaContainer: React.FC = () => {
        return (
            <>
                <StyledMediaContainer>
                    <StyledRow>
                        <StyledLabel>
                            <DownloadOutlinedIcon />
                            <Typography variant="body2" color="text.secondary">
                                Incoming
                            </Typography>
                        </StyledLabel>
                        {iconList(incoming)}
                    </StyledRow>
                    <StyledRow>
                        <StyledLabel>
                            <UploadOutlinedIcon />
                            <Typography variant="body2" color="text.secondary">
                                Outgoing
                            </Typography>
                        </StyledLabel>
                        {iconList(outgoing)}
                    </StyledRow>
                </StyledMediaContainer>
            </>
        );
    };

    return (
        <>
            <StyledCard elevation={1} onClick={() => onSelect(uid)}>
                <ThreeDotsButton
                    uid={uid}
                    actions={[
                        { name: 'Duplicate', onSelect: onDuplicate, icon: <CopyAllOutlinedIcon /> },
                        { name: 'Delete', onSelect: onDelete, icon: <DeleteOutlineIcon /> },
                    ]}
                />
                <StyledCardUpper>
                    <StyledUserAvatars>
                        {users.map((user, index) => {
                            return user.avatar ? (
                                <Tooltip title={handleDisplayName(user.email, user.firstName, user.lastName)}>
                                    <SelectorImageBox key={'userAvatar' + index}>
                                        <img
                                            src={`data:image/jpeg;base64,${user.avatar}`}
                                            width={30}
                                            height={30}
                                            alt={user.firstName + ' ' + user.lastName}
                                        />
                                    </SelectorImageBox>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    key={index}
                                    title={handleDisplayName(user.email, user.firstName, user.lastName)}
                                >
                                    <div>
                                        <AvatarContainer
                                            key={'userAvatar' + index}
                                            credentials={{
                                                firstName: user.firstName ?? '',
                                                lastName: user.lastName ?? '',
                                                email: user.email ?? '',
                                            }}
                                            size="small"
                                        />
                                    </div>
                                </Tooltip>
                            );
                        })}
                    </StyledUserAvatars>
                    <Box display="flex">
                        {shared && (
                            <Typography color="success.dark" variant="body2" marginRight={1}>
                                Shared
                            </Typography>
                        )}
                        {/* <StyledStatus
                        label={status === 'ACTIVE' ? 'Active' : 'Inactive'}
                        sx={{
                            backgroundColor: status === 'ACTIVE' ? 'success.main' : 'action.disabledBackground',
                            color: status === 'ACTIVE' ? 'primary.contrastText' : 'text.primary',
                        }}
                        /> */}
                        {template && (
                            <StyledStatus
                                label="Template"
                                sx={{ backgroundColor: 'warning.light', color: 'warning.contrastText' }}
                            />
                        )}
                    </Box>
                </StyledCardUpper>
                <CardMedia component={MediaContainer} />
                <StyledCardContent>
                    <SelectorItemTitle title={title} />
                    <SelectorItemDescription description={description} />
                    <StyledFooter>
                        <Tooltip title={ENVIRONMENTS_TOOLTIP} placement="top-end">
                            <StyledEnvironmentsContainer>
                                <StyledEnvironmentsNumber badgeContent={environments.length} color="primary" />
                                <Typography color="text.secondary" variant="body2">
                                    {environments.length === 1 ? 'Environment' : 'Environments'}
                                </Typography>
                            </StyledEnvironmentsContainer>
                        </Tooltip>
                        {orgName && (
                            <Tooltip title={orgName}>
                                <StyledOrgInformation>
                                    <HubOutlinedIcon />
                                    <Typography color="text.secondary" variant="body2" noWrap>
                                        {orgName}
                                    </Typography>
                                </StyledOrgInformation>
                            </Tooltip>
                        )}
                    </StyledFooter>
                </StyledCardContent>
            </StyledCard>
        </>
    );
};
