import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React from 'react';
import { NetSuiteConnection } from './NetSuiteAppConfigureDialog';

export enum NetSuiteWizardStage {
    ADDURL,
    CREATE,
    DETAILS,
    AUTHORIZE,
    SUCCESS,
}

export interface NetSuiteWizardStepsProps {
    readonly stage: NetSuiteWizardStage;
}

export const NetSuiteWizardSteps: React.FC<NetSuiteWizardStepsProps> = ({ stage }) => {
    return (
        <Stepper alternativeLabel activeStep={stage}>
            <Step key={NetSuiteWizardStage.ADDURL}>
                <StepLabel>Add NetSuite account ID</StepLabel>
            </Step>
            <Step key={NetSuiteWizardStage.CREATE}>
                <StepLabel>Create Integration Application in NetSuite</StepLabel>
            </Step>
            <Step key={NetSuiteWizardStage.DETAILS}>
                <StepLabel>Create Client Credentials mapping</StepLabel>
            </Step>
            <Step key={NetSuiteWizardStage.AUTHORIZE}>
                <StepLabel>Authorize</StepLabel>
            </Step>
        </Stepper>
    );
};

export type StageType = React.FC<
    NetSuiteConnection & {
        stage: NetSuiteWizardStage;
        setStage: (stage: NetSuiteWizardStage) => void;
    }
>;
