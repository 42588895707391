import { useObservableState } from 'observable-hooks';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-location';
import { OrganizationDetails } from '../../components/organizations/details';
import { selectedOrganizationViewDetails$, selectedOrganizationUid$ } from '../../store/organization';

export type OrganizationTab = 'usage' | 'details' | 'members' | 'plan' | 'billing';

export const OrganizationContainer: React.FC = () => {
    const navigate = useNavigate();
    const navigateTo = (path: string): void => navigate?.({ to: path });
    const location = useLocation();

    const details = useObservableState(selectedOrganizationViewDetails$);
    const orgUid = useObservableState(selectedOrganizationUid$) || '';

    const [selectedTab, setSelectedTab] = useState<OrganizationTab>('usage');

    const handleSelectTab = (tab: OrganizationTab): void => {
        navigateTo(`./${tab}`);
    };

    const getSubroute = (): OrganizationTab => {
        const subroutes = location.current.pathname.split('/');
        const orgUidIndex = subroutes.indexOf(orgUid);
        const subroute = subroutes[orgUidIndex + 1];
        return subroute as OrganizationTab;
    };

    useEffect(() => {
        if (location.current.pathname.includes(`team/${orgUid}`)) {
            const subroute = getSubroute();
            if (!subroute) {
                setSelectedTab('usage');
            } else {
                setSelectedTab(subroute);
            }
        }
    });

    return (
        <OrganizationDetails
            avatar=""
            canManageBillingDetails={!!details?.canManageBillingDetails}
            canManagePlans={!!details?.canManagePlans}
            name={details?.name ?? ''}
            selectedTab={selectedTab}
            onSelectTab={handleSelectTab}
        />
    );
};
