import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';
import React from 'react';

const Centered = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
}));

export const CenteredLoadingSpinner: React.FC = () => {
    return (
        <Centered>
            <CircularProgress />
        </Centered>
    );
};
