import { getInvocationDataUrl as getInvocationDataUrlRpc } from '@avst-stitch/repository-lib/lib';
import {
    Response as InvocationLogsResponse,
    Request as InvocationLogsRequest,
    InvocationLogEntry as InvocationLog,
    InvocationLogs,
} from '@avst-stitch/repository-lib/lib/rpcs/getInvocationDataUrl';
import { repositoryInvoker } from '../utils/repository';
export type { InvocationLogsResponse, InvocationLog, InvocationLogs };

export const getInvocationDataUrl = async (request: InvocationLogsRequest): Promise<InvocationLogsResponse> => {
    return await getInvocationDataUrlRpc(repositoryInvoker, request);
};
